<ion-content drKeyboard>
  <dr-auth-wrapper>
    <div
      #formContainer
      class="form-container animate__animated animate__faster"
      [class]="activeFormAnimation()"
    >
      @switch (activeForm()) {
        @case ('signIn') {
          <dr-sign-in
            (signUp)="goToSignUp()"
            (forgotPassword)="goToForgotPassword()"
          ></dr-sign-in>
        }
        @case ('forgotPassword') {
          <dr-forgot-password
            (signIn)="goToSignIn()"
            (submitted)="goToForgotPasswordVerificationSuccess()"
          ></dr-forgot-password>
        }
        @case ('forgotPasswordVerification') {
          <dr-code-verification
            [backLinkLabel]="'Forgot password' | translate"
            (back)="goToForgotPassword()"
            (verified)="goToResetPassword()"
            type="forgotPassword"
          ></dr-code-verification>
        }
        @case ('resetPassword') {
          <dr-reset-password
            (submitted)="goToResetPasswordSuccess()"
            (signIn)="goToSignIn()"
          ></dr-reset-password>
        }
        @case ('signUpSuccess') {
          <dr-auth-success
            [message]="'auth.email_verified' | translate"
          ></dr-auth-success>
        }
        @case ('resetPasswordSuccess') {
          <dr-auth-success
            [message]="'auth.password_reset_success' | translate"
          ></dr-auth-success>
        }
        @case ('signUp') {
          <dr-sign-up
            (submitted)="goToSignUpVerification()"
            (signIn)="goToSignIn()"
            (openPrivacyPolicy)="visibleDocument = 'privacy-policy'"
            (openTermsConditions)="visibleDocument = 'terms-conditions'"
          ></dr-sign-up>
        }
        @case ('signUpVerification') {
          <dr-code-verification
            (verified)="goToSignUpSuccess()"
            (back)="goToSignUp()"
            [backLinkLabel]="'Sign up' | translate"
            type="signUp"
          ></dr-code-verification>
        }
      }
    </div>
  </dr-auth-wrapper>
</ion-content>

<ion-modal #modal [isOpen]="!!visibleDocument">
  <ng-template>
    <ion-header collapse="fade">
      <ion-toolbar>
        <ion-title></ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="visibleDocument = undefined; modal.dismiss()">
            <ion-icon name="close-outline" size="large"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      @switch (visibleDocument) {
        @case ('privacy-policy') {
          <dr-privacy-policy></dr-privacy-policy>
        }
        @case ('terms-conditions') {
          <dr-terms-conditions></dr-terms-conditions>
        }
      }
    </ion-content>
  </ng-template>
</ion-modal>

<dr-loading [show]="!!(authState.loading$ | async)"></dr-loading>
