import { Component, Input } from '@angular/core';
import { ButtonComponent, SuccessComponent, TextComponent } from '@dr/ui';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dr-auth-success',
  templateUrl: 'auth-success.component.html',
  styleUrls: ['../../auth.scss', './auth-success.component.scss'],
  imports: [
    TextComponent,
    TranslateModule,
    ButtonComponent,
    IonicModule,
    SuccessComponent,
  ],
  standalone: true,
})
export class AuthSuccessComponent {
  @Input() message?: string;

  constructor(private navController: NavController) {}

  continue(): void {
    this.navController.navigateRoot('/', {
      animated: false,
    });
  }
}
