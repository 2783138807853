@if (!company.as) {
  <dr-empty
    [message]="'company.my_companies.no_active_subscription' | translate"
  ></dr-empty>
}

@if (company.as; as subscription) {
  <div class="header">
    <dr-text class="title">
      <span>{{
        'company.my_companies.current_subscription_title' | translate
      }}</span></dr-text
    >

    @if (includeSubscriptionActions) {
      <dr-link
        (click)="
          IsWebVersion() ? popover.present($event) : actionSheet.present()
        "
      >
        <span>
          {{ 'company.my_companies.subscription_actions' | translate }}
        </span>
      </dr-link>
    }
  </div>

  <div class="subscription-card">
    <div class="card">
      <div class="card-title">
        <div class="logo-wrapper"><dr-logo></dr-logo></div>

        <div class="card-title-text">
          <dr-text>
            <span class="card-title-top-text">{{
              subscription.sb.t | translate
            }}</span>
          </dr-text>
          <dr-text color="medium">
            <span>{{
              (subscription.pt === 'monthly'
                ? 'company.my_companies.monthly_plan'
                : 'company.my_companies.annually_plan'
              ) | translate
            }}</span>
          </dr-text>
        </div>

        <div class="card-title-text price-container">
          <dr-text color="primary" class="price">
            <span class="card-title-top-text">${{ formatPrice() }}</span>
          </dr-text>
          <dr-text color="medium">
            <span>{{ 'company.my_companies.per_month' | translate }}</span>
          </dr-text>
        </div>
      </div>

      <div class="renewal-container">
        <dr-text color="medium">
          <span>{{ 'company.my_companies.next_renewal' | translate }}:</span>
        </dr-text>

        <dr-text>
          <span>-</span>
        </dr-text>
      </div>

      @if (includeSubscriptionActions) {
        <dr-banner
          [title]="'company.my_companies.need_more_features' | translate"
          [subtitle]="'company.my_companies.choose_new_plan' | translate"
          [actionTitle]="'company.my_companies.upgrade_plan' | translate"
          (action)="changeSubscription()"
        ></dr-banner>
      }
    </div>
  </div>

  <div class="header">
    <dr-text class="title">
      <span>{{
        'company.my_companies.payment_history' | translate
      }}</span></dr-text
    >
  </div>

  <ion-content>
    <div class="card">
      <div class="payment-item">
        <ion-icon name="checkmark-done-outline" color="success"></ion-icon>

        <div>
          <div class="payment-item-text">
            <dr-text class="payment-item-title">
              <span>{{ subscription.sb.t | translate }}</span>
            </dr-text>
            <dr-text class="payment-item-subtitle" color="medium">
              <span>{{ subscription.cAt | localizedDate }}</span>
            </dr-text>
          </div>
        </div>

        <dr-text class="price" color="primary">${{ formatPrice() }}</dr-text>
      </div>
    </div>
  </ion-content>
}

<ion-action-sheet #actionSheet [buttons]="actionButtons"></ion-action-sheet>

<ion-popover #popover side="left" alignment="top" arrow="false">
  <ng-template>
    <ion-content>
      @for (action of actionButtons; track $index) {
        @if (!action.role) {
          <div
            class="action-item"
            [ngClass]="action.cssClass"
            (click)="action?.handler(); popover.dismiss()"
          >
            {{ action.text }}
          </div>
        }
      }
    </ion-content>
  </ng-template>
</ion-popover>

<ion-modal
  #cancelSubscriptionModal
  (didDismiss)="cancelReasonControl.patchValue('')"
>
  <ng-template>
    <ion-header collapse="fade">
      <ion-toolbar>
        <ion-title></ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="cancelSubscriptionModal.dismiss()">
            <ion-icon name="close-outline" size="large"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="cancel-container">
        <dr-text class="cancel-title" [centered]="true">{{
          'company.cancel_company_subscription.select_subscription_cancel_reason'
            | translate
        }}</dr-text>

        <dr-radio
          [fControl]="cancelReasonControl"
          [options]="cancelRequestReasons"
        ></dr-radio>

        <div class="cancel-actions">
          <dr-button
            [label]="'company.cancel_company_subscription.continue' | translate"
            fill="clear"
            color="medium"
            [disabled]="!cancelReasonControl.value"
            (click)="cancelSubscriptionModal.dismiss(); cancelSubscription()"
          ></dr-button>
          <dr-button
            (click)="cancelSubscriptionModal.dismiss()"
            [label]="'company.cancel_company_subscription.cancel' | translate"
          ></dr-button>
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
