import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { IonicModule, IonPopover } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TextComponent } from '../../text/text.component';

@Component({
  selector: 'dr-premium-mark',
  templateUrl: 'premium-mark.component.html',
  styleUrls: ['premium-mark.component.scss'],
  standalone: true,
  imports: [IonicModule, TextComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PremiumMarkComponent {
  @ViewChild(IonPopover) ionPopover!: IonPopover;

  @Input() premium?: boolean;

  isPopoverOpened = false;

  click(event: Event) {
    if (!this.premium) {
      return;
    }

    this.ionPopover.event = event;
    this.isPopoverOpened = true;
  }
}
