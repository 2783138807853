export const generateFormData = <T extends object>(
  data: T,
  fileKeys: (keyof T)[]
) => {
  const formData = new FormData();

  Object.keys(data)
    .filter((key) => !!data[key as keyof T])
    .forEach((key) => {
      if (fileKeys.includes(key as keyof T) && data[key as keyof T]) {
        formData.append(
          key,
          data[key as keyof T] as Blob,
          (data[key as keyof T] as File).name
        );
      } else {
        const value = data[key as keyof T];

        if (Array.isArray(value)) {
          value.forEach((v) => {
            formData.append(`${key}[]`, convertToFormDataValue(v));
          });
        } else {
          formData.append(key, convertToFormDataValue(value));
        }
      }
    });

  return formData;
};

const convertToFormDataValue = (value: unknown): string => {
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  return value as string;
};
