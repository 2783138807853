import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent, TextComponent } from '@dr/ui';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dr-company-survey-intro',
  templateUrl: 'company-survey-intro.component.html',
  styleUrls: ['company-survey-intro.component.scss'],
  imports: [IonicModule, TextComponent, TranslateModule, ButtonComponent],
  standalone: true,
})
export class CompanySurveyIntroComponent {
  @Output() started: EventEmitter<void> = new EventEmitter<void>();
}
