export const ThemeActionsName = 'Theme';

export type AppTheme = 'system' | 'dark' | 'light';

export namespace ThemeActions {
  export class SetAppTheme {
    static readonly type = `[${ThemeActionsName}] Set App Theme`;

    constructor(public appTheme: AppTheme) {}
  }
}
