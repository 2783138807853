import { AsyncPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { LoadingComponent } from '@dr/ui';
import { CurrentUserState } from '@dr/user';
import { IonicModule } from '@ionic/angular';
import { map } from 'rxjs';
import { ContactFormModule } from '../../contact-form.module';
import { ContactFormState } from '../../states/contact-form/contact-form.state';
import { ContactFormMessageComponent } from '../contact-form-message/contact-form-message.component';

@Component({
  selector: 'dr-contact-form-messages',
  templateUrl: 'contact-form-messages.component.html',
  styleUrls: ['contact-form-messages.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    ContactFormMessageComponent,
    ContactFormModule,
    AsyncPipe,
    LoadingComponent,
  ],
})
export class ContactFormMessagesComponent implements OnInit {
  @Input({ required: true }) id!: string;

  @Output() messagesLoaded = new EventEmitter<void>();

  messages$ = this.contactFormState.topicMessages$.pipe(
    map((topicMessages) => topicMessages[this.id])
  );

  constructor(
    public contactFormState: ContactFormState,
    public currentUserState: CurrentUserState,
    public elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.loadMessages();
  }

  loadMessages(): void {
    this.contactFormState
      .getTopicMessagesList$(this.id)
      .subscribe(() => setTimeout(() => this.messagesLoaded.emit(), 20));
  }
}
