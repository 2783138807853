@if (loaded()) {
  @switch (connectionStatus()) {
    @case ('requested') {
      @if (
        (requestedUserId() && requestedUserId() === currentUserId()) ||
        (currentCompanyId() && currentCompanyId() === requestedCompanyId())
      ) {
        <dr-button
          (click)="cancelRequest.emit()"
          [label]="'connections.connect_sent' | translate"
          color="warning"
          size="small"
        ></dr-button>
      } @else {
        <dr-button
          (click)="approveConnection.emit()"
          [label]="'connections.approve_connect' | translate"
          color="success"
          size="small"
        ></dr-button>
      }
    }
    @case ('connected') {
      <!--<dr-button
      (click)="removeRequest.emit(connection)"
      [label]="'connections.remove_connect' | translate"
      color="danger"
      size="small"
    ></dr-button>-->
      <dr-button
        [label]="'connections.connected' | translate"
        color="medium"
        size="small"
      ></dr-button>
    }
    @default {
      <dr-button
        (click)="requestConnection.emit()"
        [label]="'connections.send_connect' | translate"
        color="primary"
        size="small"
      ></dr-button>
    }
  }
} @else {
  <dr-skeleton [repeat]="1" type="small-button"></dr-skeleton>
}
