import { SubscriptionPeriod } from '../interfaces/company';
import { UpdateCompanyInfoData } from '../services/company-api.service';

export const MyCompaniesActionsName = 'MyCompanies';

export class SetActiveCompanyAction {
  static readonly type = `[${MyCompaniesActionsName}] Set Active Company`;

  constructor(public id: string) {}
}

export class CheckActiveCompanyAction {
  static readonly type = `[${MyCompaniesActionsName}] Check Active Company`;
}

export class UpdateActiveCompanyInfoAction {
  static readonly type = `[${MyCompaniesActionsName}] Update Active Company Info`;

  constructor(public data: UpdateCompanyInfoData) {}
}

export class CancelUpdateActiveCompanyInfoAction {
  static readonly type = `[${MyCompaniesActionsName}] Cancel Update Active Company Info`;
}

export class ResetActiveCompanyAction {
  static readonly type = `[${MyCompaniesActionsName}] Reset Active Company`;
}

export class SubscribeAction {
  static readonly type = `${MyCompaniesActionsName} Subscribe`;

  constructor(
    public subscriptionId: string,
    public period: SubscriptionPeriod
  ) {}
}
