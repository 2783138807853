import { AsyncPipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { FeaturesModule } from '@dr/features';
import {
  ButtonComponent,
  InputComponent,
  LoadingComponent,
  TextComponent,
} from '@dr/ui';
import { KeyboardDirective, ValidationService } from '@dr/utils';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AuthState } from '../../states/auth/auth.state';

@Component({
  selector: 'dr-auth',
  templateUrl: 'admin-auth.component.html',
  styleUrls: ['../../auth.scss', 'admin-auth.component.scss'],
  imports: [
    IonicModule,
    FeaturesModule,
    TextComponent,
    TranslateModule,
    KeyboardDirective,
    LoadingComponent,
    AsyncPipe,
    InputComponent,
    ButtonComponent,
  ],
  standalone: true,
})
export class AdminAuthComponent implements OnDestroy {
  @ViewChild('email') email?: InputComponent<string>;
  @ViewChild('password') password?: InputComponent<string>;

  emailControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.emailValidators
  );
  passwordControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.passwordValidators
  );

  private destroyRef = inject(DestroyRef);

  constructor(
    public validationService: ValidationService,
    public authState: AuthState,
    private navController: NavController
  ) {}

  ngOnDestroy() {
    this.authState.resetSignInData();
  }

  signIn(): void {
    this.validateFormElements();

    if (this.emailControl.invalid || this.passwordControl.invalid) {
      return;
    }

    this.authState
      .signInWithCredentials$({
        email: this.emailControl.value as string,
        password: this.passwordControl.value as string,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        setTimeout(() => {
          this.navController.navigateRoot('/', {
            animated: false,
          });
        });
      });
  }

  private validateFormElements(): void {
    this.email?.validate();
    this.password?.validate();
  }
}
