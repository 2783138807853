import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ShortNumberPipe } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TextComponent } from '../text/text.component';

export interface SegmentItem<T> {
  label: string;
  type: T;
  badge$?: Observable<number>;
  badgeColor?: 'success' | 'warning' | 'danger';
}

@Component({
  selector: 'dr-segment',
  templateUrl: 'segment.component.html',
  styleUrls: ['segment.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TextComponent, IonicModule, TranslateModule, AsyncPipe, ShortNumberPipe],
})
export class SegmentComponent<T> {
  @Input({ required: true }) value!: T;
  @Input({ required: true }) items!: SegmentItem<T>[];

  @Output() changeType = new EventEmitter<T>();
}
