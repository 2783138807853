import {
  ContactFormStatus,
  NewTopicData,
  NewTopicMessage,
} from '../../interfaces/contact-form';

export const ContactFormActionsName = 'ContactForm';

export class CreateTopicAction {
  static readonly type = `[${ContactFormActionsName}] Create Topic`;

  constructor(public data: NewTopicData) {}
}

export class GetTopicListAction {
  static readonly type = `[${ContactFormActionsName}] Get Topics List`;
}

export class LoadMoreTopicsAction {
  static readonly type = `[${ContactFormActionsName}] Load More Topics`;
}

export class CountAttentionTopicsAction {
  static readonly type = `[${ContactFormActionsName}] Count New Topics`;
}

export class CountPendingTopicsAction {
  static readonly type = `[${ContactFormActionsName}] Count Pending Topics`;
}

export class OpenTopicAction {
  static readonly type = `[${ContactFormActionsName}] Open Topic`;

  constructor(public topicId: string) {}
}

export class ChangeTopicStatusAction {
  static readonly type = `[${ContactFormActionsName}] Change Topic Status`;

  constructor(public topicId: string, public status: ContactFormStatus) {}
}

export class GetTopicMessagesListAction {
  static readonly type = `[${ContactFormActionsName}] Get Topic Messages List`;

  constructor(public topicId: string) {}
}

export class SendMessageAction {
  static readonly type = `[${ContactFormActionsName}] Send Message`;

  constructor(public topicId: string, public data: NewTopicMessage) {}
}
