<div class="header">
  <dr-text>
    <span class="title">{{ 'Reset Password' | translate }}</span>
  </dr-text>
  <dr-text>
    <span class="subtitle">{{ 'auth.enter_account_email2' | translate }}</span>
  </dr-text>
</div>

<dr-input
  class="email"
  type="email"
  [fControl]="emailFormControl"
  [label]="'Email' | translate"
  [errors]="validationService.appValidationRules.emailErrorMessages()"
  [required]="true"
></dr-input>

<dr-input
  #password
  type="password"
  [fControl]="passwordControl"
  [label]="'Password' | translate"
  [errors]="validationService.appValidationRules.passwordErrorMessages()"
  [required]="true"
></dr-input>

<dr-input
  #confirmPassword
  type="password"
  [fControl]="confirmPasswordControl"
  [label]="'auth.confirm_new_password' | translate"
  [errors]="validationService.appValidationRules.passwordErrorMessages()"
  [required]="true"
></dr-input>

<dr-button
  expand="block"
  iconName="arrow-forward-outline"
  [label]="'Reset Password' | translate"
  (click)="reset()"
></dr-button>

@if (!!(authState.resetPasswordError$ | async)) {
  <dr-text [small]="true" [centered]="true" color="danger" class="error">
    <span>{{ authState.resetPasswordError$ | async }}</span>
  </dr-text>
}

<dr-text class="switch-link" [centered]="true">
  {{ 'Back to' | translate }}
  <dr-link (click)="signIn.emit()">{{ 'Sign in' | translate }}</dr-link>
</dr-text>
