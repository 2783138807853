import { AppLanguage } from '@dr/localization';
import { AppTheme } from '@dr/theme';

export type UserRole = 'user' | 'admin' | 'business';
export type UserGender = 'male' | 'female' | 'non-binary' | 'other';

export interface UserCompany {
  id: string;
  nm: string;
  lXS: string;
  nch: string;
  s: 'pending' | 'rejected' | 'approved';
  ps: 'non-paid' | 'paid';
  msg?: string;
}

export interface User {
  id: string;
  email: string;
  fn: string;
  ln: string;
  bio: string;
  inst: string;
  bd: string;
  g: UserGender;
  ph: string;
  ol: string;
  aXS: string;
  ac: string;
  aXL?: string;
  cAt: Date;
  lvd: Date;
  qr?: string;
  d: {
    t: AppTheme;
    l: AppLanguage;
    pne: boolean;
    nse: boolean;
    nve: boolean;
  };

  // only for business app
  mcs?: UserCompany[];
}
