import { AsyncPipe } from '@angular/common';
import { Component, computed, OnInit, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import {
  ButtonComponent,
  ConfirmComponent,
  EmptyComponent,
  ErrorFooterComponent,
  LinkComponent,
  LoadingComponent,
  SegmentComponent,
  SegmentItem,
  SelectComponent,
  TextareaComponent,
  TextComponent,
  EntityNameComponent, SkeletonComponent,
} from '@dr/ui';
import {
  getPaginationNumber,
  KeyboardDirective,
  SmartComponent,
  ValidationService,
} from '@dr/utils';
import { IonicModule, IonInfiniteScroll, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { finalize, map, Observable } from 'rxjs';
import { CompaniesModule } from '../../companies.module';
import {
  Company,
  CompanyPaidStatus,
  CompanyStatus,
} from '../../interfaces/company';
import {
  CompaniesState,
  CompanyByStatusState,
} from '../../states/companies.state';
import { CompanyShortInfoComponent } from '../company-short-info/company-short-info.component';

export interface CompanyItem extends Company {
  checked: boolean;
}

@Component({
  selector: 'dr-companies-manager',
  templateUrl: 'companies-manager.component.html',
  styleUrls: ['companies-manager.component.scss'],
  imports: [
    IonicModule,
    TranslateModule,
    AsyncPipe,
    FormsModule,
    EmptyComponent,
    ErrorFooterComponent,
    KeyboardDirective,
    EntityNameComponent,
    TextComponent,
    LinkComponent,
    ButtonComponent,
    LoadingComponent,
    TextareaComponent,
    SelectComponent,
    ConfirmComponent,
    CompaniesModule,
    CompanyShortInfoComponent,
    SegmentComponent,
    SkeletonComponent,
  ],
  standalone: true,
})
export class CompaniesManagerComponent
  extends SmartComponent
  implements OnInit
{
  @ViewChild(IonInfiniteScroll) ionInfiniteScroll?: IonInfiniteScroll;

  currentCompaniesType = signal<CompanyStatus | CompanyPaidStatus>('pending');

  pendingCompaniesState = signal<CompanyByStatusState | undefined>(undefined);
  nonPaidCompaniesState = signal<CompanyByStatusState | undefined>(undefined);
  paidCompaniesState = signal<CompanyByStatusState | undefined>(undefined);

  currentCompaniesState = computed(() => {
    if (this.currentCompaniesType() === 'pending') {
      return this.pendingCompaniesState();
    } else if (this.currentCompaniesType() === 'non-paid') {
      return this.nonPaidCompaniesState();
    } else {
      return this.paidCompaniesState();
    }
  });
  checkedCompanies = computed(() =>
    ((this.currentCompaniesState()?.data || []) as CompanyItem[]).filter(
      (request) => request.checked
    )
  );

  types: SegmentItem<CompanyStatus | CompanyPaidStatus>[] = [
    {
      label: 'company.list.requests_title',
      type: 'pending',
      badge$: this.companiesState.pending$.pipe(
        map((state) => state.count || 0)
      ),
      badgeColor: 'warning',
    },
    {
      label: 'company.list.non_paid_title',
      type: 'non-paid',
      badge$: this.companiesState.nonPaid$.pipe(
        map((state) => state.count || 0)
      ),
      badgeColor: 'danger',
    },
    {
      label: 'company.list.paid_title',
      type: 'paid',
      badge$: this.companiesState.paid$.pipe(map((state) => state.count || 0)),
      badgeColor: 'success',
    },
  ];

  toggleAllEnabled = false;

  constructor(
    public validationService: ValidationService,
    public companiesState: CompaniesState,
    private navController: NavController
  ) {
    super();
  }

  override async ngOnInit() {
    await super.ngOnInit();

    this.init();

    this.companiesState.pending$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state) => {
        this.pendingCompaniesState.set({
          ...state,
          data: state.data?.map((company) => ({
            ...company,
            checked: this.toggleAllEnabled,
          })),
        });
      });

    this.companiesState.nonPaid$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state) => {
        this.nonPaidCompaniesState.set({
          ...state,
          data: state.data?.map((company) => ({
            ...company,
            checked: this.toggleAllEnabled,
          })),
        });
      });

    this.companiesState.paid$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state) => {
        this.paidCompaniesState.set({
          ...state,
          data: state.data?.map((company) => ({
            ...company,
            checked: this.toggleAllEnabled,
          })),
        });
      });
  }

  override onResumeApp() {
    super.onResumeApp();

    this.init();
  }

  openCompanyPreview(company: Company) {
    this.navController.navigateForward(`/companies/company/${company.id}`);
  }

  changeType(type: CompanyStatus | CompanyPaidStatus) {
    this.currentCompaniesType.set(type);

    this.init();
  }

  tryAgain() {
    this.init();

    if (this.pendingCompaniesState()?.data?.length) {
      this.expand();
    } else {
      this.init();
    }
  }

  toggleAll(event: Event) {
    const checked = (event as CustomEvent).detail.checked;

    if (this.currentCompaniesType() === 'pending') {
      this.pendingCompaniesState.update((state) => ({
        ...state,
        data: state?.data?.map((company) => ({
          ...company,
          checked,
        })),
      }));
    } else if (this.currentCompaniesType() === 'non-paid') {
      this.nonPaidCompaniesState.update((state) => ({
        ...state,
        data: state?.data?.map((company) => ({
          ...company,
          checked,
        })),
      }));
    } else if (this.currentCompaniesType() === 'paid') {
      this.paidCompaniesState.update((state) => ({
        ...state,
        data: state?.data?.map((company) => ({
          ...company,
          checked,
        })),
      }));
    }
  }

  toggleCompany(companyItem: CompanyItem) {
    if (this.currentCompaniesType() === 'pending') {
      this.pendingCompaniesState.update((state) => ({
        ...state,
        data: state?.data?.map((company) => {
          if (company.id === companyItem.id) {
            return {
              ...company,
              checked: !companyItem.checked,
            };
          }

          return company;
        }),
      }));
    } else if (this.currentCompaniesType() === 'non-paid') {
      this.nonPaidCompaniesState.update((state) => ({
        ...state,
        data: state?.data?.map((company) => {
          if (company.id === companyItem.id) {
            return {
              ...company,
              checked: !companyItem.checked,
            };
          }

          return company;
        }),
      }));
    } else if (this.currentCompaniesType() === 'paid') {
      this.paidCompaniesState.update((state) => ({
        ...state,
        data: state?.data?.map((company) => {
          if (company.id === companyItem.id) {
            return {
              ...company,
              checked: !companyItem.checked,
            };
          }

          return company;
        }),
      }));
    }
  }

  search(event: Event) {
    this.reload((event as CustomEvent).detail.value);
  }

  init() {
    if (this.currentCompaniesType() === 'pending') {
      this.companiesState.loadListByStatus$('pending');
    } else if (this.currentCompaniesType() === 'non-paid') {
      this.companiesState.loadListByPaidStatus$('non-paid');
    } else {
      this.companiesState.loadListByPaidStatus$('paid');
    }
  }

  reload(search?: string) {
    if (this.currentCompaniesType() === 'pending') {
      this.companiesState.reloadListByStatus$('pending', search);
    } else if (this.currentCompaniesType() === 'non-paid') {
      this.companiesState.reloadListByPaidStatus$('non-paid', search);
    } else {
      this.companiesState.reloadListByPaidStatus$('paid', search);
    }
  }

  expand(): void {
    const take = getPaginationNumber(60);
    let obs$: Observable<void>;

    if (this.currentCompaniesType() === 'pending') {
      obs$ = this.companiesState.extendListByStatus$('pending', take);
    } else if (this.currentCompaniesType() === 'non-paid') {
      obs$ = this.companiesState.extendListByPaidStatus$('non-paid', take);
    } else {
      obs$ = this.companiesState.extendListByPaidStatus$('paid', take);
    }

    obs$.pipe(finalize(() => this.ionInfiniteScroll?.complete())).subscribe();
  }

  delete() {
    const checkedCompanies = this.checkedCompanies();

    if (!checkedCompanies.length) {
      return;
    }

    this.companiesState.delete$(
      checkedCompanies.map((company) => company.id),
      this.currentCompaniesType()
    );
  }
}
