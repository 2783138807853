import { NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dr-skeleton',
  templateUrl: 'skeleton.component.html',
  styleUrls: ['skeleton.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule, NgStyle],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonComponent implements OnInit {
  @Input() repeat?: number;
  @Input() type?: 'avatar-text' | 'profile' | 'counter' | 'small-button';
  @Input() maxHeight?: number;

  skeletonItems: number[] = [];

  ngOnInit() {
    this.skeletonItems = Array(this.repeat || 20);
  }
}
