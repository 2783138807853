import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface AppFeatures {
  // auth
  a_si: boolean; // sign in
  a_su: boolean; // sign up
  a_su_ep: boolean; // sign up with email and password
  a_si_ep: boolean; // sign in with email and password
  a_p: boolean; // auth with providers
  a_p_g: boolean; // auth with Google
  a_p_a: boolean; // auth with Apple
  a_fp: boolean; // auth with Apple
  // contact form
  cf: boolean; // contact form
  cf_ct: boolean; // create topic
  // change user data
  cu: boolean; // change user data
  cu_a: boolean; // change avatar
  cu_bio: boolean; // change bio
  cu_fn: boolean; // change first name
  cu_ln: boolean; // change last name
  cu_g: boolean; // change gender
  cu_b: boolean; // change birthday
  cu_p: boolean; // change phone
  cu_inst: boolean; // change instagram
  cu_ol: boolean; // change other link
  // change company information
  cc: boolean; // change company information
  cc_a: boolean; // change avatar
  cc_nm: boolean; // change name
  cc_l: boolean; // change link
  cc_es: boolean; // change employees size
  cc_cs: boolean; // change clients size
  cc_nch: boolean; // change niche
  cc_c: boolean; // change country
  cc_p: boolean; // change place
  // change device settings
  cd: boolean; // change device settings
  cd_t: boolean; // change theme
  cd_l: boolean; // change language
  cd_pn: boolean; // change push notifications
  cd_sn: boolean; // change notifications sound
  cd_vn: true; // change notifications vibration
  // delete
  dp: boolean; // delete profile
  dc: boolean; // delete company
  // notifications
  n: boolean; // notifications
}

@Injectable()
export class FeaturesApiService {
  constructor(private httpClient: HttpClient) {}

  getAppFeatures$(): Observable<AppFeatures> {
    return this.httpClient.get<AppFeatures>('/api/features');
  }
}
