import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as countries from '../data/countries.json';

export interface Country {
  alpha2: string;
  [key: string]: string;
}

@Injectable()
export class CountyService {
  public readonly countries = Object.values(countries as unknown as Country[])
    .map((country) => ({
      label: country[this.translateService.defaultLang || 'en'],
      value: country.alpha2,
    }))
    .filter((c) => c.label && c.value)
    .sort((a, b) => a.label.localeCompare(b.label));

  constructor(private translateService: TranslateService) {}
}
