<dr-avatar
  [src]="imgUrl()"
  [name]="fullName()"
  [color]="color()"
  size="medium"
></dr-avatar>

<div class="text">
  <dr-text color="dark">
    <div class="full-name max-1-lines">{{ fullName() }}</div>
  </dr-text>
  <dr-text color="medium">
    @if (subtitle(); as subtitle) {
      <div class="subtitle max-1-lines">{{ subtitle }}</div>
    }
  </dr-text>
</div>
