<div class="input" (click)="showPopover($event)" [class.disabled]="disabled">
  @if (label) {
    <ion-label [position]="disabled || fControl.value ? 'stacked' : 'fixed'">{{
      label
    }}</ion-label>
  }

  @if (fControl.value) {
    <span>
      {{ $any(fControl.value) | localizedDate: 'MMMM d, y' }}
    </span>

    <ion-icon
      (click)="$event.stopPropagation(); clear()"
      name="close-circle"
    ></ion-icon>
  }
</div>

<ion-popover
  alignment="start"
  side="bottom"
  [arrow]="false"
  (didDismiss)="!disabled && changed.emit(fControl.value)"
>
  <ng-template>
    <ion-datetime presentation="date" [formControl]="fControl"></ion-datetime>
  </ng-template>
</ion-popover>
