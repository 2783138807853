import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnDestroy,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Camera, CameraPermissionState } from '@capacitor/camera';
import { Capacitor, PermissionState } from '@capacitor/core';
import { LoadingComponent } from '@dr/ui';
import { ToastService } from '@dr/utils';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';
import { Html5Qrcode } from 'html5-qrcode';
import { fromEvent, Subject, Subscription, tap, throttleTime } from 'rxjs';
import { unHashQrCodeData } from '../../utils/qr-hash';

export enum QRCodeType {
  user = 'userQR',
  company = 'companyQR',
}

@Component({
  selector: 'dr-qr-scan',
  templateUrl: 'qr-scan-modal.component.html',
  styleUrls: ['qr-scan-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, AsyncPipe, LoadingComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrScanModalComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) validKeys!: string[];

  readonly nativeDevice = Capacitor.isNativePlatform();

  started = signal<boolean>(false);
  permissionsDenied = signal<PermissionState | undefined>(undefined);

  private html5Qrcode?: Html5Qrcode;
  private permissionsErrorToast?: HTMLIonToastElement;
  private destroyRef = inject(DestroyRef);
  private windowFocusChangesSubs?: Subscription;
  private invalidCode$ = new Subject<void>();

  constructor(
    public modalController: ModalController,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  ngAfterViewInit() {
    this.init();
    this.listenScanningBadCodes();
  }

  ngOnDestroy() {
    this.html5Qrcode?.stop();
    this.permissionsErrorToast?.dismiss();
  }

  private async onSuccessScan(text: string) {
    try {
      const data = JSON.parse(unHashQrCodeData(text));

      if (
        Object.keys(data).some(
          (key) => this.validKeys.includes(key) && !!data[key]
        )
      ) {
        await this.modalController.dismiss(data);
      }
    } catch (e) {
      this.invalidCode$.next();
    }
  }

  private async showPermissionsError() {
    this.permissionsErrorToast?.dismiss();

    this.permissionsErrorToast = await this.toastService.showToast({
      color: 'warning',
      message: 'scan_barcode.warn_message',
      duration: 99999999,
      position: 'top',
      buttons: this.nativeDevice
        ? [
            {
              text: this.translateService.instant('scan_barcode.open'),
              role: 'info',
              handler: () => {
                NativeSettings.open({
                  optionAndroid: AndroidSettings.ApplicationDetails,
                  optionIOS: IOSSettings.App,
                });
              },
            },
            {
              text: this.translateService.instant('scan_barcode.close'),
              role: 'cancel',
            },
          ]
        : [
            {
              text: this.translateService.instant('scan_barcode.ok'),
              role: 'cancel',
            },
          ],
    });
  }

  private listenWindowFocusChanges() {
    if (this.windowFocusChangesSubs) {
      return;
    }

    this.windowFocusChangesSubs = fromEvent(window, 'focus')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.init());
  }

  private listenScanningBadCodes() {
    this.invalidCode$
      .pipe(
        throttleTime(1000),
        tap(() => {
          this.toastService.showToast({
            color: 'danger',
            message: 'scan_barcode.invalid_code',
            duration: 1000,
            position: 'top',
          });
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private async init(): Promise<void> {
    let cameraPermission: CameraPermissionState;

    if (Capacitor.isNativePlatform()) {
      const { camera } = await Camera.requestPermissions({
        permissions: ['camera'],
      });

      cameraPermission = camera;
    } else {
      const status = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });

      if (status.state === 'prompt') {
        try {
          await navigator.mediaDevices.getUserMedia({ video: true });

          cameraPermission = status.state;
        } catch (e) {
          cameraPermission = 'denied';
        }
      } else {
        cameraPermission = status.state;
      }
    }

    if (cameraPermission === 'granted' || cameraPermission === 'limited') {
      await this.scan();
      this.permissionsErrorToast?.dismiss();
      this.permissionsDenied.set('granted');
    } else {
      this.permissionsDenied.set(cameraPermission);
      await this.showPermissionsError();

      if (this.html5Qrcode) {
        this.html5Qrcode?.stop();
      }
    }

    this.listenWindowFocusChanges();
  }

  private async scan() {
    this.html5Qrcode = new Html5Qrcode('qr-scanner');

    await this.html5Qrcode.start(
      {
        facingMode: 'environment',
      },
      {
        fps: 4,
        qrbox: { width: 250, height: 250 },
        videoConstraints: {
          width: 1920,
          height: 1080,
          facingMode: 'environment',
        },
      },
      this.onSuccessScan.bind(this),
      () => {}
    );

    setTimeout(
      () => this.started.set(true),
      Capacitor.isNativePlatform() ? 1500 : 0
    );
  }
}
