export const openInstagramLink = (link: string) => {
  let nickname: string;

  if (link.indexOf('/') !== -1) {
    const domainRegex = /^((http|https):\/\/)?(www\.)?([^\/]+)(.*)$/;

    nickname = link
      .replace(
        domainRegex,
        (match, protocol, protocolType, www, domain, path) => path
      )
      .replace('/', '');
  } else {
    nickname = link;
  }

  openLinkInNewTab(`https://www.instagram.com/${nickname}`);
};

export const openLinkInNewTab = (link: string) => {
  window.open(!/^https?:\/\//i.test(link) ? `http://${link}` : link, '_blank');
};
