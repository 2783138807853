import { AsyncPipe } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import {
  BannerComponent,
  EmptyComponent,
  ErrorFooterComponent,
  LinkComponent,
  LoadingComponent,
  SegmentComponent,
  SegmentItem,
  TextComponent,
} from '@dr/ui';
import { SmartComponent } from '@dr/utils';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, combineLatest } from 'rxjs';
import { CompaniesModule } from '../../companies.module';
import { CompanyUpdatesModule } from '../../company-updates.module';
import { Company, CompanyRequestChanges } from '../../interfaces/company';
import { CompaniesState } from '../../states/companies.state';
import { CompanyDetailsState } from '../../states/company-details.state';
import { CompanyUpdatesState } from '../../states/company-updates.state';
import { CompanyActiveSubscriptionViewComponent } from '../company-active-subscription-view/company-active-subscription-view.component';
import { CompanyRequestChangesComponent } from '../company-request-changes/company-request-changes.component';
import { CompanyShortInfoComponent } from '../company-short-info/company-short-info.component';
import { CompanySubscriptionsPackComponent } from '../company-subscriptions-pack/company-subscriptions-pack.component';

export type CompanyPreviewSegmentType =
  | 'subscription'
  | 'subscriptions-pack'
  | 'groups'
  | 'clients';

@Component({
  selector: 'dr-company-preview',
  templateUrl: 'companies-preview.component.html',
  styleUrls: ['companies-preview.component.scss'],
  imports: [
    IonicModule,
    TranslateModule,
    CompaniesModule,
    ErrorFooterComponent,
    AsyncPipe,
    TextComponent,
    LinkComponent,
    CompanyShortInfoComponent,
    LoadingComponent,
    BannerComponent,
    CompanyUpdatesModule,
    CompanyRequestChangesComponent,
    SegmentComponent,
    CompanySubscriptionsPackComponent,
    EmptyComponent,
    CompanyActiveSubscriptionViewComponent,
  ],
  standalone: true,
})
export class CompaniesPreviewComponent
  extends SmartComponent
  implements OnInit
{
  readonly companyId = this.activatedRoute.snapshot.params['id'];
  readonly navController = inject(NavController);

  targetCompany = signal<Company | undefined>(undefined);
  error = signal<string | undefined>(undefined);
  requestChanges = computed<CompanyRequestChanges | undefined>(() => {
    const company = this.targetCompany();

    if (!company || !company.rch) {
      return undefined;
    }

    return {
      ...company.rch,
      ce: company,
    };
  });

  segmentTypes: SegmentItem<CompanyPreviewSegmentType>[] = [
    {
      label: 'company.info.subscription',
      type: 'subscription',
    },
    {
      label: 'company.info.subscriptions-pack',
      type: 'subscriptions-pack',
    },
    {
      label: 'company.info.groups',
      type: 'groups',
    },
    {
      label: 'company.info.clients',
      type: 'clients',
    },
  ];
  targetSegmentType = this.segmentTypes[0].type;

  constructor(
    public companyDetailsState: CompanyDetailsState,
    public companiesState: CompaniesState,
    public companyUpdatesState: CompanyUpdatesState,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  override onResumeApp() {
    super.onResumeApp();

    this.loadCompanyData();
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    combineLatest([
      this.companiesState.findCompanyById$(this.companyId),
      this.companyDetailsState.getCompanyById$(this.companyId),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([companyPreview, company]) => {
        if (company) {
          this.targetCompany.set(company);
        } else if (companyPreview) {
          this.companyDetailsState.setInitialCompanyDetails$(companyPreview);
        }
      });

    this.loadCompanyData();
  }

  loadCompanyData() {
    this.companyDetailsState
      .loadCompanyDetails$(this.companyId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError((error) => {
          this.error.set(error);

          throw error;
        })
      )
      .subscribe();
  }
}
