import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  ButtonComponent,
  CheckboxComponent,
  ConfirmComponent,
  InputComponent,
  LoadingComponent,
  SelectComponent,
  TextComponent,
} from '@dr/ui';
import { ToastService } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { maskitoParseNumber } from '@maskito/kit';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { CompaniesModule } from '../../companies.module';
import {
  CompanySubscription,
  CompanySubscriptionsPack,
} from '../../interfaces/company';
import { CreateCompanySubscriptionsPack } from '../../services/company-subscriptions-api.service';
import { CompanySubscriptionsState } from '../../states/company-subscriptions.state';
import { CompanySubscriptionFormComponent } from '../company-subscription-form/company-subscription-form.component';
import { CompanySubscriptionItemComponent } from '../company-subscription-item/company-subscription-item.component';

@Component({
  selector: 'dr-company-subscriptions-pack-form',
  templateUrl: './company-subscriptions-pack-form.component.html',
  styleUrls: ['./company-subscriptions-pack-form.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TextComponent,
    TranslateModule,
    InputComponent,
    ButtonComponent,
    NgTemplateOutlet,
    ConfirmComponent,
    SelectComponent,
    CheckboxComponent,
    CompanySubscriptionItemComponent,
    CompaniesModule,
    AsyncPipe,
    LoadingComponent,
    CompanySubscriptionFormComponent,
  ],
})
export class CompanySubscriptionsPackFormComponent implements AfterViewInit {
  @ViewChildren(CompanySubscriptionFormComponent)
  subscriptionFormComponents?: QueryList<CompanySubscriptionFormComponent>;

  @Input() editPack?: CompanySubscriptionsPack;

  @Output() sent: EventEmitter<void> = new EventEmitter<void>();

  labelControl = new FormControl('', [Validators.required]);
  annuallyDiscountControl = new FormControl<number>(0, [Validators.required]);
  subscriptions: (CompanySubscription | object)[] = [];
  previewSubscriptionItem?: {
    primary: boolean;
    title: string;
    oldPrice?: number;
    currentPrice?: number;
    bulletPoints?: string[];
  };

  constructor(
    public companySubscriptionsState: CompanySubscriptionsState,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {}

  ngAfterViewInit() {
    if (this.editPack) {
      this.labelControl.patchValue(this.editPack.l);
      this.annuallyDiscountControl.patchValue(this.editPack.adp);
      this.subscriptions.push(...this.editPack.sbs);
    }
  }

  addSubscription() {
    this.subscriptions.push({});
  }

  submit(): void {
    this.subscriptionFormComponents?.forEach((subscriptionFormComponent) =>
      subscriptionFormComponent.formGroup.updateValueAndValidity()
    );

    if (
      this.labelControl.invalid ||
      this.annuallyDiscountControl.invalid ||
      !this.subscriptionFormComponents?.length ||
      this.subscriptionFormComponents.some(
        (subscriptionFormComponent) =>
          subscriptionFormComponent.formGroup.invalid
      )
    ) {
      this.toastService.showToast({
        color: 'danger',
        message: 'subscriptions.packs.form.fill_required_elements_error',
        duration: 1000,
        position: 'top',
      });

      return;
    }

    const subscriptions = this.subscriptionFormComponents.map(
      (subscriptionFormComponent) => ({
        ...subscriptionFormComponent.formGroup.value,
        bulletPoints: (
          subscriptionFormComponent.formGroup.value.bulletPoints || []
        ).map((bp) => JSON.parse(bp)),
        oldPrice: subscriptionFormComponent.formGroup.value.oldPrice
          ? maskitoParseNumber(
              String(subscriptionFormComponent.formGroup.value.oldPrice)
            )
          : null,
        currentPrice: subscriptionFormComponent.formGroup.value.currentPrice
          ? maskitoParseNumber(
              String(subscriptionFormComponent.formGroup.value.currentPrice)
            )
          : 0,
        annuallyDiscount: Number(this.annuallyDiscountControl.value),
      })
    ) as CreateCompanySubscriptionsPack['subscriptions'];

    if (this.editPack) {
      this.companySubscriptionsState
        .updateSubscriptionsPack$(this.editPack.id, {
          label: String(this.labelControl.value),
          annuallyDiscount: Number(this.annuallyDiscountControl.value),
          subscriptions,
        })
        .pipe(first())
        .subscribe(() => this.sent.emit());
    } else {
      this.companySubscriptionsState
        .createSubscriptionsPack$({
          label: String(this.labelControl.value),
          annuallyDiscount: Number(this.annuallyDiscountControl.value),
          subscriptions,
        })
        .pipe(first())
        .subscribe(() => this.sent.emit());
    }
  }

  removeSubscription(index: number) {
    this.subscriptions.splice(index, 1);
  }
}
