import { Component, inject } from '@angular/core';
import { ShortNumberPipe } from '@dr/utils';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectionsState } from '../../states/connections.state';

@Component({
  selector: 'dr-new-requests-navigator',
  templateUrl: './new-requests-navigator.component.html',
  styleUrls: ['./new-requests-navigator.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule, ShortNumberPipe],
})
export class NewRequestsNavigatorComponent {
  readonly connectionsState = inject(ConnectionsState);
  readonly navController = inject(NavController);

  openRequests() {
    this.navController.navigateForward('/requests');
  }
}
