import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegularSuccessResponse } from '@dr/utils';
import { Observable } from 'rxjs';

export interface UploadedFile {
  id: string;
  mt: string;
  nm: string;
  sz: number;
  url: string;
}

@Injectable()
export class FileApiService {
  constructor(private httpClient: HttpClient) {}

  upload$(file: File): Observable<UploadedFile> {
    const formData = new FormData();

    formData.append('file', file, file.name);

    return this.httpClient.post<UploadedFile>('/api/file', formData);
  }

  delete$(id: string): Observable<RegularSuccessResponse> {
    return this.httpClient.delete<RegularSuccessResponse>(
      `/api/file/${id}`,
      {}
    );
  }
}
