import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  finalize,
  first,
  from,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timer,
  zip,
} from 'rxjs';
import { AppEnvironment, AppEnvironmentI } from '../injectors/app-environment';
import { IsWebVersion } from '../is-web-version';
import { ToastService } from '../services/toast.service';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {
  private identifier?: string;
  private deviceInfo?: string;
  private appVersion?: string;

  constructor(
    @Inject(AppEnvironment) public appEnvironment: AppEnvironmentI,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {}

  intercept(httpRequest: HttpRequest<unknown>, nextHandler: HttpHandler) {
    const identifier$ = !this.identifier
      ? from(Device.getId()).pipe(
          first(),
          tap(({ identifier }) => (this.identifier = identifier)),
          catchError((err) => {
            // eslint-disable-next-line no-console
            console.error('[Identifier]:', err);

            this.identifier = 'unknown';

            return of(undefined);
          })
        )
      : of(undefined);

    const appVersion$ = !this.appVersion
      ? (Capacitor.isNativePlatform()
          ? from(App.getInfo())
          : of({ version: 'web' })
        ).pipe(tap(({ version }) => (this.appVersion = version)))
      : of(undefined);

    const deviceInfo$ = !this.deviceInfo
      ? from(Device.getInfo()).pipe(
          tap((deviceInfo) => (this.deviceInfo = JSON.stringify(deviceInfo))),
          catchError((err) => {
            this.deviceInfo = JSON.stringify({});

            // eslint-disable-next-line no-console
            console.error('[Device]:', err);

            return of(undefined);
          })
        )
      : of(undefined);

    return zip(identifier$, deviceInfo$, appVersion$).pipe(
      switchMap(() => {
        let requestUrl: string;

        if (
          httpRequest.url.indexOf('/api/') === 0 ||
          httpRequest.url.indexOf('api/') === 0
        ) {
          requestUrl = httpRequest.url.replace(/^(\/api\/|api\/)/, '');
        } else {
          requestUrl = '';
        }

        if (requestUrl) {
          httpRequest = httpRequest.clone({
            url: `${this.appEnvironment.apiUri}/${requestUrl}`,
          });
        }

        httpRequest = httpRequest.clone({
          headers: httpRequest.headers
            .set('identifier', this.identifier as string)
            .set('lang', this.translateService.getDefaultLang())
            .set('app', this.appEnvironment.appPrefix)
            .set('version', this.appVersion as string)
            .set('device-info', this.deviceInfo as string),
        });

        return this.runHttpEvent$(nextHandler.handle(httpRequest));
      })
    );
  }

  private runHttpEvent$(requestHandler$: Observable<HttpEvent<unknown>>) {
    const position = IsWebVersion() ? 'top' : 'bottom';
    let longRequestToast: HTMLIonToastElement;
    let tooLongRequestToast: HTMLIonToastElement;
    let prevented = false;

    const prevent$ = new Subject<void>();
    const tooLongRequest$ = timer(20 * 1000).pipe(
      tap(async () => {
        tooLongRequestToast = await this.toastService.showToast({
          color: 'warning',
          message: 'request_timeout.too_long_message',
          duration: 99999999,
          position,
          buttons: [
            {
              text: this.translateService.instant('request_timeout.wait'),
              role: 'info',
            },
            {
              text: this.translateService.instant('request_timeout.prevent'),
              role: 'cancel',
              handler: () => {
                prevented = true;
                prevent$.next();
              },
            },
          ],
        });
      })
    );

    const dispatcher = timer(5 * 1000)
      .pipe(
        tap(async () => {
          longRequestToast = await this.toastService.showToast({
            color: 'secondary',
            message: 'request_timeout.long_message',
            duration: 19 * 1000,
            position,
          });
        }),
        switchMap(() => tooLongRequest$),
        first()
      )
      .subscribe();

    return requestHandler$.pipe(
      takeUntil(prevent$),
      finalize(() => {
        dispatcher.unsubscribe();

        // timeout uses because for some cases longRequestToast or tooLongRequestToast can't init in time
        setTimeout(() => {
          if (longRequestToast) {
            longRequestToast.dismiss();
          }

          if (tooLongRequestToast) {
            tooLongRequestToast.dismiss();

            if (!prevented) {
              this.toastService.showToast({
                message: 'request_timeout.thanks_for_waiting',
                position,
              });
            }
          }
        }, 300);
      })
    );
  }
}
