import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  input,
  Input,
  OnChanges,
  Output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AvatarComponent, ButtonComponent, SpinnerComponent } from '@dr/ui';
import { IsWebVersion } from '@dr/utils';
import { ActionSheetButton, IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'dr-avatar-form',
  templateUrl: 'avatar-form.component.html',
  styleUrls: ['avatar-form.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    AvatarComponent,
    ButtonComponent,
    TranslateModule,
    SpinnerComponent,
  ],
  providers: [FileService],
})
export class AvatarFormComponent implements OnChanges {
  @Input() src?: string;
  @Input() name?: string;
  @Input() disabled?: boolean;

  color = input<string>();

  @Output() update: EventEmitter<File> = new EventEmitter<File>();
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  loading = signal(false);
  actions = signal<ActionSheetButton[]>([]);

  private destroyRef = inject(DestroyRef);

  constructor(
    private translateService: TranslateService,
    private fileService: FileService
  ) {}

  ngOnChanges() {
    this.actions.set(this.prepareActions());
  }

  setResult(e: CustomEvent) {
    if (!e?.detail?.data?.action) {
      return;
    }

    if (e.detail.data.action === 'delete') {
      this.delete.emit();
    }

    if (e.detail.data.action === 'upload') {
      if (IsWebVersion()) {
        this.loading.set(true);
      }

      this.fileService
        .attachImages$()
        .pipe(
          finalize(() => {
            this.loading.set(false);
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((files) => {
          this.update.emit(files[0]);
        });
    }
  }

  private prepareActions(): ActionSheetButton[] {
    const arr = [];

    if (this.src) {
      arr.push({
        text: this.translateService.instant('Delete'),
        role: 'destructive',
        data: {
          action: 'delete',
        },
      });
    }

    return [
      ...arr,
      {
        text: this.translateService.instant('Upload'),
        data: {
          action: 'upload',
        },
      },
      {
        text: this.translateService.instant('Cancel'),
        role: 'cancel',
        data: {
          action: 'cancel',
        },
      },
    ];
  }
}
