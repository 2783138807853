import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { IonColor } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'dr-button',
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.scss'],
  standalone: true,
  imports: [IonicModule, SpinnerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() label?: string;
  @Input() expand?: 'block' | 'full';
  @Input() shape?: 'round';
  @Input() iconName?: string;
  @Input() iconSize?: 'small' | 'default' | 'large' = 'default';
  @Input() iconColor?: IonColor;
  @Input() disabled?: boolean;
  @Input() loading?: boolean;
  @Input() color?: IonColor;
  @Input() iconPosition?: 'start' | 'end' = 'end';
  @Input() fill?: 'clear' | 'outline' | 'solid' | 'default';
  @Input() size?: 'small' | 'default' | 'large' = 'default';
  @Input() type?: 'button' | 'reset' | 'submit' = 'button';

  constructor(private elementRef: ElementRef) {
    this.elementRef.nativeElement.onclick = (e: Event) => {
      if (this.disabled || this.loading) {
        e.stopImmediatePropagation();
      }
    };
  }
}
