import { Component, DestroyRef, inject, Input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { ButtonComponent, TextComponent } from '@dr/ui';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { filesize } from 'filesize';
import { finalize, of, switchMap, tap } from 'rxjs';
import { FileModule } from '../../file.module';
import { FileApiService, UploadedFile } from '../../services/file-api.service';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'dr-file-uploader',
  templateUrl: 'file-uploader.component.html',
  styleUrls: ['file-uploader.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TextComponent,
    TranslateModule,
    FileModule,
    ButtonComponent,
  ],
})
export class FileUploaderComponent {
  @Input({ required: true }) fControl!: FormControl<File | UploadedFile | null>;
  @Input() accept?: string;
  @Input() label?: string;
  @Input() multiple?: boolean;
  @Input() useRemoteUpload?: boolean;
  @Input() attachFrom?: 'gallery' | 'images' = 'gallery';

  uploading = signal<boolean>(false);

  private destroyRef = inject(DestroyRef);

  get attachedFile() {
    return this.useRemoteUpload
      ? (this.fControl?.value as UploadedFile)
      : (this.fControl?.value as File);
  }

  get attachedFileSize() {
    return filesize(
      (this.useRemoteUpload
        ? (this.fControl?.value as UploadedFile)?.sz
        : (this.fControl?.value as File)?.size) || 0,
      {
        base: 2,
        standard: 'jedec',
      }
    );
  }

  constructor(
    private fileService: FileService,
    private fileApiService: FileApiService
  ) {}

  attach() {
    if (this.uploading()) {
      return;
    }

    this.uploading.set(true);

    (this.attachFrom === 'images'
      ? this.fileService.attachImages$()
      : this.fileService.attachMediaFromGallery$()
    )
      .pipe(
        switchMap((files) => {
          const file = files[0];

          if (this.useRemoteUpload) {
            return this.fileApiService
              .upload$(file)
              .pipe(
                tap((uploadedFile) => this.fControl.patchValue(uploadedFile))
              );
          }

          return of(undefined).pipe(
            tap(() => {
              this.fControl.patchValue(file);
            })
          );
        }),
        finalize(() => {
          this.uploading.set(false);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  deleteFile() {
    this.fControl.patchValue(null);
  }
}
