export type CompanyStatus = 'pending' | 'approved' | 'rejected';
export type CompanyPaidStatus = 'non-paid' | 'paid';
export type SubscriptionPeriod = 'monthly' | 'annually';

export interface Company {
  id: string;
  nm: string; // name
  qr: string; // qr hash
  l: string; // link
  lXL: string; // logo XL size
  lXS: string; // logo XS size
  lc: string; // logo color
  emn: number; // employees min
  emx?: number; // employees max
  cmn: number; // clients min
  cmx?: number; // clients max
  nch: string; // niche
  c: string; // country
  s: CompanyStatus; // status
  ps: CompanyPaidStatus; // paid status
  src: string; // source
  p: string; // location, place
  msg?: string; // reject message
  sp?: CompanySubscriptionsPack;
  as?: {
    id: string;
    cAt: string;
    pt: SubscriptionPeriod;
    sb: CompanySubscription;
  };
  rch?: CompanyRequestChanges;
  ud?: {
    nm?: string; // name
    l?: string; // link
    lXL?: string; // logo XL size
    lXS?: string; // logo XS size
    emn?: number; // employees min
    emx?: number; // employees max
    cmn?: number; // clients min
    cmx?: number; // clients max
    nch?: string; // niche
    c?: string; // country
    p?: string; // location, place
  };
}

export interface CompanyRequestChanges extends Company {
  ce: Company;
  rm?: string; // reject message
}

export type CompanySubscriptionConfigKey =
  | 'ma' // mobile application
  | 'pn' // push notifications
  | 'b' // branding
  | 'd' // dashboard
  | 'm' // messaging
  | 'us' // observing user stars
  | 'ur' // observing user reviews
  | 'un' // posting notes about user
  | 'ug' // adding user goals
  | 'upr' // user can post reviews
  | 'ut' // user can add tips
  | 'qr' // support qr code
  | 'cn' // company post news
  | 'ca' // company create adds
  | 'cs' // company store
  | 'ed' // export data
  | 'nar' // non-anonymous reviews about users from other companies
  | 'tr' // top-rated sign
  | 's24' // support 24/7
  | 'cc' // colleagues counter
  | 'gc' // groups counter
  | 'uc'; // clients counter;
export interface CompanySubscriptionConfig {
  key: CompanySubscriptionConfigKey;
  label: string;
  value: boolean | number;
}

export interface CompanySubscription {
  id: string;
  t: string; // title
  op?: number; // old price
  cp: number; // current price
  cbp: CompanySubscriptionConfig[]; // bullet points
  p: boolean; // primary
  cc?: number; // companies count
  pl?: string; // pack label
  adp?: number; // annually discount
}

export interface CompanySubscriptionsPack {
  id: string;
  l: string; // label
  adp: number; // annually discount percentage
  sbs: CompanySubscription[];
}
