<div class="container">
  <div (click)="openProfile()" class="from">
    <dr-avatar
      size="medium"
      [src]="$any(notification.fc?.lXS || notification.fu?.aXS)"
      [name]="$any(notification.fc?.lXS || notification.fu?.aXS)"
      [color]="$any(notification.fc?.lc || notification.fu?.ac)"
    ></dr-avatar>
  </div>

  <div class="content">
    <div (click)="openProfile()" class="content-header">
      <dr-text color="medium">
        <small>
          @if (notification.fc) {
            {{ notification.fc.nm }}
          }
          @if (notification.fu) {
            {{ notification.fu.fn }}
            {{ notification.fu.ln }}
          }
        </small>
      </dr-text>
      <dr-text color="medium">
        <small timeago [date]="notification.cAt"></small>
      </dr-text>
    </div>

    <div class="content-section">
      <div class="text">
        <dr-text color="dark">
          <div>{{ notification.stl }}</div>
        </dr-text>
      </div>

      <div class="actions">
        @if (!notification.r) {
          <div class="new">
            <div class="attention-el"></div>
          </div>
        }

        @switch (notification.t) {
          @case ('welcome') {
            <ng-container
              [ngTemplateOutlet]="contactFormTemplate"
            ></ng-container>
          }
          @case ('new-contact-form-topic') {
            <ng-container
              [ngTemplateOutlet]="contactFormTemplate"
            ></ng-container>
          }
          @case ('new-contact-form-message') {
            <ng-container
              [ngTemplateOutlet]="contactFormTemplate"
            ></ng-container>
          }
          @case ('request-connection') {
            <dr-button
              size="small"
              color="primary"
              [label]="'connections.approve_connect' | translate"
              (click)="approveConnect()"
            ></dr-button>
          }
        }
      </div>
    </div>
  </div>
</div>

<ng-template #contactFormTemplate>
  <dr-button
    (click)="contactForm()"
    [label]="'contact_form.notification' | translate"
    color="primary"
    size="small"
  ></dr-button>
</ng-template>
