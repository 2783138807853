import { maskitoParseNumber } from '@maskito/kit';
import { SubscriptionPeriod } from '../interfaces/company';

export const formatPrice = (
  price: number,
  periodType: SubscriptionPeriod,
  annuallyDiscount?: number
) => {
  price = maskitoParseNumber(String(price));

  if (!annuallyDiscount || periodType === 'monthly') {
    return price.toFixed(2);
  }

  return (price - (price / 100) * Number(annuallyDiscount)).toFixed(2);
};
