const hashCode = `DayRouter_brand`;

export const hashQrCodeDada = (inputString: string) => {
  let obfuscated = '';

  for (let i = 0; i < inputString.length; i++) {
    const charCode =
      inputString.charCodeAt(i) ^ hashCode.charCodeAt(i % hashCode.length);

    obfuscated += String.fromCharCode(charCode);
  }

  return obfuscated;
};

export const unHashQrCodeData = (obfuscatedString: string) => {
  let deObfuscated = '';

  for (let i = 0; i < obfuscatedString.length; i++) {
    const charCode =
      obfuscatedString.charCodeAt(i) ^ hashCode.charCodeAt(i % hashCode.length);

    deObfuscated += String.fromCharCode(charCode);
  }

  return deObfuscated;
};
