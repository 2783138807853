import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateFormData,
  PaginationList,
  PaginationParams,
  RegularSuccessResponse,
} from '@dr/utils';
import { Observable } from 'rxjs';
import {
  ContactFormStatus,
  ContactFormTopic,
  ContactFormTopicMessage,
  NewTopicData,
  NewTopicMessage,
} from '../interfaces/contact-form';

@Injectable()
export class ContactFormApiService {
  constructor(private httpClient: HttpClient) {}

  getTopics$(
    params: PaginationParams
  ): Observable<PaginationList<ContactFormTopic>> {
    return this.httpClient.get<PaginationList<ContactFormTopic>>(
      '/api/contact-form/list',
      {
        params: {
          ...params,
        },
      }
    );
  }

  countAttentionTopics$(): Observable<number> {
    return this.httpClient.get<number>('/api/contact-form/attention');
  }

  countPendingTopics$(): Observable<number> {
    return this.httpClient.get<number>('/api/contact-form/pending');
  }

  getTopicMessages$(id: string): Observable<ContactFormTopicMessage[]> {
    return this.httpClient.get<ContactFormTopicMessage[]>(
      `/api/contact-form/${id}/messages`
    );
  }

  createTopic$(data: NewTopicData): Observable<RegularSuccessResponse> {
    return this.httpClient.post<RegularSuccessResponse>(
      `/api/contact-form/create`,
      generateFormData(data, ['file'])
    );
  }

  sendMessage$(
    id: string,
    data: NewTopicMessage
  ): Observable<RegularSuccessResponse> {
    return this.httpClient.post<RegularSuccessResponse>(
      `/api/contact-form/${id}/message`,
      generateFormData(data, ['file'])
    );
  }

  openTopic$(id: string): Observable<RegularSuccessResponse> {
    return this.httpClient.put<RegularSuccessResponse>(
      `/api/contact-form/${id}/open`,
      {}
    );
  }

  changeTopicStatus$(
    id: string,
    status: ContactFormStatus
  ): Observable<RegularSuccessResponse> {
    return this.httpClient.put<RegularSuccessResponse>(
      `/api/contact-form/${id}/status`,
      {
        status,
      }
    );
  }
}
