import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  QueryList,
  signal,
  ViewChildren,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TextComponent } from '../text/text.component';

export interface TabGroupItem {
  key: string;
  label: string;
  skipTranslation?: string;
}

@Component({
  selector: 'dr-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule, TextComponent],
})
export class TabGroupComponent implements AfterViewInit, OnChanges {
  @ViewChildren('tabGroupComponent')
  tabGroupComponents?: QueryList<ElementRef>;

  @Input({ required: true }) tabs!: TabGroupItem[];
  @Input() activeTabKey?: TabGroupItem['key'];

  @Output() selectTab = new EventEmitter<TabGroupItem['key']>();

  indicatorWidth = signal<string>('');
  indicatorTransform = signal<string>('');
  animateIndicator = signal<boolean>(false);

  ngAfterViewInit() {
    this.updateIndicatorStyles();
  }

  ngOnChanges() {
    this.updateIndicatorStyles();
  }

  makeTabActive(tab: TabGroupItem) {
    this.selectTab.emit(tab.key);

    this.updateIndicatorStyles();
  }

  @HostListener('window:resize')
  private updateIndicatorStyles() {
    setTimeout(() => {
      const activeTab = this.tabGroupComponents?.find(
        (el) => el.nativeElement.getAttribute('key') === this.activeTabKey
      );

      if (activeTab) {
        this.indicatorWidth.set(`${activeTab.nativeElement.offsetWidth}px`);
        this.indicatorTransform.set(
          `translateX(${activeTab.nativeElement.offsetLeft}px)`
        );

        if (!this.animateIndicator()) {
          setTimeout(() => this.animateIndicator.set(true));
        }
      }
    }, 100);
  }
}
