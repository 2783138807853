import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  BannerComponent,
  ButtonComponent,
  EmptyComponent,
  LinkComponent,
  LogoComponent,
  RadioComponent,
  RadioOption,
  TextComponent,
} from '@dr/ui';
import { IsWebVersion, LocalizedDatePipe } from '@dr/utils';
import {
  ActionSheetButton,
  IonicModule,
  IonModal,
  NavController,
} from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Company } from '../../interfaces/company';
import { formatPrice } from '../../utils/format-price';

@Component({
  selector: 'dr-company-active-subscription-view',
  templateUrl: './company-active-subscription-view.component.html',
  styleUrls: ['./company-active-subscription-view.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    AsyncPipe,
    EmptyComponent,
    TranslateModule,
    TextComponent,
    LinkComponent,
    LogoComponent,
    BannerComponent,
    NgClass,
    LocalizedDatePipe,
    ButtonComponent,
    RadioComponent,
  ],
})
export class CompanyActiveSubscriptionViewComponent {
  @ViewChild('cancelSubscriptionModal') cancelSubscriptionModal?: IonModal;

  @Input({ required: true }) company!: Company;
  @Input() includeSubscriptionActions?: boolean;

  readonly IsWebVersion = IsWebVersion;

  cancelReasonControl = new FormControl<string>('');
  cancelRequestReasons: RadioOption<string>[] = [
    {
      label: this.translateService.instant(
        'company.cancel_company_subscription.package_not_good'
      ),
      value: this.translateService.instant(
        'company.cancel_company_subscription.package_not_good'
      ),
    },
    {
      label: this.translateService.instant(
        'company.cancel_company_subscription.found_another_solution'
      ),
      value: this.translateService.instant(
        'company.cancel_company_subscription.found_another_solution'
      ),
    },
    {
      label: this.translateService.instant(
        'company.cancel_company_subscription.platform_convenience'
      ),
      value: this.translateService.instant(
        'company.cancel_company_subscription.platform_convenience'
      ),
    },
    {
      label: this.translateService.instant(
        'company.cancel_company_subscription.small_budget'
      ),
      value: this.translateService.instant(
        'company.cancel_company_subscription.small_budget'
      ),
    },
    {
      label: this.translateService.instant(
        'company.cancel_company_subscription.other'
      ),
      value: this.translateService.instant(
        'company.cancel_company_subscription.other'
      ),
    },
  ];

  actionButtons: ActionSheetButton[] = [
    {
      text: this.translateService.instant('company.my_companies.change_plan'),
      handler: () => {
        this.changeSubscription();
      },
    },
    {
      text: this.translateService.instant(
        'company.my_companies.change_payment_method'
      ),
      handler: () => {
        alert("You can't update payment method yet");
      },
      cssClass: 'disabled',
    },
    {
      text: this.translateService.instant(
        'company.my_companies.request_cancellation'
      ),
      handler: () => {
        this.cancelSubscriptionModal?.present();
      },
    },
    {
      text: this.translateService.instant('company.my_companies.cancel'),
      role: 'cancel',
    },
  ];

  constructor(
    private translateService: TranslateService,
    private navController: NavController
  ) {}

  formatPrice() {
    if (!this.company.as) {
      return '';
    }

    return formatPrice(
      this.company.as.sb.cp,
      this.company.as.pt,
      this.company.as.sb.adp
    );
  }

  changeSubscription() {
    this.navController.navigateRoot('/company/subscriptions-pack');
  }

  cancelSubscription(): void {
    const value = this.cancelReasonControl.value;

    if (!value) {
      return;
    }

    this.navController.navigateForward('/contact-form', {
      queryParams: {
        message: `${this.translateService.instant(
          'company.cancel_company_subscription.i_want_to_cancel_subscription'
        )} ${value}`,
      },
    });
  }
}
