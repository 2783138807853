<dr-loading
  [show]="!!(companySubscriptionsState.subscriptionProcessing$ | async)"
  [message]="'Wait for the process to complete' | translate"
></dr-loading>

<div class="subscription-form">
  <dr-button
    class="preview-subscription"
    color="primary"
    size="small"
    iconSize="small"
    iconName="eye-outline"
    (click)="previewSubscription(formGroup)"
  ></dr-button>

  @if (canDelete) {
    <dr-button
      class="remove-subscription"
      color="danger"
      size="small"
      iconSize="small"
      iconName="trash-outline"
      (click)="confirmDialog.open()"
    ></dr-button>
  }

  <dr-checkbox
    class="highlight"
    [fControl]="formGroup.controls['primary']"
    labelPlacement="start"
  >
    <dr-text>
      <span>{{ 'subscriptions.subscription.highlight' | translate }}</span>
    </dr-text>
  </dr-checkbox>

  <dr-input
    [fControl]="formGroup.controls['oldPrice']"
    [label]="'subscriptions.subscription.old_price_label' | translate"
    [mask]="priceMask"
  ></dr-input>

  <dr-input
    [fControl]="formGroup.controls['currentPrice']"
    [label]="'subscriptions.subscription.current_price_label' | translate"
    [mask]="priceMask"
    [required]="true"
  ></dr-input>

  <dr-select
    [interface]="IsWebVersion() ? 'popover' : 'action-sheet'"
    [fControl]="formGroup.controls['title']"
    [options]="subscriptionsNames"
    [label]="'subscriptions.subscription.title' | translate"
  ></dr-select>

  <dr-select
    [interface]="IsWebVersion() ? 'popover' : 'action-sheet'"
    [fControl]="$any(formGroup.controls['bulletPoints'])"
    [options]="bulletPoints"
    [multiple]="true"
    [label]="'subscriptions.subscription.bullet_points' | translate"
  ></dr-select>
</div>

<dr-confirm
  #confirmDialog
  confirmButtonColor="danger"
  [title]="
    'subscriptions.subscription.remove_subscription_question' | translate
  "
  (confirm)="remove.emit()"
></dr-confirm>

<ion-modal
  [isOpen]="!!previewSubscriptionItem"
  (didDismiss)="previewSubscriptionItem = undefined"
>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title></ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="previewSubscriptionItem = undefined">
            <ion-icon name="close-outline" size="large"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div class="preview">
        @if (previewSubscriptionItem) {
          <dr-company-subscription-item
            [primary]="previewSubscriptionItem.primary"
            [title]="previewSubscriptionItem.title"
            [oldPrice]="previewSubscriptionItem.oldPrice"
            [currentPrice]="previewSubscriptionItem.currentPrice"
            [bulletPoints]="previewSubscriptionItem.bulletPoints"
            [annuallyDiscount]="annuallyDiscount"
          ></dr-company-subscription-item>
        }
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
