import { Injectable } from '@angular/core';
import { ResetCurrentUserDataAction } from '@dr/utils';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { first, Observable } from 'rxjs';
import { appThemes } from '../../utils/app-themes';
import { AppTheme, ThemeActions, ThemeActionsName } from './theme.actions';

export interface ThemeStateModel {
  appTheme: AppTheme;
}

const defaults: ThemeStateModel = {
  appTheme: 'system',
};

@State<ThemeStateModel>({
  name: ThemeActionsName,
  defaults,
})
@Injectable()
export class ThemeState {
  @Select(ThemeState) state$!: Observable<ThemeStateModel>;
  @Select(ThemeState.appTheme) appTheme$!: Observable<AppTheme>;

  appThemeOnce$ = this.appTheme$.pipe(first());

  constructor(private store: Store) {}

  @Selector()
  static appTheme(state: ThemeStateModel) {
    return state.appTheme;
  }

  init(): void {
    this.appThemeOnce$.subscribe((appTheme) => {
      this.setAppTheme$(appTheme);
    });

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', () => {
        this.appThemeOnce$.subscribe((appTheme) => {
          this.setAppTheme$(appTheme);
        });
      });
  }

  setAppTheme$(appTheme: AppTheme) {
    return this.store.dispatch(new ThemeActions.SetAppTheme(appTheme));
  }

  @Action(ThemeActions.SetAppTheme)
  private _setAppTheme$(
    ctx: StateContext<ThemeStateModel>,
    action: ThemeActions.SetAppTheme
  ) {
    const html = document.getElementsByTagName('html').item(0)?.classList;
    let appTheme = action.appTheme;

    if (action.appTheme === 'system') {
      appTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
    }

    html?.remove(...appThemes.map(({ value }) => value));
    html?.add(appTheme);

    ctx.patchState({
      appTheme: action.appTheme,
    });

    // eslint-disable-next-line no-console
    console.log('App theme: ', appTheme);
  }

  @Action(ResetCurrentUserDataAction)
  private reset(ctx: StateContext<ThemeStateModel>): void {
    ctx.setState(defaults);
  }
}
