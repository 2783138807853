import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Company } from '@dr/company';
import { User } from '@dr/user';
import {
  PaginationList,
  PaginationParams,
  RegularSuccessResponse,
} from '@dr/utils';
import { Observable } from 'rxjs';
import {
  CompanyConnection,
  Connection,
  CalculateConnections,
  UserConnection,
} from '../interfaces/connection';

@Injectable()
export class ConnectionsApiService {
  private readonly httpClient = inject(HttpClient);

  countUserConnections$(userId: string): Observable<CalculateConnections> {
    return this.httpClient.get<CalculateConnections>(
      `/api/connections/count/user/${userId}`
    );
  }

  countCompanyConnections$(
    companyId: string
  ): Observable<CalculateConnections> {
    return this.httpClient.get<CalculateConnections>(
      `/api/connections/count/company/${companyId}`
    );
  }

  countNewRequests$(): Observable<number> {
    return this.httpClient.get<number>('/api/connections/requests/count');
  }

  getConnectionStatusWithUser$(userId: string): Observable<Connection> {
    return this.httpClient.get<Connection>(
      `/api/connections/status/user/${userId}`
    );
  }

  getConnectionStatusWithCompany$(companyId: string): Observable<Connection> {
    return this.httpClient.get<Connection>(
      `/api/connections/status/company/${companyId}`
    );
  }

  newRequestsList$(
    params: PaginationParams
  ): Observable<PaginationList<Connection>> {
    return this.httpClient.get<PaginationList<Connection>>(
      '/api/connections/requests/list',
      {
        params: {
          ...params,
        },
      }
    );
  }

  usersList$(
    userId: string,
    params: PaginationParams
  ): Observable<PaginationList<Connection>> {
    return this.httpClient.get<PaginationList<Connection>>(
      `/api/connections/${userId}/users/list`,
      {
        params: {
          ...params,
        },
      }
    );
  }

  companiesList$(
    companyId: Company['id'],
    params: PaginationParams
  ): Observable<PaginationList<Connection>> {
    return this.httpClient.get<PaginationList<Connection>>(
      `/api/connections/${companyId}/companies/list`,
      {
        params: {
          ...params,
        },
      }
    );
  }

  findPossibleUserConnections$(
    params: PaginationParams
  ): Observable<UserConnection[]> {
    return this.httpClient.get<UserConnection[]>(
      '/api/connections/users/find',
      {
        params: {
          ...params,
        },
      }
    );
  }

  findPossibleCompanyConnections$(
    params: PaginationParams
  ): Observable<CompanyConnection[]> {
    return this.httpClient.get<CompanyConnection[]>(
      '/api/connections/companies/find',
      {
        params: {
          ...params,
        },
      }
    );
  }

  requestToUser$(userId: string): Observable<User> {
    return this.httpClient.post<User>(
      `/api/connections/request/user/${userId}`,
      {}
    );
  }

  requestToCompany$(companyId: string): Observable<User> {
    return this.httpClient.post<User>(
      `/api/connections/request/company/${companyId}`,
      {}
    );
  }

  addUser$(qrId: string): Observable<User> {
    return this.httpClient.post<User>(`/api/connections/add/user/${qrId}`, {});
  }

  addCompany$(qrId: string): Observable<User> {
    return this.httpClient.post<User>(
      `/api/connections/add/company/${qrId}`,
      {}
    );
  }

  approveUserRequest$(userId: string): Observable<RegularSuccessResponse> {
    return this.httpClient.post<RegularSuccessResponse>(
      `/api/connections/approve/user/${userId}`,
      {}
    );
  }

  approveCompanyRequest$(
    companyId: string
  ): Observable<RegularSuccessResponse> {
    return this.httpClient.post<RegularSuccessResponse>(
      `/api/connections/approve/company/${companyId}`,
      {}
    );
  }

  deleteUserConnection$(userId: string): Observable<RegularSuccessResponse> {
    return this.httpClient.delete<RegularSuccessResponse>(
      `/api/connections/delete/user/${userId}`,
      {}
    );
  }

  deleteCompanyConnection$(
    companyId: string
  ): Observable<RegularSuccessResponse> {
    return this.httpClient.delete<RegularSuccessResponse>(
      `/api/connections/delete/company/${companyId}`,
      {}
    );
  }
}
