import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dr-loading',
  templateUrl: 'loading.component.html',
  styleUrls: ['loading.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  show = input<boolean>();
  message = input<string>();
  inner = input<boolean>();
  hasBackdrop = input<boolean>(true);
}
