import { Injectable } from '@angular/core';
import { ResetCurrentUserDataAction } from '@dr/utils';
import { TranslateService } from '@ngx-translate/core';
import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { TimeagoIntl } from 'ngx-timeago';
import { first, map, Observable } from 'rxjs';
import { strings as englishStrings } from 'ngx-timeago/language-strings/en';
import { strings as ukStrings } from 'ngx-timeago/language-strings/uk';
import { AppLanguage, appLanguages } from '../../utils/app-languages';
import {
  LocalizationActions,
  LocalizationActionsName,
} from './localization.actions';

export interface LocalizationStateModel {
  appLanguage?: AppLanguage;
}

const defaults: LocalizationStateModel = {};

@State<LocalizationStateModel>({
  name: LocalizationActionsName,
  defaults,
})
@Injectable()
export class LocalizationState {
  @Select(LocalizationState) state$!: Observable<LocalizationStateModel>;

  appLanguage$ = this.state$.pipe(map((state) => state.appLanguage));

  appLanguageOnce$ = this.appLanguage$.pipe(first());

  constructor(
    private store: Store,
    private translateService: TranslateService,
    private timeagoIntl: TimeagoIntl
  ) {}

  init(): void {
    this.appLanguageOnce$.subscribe((language) => {
      this.setAppLanguage$(
        language || (this.translateService.getBrowserLang() as AppLanguage)
      );
    });
  }

  setAppLanguage$(appLanguage: AppLanguage) {
    const isAppLanguageSupported = appLanguages.some(
      (language) => language === appLanguage
    );

    if (!isAppLanguageSupported) {
      appLanguage = this.translateService.getBrowserLang() as AppLanguage;

      const isBrowserLanguageSupported = appLanguages.some(
        (language) => language === appLanguage
      );

      if (!isBrowserLanguageSupported) {
        appLanguage = appLanguages[0];
      }
    }

    return this.store.dispatch(
      new LocalizationActions.SetAppLanguage(appLanguage)
    );
  }

  @Action(LocalizationActions.SetAppLanguage)
  private _setAppLanguage$(
    ctx: StateContext<LocalizationStateModel>,
    action: LocalizationActions.SetAppLanguage
  ) {
    ctx.patchState({
      appLanguage: action.appLanguage,
    });

    this.translateService.setDefaultLang(action.appLanguage);

    switch (action.appLanguage) {
      case 'en':
        this.timeagoIntl.strings = englishStrings;
        break;
      case 'uk':
        this.timeagoIntl.strings = ukStrings;
        break;
      default:
        break;
    }

    this.timeagoIntl.changes.next();

    // eslint-disable-next-line no-console
    console.info('App language:', action.appLanguage);
  }

  @Action(ResetCurrentUserDataAction)
  private reset$(ctx: StateContext<LocalizationStateModel>): void {
    ctx.setState(defaults);
  }
}
