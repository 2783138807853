import { inject } from '@angular/core';
import { CurrentUserState } from '@dr/user';
import { NavController } from '@ionic/angular';
import { first, map, tap, withLatestFrom } from 'rxjs';
import { MyCompaniesState } from '../states/my-companies.state';

export const noSelectedCompany = () => {
  const myCompaniesState = inject(MyCompaniesState);
  const currentUserState = inject(CurrentUserState);
  const navController = inject(NavController);

  return currentUserState.checkedUserOnes$.pipe(
    withLatestFrom(myCompaniesState.activeCompany$),
    map(([, activeCompany]) => !activeCompany),
    tap((result) => {
      if (!result) {
        navController.navigateRoot('/company/subscriptions-pack', {
          animated: false,
        });
      }
    })
  );
};

export const hasSelectedCompany = () => {
  const myCompaniesState = inject(MyCompaniesState);
  const currentUserState = inject(CurrentUserState);
  const navController = inject(NavController);

  return currentUserState.checkedUserOnes$.pipe(
    withLatestFrom(myCompaniesState.activeCompany$),
    map(([, activeCompany]) => !!activeCompany),
    tap((result) => {
      if (!result) {
        navController.navigateRoot('/company/select', {
          animated: false,
        });
      }
    })
  );
};

export const hasCompanySubscription = () => {
  const myCompaniesState = inject(MyCompaniesState);
  const navController = inject(NavController);

  return myCompaniesState?.activeCompany$.pipe(
    first(),
    map((activeCompany) => {
      if (!activeCompany) {
        navController.navigateRoot('/company/select', {
          animated: false,
        });

        return false;
      } else if (!activeCompany.as) {
        navController.navigateRoot('/company/subscriptions-pack', {
          animated: false,
        });

        return false;
      } else {
        return true;
      }
    })
  );
};
