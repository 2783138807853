import { InjectionToken } from '@angular/core';

export interface AppEnvironmentI {
  production: boolean;
  apiUri: string;
  socketUrl: string;
  appPrefix: 'admin' | 'client' | 'business';
  supportEmail: string;
}

export const AppEnvironment = new InjectionToken<AppEnvironmentI>(
  'AppEnvironment'
);
