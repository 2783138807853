import { Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import {
  AvatarComponent,
  ButtonComponent,
  ConfirmComponent,
  LinkComponent,
  TextareaComponent,
  TextComponent,
} from '@dr/ui';
import {
  CountyService,
  KeyboardDirective,
  openLocation,
  ValidationService,
} from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { first } from 'rxjs';
import { CompaniesModule } from '../../companies.module';
import { CompanyUpdatesModule } from '../../company-updates.module';
import { Company, CompanyRequestChanges } from '../../interfaces/company';
import { CompanyDetailsState } from '../../states/company-details.state';
import { CompanyUpdatesState } from '../../states/company-updates.state';

@Component({
  selector: 'dr-company-request-changes',
  templateUrl: 'company-request-changes.component.html',
  styleUrls: ['company-request-changes.component.scss'],
  imports: [
    IonicModule,
    AvatarComponent,
    ButtonComponent,
    LinkComponent,
    TextComponent,
    TranslateModule,
    ConfirmComponent,
    KeyboardDirective,
    TextareaComponent,
    CompanyUpdatesModule,
    CompaniesModule,
  ],
  providers: [CountyService],
  standalone: true,
})
export class CompanyRequestChangesComponent {
  @Input({ required: true }) company!: Company;
  @Input({ required: true }) requestChange!: CompanyRequestChanges;

  readonly openLocation = openLocation;

  rejectMessageControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.companyRejectMessageValidators
  );

  private destroyRef = inject(DestroyRef);

  constructor(
    public companyUpdatesState: CompanyUpdatesState,
    public validationService: ValidationService,
    private countyService: CountyService,
    private companyDetailsState: CompanyDetailsState
  ) {}

  getCountry(country: string) {
    return this.countyService.countries?.find((c) => c.value === country)
      ?.label;
  }

  rejectRequest() {
    this.companyUpdatesState
      .rejectCompanyRequestChanges$(
        this.company.id,
        this.rejectMessageControl.value as string
      )
      .pipe(first(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.companyDetailsState.updateCompanyData$(this.company.id, {
          rch: undefined,
        });
      });
  }

  resetRejectModal() {
    this.rejectMessageControl.patchValue('');
    this.rejectMessageControl.reset();
  }

  approve() {
    this.companyUpdatesState
      .approveCompanyRequestChanges$(this.requestChange.id)
      .pipe(first(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.companyDetailsState.updateCompanyData$(this.company.id, {
          rch: undefined,
        });
      });
  }
}
