<ion-progress-bar
  class="animate__animated animate__fadeInUp"
  color="primary"
  [value]="progress()"
></ion-progress-bar>

<div class="question-wrapper">
  <div class="question-container">
    <dr-button
      fill="clear"
      color="primary"
      iconName="arrow-back-outline"
      class="back-btn"
      (click)="goBack()"
    ></dr-button>

    @for (step of steps; track $index) {
      @if (step.id === currentStepId()) {
        <dr-text>
          <h1>{{ step.title | translate }}</h1>
        </dr-text>

        <dr-hint
          [text]="'company.survey.generic_hint' | translate"
          color="medium"
        ></dr-hint>

        <div class="form">
          @if (currentStepId() === 'name') {
            <dr-input
              class="animate__animated animate__fast animate__fadeInDown"
              autocapitalize="on"
              [fControl]="nameControl"
              [maxlength]="100"
              [required]="true"
              [placeholder]="'company.survey.company_name' | translate"
              [errors]="
                validationService.appValidationRules.companyNameErrorMessages()
              "
            ></dr-input>
          } @else if (currentStepId() === 'link') {
            <dr-input
              class="animate__animated animate__fast animate__fadeInDown"
              [fControl]="linkControl"
              [maxlength]="100"
              [placeholder]="'company.survey.company_link' | translate"
              [errors]="
                validationService.appValidationRules.companyLinkErrorMessages()
              "
            ></dr-input>
          } @else if (currentStepId() === 'employees-size') {
            <dr-select
              class="animate__animated animate__fast animate__fadeInDown"
              [interface]="IsWebVersion() ? 'popover' : 'action-sheet'"
              [fControl]="$any(employeesSizeControl)"
              [options]="employeesSizes"
              [required]="true"
              [placeholder]="'company.survey.company_size' | translate"
            ></dr-select>
          } @else if (currentStepId() === 'clients-size') {
            <dr-select
              class="animate__animated animate__fast animate__fadeInDown"
              [interface]="IsWebVersion() ? 'popover' : 'action-sheet'"
              [fControl]="$any(clientsSizeControl)"
              [options]="clientsSizes"
              [required]="true"
              [placeholder]="'company.survey.company_clients_size' | translate"
            ></dr-select>
          } @else if (currentStepId() === 'niche') {
            <dr-input
              class="animate__animated animate__fast animate__fadeInDown"
              autocapitalize="on"
              [fControl]="nicheControl"
              [maxlength]="200"
              [required]="true"
              [placeholder]="'company.survey.company_niche' | translate"
              [errors]="
                validationService.appValidationRules.companyNicheErrorMessages()
              "
            ></dr-input>
          } @else if (currentStepId() === 'logo') {
            <dr-file-uploader
              class="animate__animated animate__fast animate__fadeInDown"
              attachFrom="images"
              [fControl]="$any(logoControl)"
            ></dr-file-uploader>
          } @else if (currentStepId() === 'country') {
            <dr-select
              class="animate__animated animate__fast animate__fadeInDown"
              [interface]="IsWebVersion() ? 'popover' : 'action-sheet'"
              [fControl]="$any(countryControl)"
              [options]="countyService.countries"
              [required]="true"
              [placeholder]="'company.survey.company_country' | translate"
              [errors]="
                validationService.appValidationRules.companyCountryErrorMessages()
              "
            ></dr-select>
          } @else if (currentStepId() === 'place') {
            <dr-input
              class="animate__animated animate__fast animate__fadeInDown"
              [fControl]="placeControl"
              [maxlength]="200"
              [placeholder]="'company.survey.company_place' | translate"
              [errors]="
                validationService.appValidationRules.companyPlaceErrorMessages()
              "
            ></dr-input>
          } @else if (currentStepId() === 'source' && companySources) {
            <dr-select
              class="animate__animated animate__fast animate__fadeInDown"
              [interface]="IsWebVersion() ? 'popover' : 'action-sheet'"
              [fControl]="sourceControl"
              [options]="companySources"
              [placeholder]="'company.survey.company_source' | translate"
              [errors]="
                validationService.appValidationRules.companySourceErrorMessages()
              "
            ></dr-select>

            @if (sourceControl.value === 'other') {
              <dr-textarea
                class="animate__animated animate__fast animate__fadeInDown"
                autocapitalize="sentences"
                enterKey="enter"
                [fControl]="sourceOtherControl"
                [minRows]="1"
                [maxRows]="5"
                [required]="true"
                [maxlength]="200"
                [placeholder]="
                  'company.survey.company_source_other' | translate
                "
                [errors]="
                  validationService.appValidationRules.companySourceOtherErrorMessages()
                "
              ></dr-textarea>
            }
          }
        </div>

        <div class="actions">
          @if (step.canSkip) {
            <dr-button
              fill="clear"
              color="primary"
              [label]="'company.survey.skip' | translate"
              (click)="goToNext(true)"
            ></dr-button>
          }

          <dr-button
            color="primary"
            [label]="
              (step.nextId ? 'company.survey.next' : 'company.survey.finish')
                | translate
            "
            iconName="arrow-forward-outline"
            [disabled]="!canGoNext"
            (click)="goToNext()"
          ></dr-button>
        </div>
      }
    }
  </div>
</div>
