import {
  DestroyRef,
  Directive,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { Actions, ofActionSuccessful } from '@ngxs/store';

export class ResetCurrentUserDataAction {
  static readonly type = '[ActiveData] Reset Current User';
}

export class ActiveCompanyChangedAction {
  static readonly type = '[ActiveData] Active Company Changed';
}

@Directive()
export class SmartComponent implements OnInit, OnDestroy {
  protected destroyRef = inject(DestroyRef);
  protected actions$ = inject(Actions);

  private appStateChangeListener?: PluginListenerHandle;

  async ngOnInit() {
    this.appStateChangeListener = await App.addListener(
      'appStateChange',
      ({ isActive }) => {
        if (isActive) {
          this.onResumeApp();
        } else {
          this.onPauseApp();
        }
      }
    );

    this.actions$
      .pipe(ofActionSuccessful(ResetCurrentUserDataAction))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.onResetCurrentUser());

    this.actions$
      .pipe(ofActionSuccessful(ActiveCompanyChangedAction))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.onActiveCompanyChanged());
  }

  ngOnDestroy() {
    this.appStateChangeListener?.remove();
  }

  onResumeApp(): void {}

  onPauseApp(): void {}

  onResetCurrentUser(): void {}

  onActiveCompanyChanged(): void {}
}
