@if (currentUserState.currentUser$ | async; as currentUser) {
  @if (currentUser.mcs?.length) {
    <div class="company-items">
      @if (currentUser.mcs) {
        @for (company of currentUser.mcs; track $index) {
          <div
            class="company-item"
            (click)="
              company.s === 'approved'
                ? setActiveCompany.emit(company)
                : setRequestCompany.emit(company)
            "
          >
            <div class="company-item-content">
              <dr-text>
                <div class="title">{{ company.nm }}</div>
              </dr-text>

              <dr-text color="medium">
                <small>{{ company.nch }}</small>
              </dr-text>
            </div>

            @if (company.s === 'pending') {
              <ion-chip color="medium">{{
                'status.pending' | translate
              }}</ion-chip>
            } @else if (company.s === 'rejected') {
              <ion-chip color="danger">{{
                'status.rejected' | translate
              }}</ion-chip>
            } @else if (company.s === 'approved') {
              <ion-chip color="success">{{
                'status.owner' | translate
              }}</ion-chip>

<!--              <ion-chip color="secondary">{{-->
<!--                  'status.employee' | translate-->
<!--                }}</ion-chip>-->
            }
          </div>
        }
      }
    </div>
  } @else {
    <div class="empty-template">
      <div class="empty-template-block animate__animated animate__fadeInUp">
        <dr-text [centered]="true">
          <div>{{ 'company.list.no_company_message' | translate }}</div>
        </dr-text>

        <dr-button
          class="create-btn"
          size="small"
          color="tertiary"
          [label]="'company.list.add_company' | translate"
          (click)="addCompany.emit()"
        ></dr-button>
      </div>

      <div
        class="empty-template-block animate__animated animate__fadeInUp animate__delay-1s"
      >
        <dr-text [centered]="true">
          <div>{{ 'company.list.become_company_member' | translate }}</div>
        </dr-text>

        <dr-button
          iconName="qr-code"
          iconSize="large"
          fill="clear"
          color="tertiary"
          (click)="showQr.emit()"
        ></dr-button>
      </div>
    </div>
  }
}
