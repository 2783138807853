import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LinkComponent, TextComponent } from '@dr/ui';
import { IonicModule } from '@ionic/angular';
import { IsWebVersion } from '@dr/utils';
import {
  CompanySubscription,
  CompanySubscriptionsPack,
  SubscriptionPeriod,
} from '../../interfaces/company';
import { CompanySubscriptionItemComponent } from '../company-subscription-item/company-subscription-item.component';

@Component({
  selector: 'dr-company-subscriptions-pack',
  templateUrl: './company-subscriptions-pack.component.html',
  styleUrls: ['./company-subscriptions-pack.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CompanySubscriptionItemComponent,
    TextComponent,
    LinkComponent,
  ],
})
export class CompanySubscriptionsPackComponent {
  @Input({ required: true }) subscriptionsPack!: CompanySubscriptionsPack;
  @Input() animated?: boolean = true;

  @Output() selectSubscription = new EventEmitter<{
    subscription: CompanySubscription;
    period: SubscriptionPeriod;
  }>();

  readonly IsWebVersion = IsWebVersion();

  subscribe(subscription: CompanySubscription, period: SubscriptionPeriod) {
    this.selectSubscription.emit({
      subscription,
      period,
    });
  }
}
