import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonColor } from '@dr/utils';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-logo',
  templateUrl: 'logo.component.html',
  styleUrls: ['logo.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TextComponent],
})
export class LogoComponent {
  @Input() type?: 'onlyIcon' | 'iconText' | 'onlyText' = 'onlyIcon';
  @Input() logoSize?: 'small' | 'medium' | 'large' | 'default' = 'default';
  @Input() textColor?: IonColor = 'primary';
}
