<ion-select
  toggleIcon="chevron-down-outline"
  [interface]="interface"
  [cancelText]="'Cancel' | translate"
  [formControl]="fControl"
  [required]="required"
  [labelPlacement]="disabled || placeholder ? 'stacked' : 'floating'"
  [placeholder]="placeholder"
  [class.disabled]="disabled"
  [interfaceOptions]="{ arrow: false }"
  [multiple]="multiple"
  (ionChange)="!disabled && changed.emit($any($event)?.detail?.value)"
  (ionBlur)="validate()"
>
  @if (label) {
    <div class="label" slot="label">
      {{ label }}
      @if (required) {
        <dr-text color="danger">*</dr-text>
      }
    </div>
  }
  @for (option of options; track $index) {
    <ion-select-option [value]="option.value">{{
      option.label
    }}</ion-select-option>
  }
</ion-select>

@if (errorText()) {
  <dr-text color="danger">
    <small>{{ errorText() }}</small>
  </dr-text>
}
