@if (uploading()) {
  <ion-spinner></ion-spinner>
} @else {
  @if (!fControl.value) {
    <div class="form" (click)="attach()">
      <ion-icon name="images-outline"></ion-icon>
      <dr-text>
        <small>{{
          label || ((multiple ? 'Add files' : 'Add file') | translate)
        }}</small>
      </dr-text>
    </div>
  }

  @if (attachedFile; as file) {
    <div class="file-container">
      <div style="height: 25px"></div>
      <dr-text>
        <small class="text">{{ $any(file)?.name || $any(file)?.nm }}</small>
      </dr-text>

      <div class="file-container-footer">
        <dr-text color="medium">
          <small class="text">{{ attachedFileSize }}</small>
        </dr-text>

        <div class="delete-action" (click)="deleteFile()">
          <ion-icon name="close-outline"></ion-icon>
        </div>
      </div>
    </div>
  }
}
