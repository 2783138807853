import { Injectable } from '@angular/core';
import { ToastButton, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IonColor } from '../types/ion-color';

export interface ToastParams {
  message: string;
  messageParams?: object;
  skipTranslation?: boolean;
  position?: 'top' | 'middle' | 'bottom';
  color?: IonColor;
  duration?: number;
  translucent?: boolean;
  header?: string;
  buttons?: ToastButton[];
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  async showToast(params: ToastParams) {
    const toast = await this.toastController.create({
      ...params,
      message: params.skipTranslation
        ? params.message
        : this.translateService.instant(params.message, params.messageParams),
      translucent: params.translucent ?? true,
      duration: params.duration ?? 2000,
      color: params.color ?? 'success',
      position: params.position ?? 'top',
      buttons: params.buttons,
    });

    await toast.present();

    return toast;
  }
}
