import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AvatarComponent } from '../../avatar/avatar.component';
import { TextComponent } from '../../text/text.component';

@Component({
  selector: 'dr-entity-name',
  templateUrl: 'entity-name.component.html',
  styleUrls: ['entity-name.component.scss'],
  standalone: true,
  imports: [IonicModule, TextComponent, AvatarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityNameComponent {
  firstName = input<string>();
  imgUrl = input<string>();
  lastName = input<string>();
  subtitle = input<string>();
  color = input<string>();

  fullName = computed(() =>
    `${this.firstName() || ''} ${this.lastName() || ''}`.trim()
  );
}
