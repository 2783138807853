<div class="search-wrapper" [class.disabled]="!!disabled">
  <div class="input-container">
    <dr-input
      [fControl]="searchControl"
      [maxlength]="maxlength"
      [required]="required"
      [placeholder]="placeholder"
      [label]="label"
      [errors]="errors"
      [debounce]="debounce"
      [autocapitalize]="autocapitalize"
      (focusIn)="isPopoverOpened = true"
      (click)="$event.stopPropagation();"
      (enter)="search.emit($event)"
    ></dr-input>

    @if (loading) {
      <ion-spinner></ion-spinner>
    }
  </div>

  @if (isPopoverOpened && options.length) {
    <div class="popover-content">
      @for (option of options; track $index) {
        <div
          class="option">
          <dr-entity-name
            [firstName]="option.label"
            [imgUrl]="option.img"
            [color]="option.color"
            (click)="addOption(option)"
          ></dr-entity-name>

          @if (optionSelected(option)) {
            <ion-icon name="checkmark-outline"></ion-icon>
          }
        </div>
      }

    </div>
  }

</div>

<div class="chips-container" [class.disabled]="!!disabled">
  @for (item of fControl.value; track $index) {
    <ion-chip>
      @if (item.img) {
        <ion-avatar>
          <img alt="Avatar" [src]="item.img"/>
        </ion-avatar>
      }

      <ion-label>{{item.label}}</ion-label>
      <ion-icon (click)="removeOption(item)" name="close-circle"></ion-icon>
    </ion-chip>
  }
</div>
