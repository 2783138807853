<div [class.disabled]="disabled">
  @if (!mask) {
    <ion-input
      [formControl]="fControl"
      [placeholder]="placeholder"
      [type]="inputType"
      [clearInput]="type !== 'password'"
      [autocomplete]="inputType"
      [autocapitalize]="autocapitalize"
      [helperText]="helperText"
      [errorText]="errorText()"
      [labelPlacement]="disabled || placeholder ? 'stacked' : 'floating'"
      [maxlength]="$any(maxlength)"
      [minlength]="$any(minlength)"
      [counter]="counter"
      [counterFormatter]="counterFormatter"
      [autofocus]="autofocus"
      [debounce]="debounce"
      [enterkeyhint]="enterKey"
      [inputmode]="inputMode"
      [clearOnEdit]="false"
      [max]="max"
      [min]="min"
      [step]="step"
      [readonly]="disabled"
      [required]="$any(required)"
      [spellcheck]="spellcheck"
      (ionInput)="!disabled && enter.emit(fControl.value)"
      (ionFocus)="!disabled && focusIn.emit(fControl.value)"
      (ionBlur)="!disabled && focusOut.emit(fControl.value)"
    >
      @if (label) {
        <div slot="label">
          {{ label }}
          @if (required) {
            <dr-text color="danger">*</dr-text>
          }
        </div>
      }

      @if (type === 'password') {
        <ion-button
          slot="end"
          fill="clear"
          size="small"
          (click)="togglePassword()"
        >
          <ion-icon
            slot="icon-only"
            size="small"
            [name]="passwordVisible ? 'eye-outline' : 'eye-off-outline'"
          ></ion-icon>
        </ion-button>
      }
    </ion-input>
  } @else {
    <ion-input
      [formControl]="fControl"
      [placeholder]="placeholder"
      [type]="inputType"
      [clearInput]="type !== 'password'"
      [autocomplete]="inputType"
      [helperText]="helperText"
      [errorText]="errorText()"
      [labelPlacement]="disabled || placeholder ? 'stacked' : 'floating'"
      [maxlength]="$any(maxlength)"
      [minlength]="$any(minlength)"
      [counter]="counter"
      [counterFormatter]="counterFormatter"
      [autofocus]="autofocus"
      [debounce]="debounce"
      [enterkeyhint]="enterKey"
      [inputmode]="inputMode"
      [max]="max"
      [min]="min"
      [step]="step"
      [readonly]="disabled"
      [clearOnEdit]="false"
      [required]="$any(required)"
      [spellcheck]="spellcheck"
      [maskito]="$any(mask)"
      [maskitoElement]="maskElement"
      (ionInput)="!disabled && enter.emit(fControl.value)"
      (ionBlur)="!disabled && focusOut.emit(fControl.value)"
    >
      @if (label) {
        <div slot="label">
          {{ label }}
          @if (required) {
            <dr-text color="danger">*</dr-text>
          }
        </div>
      }
    </ion-input>
  }
</div>
