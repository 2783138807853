<div class="content">
  <ng-content></ng-content>
</div>

@if (!premium) {
  <span class="button">
    <ion-icon name="lock-open" color="dark"></ion-icon>
  </span>
}

<ion-popover
  [showBackdrop]="false"
  [isOpen]="isPopoverOpened"
  (didDismiss)="isPopoverOpened = false"
>
  <ng-template>
    <div class="popover-content">
      <dr-text [small]="true">{{
        'Premium account required' | translate
      }}</dr-text>
    </div>
  </ng-template>
</ion-popover>
