import {
  Component,
  computed,
  DestroyRef,
  HostListener,
  inject,
  Input,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { CompaniesState, CompanyUpdatesState } from '@dr/company';
import { ContactFormState } from '@dr/contact-form';
import { AppFeaturesState } from '@dr/features';
import { NotificationsState } from '@dr/notifications';
import { NavigationMenuItem } from '@dr/ui';
import { CurrentUserState, UsersState } from '@dr/user';
import { IsWebVersion, PushNotificationsService } from '@dr/utils';
import {
  IonMenu,
  IonPopover,
  NavController,
  PopoverController,
} from '@ionic/angular';
import { combineLatest, map, take, tap, timer } from 'rxjs';

@Component({
  selector: 'dr-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  @ViewChild('notificationsPopover') notificationsPopover?: IonPopover;

  @Input({ required: true }) ionMenu!: IonMenu;

  userAccordionState = signal<'collapsed' | 'expanded'>('collapsed');
  userAccordionArrowState = computed(() =>
    this.userAccordionState() === 'expanded'
      ? 'chevron-up-outline'
      : 'chevron-down-outline'
  );

  public headerMenuItems: NavigationMenuItem[] = [
    {
      title: 'Settings',
      url: () => '/settings',
      leftIcon: 'settings-outline',
      rootNavigation: true,
      callback: () => {
        this.ionMenu.close();
        this.userAccordionState.set('collapsed');
      },
    },
  ];

  public contentMenuItems: NavigationMenuItem[] = [
    {
      title: 'Users',
      url: () => '/users',
      leftIcon: 'people-outline',
      rootNavigation: true,
      callback: () => {
        this.ionMenu.close();
        this.userAccordionState.set('collapsed');
      },
    },
    {
      title: 'Contact form',
      url: () => '/contact-form',
      leftIcon: 'warning-outline',
      counters$: combineLatest([
        this.contactFormState.attentionTopicsCounter$,
        this.contactFormState.pendingTopicsCounter$,
      ]).pipe(
        map(([attentionTopicsCounter, pendingTopicsCounter]) => [
          {
            value: attentionTopicsCounter,
            color: 'warning',
            tooltip: 'contact_form.attention_topics',
          },
          {
            value: pendingTopicsCounter,
            color: 'secondary',
            tooltip: 'contact_form.pending_topics',
          },
        ])
      ),
      hidden$: this.appFeaturesState
        .isFeatureAvailable$('cf')
        .pipe(map((available) => !available)),
      rootNavigation: true,
      callback: () => {
        this.ionMenu.close();
        this.userAccordionState.set('collapsed');
      },
    },
    {
      title: 'company.list.title',
      url: () => '/companies/list',
      leftIcon: 'business-outline',
      counters$: this.companiesState.pending$.pipe(
        map((state) => [
          {
            value: state?.count || 0,
            color: 'warning',
            tooltip: 'company.list.new_requests',
          },
        ])
      ),
      rootNavigation: true,
      callback: () => {
        this.ionMenu.close();
        this.userAccordionState.set('collapsed');
      },
    },
    {
      title: 'company.request_changes.title',
      url: () => '/companies/request-changes',
      leftIcon: 'build-outline',
      counters$: this.companyUpdatesState.countRequestChanges$.pipe(
        map((value) => [
          {
            value: value || 0,
            color: 'secondary',
            tooltip: 'company.request_changes.new_request_changes',
          },
        ])
      ),
      rootNavigation: true,
      callback: () => {
        this.ionMenu.close();
        this.userAccordionState.set('collapsed');
      },
    },
    {
      title: 'subscriptions.title',
      url: () => '/subscriptions',
      leftIcon: 'pricetags-outline',
      rootNavigation: true,
      callback: () => {
        this.ionMenu.close();
        this.userAccordionState.set('collapsed');
      },
    },
    {
      title: 'notifications.send_form.title',
      url: () => '/inform',
      leftIcon: 'send-outline',
      rootNavigation: true,
      callback: () => {
        this.ionMenu.close();
        this.userAccordionState.set('collapsed');
      },
    },
  ];

  notifications$ = this.notificationsState.notifications$.pipe(
    tap((notifications) => {
      if (notifications?.length) {
        this.notificationsState.readAllUnread$();
      }
    })
  );

  private destroyRef = inject(DestroyRef);

  constructor(
    public currentUserState: CurrentUserState,
    public notificationsState: NotificationsState,
    public usersState: UsersState,
    public router: Router,
    private navController: NavController,
    private popoverController: PopoverController,
    private pushNotificationsService: PushNotificationsService,
    private contactFormState: ContactFormState,
    private appFeaturesState: AppFeaturesState,
    private companiesState: CompaniesState,
    private companyUpdatesState: CompanyUpdatesState
  ) {}

  ngOnInit() {
    this.notificationsState.getCountUnreadNotifications$();
    this.contactFormState.countAttentionTopics$();
    this.contactFormState.countPendingTopics$();
    this.companiesState.countByStatus$();
    this.companyUpdatesState.countCompanyRequestChanges$();

    this.initPushNotifications();
    this.notificationsState.listenNotificationsEvents();
    this.companiesState.listenSocketsEvents();
    this.initOnlineData();
  }

  openNotifications(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (IsWebVersion()) {
      this.notificationsState
        .getNotificationsList$()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();

      this.notificationsPopover?.present(event as MouseEvent);
      this.notificationsState.updateNotificationsVisibility(true);
    } else {
      this.navController.navigateRoot('/notifications');
    }

    setTimeout(() => this.ionMenu.close(), 200);
  }

  loadMoreNotifications() {
    this.notificationsState
      .loadMore$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  openProfile(route: string, event?: Event) {
    if (event) {
      event.stopPropagation();
    }

    this.userAccordionState.set('collapsed');
    this.navController.navigateRoot(route);

    setTimeout(() => this.ionMenu.close(), 200);
  }

  toggleUserAccordion(): void {
    this.userAccordionState.set(
      this.userAccordionState() === 'collapsed' ? 'expanded' : 'collapsed'
    );
  }

  @HostListener('window:resize')
  private async resizeTrigger() {
    const popover = await this.popoverController.getTop();

    if (popover) {
      this.popoverController.dismiss();
    }
  }

  private initPushNotifications(): void {
    this.pushNotificationsService.token$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((fbt) => this.currentUserState.setFBT$(fbt));

    this.pushNotificationsService.init();
  }

  private initOnlineData(): void {
    timer(1000, 30 * 1000)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.usersState.getOnlineUsersData$());
  }
}
