import { AsyncPipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import {
  ButtonComponent,
  InputComponent,
  LinkComponent,
  TextComponent,
} from '@dr/ui';
import { ValidationService } from '@dr/utils';
import { TranslateModule } from '@ngx-translate/core';
import { AuthState } from '../../states/auth/auth.state';

@Component({
  selector: 'dr-forgot-password',
  templateUrl: 'forgot-password.component.html',
  styleUrls: ['../../auth.scss', './forgot-password.component.scss'],
  imports: [
    TextComponent,
    InputComponent,
    TranslateModule,
    ButtonComponent,
    LinkComponent,
    AsyncPipe,
  ],
  standalone: true,
})
export class ForgotPasswordComponent implements OnDestroy {
  @ViewChild('email') email?: InputComponent<string>;

  @Output() submitted = new EventEmitter<void>();
  @Output() signIn = new EventEmitter<void>();

  emailControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.emailValidators
  );

  private destroyRef = inject(DestroyRef);

  constructor(
    public validationService: ValidationService,
    public authState: AuthState
  ) {}

  ngOnDestroy() {
    this.authState.resetForgotPasswordData();
  }

  send(): void {
    this.email?.validate();

    if (this.emailControl.invalid) {
      return;
    }

    this.authState
      .forgotPassword$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.submitted.emit();
      });
  }
}
