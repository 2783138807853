import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileApiService } from './services/file-api.service';
import { FileService } from './services/file.service';

@NgModule({
  imports: [CommonModule],
  providers: [FileService, FileApiService],
})
export class FileModule {}
