import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { first } from 'rxjs';
import { AppFeatures } from '../services/features-api.service';
import { AppFeaturesState } from '../states/app-features/app-features.state';

@Directive({
  selector: '[drHasFeature]',
})
export class HasFeatureDirective {
  @Input() set drHasFeature(params: {
    name: keyof AppFeatures;
    defaultValue?: boolean;
    noFeatureTemplate?: TemplateRef<unknown>;
  }) {
    this.appFeaturesState
      .isFeatureAvailable$(params.name, params.defaultValue)
      .pipe(first())
      .subscribe((isAvailable) => {
        if (isAvailable) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();

          if (params.noFeatureTemplate) {
            this.viewContainer.createEmbeddedView(params.noFeatureTemplate);
          }
        }
      });
  }

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private appFeaturesState: AppFeaturesState
  ) {}
}
