<ion-button mode="ios" fill="clear">
  <ion-avatar
    [class]="size()"
    [class.no-image]="!src()"
    [style.background-color]="color()"
  >
    @if (!src()) {
      <div class="initials">{{ initials() }}</div>
    } @else {
      <img alt="Avatar" [src]="src()" />
    }
  </ion-avatar>
</ion-button>
