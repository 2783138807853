import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-coding',
  templateUrl: 'coding.component.html',
  styleUrls: ['coding.component.scss'],
  standalone: true,
  imports: [TextComponent, IonicModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodingComponent {}
