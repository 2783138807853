import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { UserApiService } from '../../services/user-api.service';
import { UsersState } from './users.state';

@NgModule({
  imports: [NgxsModule.forFeature([UsersState])],
  providers: [UserApiService],
})
export class UsersModule {}
