import { Company } from '../interfaces/company';

export const CompanyDetailsActionsName = 'CompanyDetails';

export class LoadCompanyDetailsAction {
  static readonly type = `[${CompanyDetailsActionsName}] Load Company Details`;

  constructor(public id: string) {}
}

export class UpdateCompanyAction {
  static readonly type = `[${CompanyDetailsActionsName}] Update Company`;

  constructor(public id: string, public data: Partial<Company>) {}
}

export class SetInitialCompanyDetailsAction {
  static readonly type = `[${CompanyDetailsActionsName}] Set Initial Company Details`;

  constructor(public company: Company) {}
}
