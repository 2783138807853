import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PaginationList,
  PaginationParams,
  RegularSuccessResponse,
} from '@dr/utils';
import { Observable } from 'rxjs';
import { Notification } from '../interfaces/notification';

@Injectable()
export class NotificationsApiService {
  constructor(private httpClient: HttpClient) {}

  getNotifications$(
    params: PaginationParams
  ): Observable<PaginationList<Notification>> {
    return this.httpClient.get<PaginationList<Notification>>(
      '/api/notifications',
      {
        params: {
          ...params,
        },
      }
    );
  }

  getCountOfUnreadNotifications$(): Observable<number> {
    return this.httpClient.get<number>('/api/notifications/new');
  }

  readAllUnreadNotifications$(): Observable<RegularSuccessResponse> {
    return this.httpClient.put<RegularSuccessResponse>(
      '/api/notifications/read',
      {}
    );
  }
}
