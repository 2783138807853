import { Injectable } from '@angular/core';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {
  catchError,
  filter,
  map,
  Observable,
  of,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  AppFeatures,
  FeaturesApiService,
} from '../../services/features-api.service';
import { AppFeaturesActions, AppFeaturesName } from './app-features.actions';

export interface AppFeaturesStateModel {
  features?: AppFeatures;
  loaded?: boolean;
  error?: string;
}

const defaults: AppFeaturesStateModel = {};

@State<AppFeaturesStateModel>({
  name: AppFeaturesName,
  defaults,
})
@Injectable()
export class AppFeaturesState {
  @Select(AppFeaturesState.features) features$!: Observable<
    AppFeatures | undefined
  >;
  @Select(AppFeaturesState.error) error$!: Observable<AppFeatures | undefined>;
  @Select(AppFeaturesState.loaded) loaded$!: Observable<
    AppFeatures | undefined
  >;

  loading$ = this.loaded$.pipe(map((loaded) => !loaded));

  constructor(
    private featuresApiService: FeaturesApiService,
    private store: Store
  ) {}

  @Selector()
  static features(state: AppFeaturesStateModel) {
    return state.features;
  }

  @Selector()
  static error(state: AppFeaturesStateModel) {
    return state.error;
  }

  @Selector()
  static loaded(state: AppFeaturesStateModel) {
    return state.loaded;
  }

  load() {
    return this.store.dispatch(new AppFeaturesActions.Load());
  }

  isFeatureAvailable$(key: keyof AppFeatures, defaultValue = true) {
    return this.loaded$.pipe(
      filter(Boolean),
      withLatestFrom(this.features$),
      map(([, features]) =>
        features && key in features ? features[key] : defaultValue
      )
    );
  }

  @Action(AppFeaturesActions.Load)
  private _load(ctx: StateContext<AppFeaturesStateModel>) {
    return this.featuresApiService.getAppFeatures$().pipe(
      tap((features) =>
        ctx.patchState({
          features,
          loaded: true,
        })
      ),
      catchError((error) => {
        ctx.patchState({
          error: error?.message || error,
          loaded: true,
        });

        return of(undefined);
      })
    );
  }
}
