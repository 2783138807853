import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { register } from 'swiper/element/bundle';

register();

@Directive({
  selector: '[drSwiper]',
  standalone: true,
})
export class SwiperDirective implements AfterViewInit {
  @Input()
  drSwiper?: Record<string, unknown>;

  private readonly swiperElement: HTMLElement;

  constructor(private element: ElementRef<HTMLElement>) {
    this.swiperElement = element.nativeElement;
  }

  ngAfterViewInit(): void {
    Object.assign(this.element.nativeElement, this.drSwiper);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore - We ignore this because there is no initialize method on the HTMLElement
    this.element.nativeElement.initialize();
  }
}
