import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { CompanyApiService } from './services/company-api.service';
import { MyCompaniesState } from './states/my-companies.state';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([MyCompaniesState])],
  providers: [CompanyApiService],
})
export class MyCompaniesModule {}
