import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-banner',
  templateUrl: 'banner.component.html',
  styleUrls: ['banner.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, TextComponent],
})
export class BannerComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() actionTitle?: string;

  @HostBinding('class')
  @Input()
  color?: 'primary' | 'warning';

  @Output() action: EventEmitter<void> = new EventEmitter<void>();
}
