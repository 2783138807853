export const CompanyUpdatesActionsName = 'CompanyUpdates';

export class LoadCompanyRequestChangesAction {
  static readonly type = `[${CompanyUpdatesActionsName}] Load Company Request Changes`;
}

export class ReloadCompanyRequestChangesAction {
  static readonly type = `[${CompanyUpdatesActionsName}] Reload Company Request Changes`;
}

export class CountCompanyRequestChangesAction {
  static readonly type = `[${CompanyUpdatesActionsName}] Count Company Request Changes`;
}

export class ApproveCompanyRequestChangesAction {
  static readonly type = `[${CompanyUpdatesActionsName}] Approve Company Request Changes`;

  constructor(public id: string) {}
}

export class RejectCompanyRequestChangesAction {
  static readonly type = `[${CompanyUpdatesActionsName}] Reject Company Request Changes`;

  constructor(public id: string, public message: string) {}
}
