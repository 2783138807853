<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="navController.back()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ targetCompany()?.nm }}</ion-title>
    <ion-buttons slot="end">
      @if (companyDetailsState.detailsLoading$ | async) {
        <ion-button>
          <ion-spinner></ion-spinner>
        </ion-button>
      }
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [class.no-scroll]="!targetCompany()">
  <dr-loading
    [show]="
      !!(companiesState.pendingCompanyProcessing$ | async) ||
      !!(companyUpdatesState.requestChangesProcessing$ | async) ||
      (!targetCompany() && !error())
    "
  ></dr-loading>

  @if (targetCompany(); as company) {
    <div class="content">
      <ion-card class="preview">
        <ion-img [src]="company.lXL || company.lXS" />
        <ion-card-header>
          <ion-card-title>{{ company.nm }}</ion-card-title>
          <ion-card-subtitle>{{ company.nch }}</ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <div class="statuses">
            <ion-chip [class]="company.s">{{
              'status.' + company.s | translate
            }}</ion-chip>
            <ion-chip [class]="company.ps">{{
              'status.' + company.ps | translate
            }}</ion-chip>
          </div>

          @if (company.s === 'rejected') {
            <dr-banner
              [subtitle]="company.msg"
              color="warning"
              class="reject-message"
            ></dr-banner>
          }

          <dr-company-short-info [company]="company"></dr-company-short-info>
        </ion-card-content>
      </ion-card>

      <div class="content-data">
        @if (requestChanges(); as requestChanges) {
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle>{{
                'company.request_changes.title' | translate
              }}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <dr-company-request-changes
                [requestChange]="requestChanges"
                [company]="company"
              ></dr-company-request-changes>
            </ion-card-content>
          </ion-card>
        }

        <dr-segment
          [items]="segmentTypes"
          [value]="targetSegmentType"
          (changeType)="targetSegmentType = $event"
        ></dr-segment>

        @switch (targetSegmentType) {
          @case ('subscription') {
            <ion-card>
              <ion-card-content>
                @if (company) {
                  <dr-company-active-subscription-view
                    [company]="company"
                  ></dr-company-active-subscription-view>
                }
              </ion-card-content>
            </ion-card>
          }
          @case ('subscriptions-pack') {
            <ion-card>
              <ion-card-content>
                @if (company.sp) {
                  <dr-company-subscriptions-pack
                    [subscriptionsPack]="company.sp"
                    [animated]="false"
                  ></dr-company-subscriptions-pack>
                } @else {
                  <dr-empty [message]="'list.empty' | translate"></dr-empty>
                }
              </ion-card-content>
            </ion-card>
          }
          @case ('groups') {
            <ion-card-content>
              <dr-empty [message]="'list.empty' | translate"></dr-empty>
            </ion-card-content>
          }
          @case ('clients') {
            <ion-card-content>
              <dr-empty [message]="'list.empty' | translate"></dr-empty>
            </ion-card-content>
          }
        }
      </div>
    </div>
  }
</ion-content>

@if (error(); as error) {
  <ion-footer>
    <dr-error-footer
      [message]="error"
      (tryAgain)="loadCompanyData()"
    ></dr-error-footer>
  </ion-footer>
}
