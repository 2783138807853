import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';
import { IonColor } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-link',
  templateUrl: 'link.component.html',
  styleUrls: ['link.component.scss'],
  standalone: true,
  imports: [IonicModule, TextComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  @HostBinding('class.underline')
  @Input()
  underline?: boolean = false;

  @HostBinding('class.disabled')
  @Input()
  disabled?: boolean;

  @Input()
  color?: IonColor = 'primary';

  constructor(private elementRef: ElementRef) {
    this.elementRef.nativeElement.onclick = (e: Event) => {
      if (this.disabled) {
        e.stopImmediatePropagation();
      }
    };
  }
}
