import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Haptics, NotificationType } from '@capacitor/haptics';
import {
  DeleteConnection,
  ConnectionsState,
  ApproveConnection,
  ConnectionApproved,
  ConnectionRejected,
  RejectConnection,
} from '@dr/connections';
import { CurrentUserState, UserSocketService } from '@dr/user';
import {
  ActiveCompanyChangedAction,
  ResetCurrentUserDataAction,
  ToastService,
} from '@dr/utils';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { catchError, first, Observable, of, tap } from 'rxjs';
import { Notification } from '../interfaces/notification';
import { NotificationsApiService } from '../services/notifications-api.service';
import {
  AddNewNotificationAction,
  ApproveConnectionAction,
  CountUnreadAction,
  ListAction,
  LoadMoreAction,
  MarkAllAsReadAction,
  NotificationListVisibleAction,
  NotificationsActionsName,
  ReadAllUnreadAction,
  ReadNotificationAction,
} from './notifications.actions';

export interface NotificationsStateModel {
  notifications?: Notification[];
  countUnreadNotifications?: number;
  canLoadMore?: boolean;
  loaded?: boolean;
  error?: string;
  notificationsVisible?: boolean;
}

const defaults: NotificationsStateModel = {};

@State<NotificationsStateModel>({
  name: NotificationsActionsName,
  defaults,
})
@Injectable()
export class NotificationsState {
  @Select(NotificationsState.loading) loading$!: Observable<boolean>;
  @Select(NotificationsState.error) error$!: Observable<string | undefined>;
  @Select(NotificationsState.canLoadMore) canLoadMore$!: Observable<boolean>;
  @Select(NotificationsState.loaded) loaded$!: Observable<boolean>;
  @Select(NotificationsState.hasUnreadNotifications)
  hasUnreadNotifications$!: Observable<boolean>;
  @Select(NotificationsState.notifications) notifications$!: Observable<
    Notification[]
  >;
  @Select(NotificationsState.notificationsVisible)
  notificationsVisible$!: Observable<boolean>;
  @Select(actionsExecuting([LoadMoreAction]))
  loadingMore$!: Observable<boolean | undefined>;
  @Select(
    actionsExecuting([
      ApproveConnectionAction,
      ApproveConnection,
      RejectConnection,
    ])
  )
  notificationsActionsLoading$!: Observable<boolean>;

  private readonly newNotificationSound = new Audio(
    '/assets/sounds/new-notification.mp3'
  );

  constructor(
    private notificationsApiService: NotificationsApiService,
    private store: Store,
    private userSocketService: UserSocketService,
    private currentUserState: CurrentUserState,
    private toastService: ToastService,
    private connectionsState: ConnectionsState
  ) {}

  @Selector()
  static loading(state: NotificationsStateModel) {
    return !state.notifications;
  }

  @Selector()
  static notifications(state: NotificationsStateModel) {
    return state.notifications || [];
  }

  @Selector()
  static error(state: NotificationsStateModel) {
    return state.error;
  }

  @Selector()
  static hasUnreadNotifications(state: NotificationsStateModel) {
    return !!state.countUnreadNotifications;
  }

  @Selector()
  static canLoadMore(state: NotificationsStateModel) {
    return state.canLoadMore;
  }

  @Selector()
  static loaded(state: NotificationsStateModel) {
    return state.loaded;
  }

  @Selector()
  static notificationsVisible(state: NotificationsStateModel) {
    return state.notificationsVisible;
  }

  getNotificationsList$(reload = false) {
    return this.store.dispatch(new ListAction(reload));
  }

  getCountUnreadNotifications$() {
    return this.store.dispatch(new CountUnreadAction());
  }

  markAllAsRead(): void {
    this.store.dispatch(new MarkAllAsReadAction());
  }

  readAllUnread$() {
    return this.store.dispatch(new ReadAllUnreadAction());
  }

  readNotification$(notification: Notification) {
    this.store.dispatch(new ReadNotificationAction(notification));
  }

  updateNotificationsVisibility(visible: boolean) {
    this.store.dispatch(new NotificationListVisibleAction(visible));
  }

  loadMore$() {
    return this.store.dispatch(new LoadMoreAction());
  }

  approveConnectionAction$(notification: Notification) {
    return this.store.dispatch(new ApproveConnectionAction(notification));
  }

  stopListenNotificationsEvents() {
    this.userSocketService.off('new_notification');
    this.userSocketService.off('read_all_notifications');
  }

  listenNotificationsEvents(filterByPayload?: Partial<Notification>): void {
    this.userSocketService.onEvent(
      'new_notification',
      (notification: Notification) => {
        if (
          filterByPayload &&
          Object.keys(filterByPayload).some(
            (key) =>
              notification[key as keyof Notification] !==
              filterByPayload[key as keyof Notification]
          )
        ) {
          return;
        }

        this.store.dispatch(new AddNewNotificationAction(notification));

        if (!Capacitor.isNativePlatform()) {
          this.toastService.showToast({
            color: 'success',
            message: 'notifications.new_notification',
            duration: 1500,
            position: 'top',
          });
        }

        this.currentUserState.checkedUserOnes$.subscribe((currentUser) => {
          if (!Capacitor.isNativePlatform() && currentUser.d?.nse) {
            this.newNotificationSound.play();
          }

          if (Capacitor.isNativePlatform() && currentUser.d.nve) {
            Haptics.notification({
              type: NotificationType.Success,
            });
          }
        });
      }
    );

    this.userSocketService.onEvent('read_all_notifications', () => {
      this.notificationsVisible$.pipe(first()).subscribe((visible) => {
        if (!visible) {
          this.markAllAsRead();
        }
      });
    });
  }

  @Action(ListAction)
  private _getNotificationsList$(
    ctx: StateContext<NotificationsStateModel>,
    action: ListAction
  ) {
    if (ctx.getState().loaded && !action.reload) {
      return of(undefined);
    }

    ctx.patchState({
      error: undefined,
      loaded: true,
    });

    return this.notificationsApiService.getNotifications$({}).pipe(
      tap((response) => {
        ctx.patchState({
          notifications: response.items,
          countUnreadNotifications: Math.max(
            ctx.getState().countUnreadNotifications || 0,
            response.items.filter((notification) => !notification.r).length
          ),
          canLoadMore: response.count > response.items.length,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          error,
          loaded: !ctx.getState().notifications?.length,
        });

        throw error;
      })
    );
  }

  @Action(ApproveConnectionAction)
  private approveConnectionAction(
    ctx: StateContext<NotificationsStateModel>,
    action: ApproveConnectionAction
  ) {
    const fromUserId = action.notification.c?.u?.id;
    const fromCompanyId = action.notification.c?.c?.id;

    if (!fromUserId && !fromCompanyId) {
      return;
    }

    return (
      fromUserId
        ? this.connectionsState.approveConnection$(fromUserId, 'user')
        : this.connectionsState.approveConnection$(
            fromCompanyId as string,
            'company'
          )
    ).pipe(
      tap(() => {
        ctx.patchState({
          notifications: ctx
            .getState()
            .notifications?.filter(
              (notification) => notification.id !== action.notification.id
            ),
        });
      })
    );
  }

  @Action(ConnectionApproved)
  @Action(ConnectionRejected)
  private handleUserConnection(
    ctx: StateContext<NotificationsStateModel>,
    action: ConnectionApproved | ConnectionRejected
  ) {
    ctx.patchState({
      notifications: ctx
        .getState()
        .notifications?.filter(
          (notification) =>
            notification.c?.u?.id !== action.entityId &&
            notification.c?.c?.id !== action.entityId
        ),
    });
  }

  @Action(DeleteConnection)
  private connectionRequestDeleted(
    ctx: StateContext<NotificationsStateModel>,
    action: DeleteConnection
  ) {
    ctx.patchState({
      notifications: ctx
        .getState()
        .notifications?.filter(
          (notification) => notification.c?.id !== action.connection.id
        ),
    });
  }

  @Action(LoadMoreAction)
  private _loadMore$(ctx: StateContext<NotificationsStateModel>) {
    const notifications = ctx.getState().notifications || [];

    if (!notifications.length) {
      return of(undefined);
    }

    const lastNotificationId = notifications[notifications.length - 1].id;

    return this.notificationsApiService
      .getNotifications$({ after: lastNotificationId })
      .pipe(
        tap((response) => {
          ctx.patchState({
            notifications: [...notifications, ...response.items],
            canLoadMore: response.count > response.items.length,
          });
        }),
        catchError((error) => {
          ctx.patchState({
            error,
          });

          throw error;
        })
      );
  }

  @Action(CountUnreadAction)
  private _getCountUnreadNotifications$(
    ctx: StateContext<NotificationsStateModel>
  ) {
    ctx.patchState({
      error: undefined,
    });

    return this.notificationsApiService.getCountOfUnreadNotifications$().pipe(
      tap((countUnreadNotifications) => {
        ctx.patchState({
          countUnreadNotifications,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          error,
        });

        throw error;
      })
    );
  }

  @Action(ReadAllUnreadAction)
  private _readAllUnread$(ctx: StateContext<NotificationsStateModel>) {
    if (
      ctx.getState().notifications?.every((n) => n.r) ||
      !ctx.getState().notificationsVisible
    ) {
      return of({ success: true });
    }

    return this.notificationsApiService.readAllUnreadNotifications$();
  }

  @Action(MarkAllAsReadAction)
  private _markAsRead(ctx: StateContext<NotificationsStateModel>) {
    ctx.patchState({
      notifications: ctx.getState().notifications?.map((notification) => ({
        ...notification,
        r: true,
      })),
      countUnreadNotifications: 0,
    });
  }

  @Action(AddNewNotificationAction)
  private _addNewNotification(
    ctx: StateContext<NotificationsStateModel>,
    action: AddNewNotificationAction
  ) {
    const countUnreadNotifications = ctx.getState().countUnreadNotifications;

    if (!ctx.getState().loaded) {
      ctx.patchState({
        countUnreadNotifications:
          typeof countUnreadNotifications !== 'undefined'
            ? countUnreadNotifications + 1
            : countUnreadNotifications,
      });

      return;
    }

    ctx.patchState({
      notifications: [
        action.notification,
        ...(ctx.getState().notifications || []),
      ],
      countUnreadNotifications:
        typeof countUnreadNotifications !== 'undefined'
          ? countUnreadNotifications + 1
          : 1,
    });
  }

  @Action(ReadNotificationAction)
  private _readNotification(
    ctx: StateContext<NotificationsStateModel>,
    action: ReadNotificationAction
  ) {
    const notifications = ctx.getState().notifications;

    if (!notifications?.length) {
      return;
    }

    ctx.patchState({
      notifications: notifications.map((n) => {
        if (n.id === action.notification.id) {
          return {
            ...n,
            r: true,
          };
        }

        return n;
      }),
    });
  }

  @Action(NotificationListVisibleAction)
  private _updateNotificationsVisibility(
    ctx: StateContext<NotificationsStateModel>,
    action: NotificationListVisibleAction
  ) {
    ctx.patchState({
      notificationsVisible: action.visible,
    });
  }

  @Action(ResetCurrentUserDataAction)
  @Action(ActiveCompanyChangedAction)
  private _reset(ctx: StateContext<NotificationsStateModel>) {
    ctx.setState(defaults);
  }
}
