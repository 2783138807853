import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { CompanySubscriptionsApiService } from './services/company-subscriptions-api.service';
import { CompanySubscriptionsState } from './states/company-subscriptions.state';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([CompanySubscriptionsState])],
  providers: [CompanySubscriptionsApiService],
})
export class CompanySubscriptionsModule {}
