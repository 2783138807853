import { AsyncPipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorFooterComponent, TextComponent } from '@dr/ui';
import { KeyboardDirective } from '@dr/utils';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { ContactFormMessagesComponent } from '../../components/contact-form-messages/contact-form-messages.component';
import { ContactFormSendMessageComponent } from '../../components/contact-form-send-message/contact-form-send-message.component';
import { ContactFormTopic } from '../../interfaces/contact-form';
import { ContactFormState } from '../../states/contact-form/contact-form.state';

@Component({
  selector: 'dr-contact-form-chat',
  templateUrl: 'contact-form-chat.component.html',
  styleUrls: ['contact-form-chat.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    ContactFormMessagesComponent,
    ContactFormSendMessageComponent,
    KeyboardDirective,
    TranslateModule,
    AsyncPipe,
    TextComponent,
    ErrorFooterComponent,
  ],
})
export class ContactFormChatComponent implements OnInit, OnDestroy {
  readonly currentTopicId: string = this.activatedRoute.snapshot.params['id'];
  readonly navController = inject(NavController);

  currentTopic$: Observable<ContactFormTopic | undefined> =
    this.contactFormState.topics$.pipe(
      map((topics) => topics?.find((topic) => topic.id === this.currentTopicId))
    );

  currentTopicMessagesState$ = this.contactFormState.topicMessages$.pipe(
    map((topicMessages) => topicMessages[this.currentTopicId])
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private contactFormState: ContactFormState
  ) {}

  ngOnInit() {
    this.contactFormState.openTopic$(this.currentTopicId);
  }

  ngOnDestroy() {
    this.contactFormState.getTopicsList$();
  }
}
