import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseInput } from '@dr/utils';
import { IonicModule, IonTextarea } from '@ionic/angular';
import { AutosizeModule } from 'ngx-autosize';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-textarea',
  templateUrl: 'textarea.component.html',
  styleUrls: ['textarea.component.scss'],
  standalone: true,
  imports: [IonicModule, ReactiveFormsModule, TextComponent, AutosizeModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent<V> extends BaseInput<V> {
  @ViewChild(IonTextarea) ionTextarea?: IonTextarea;

  @Input() minRows = 1;
  @Input() maxRows = 5;
}
