<div class="wrapper">
  <dr-avatar
    [src]="imageUrl"
    [name]="name"
    [color]="color()"
    size="extra-large"
  ></dr-avatar>

  @if (config(); as config) {
    <ngx-qrcode-styling [config]="config" template="sky"></ngx-qrcode-styling>
  }

  <div class="title">{{ name }}</div>
</div>
