<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>{{ 'company.my_companies.title' | translate }}</ion-title>
  </ion-toolbar>

  <ion-toolbar>
    <dr-segment
      [items]="types"
      [value]="currentCompaniesType()"
      (changeType)="changeType($event)"
    ></dr-segment>
  </ion-toolbar>

  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-checkbox
        mode="md"
        [disabled]="!currentCompaniesState()?.data?.length"
        [(ngModel)]="toggleAllEnabled"
        (ionChange)="toggleAll($event)"
      ></ion-checkbox>
    </ion-buttons>
    <ion-searchbar
      [value]="currentCompaniesState()?.search"
      [debounce]="500"
      [disabled]="
        !currentCompaniesState()?.data?.length &&
        !currentCompaniesState()?.search
      "
      (ionInput)="search($event)"
    ></ion-searchbar>
    <ion-buttons slot="end">
      <ion-button
        [class.infinite-rotating]="!!(companiesState.listLoading$ | async)"
        [disabled]="
          (!currentCompaniesState()?.data?.length &&
            !currentCompaniesState()?.search) ||
          !!(companiesState.listLoading$ | async)
        "
        (click)="reload()"
      >
        <ion-icon name="refresh-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  @if (checkedCompanies().length; as checkedByType) {
    <ion-toolbar>
      <div class="actions">
        <dr-text class="actions-title" color="dark">
          <small>{{ 'Selected' | translate }}: </small>
          {{ checkedByType }}
        </dr-text>
        <dr-button
          size="small"
          fill="solid"
          color="danger"
          [label]="'Delete' | translate"
          (click)="confirmDialog.open()"
        ></dr-button>
      </div>
    </ion-toolbar>
  }
</ion-header>

<ion-content
  drKeyboard
  [class.no-scroll]="!currentCompaniesState()?.data?.length"
>
  @if (!currentCompaniesState()?.data?.length) {
    @if (currentCompaniesState()?.search) {
      <dr-empty [message]="'list.nothing_found' | translate"></dr-empty>
    }

    @if (currentCompaniesState()?.data?.length === 0) {
      <dr-empty [message]="'list.empty' | translate"></dr-empty>
    }

    @if (currentCompaniesState()?.data === undefined) {
      <dr-skeleton [maxHeight]="60"></dr-skeleton>
    }
  }

  @if (currentCompaniesState()?.data) {
    <ion-accordion-group>
      @for (company of currentCompaniesState()?.data; track company.id) {
        <ion-accordion>
          <ion-item slot="header" color="light" class="header">
            <ion-checkbox
              mode="md"
              slot="start"
              [ngModel]="company.checked"
              (click)="$event.stopPropagation()"
              (ionChange)="toggleCompany(company)"
            ></ion-checkbox>

            <dr-entity-name
              [imgUrl]="company.lXS"
              [firstName]="company.nm"
              [subtitle]="company.nch"
              [color]="company.lc"
              (click)="$event.stopPropagation(); openCompanyPreview(company)"
            ></dr-entity-name>
          </ion-item>

          <div class="ion-padding" slot="content">
            <dr-company-short-info [company]="company"></dr-company-short-info>
          </div>
        </ion-accordion>
      }
    </ion-accordion-group>
  }

  @if (currentCompaniesState()?.canLoadMore) {
    <ion-infinite-scroll (ionInfinite)="expand()">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  }
</ion-content>

@if (currentCompaniesState()?.error) {
  <ion-footer>
    <dr-error-footer
      [message]="currentCompaniesState()?.error"
      (tryAgain)="tryAgain()"
    ></dr-error-footer>
  </ion-footer>
}

<dr-loading
  [show]="!!(companiesState.pendingCompanyProcessing$ | async)"
  [message]="'company.list.loading' | translate"
></dr-loading>

<dr-confirm
  #confirmDialog
  [title]="'company.list.delete_requests' | translate"
  (confirm)="delete()"
></dr-confirm>
