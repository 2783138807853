import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppEnvironment, AppEnvironmentI } from '../injectors/app-environment';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export class MissingTranslationService implements MissingTranslationHandler {
  constructor(@Inject(AppEnvironment) public appEnvironment: AppEnvironmentI) {}

  handle(params: MissingTranslationHandlerParams) {
    return this.appEnvironment.production ? params.key : `[${params.key}]`;
  }
}
