import { inject, Injectable } from '@angular/core';
import { UserSocketService } from '@dr/user';
import { Subject } from 'rxjs';
import { Connection } from '../interfaces/connection';

type ConnectionSocketType =
  | 'connection_request_user'
  | 'connection_request_company'
  | 'connection_add_user'
  | 'connection_add_company'
  | 'connection_delete_user'
  | 'connection_delete_company';

const connectionSocketEvents: ConnectionSocketType[] = [
  'connection_request_user',
  'connection_request_company',
  'connection_add_user',
  'connection_add_company',
  'connection_delete_user',
  'connection_delete_company',
];

export interface ConnectionSocket {
  name: ConnectionSocketType;
  connection: Connection;
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionSocketService {
  readonly onEvent$ = new Subject<ConnectionSocket>();

  readonly userSocketService = inject(UserSocketService);

  connect() {
    connectionSocketEvents.forEach((eventName) => {
      this.userSocketService.onEvent(eventName, (connection: Connection) => {
        this.onEvent$.next({
          name: eventName,
          connection,
        });
      });
    });
  }
}
