import { AppLanguage } from '../../utils/app-languages';

export const LocalizationActionsName = 'Localization';

export namespace LocalizationActions {
  export class SetAppLanguage {
    static readonly type = `[${LocalizationActionsName}] Set App Language`;

    constructor(public appLanguage: AppLanguage) {}
  }
}
