@for (menuItem of menuItems; track $index) {
  @if (!menuItem.hidden$ || (menuItem.hidden$ | async) === false) {
    <ion-item
      detail="false"
      [class.active]="menuItem.url && router.url.indexOf(menuItem.url()) !== -1"
      (click)="openRoute(menuItem)"
      lines="none"
    >
      <div class="item-container">
        <ion-icon slot="start" [name]="menuItem.leftIcon"></ion-icon>

        <ion-label>
          <dr-text>
            <span>{{ menuItem.title | translate }}</span>
          </dr-text>
        </ion-label>

        @if (menuItem.counters$) {
          @for (counter of menuItem.counters$ | async; track $index) {
            @if (counter.value) {
              <ion-badge
                [color]="counter.color"
                slot="end"
                (mouseenter)="
                  counter.tooltip && tooltipComponent.present($event)
                "
                (mouseleave)="counter.tooltip && tooltipComponent.dismiss()"
                >{{ counter.value | shortNumber }}
              </ion-badge>
            }
            <dr-tooltip #tooltipComponent [text]="counter.tooltip"></dr-tooltip>
          }
        }
        @if (menuItem.rightIcon$) {
          <ion-icon
            slot="start"
            [name]="menuItem.rightIcon$ | async"
          ></ion-icon>
        }
      </div>
    </ion-item>
  }
  @if (menuItem.hasBottomLine) {
    <div class="divider"></div>
  }
}
