import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent, TextComponent } from '@dr/ui';
import { CurrentUserState, UserCompany } from '@dr/user';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dr-my-companies-list',
  templateUrl: 'my-companies-list.component.html',
  styleUrls: ['my-companies-list.component.scss'],
  imports: [
    IonicModule,
    AsyncPipe,
    TextComponent,
    TranslateModule,
    ButtonComponent,
  ],
  standalone: true,
})
export class MyCompaniesListComponent {
  @Output() setRequestCompany = new EventEmitter<UserCompany>();
  @Output() setActiveCompany = new EventEmitter<UserCompany>();
  @Output() addCompany = new EventEmitter<void>();
  @Output() showQr = new EventEmitter<void>();

  constructor(public currentUserState: CurrentUserState) {}
}
