import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NotificationsApiService } from './services/notifications-api.service';
import { NotificationsState } from './state/notifications.state';

@NgModule({
  imports: [NgxsModule.forFeature([NotificationsState])],
  providers: [NotificationsApiService],
})
export class NotificationsModule {}
