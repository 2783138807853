import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'dr-modal',
  template: '<ion-nav [root]="root"></ion-nav>',
  standalone: true,
  imports: [IonicModule],
})
export class NavModalComponent {
  root!: typeof Component;
}
