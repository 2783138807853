export const companySources = [
  {
    label: 'company.survey.company_source_type.friends',
    value: 'friends',
  },
  {
    label: 'company.survey.company_source_type.social',
    value: 'soc',
  },
  {
    label: 'company.survey.company_source_type.social_business',
    value: 'soc_business',
  },
  {
    label: 'company.survey.company_source_type.search',
    value: 'search',
  },
  {
    label: 'company.survey.company_source_type.other',
    value: 'other',
  },
];
