import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, ConfirmComponent, TextComponent } from '@dr/ui';
import { CurrentUserState, UserCompany } from '@dr/user';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dr-company-survey-finish',
  templateUrl: 'company-survey-finish.component.html',
  styleUrls: ['company-survey-finish.component.scss'],
  imports: [
    IonicModule,
    TextComponent,
    TranslateModule,
    ButtonComponent,
    AsyncPipe,
    ConfirmComponent,
  ],
  standalone: true,
})
export class CompanySurveyFinishComponent {
  @Input({ required: true }) request!: UserCompany;

  @Output() deleted: EventEmitter<UserCompany> =
    new EventEmitter<UserCompany>();
  @Output() support: EventEmitter<void> = new EventEmitter<void>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  constructor(public currentUserState: CurrentUserState) {}
}
