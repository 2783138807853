import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  EmptyComponent,
  ErrorFooterComponent,
  LoadingComponent,
  EntityNameComponent, SkeletonComponent,
} from '@dr/ui';
import { SmartComponent } from '@dr/utils';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CompanyUpdatesModule } from '../../company-updates.module';
import { Company } from '../../interfaces/company';
import { CompanyUpdatesState } from '../../states/company-updates.state';
import { CompanyRequestChangesComponent } from '../company-request-changes/company-request-changes.component';

@Component({
  selector: 'dr-request-company-changes-list',
  templateUrl: 'request-company-changes-list.component.html',
  styleUrls: ['request-company-changes-list.component.scss'],
  imports: [
    CompanyUpdatesModule,
    IonicModule,
    TranslateModule,
    AsyncPipe,
    EmptyComponent,
    EntityNameComponent,
    ErrorFooterComponent,
    LoadingComponent,
    CompanyRequestChangesComponent,
    SkeletonComponent,
  ],
  standalone: true,
})
export class RequestCompanyChangesListComponent
  extends SmartComponent
  implements OnInit
{
  constructor(
    public companyUpdatesState: CompanyUpdatesState,
    private navController: NavController
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.companyUpdatesState.loadCompanyRequestChanges$();
  }

  override onResumeApp() {
    super.onResumeApp();

    this.companyUpdatesState.reloadCompanyRequestChanges$();
  }

  openCompanyPreview(company: Company) {
    this.navController.navigateForward(`/companies/company/${company.id}`);
  }
}
