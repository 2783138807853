import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TextComponent } from '@dr/ui';
import { SwiperDirective } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dr-auth-wrapper',
  templateUrl: 'auth-wrapper.component.html',
  styleUrls: ['auth-wrapper.component.scss'],
  imports: [IonicModule, SwiperDirective, TextComponent, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
})
export class AuthWrapperComponent {
  supportSlider = !Capacitor.isNativePlatform();

  slides = [
    {
      title: 'Here, there, everywhere',
      subtitle:
        'Manage the business anywhere at any time,\n create new teams, add participants and more.',
      imageUrl:
        'https://beapythondev.files.wordpress.com/2020/01/canva-software-developer-working.jpg',
    },
    {
      title: 'Here, there, everywhere',
      subtitle:
        'Manage the business anywhere at any time,\n create new teams, add participants and more.',
      imageUrl:
        'https://usa.bootcampcdn.com/wp-content/uploads/sites/108/2021/12/tes_gen_blog_post_071921_1233182206-1-800x412.jpg',
    },
    {
      title: 'Here, there, everywhere',
      subtitle:
        'Manage the business anywhere at any time,\n create new teams, add participants and more.',
      imageUrl:
        'https://www.open.edu.au/-/media/blog/2022/10-october/software-developer-vs-engineer_edited.jpg?h=402&iar=0&w=715&rev=22dc593363e74d4285cb4042c8401af0&hash=A719998C2F009EC9F76F63BFCDFBD24A',
    },
  ];
}
