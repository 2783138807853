import { Component, computed, inject, input } from '@angular/core';
import { ConnectionsState } from '@dr/connections';
import { SkeletonComponent, TextComponent } from '@dr/ui';
import { ShortNumberPipe } from '@dr/utils';
import { NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dr-count-connections',
  templateUrl: './count-connections.component.html',
  styleUrls: ['./count-connections.component.scss'],
  standalone: true,
  imports: [TextComponent, ShortNumberPipe, TranslateModule, SkeletonComponent],
})
export class CountConnectionsComponent {
  connectionsState = inject(ConnectionsState);
  navController = inject(NavController);

  entityId = input.required<string>();
  calculateConnections = computed(
    () => this.connectionsState.getEntity(this.entityId()).countConnections
  );

  openUsers() {
    this.navController.navigateForward(`/connections/users/${this.entityId()}`);
  }

  openCompanies() {
    this.navController.navigateForward(
      `/connections/companies/${this.entityId()}`
    );
  }
}
