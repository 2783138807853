import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateFormData,
  PaginationList,
  PaginationParams,
  RegularSuccessResponse,
} from '@dr/utils';
import { Observable } from 'rxjs';
import {
  Company,
  CompanyPaidStatus,
  CompanyRequestChanges,
  CompanyStatus,
  SubscriptionPeriod,
} from '../interfaces/company';

export interface CreateCompanyData {
  name: string;
  link: string;
  min_employees: number;
  max_employees?: number;
  min_clients: number;
  max_clients?: number;
  niche: string;
  logo: File;
  country: string;
  source: string;
  place: string;
}

export interface UpdateCompanyInfoData {
  name?: string | null;
  link?: string | null;
  min_employees?: number | null;
  max_employees?: number | null;
  min_clients?: number | null;
  max_clients?: number | null;
  niche?: string | null;
  logo?: File | null;
  country?: string | null;
  place?: string | null;
}

export interface CompanyPagination extends PaginationParams {
  status?: CompanyStatus;
  paidStatus?: CompanyPaidStatus;
}

@Injectable()
export class CompanyApiService {
  constructor(private httpClient: HttpClient) {}

  getCompanies$(
    params: CompanyPagination
  ): Observable<PaginationList<Company>> {
    return this.httpClient.get<PaginationList<Company>>(`/api/company/list`, {
      params: {
        ...params,
      },
    });
  }

  count$(): Observable<Record<CompanyStatus | CompanyPaidStatus, number>> {
    return this.httpClient.get<
      Record<CompanyStatus | CompanyPaidStatus, number>
    >(`/api/company/list/count`);
  }

  countRequestChanges$(): Observable<number> {
    return this.httpClient.get<number>(`/api/company/request-changes/count`);
  }

  getRequestChanges$(): Observable<PaginationList<CompanyRequestChanges>> {
    return this.httpClient.get<PaginationList<CompanyRequestChanges>>(
      `/api/company/request-changes`
    );
  }

  reject$(id: string, message: string) {
    return this.httpClient.post(`/api/company/reject/${id}`, {
      message,
    });
  }

  approve$(companyId: string, packId: string) {
    return this.httpClient.post(`/api/company/approve/${companyId}`, {
      packId,
    });
  }

  subscribe$(
    companyId: string,
    subscriptionId: string,
    period: SubscriptionPeriod
  ): Observable<Company> {
    return this.httpClient.post<Company>(
      `/api/company/subscribe/${companyId}/${subscriptionId}`,
      {
        period,
      }
    );
  }

  getCompanyProfileId$(id: string): Observable<Company> {
    return this.httpClient.get<Company>(`/api/company/${id}`);
  }

  createCompany$(data: CreateCompanyData): Observable<Company> {
    return this.httpClient.post<Company>(
      '/api/company/request',
      generateFormData(data, ['logo'])
    );
  }

  requestChanges$(
    id: string,
    data: UpdateCompanyInfoData
  ): Observable<Company> {
    return this.httpClient.put<Company>(
      `/api/company/change/${id}`,
      generateFormData(data, ['logo'])
    );
  }

  approveRequestChanges$(id: string): Observable<RegularSuccessResponse> {
    return this.httpClient.put<RegularSuccessResponse>(
      `/api/company/request-changes/${id}/approve`,
      {}
    );
  }

  rejectRequestChanges$(
    id: string,
    message: string
  ): Observable<RegularSuccessResponse> {
    return this.httpClient.put<RegularSuccessResponse>(
      `/api/company/request-changes/${id}/reject`,
      {
        message,
      }
    );
  }

  deleteRequest$(id: string) {
    return this.httpClient.delete(`/api/company/request/${id}`, {});
  }

  deleteRequestChanges$(id: string) {
    return this.httpClient.delete<Company>(`/api/company/change/${id}`, {});
  }

  delete$(ids: string[]) {
    return this.httpClient.delete(`/api/company`, {
      body: {
        ids,
      },
    });
  }
}
