import { AsyncPipe, Location } from '@angular/common';
import { Component } from '@angular/core';
import {
  MyCompaniesState,
  CompanySubscription,
  SubscriptionPeriod,
} from '@dr/company';
import { LinkComponent, TextComponent } from '@dr/ui';
import { IonicModule, NavController } from '@ionic/angular';
import { IsWebVersion } from '@dr/utils';
import { TranslateModule } from '@ngx-translate/core';
import { first, of, switchMap, tap } from 'rxjs';
import { CompanySubscriptionsPackComponent } from '../company-subscriptions-pack/company-subscriptions-pack.component';

@Component({
  selector: 'dr-company-subscriptions-selection',
  templateUrl: './company-subscriptions-selection.component.html',
  styleUrls: ['./company-subscriptions-selection.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CompanySubscriptionsPackComponent,
    TextComponent,
    LinkComponent,
    TranslateModule,
    AsyncPipe,
  ],
})
export class CompanySubscriptionsSelectionComponent {
  readonly IsWebVersion = IsWebVersion();

  constructor(
    private navController: NavController,
    public myCompaniesState: MyCompaniesState,
    private location: Location
  ) {}

  contactSupport() {
    this.navController.navigateForward('/contact-form');
  }

  goBack() {
    this.myCompaniesState.activeCompany$
      .pipe(
        first(),
        switchMap((activeCompany) => {
          return (
            activeCompany?.as?.sb
              ? of(undefined)
              : this.myCompaniesState.setActiveCompany$()
          ).pipe(
            first(),
            tap(() => {
              this.location.back();
            })
          );
        })
      )
      .subscribe();
  }

  subscribe(data: {
    subscription: CompanySubscription;
    period: SubscriptionPeriod;
  }) {
    this.myCompaniesState
      .subscribe$(data.subscription.id, data.period)
      .pipe(first())
      .subscribe(() => {
        this.navController.navigateRoot('/', {
          animated: false,
        });
      });
  }
}
