<ion-content drKeyboard>
  <div class="wrapper">
    <div class="form">
      <div class="header">
        <dr-text>
          <span class="title">{{ 'Sign in' | translate }}</span>
        </dr-text>
        <dr-text>
          <span class="subtitle">{{
            'auth.enter_email_and_password' | translate
          }}</span>
        </dr-text>
      </div>

      <dr-input
        #email
        type="email"
        inputMode="email"
        [fControl]="emailControl"
        [label]="'Email' | translate"
        [errors]="validationService.appValidationRules.emailErrorMessages()"
        [required]="true"
      ></dr-input>

      <dr-input
        #password
        type="password"
        [fControl]="passwordControl"
        [label]="'Password' | translate"
        [errors]="validationService.appValidationRules.passwordErrorMessages()"
        [required]="true"
      ></dr-input>

      @if (!!(authState.signInError$ | async)) {
        <dr-text [small]="true" [centered]="true" color="danger" class="error">
          <span>{{ authState.signInError$ | async }}</span>
        </dr-text>
      }

      <dr-button
        expand="block"
        iconName="arrow-forward-outline"
        [label]="'Sign in' | translate"
        (click)="signIn()"
      ></dr-button>
    </div>
  </div>
</ion-content>

<dr-loading [show]="!!(authState.loading$ | async)"></dr-loading>
