import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FeaturesModule } from '@dr/features';
import {
  ButtonComponent,
  CodingComponent,
  ErrorFooterComponent,
  TextComponent,
} from '@dr/ui';
import { PushNotificationsService, SmartComponent } from '@dr/utils';
import {
  IonicModule,
  IonInfiniteScroll,
  IonRefresher,
  NavController,
} from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';
import { finalize, first, tap } from 'rxjs';
import { NotificationsListComponent } from '../../components/notifications-list/notifications-list.component';
import { NotificationsModule } from '../../notifications.module';
import { NotificationsState } from '../../state/notifications.state';

@Component({
  selector: 'dr-notifications',
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.scss'],
  imports: [
    IonicModule,
    AsyncPipe,
    ErrorFooterComponent,
    TranslateModule,
    ButtonComponent,
    TextComponent,
    FeaturesModule,
    CodingComponent,
    NotificationsListComponent,
    NotificationsModule,
  ],
  standalone: true,
})
export class NotificationsComponent extends SmartComponent implements OnInit {
  @ViewChild(IonRefresher) ionRefresher!: IonRefresher;
  @ViewChild(IonInfiniteScroll) ionInfiniteScroll!: IonInfiniteScroll;

  readonly navController = inject(NavController);

  notifications$ = this.notificationsState.notifications$.pipe(
    tap((notifications) => {
      if (notifications?.length) {
        this.notificationsState.readAllUnread$();
      }
    })
  );

  constructor(
    public notificationsState: NotificationsState,
    public pushNotificationsService: PushNotificationsService
  ) {
    super();
  }

  override async ngOnInit() {
    await super.ngOnInit();

    this.init();
  }

  ionViewWillEnter() {
    this.notificationsState.updateNotificationsVisibility(true);
    this.notificationsState.notifications$
      .pipe(first())
      .subscribe((notifications) => {
        if (notifications.length) {
          this.notificationsState.readAllUnread$();
        }
      });
  }

  ionViewDidLeave(): void {
    this.notificationsState.updateNotificationsVisibility(false);
    this.notificationsState.markAllAsRead();
  }

  override onResumeApp() {
    super.onResumeApp();

    this.init(true);
  }

  override onActiveCompanyChanged() {
    super.onActiveCompanyChanged();

    this.init(true);
  }

  openNativeSettings(): void {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  }

  tryAgain(): void {
    this.notificationsState.notifications$
      .pipe(first())
      .subscribe((notifications) => {
        if (notifications?.length) {
          this.ionInfiniteScroll.ionInfinite.emit();

          this.loadMore();
        } else {
          this.init(true);
        }
      });
  }

  refresh() {
    this.notificationsState
      .getNotificationsList$(true)
      .pipe(finalize(() => this.ionRefresher.complete()))
      .subscribe();
  }

  init(reload?: boolean): void {
    this.notificationsState
      .getNotificationsList$(reload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  loadMore() {
    this.notificationsState
      .loadMore$()
      .pipe(
        finalize(() => {
          this.ionInfiniteScroll?.complete();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
