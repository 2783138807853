import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseInput } from '@dr/utils';
import { IonicModule } from '@ionic/angular';

export interface RadioOption<V> {
  label: string;
  value: V;
}

@Component({
  selector: 'dr-radio',
  templateUrl: 'radio.component.html',
  styleUrls: ['radio.component.scss'],
  standalone: true,
  imports: [IonicModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent<V> extends BaseInput<V> {
  @Input({ required: true }) options!: RadioOption<V>[];

  @Output() changed: EventEmitter<V> = new EventEmitter<V>();
}
