import { AsyncPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  CompaniesModule,
  CompanySubscriptionsModule,
  CompanyUpdatesModule,
} from '@dr/company';
import { ContactFormModule } from '@dr/contact-form';
import { FeaturesModule } from '@dr/features';
import {
  NotificationsModule,
  NotificationsListComponent,
} from '@dr/notifications';
import {
  ButtonComponent,
  CodingComponent,
  NavigationMenuComponent,
  TextComponent,
  EntityNameComponent,
} from '@dr/ui';
import { ShortNumberPipe } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SideMenuComponent } from './side-menu.component';

@NgModule({
  declarations: [SideMenuComponent],
    imports: [
        AsyncPipe,
        IonicModule,
        RouterLinkActive,
        RouterLink,
        TextComponent,
        TranslateModule,
        EntityNameComponent,
        NotificationsModule,
        ButtonComponent,
        ContactFormModule,
        NavigationMenuComponent,
        FeaturesModule,
        CodingComponent,
        CompaniesModule,
        CompanySubscriptionsModule,
        CompanyUpdatesModule,
        NotificationsListComponent,
        ShortNumberPipe,
    ],
  exports: [SideMenuComponent],
})
export class SideMenuModule {}
