<dr-text [color]="color()" [centered]="centered()" [small]="small()">
  <span
    #spanElement
    [innerText]="text"
    [style.-webkit-line-clamp]="collapsed ? linesCount : 'initial'"
  ></span>
</dr-text>

@if (isTextCouldBeCollapsed) {
  <dr-button
    [iconName]="collapsed ? 'chevron-down' : 'chevron-up'"
    [label]="collapsed ? 'More' : 'Less'"
    fill="clear"
    size="small"
    (click)="collapsed = !collapsed"
  ></dr-button>
}
