import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EmailPattern, PasswordPattern } from '../patterns';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  get appValidationRules() {
    return {
      firstNameValidators: [Validators.required, Validators.maxLength(100)],
      firstNameErrorMessages: () => ({
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.first_name'),
        }),
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 100,
          }
        ),
      }),
      lastNameValidators: [Validators.required, Validators.maxLength(100)],
      lastNameErrorMessages: () => ({
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.last_name'),
        }),
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 100,
          }
        ),
      }),
      instagramValidators: [Validators.maxLength(100)],
      instagramErrorMessages: () => ({
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 100,
          }
        ),
      }),
      otherLinkValidators: [Validators.maxLength(300)],
      otherLinkErrorMessages: () => ({
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 300,
          }
        ),
      }),
      profileBioValidators: [Validators.maxLength(400)],
      profileBioErrorMessages: () => ({
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 300,
          }
        ),
      }),
      emailValidators: [Validators.required, Validators.pattern(EmailPattern)],
      emailErrorMessages: (error?: object | string | null) => ({
        pattern: this.translateService.instant('validation_error.email'),
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.email'),
        }),
        server:
          !!error && typeof error === 'string'
            ? this.translateService.instant(error)
            : '',
      }),
      passwordValidators: [
        Validators.required,
        Validators.pattern(PasswordPattern),
      ],
      confirmPasswordValidators: (password: FormControl) => [
        Validators.required,
        Validators.pattern(PasswordPattern),
        (control: AbstractControl): ValidationErrors | null =>
          control.value === password.value
            ? null
            : {
                matching: true,
              },
      ],
      passwordErrorMessages: () => ({
        pattern: this.translateService.instant(
          'validation_error.password_pattern'
        ),
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.password'),
        }),
        matching: this.translateService.instant(
          'validation_error.password_matching'
        ),
      }),
      usernameValidators: [Validators.required, Validators.maxLength(100)],
      usernameErrorMessages: () => ({
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.username'),
        }),
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 100,
          }
        ),
      }),
      contactFormMessageValidators: [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(2048),
      ],
      contactFormMessageErrorMessages: () => ({
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.message'),
        }),
        minlength: this.translateService.instant(
          'validation_error.min_length',
          {
            length: 10,
          }
        ),
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 2000,
          }
        ),
      }),
      contactFormSubjectValidators: [Validators.required],
      contactFormSubjectErrorMessages: () => ({
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.subject'),
        }),
      }),
      url: [
        Validators.pattern(
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
        ),
      ],
      urlErrorMessages: () => ({
        pattern: this.translateService.instant('validation_error.url'),
      }),
      companyNameValidators: [Validators.required, Validators.maxLength(100)],
      companyNameErrorMessages: () => ({
        required: this.translateService.instant(
          'company.survey.validation_name',
          {
            length: 1,
          }
        ),
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 100,
          }
        ),
      }),
      companyLinkValidators: [Validators.maxLength(100)],
      companyLinkErrorMessages: () => ({
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 100,
          }
        ),
      }),
      companySizeValidators: [Validators.required],
      companySizeErrorMessages: () => ({
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.company_size'),
        }),
      }),
      companyNicheValidators: [Validators.required, Validators.maxLength(100)],
      companyNicheErrorMessages: () => ({
        required: this.translateService.instant(
          'company.survey.validation_niche',
          {
            length: 1,
          }
        ),
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 200,
          }
        ),
      }),
      notificationMessageValidators: [
        Validators.required,
        Validators.maxLength(500),
      ],
      notificationMessageErrorMessages: () => ({
        required: this.translateService.instant('validation_error.min_length', {
          length: 1,
        }),
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 500,
          }
        ),
      }),
      notificationSubjectValidators: [
        Validators.required,
        Validators.maxLength(500),
      ],
      notificationSubjectErrorMessages: () => ({
        required: this.translateService.instant('validation_error.min_length', {
          length: 1,
        }),
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 100,
          }
        ),
      }),
      companyCountryValidators: [Validators.required],
      companyCountryErrorMessages: () => ({
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.country'),
        }),
      }),
      companyPlaceValidators: [
        Validators.minLength(1),
        Validators.maxLength(200),
      ],
      companyPlaceErrorMessages: () => ({
        minlength: this.translateService.instant(
          'validation_error.min_length',
          {
            length: 10,
          }
        ),
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 200,
          }
        ),
      }),
      companySourceValidators: [Validators.required],
      companySourceErrorMessages: () => ({
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.source'),
        }),
      }),
      companySourceOtherValidators: [Validators.maxLength(200)],
      companySourceOtherErrorMessages: () => ({
        maxlength: this.translateService.instant(
          'validation_error.max_length',
          {
            length: 200,
          }
        ),
      }),
      companyRejectMessageValidators: [Validators.required],
      companyRejectMessageErrorMessages: () => ({
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.reason'),
        }),
      }),
      subscriptionValidators: [Validators.required],
      subscriptionErrorMessages: () => ({
        required: this.translateService.instant('validation_error.required', {
          field: this.translateService.instant('field_name.subscription'),
        }),
      }),
    };
  }

  get formats() {
    return {
      supportedAudioTypes: [
        'audio/mpeg',
        'audio/mp3',
        'audio/mp4',
        'audio/mpeg',
        'audio/x-matroska',
      ],
      supportedVideoTypes: [
        'video/mp4',
        'application/mp4',
        'video/mpeg',
        'video/x-matroska',
        'video/3gpp',
        'video/quicktime',
        'video/x-msvideo',
        'video/x-ms-wmv',
      ],
      supportedImageTypes: [
        'image/jpeg',
        'image/png',
        'image/apng',
        'image/avif',
        'image/gif',
        'image/svg+xml',
        'image/heif',
        'image/heic',
        'image/raw',
        'image/rw2',
        'image/webp',
      ],
      supportedFileTypes: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ],
    };
  }

  get size() {
    return {
      avatar: 10485760,
      file: 20971520,
    };
  }

  constructor(private translateService: TranslateService) {}
}
