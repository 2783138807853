<div class="wrapper">
  @if (supportSlider) {
    <ion-card class="slider animate__animated animate__fadeIn">
      <swiper-container
        [drSwiper]="{
          slidesPerView: 1,
          loopedSlidesLimit: false,
          autoplay: { delay: 5000 },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            horizontalClass: 'left'
          }
        }"
      >
        @for (slide of slides; track $index) {
          <swiper-slide
            [style.background-image]="'url(' + slide.imageUrl + ')'"
          >
            <div class="slide-container">
              <dr-text>
                <span class="slide-title">{{ slide.title }}</span>
              </dr-text>
              <dr-text>
                <span class="slide-subtitle">{{ slide.subtitle }}</span>
              </dr-text>
            </div>
          </swiper-slide>
        }
      </swiper-container>
    </ion-card>
  }
  <ion-card class="form">
    <ng-content></ng-content>
  </ion-card>
</div>
