import { Directive } from '@angular/core';
import { QRCodeType } from '@dr/barcode';
import { User } from '@dr/user';
import { Connection, UserConnection } from '../../../interfaces/connection';
import { AddConnection } from '../add-connection';

@Directive()
export abstract class AddUserConnection extends AddConnection {
  override qRCodeType = QRCodeType.user;

  openConnection(connection: Connection) {
    const userId = connection?.u?.id;

    if (!userId) {
      return;
    }

    this.navController.navigateForward(`/user/${userId}`);
  }

  override addByQr$(qrId: string) {
    return this.connectionsState.addConnectionByQr$(qrId, 'user');
  }

  override findConnections$() {
    return this.connectionsApiService.findPossibleUserConnections$({
      search: this.searchText(),
    });
  }

  override deleteRequest(connection: Connection) {
    const connectionUserId = connection?.u?.id;

    if (!connectionUserId) {
      return;
    }

    this.connectionsState
      .rejectConnection$(connectionUserId, 'user')
      .subscribe(() => {
        this.deleteConnection(connection);
      });
  }

  override deleteConnection(targetConnection: Connection) {
    this.connections.set(
      this.connections()?.map(
        (connection) =>
          ({
            ...connection,
            con:
              targetConnection.u?.id === connection.u?.id
                ? undefined
                : connection.con,
          }) as UserConnection
      )
    );
  }

  override approveConnection(targetConnection: Connection) {
    const userId = targetConnection?.u?.id;

    if (!userId) {
      return;
    }

    this.connectionsState.approveConnection$(userId, 'user').subscribe(() => {
      this.markConnectionAsApproved({
        ...targetConnection,
        s: 'connected',
        u: {
          ...targetConnection.u,
          id: userId,
        },
      } as Connection);
    });
  }

  override requestConnection(targetConnection: Connection) {
    const userId = targetConnection?.u?.id;

    if (!userId) {
      return;
    }

    this.connectionsState.requestConnection$(userId, 'user').subscribe(() => {
      this.connections.set(
        this.connections()?.map(
          (connection) =>
            ({
              ...connection,
              con:
                userId === connection.u?.id
                  ? this.requestedParams()
                  : connection.con,
            }) as UserConnection
        )
      );
    });
  }

  override markConnectionAsConnected(entity: User) {
    this.connections.set(
      this.connections()?.map(
        (connection) =>
          ({
            ...connection,
            con:
              entity.id === connection.u?.id
                ? this.connectedParams()
                : connection.con,
          }) as UserConnection
      )
    );
  }

  override markConnectionAsApproved(targetConnection: Connection) {
    this.connections.set(
      this.connections()?.map(
        (connection) =>
          ({
            ...connection,
            con:
              targetConnection.u?.id === connection.u?.id
                ? targetConnection
                : connection.con,
          }) as UserConnection
      )
    );
  }

  override markConnectionAsRequested(targetConnection: Connection) {
    this.connections.set(
      this.connections()?.map(
        (connection) =>
          ({
            ...connection,
            con:
              targetConnection.u?.id === connection.u?.id
                ? {
                    ...targetConnection,
                    fu: targetConnection.u,
                  }
                : connection.con,
          }) as UserConnection
      )
    );
  }
}
