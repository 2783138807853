<ion-toggle
  [formControl]="fControl"
  [disabled]="!!disabled"
  [class.disabled]="disabled"
  (ionChange)="changed.emit(fControl.value)"
  color="dark"
>
  @if (label) {
    <dr-text>{{ label }}</dr-text>
  }
</ion-toggle>
