<div class="img-container">
  <ion-img
    src="/assets/images/rejected.png"
    class="animate__animated animate__zoomIn"
  ></ion-img>
</div>

<dr-text class="message" [centered]="true">
  <div>{{ request.msg }}</div>
</dr-text>

<dr-text class="support_message" color="medium" [centered]="true">
  <div>{{ 'company.survey.finish_support' | translate }}</div>
</dr-text>

<dr-button
  size="small"
  fill="clear"
  [label]="'contact_form.title' | translate"
  (click)="support.emit()"
></dr-button>

<div class="footer">
  <dr-button
    size="small"
    fill="clear"
    color="danger"
    [label]="'company.survey.finish_delete_btn' | translate"
    (click)="confirmDialog.open()"
  ></dr-button>
</div>

<dr-confirm
  #confirmDialog
  confirmButtonColor="danger"
  [title]="'company.survey.finish_delete_message' | translate"
  (confirm)="deleted.emit()"
></dr-confirm>
