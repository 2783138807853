import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SegmentComponent, SegmentItem, TextComponent } from '@dr/ui';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  CompanySubscriptionConfig,
  SubscriptionPeriod,
} from '../../interfaces/company';
import { formatPrice } from '../../utils/format-price';

@Component({
  selector: 'dr-company-subscription-item',
  templateUrl: './company-subscription-item.component.html',
  styleUrls: ['./company-subscription-item.component.scss'],
  standalone: true,
  imports: [
    TextComponent,
    IonicModule,
    TranslateModule,
    FormsModule,
    SegmentComponent,
  ],
})
export class CompanySubscriptionItemComponent {
  @HostBinding('class.primary')
  @Input()
  primary?: boolean;

  @Input() title?: string;
  @Input() annuallyDiscount?: number;
  @Input() oldPrice?: number;
  @Input() currentPrice?: number;
  @Input() bulletPoints?: CompanySubscriptionConfig[];

  @Output() getStarted: EventEmitter<void> = new EventEmitter<void>();

  periodType: SubscriptionPeriod = 'monthly';

  segmentItems: SegmentItem<SubscriptionPeriod>[] = [
    {
      label: 'subscriptions.period.monthly',
      type: 'monthly',
    },
    {
      label: 'subscriptions.period.annually',
      type: 'annually',
    },
  ];

  constructor(public elementRef: ElementRef) {}

  formatPrice(price: number) {
    return formatPrice(price, this.periodType, this.annuallyDiscount);
  }
}
