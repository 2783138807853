import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'dr-avatar',
  templateUrl: 'avatar.component.html',
  styleUrls: ['avatar.component.scss'],
  standalone: true,
  imports: [IonicModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  src = input<string | undefined>();
  name = input<string | undefined>();
  color = input<string | undefined>();
  size = input<
    | 'small'
    | 'medium'
    | 'large'
    | 'larger'
    | 'extra-large'
    | 'extra-large-XL'
    | undefined
  >();

  initials = computed(() =>
    (this.name() || '')
      .split(' ')
      .map((element) => element[0])
      .join('')
      .toUpperCase()
  );
}
