<ion-segment
  [value]="value"
  (ionChange)="changeType.emit($any($event).detail.value)"
  [scrollable]="true"
>
  @for (t of items; track $index) {
    <ion-segment-button [value]="t.type">
      <ion-label>
        <div class="label">
          {{ t.label | translate }}
          @if (t.badge$) {
            <ion-badge [color]="t.badgeColor">{{
              t.badge$ | async | shortNumber
            }}</ion-badge>
          }
        </div>
      </ion-label>
    </ion-segment-button>
  }
</ion-segment>
