import {
  CreateCompanySubscriptionsPack,
  UpdateCompanySubscriptionsPack,
} from '../services/company-subscriptions-api.service';

export const CompanySubscriptionsActionsName = 'CompanySubscriptions';

export class LoadSubscriptionsPacksAction {
  static readonly type = `${CompanySubscriptionsActionsName} Load Subscriptions Packs`;
}

export class ReloadSubscriptionsPacksAction {
  static readonly type = `${CompanySubscriptionsActionsName} Reload Subscriptions Packs`;
}

export class LoadSubscriptionsAction {
  static readonly type = `${CompanySubscriptionsActionsName} Load Subscriptions`;
}

export class ReloadSubscriptionsAction {
  static readonly type = `${CompanySubscriptionsActionsName} Reload Subscriptions`;
}

export class CreateSubscriptionsPackAction {
  static readonly type = `${CompanySubscriptionsActionsName} Create Subscriptions Pack`;

  constructor(public data: CreateCompanySubscriptionsPack) {}
}

export class UpdateSubscriptionsPackAction {
  static readonly type = `${CompanySubscriptionsActionsName} Update Subscriptions Pack`;

  constructor(public id: string, public data: UpdateCompanySubscriptionsPack) {}
}

export class UpdateSubscriptionAction {
  static readonly type = `${CompanySubscriptionsActionsName} Update Subscription`;

  constructor(
    public id: string,
    public data: UpdateCompanySubscriptionsPack['subscriptions'][0]
  ) {}
}

export class DeleteSubscriptionsPackAction {
  static readonly type = `${CompanySubscriptionsActionsName} Delete Subscriptions Pack`;

  constructor(public id: string) {}
}

export class DeleteSubscriptionAction {
  static readonly type = `${CompanySubscriptionsActionsName} Delete Subscription`;

  constructor(public id: string) {}
}
