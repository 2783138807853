<ion-app [class.mobile]="!IsWebVersion()">
  <ion-split-pane contentId="main-content" class="custom">
    <ion-menu
      #ionMenu
      contentId="main-content"
      type="overlay"
      [disabled]="!authState.isAuthenticated()"
    >
      <ion-content>
        @if (authState.isAuthenticated()) {
          <dr-side-menu [ionMenu]="ionMenu"></dr-side-menu>
        }
      </ion-content>
    </ion-menu>

    <ion-loading
      [isOpen]="!!(currentUserState.updating$ | async)"
    ></ion-loading>

    <ion-router-outlet id="main-content"></ion-router-outlet>

    @if (authState.isAuthenticated()) {
      <dr-current-user-connector
        (connected)="profileConnected()"
      ></dr-current-user-connector>
    }
  </ion-split-pane>

  <dr-lost-connection></dr-lost-connection>
</ion-app>
