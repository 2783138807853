import { Company } from '../interfaces/company';

export interface CompanyEmployeesSize {
  label: string;
  value: Pick<Company, 'emn' | 'emx'>;
}

export const companyEmployeesSize: CompanyEmployeesSize[] = [
  {
    label: '1-10',
    value: {
      emn: 1,
      emx: 10,
    },
  },
  {
    label: '10-50',
    value: {
      emn: 10,
      emx: 50,
    },
  },
  {
    label: '50-200',
    value: {
      emn: 50,
      emx: 200,
    },
  },
  {
    label: '> 200',
    value: {
      emn: 200,
    },
  },
];
