export type SurveyStepType =
  | 'name'
  | 'link'
  | 'employees-size'
  | 'clients-size'
  | 'niche'
  | 'logo'
  | 'country'
  | 'place'
  | 'source';
export interface SurveyStep {
  id: SurveyStepType;
  title: string;
  nextId: SurveyStepType | null;
  canSkip?: boolean;
}

export const companySurveySteps: SurveyStep[] = [
  {
    id: 'name',
    title: 'company.survey.company_name_title',
    nextId: 'link',
  },
  {
    id: 'link',
    title: 'company.survey.company_link_title',
    canSkip: true,
    nextId: 'employees-size',
  },
  {
    id: 'employees-size',
    title: 'company.survey.company_size_title',
    nextId: 'clients-size',
  },
  {
    id: 'clients-size',
    title: 'company.survey.company_clients_size_title',
    nextId: 'niche',
  },
  {
    id: 'niche',
    title: 'company.survey.company_niche_title',
    nextId: 'logo',
  },
  {
    id: 'logo',
    title: 'company.survey.company_logo_title',
    nextId: 'country',
  },
  {
    id: 'country',
    title: 'company.survey.company_country_title',
    nextId: 'place',
  },
  {
    id: 'place',
    title: 'company.survey.company_place_title',
    nextId: 'source',
    canSkip: true,
  },
  {
    id: 'source',
    title: 'company.survey.company_source_title',
    canSkip: true,
    nextId: null,
  },
];
