import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { IonColor } from '@dr/utils';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'dr-text',
  templateUrl: 'text.component.html',
  styleUrls: ['text.component.scss'],
  standalone: true,
  imports: [IonicModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.nowrap]': `nowrap()`,
    '[class.small]': `small()`,
    '[class.centered]': `centered()`,
    '[class.uppercase]': `uppercase()`,
  },
})
export class TextComponent {
  color = input<IonColor | undefined>('dark');
  underline = input<boolean>();
  uppercase = input<boolean>();
  centered = input<boolean>();
  nowrap = input<boolean>();
  small = input<boolean>();
}
