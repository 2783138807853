import { AsyncPipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import {
  ButtonComponent,
  InputComponent,
  LinkComponent,
  TextComponent,
} from '@dr/ui';
import { ValidationService } from '@dr/utils';
import { TranslateModule } from '@ngx-translate/core';
import { AuthState } from '../../states/auth/auth.state';

@Component({
  selector: 'dr-reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['../../auth.scss', './reset-password.component.scss'],
  imports: [
    TextComponent,
    InputComponent,
    TranslateModule,
    ButtonComponent,
    LinkComponent,
    AsyncPipe,
  ],
  standalone: true,
})
export class ResetPasswordComponent implements OnDestroy {
  @ViewChild('password') password?: InputComponent<string>;
  @ViewChild('confirmPassword') confirmPassword?: InputComponent<string>;

  @Output() submitted = new EventEmitter<void>();
  @Output() signIn = new EventEmitter<void>();

  emailFormControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.emailValidators
  );

  passwordControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.passwordValidators
  );
  confirmPasswordControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.confirmPasswordValidators(
      this.passwordControl
    )
  );

  private destroyRef = inject(DestroyRef);

  constructor(
    public validationService: ValidationService,
    public authState: AuthState
  ) {}

  ngOnDestroy() {
    this.authState.clearResetPasswordData();
  }

  reset(): void {
    this.validateFormElements();

    if (this.passwordControl.invalid || this.confirmPasswordControl.invalid) {
      return;
    }

    this.authState
      .resetPassword$(this.passwordControl.value as string)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.submitted.emit();
      });
  }

  private validateFormElements(): void {
    this.password?.validate();
    this.confirmPassword?.validate();
  }
}
