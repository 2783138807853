@if (file(); as attachment) {
  <dr-attachment
    [name]="attachment.name"
    [size]="attachment.size"
    [removable]="true"
    (remove)="file.set(undefined)"
    action="uploader"
  ></dr-attachment>
}

<div class="container">
  <div class="action" (click)="uploadFile()">
    <ion-icon name="attach-outline" color="dark"></ion-icon>
  </div>

  <dr-textarea
    [fControl]="messageControl"
    [minRows]="1"
    [maxRows]="5"
    [required]="true"
    [maxlength]="2000"
    [placeholder]="'contact_form.write_message' | translate"
    [errors]="
      validationService.appValidationRules.contactFormMessageErrorMessages()
    "
    autocapitalize="sentences"
    enterKey="enter"
  ></dr-textarea>

  <div
    class="action"
    (click)="send()"
    [class.disabled]="messageControl.invalid || !canSend"
  >
    <ion-icon name="send-outline"></ion-icon>
  </div>
</div>
