import { Injectable } from '@angular/core';
import { ToastService } from '@dr/utils';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { catchError, Observable, of, switchMap, tap, zip } from 'rxjs';
import {
  CompanySubscription,
  CompanySubscriptionsPack,
} from '../interfaces/company';
import {
  CompanySubscriptionsApiService,
  CreateCompanySubscriptionsPack,
  UpdateCompanySubscriptionsPack,
} from '../services/company-subscriptions-api.service';
import {
  CreateSubscriptionsPackAction,
  DeleteSubscriptionsPackAction,
  LoadSubscriptionsPacksAction,
  ReloadSubscriptionsPacksAction,
  CompanySubscriptionsActionsName,
  UpdateSubscriptionsPackAction,
  ReloadSubscriptionsAction,
  LoadSubscriptionsAction,
  UpdateSubscriptionAction,
  DeleteSubscriptionAction,
} from './company-subscriptions.actions';

export interface SubscriptionsStateModel {
  subscriptionsPacks?: CompanySubscriptionsPack[];
  subscriptionsPacksError?: string;
  subscriptionsPacksLoaded?: boolean;
  subscriptions?: CompanySubscription[];
  subscriptionsError?: string;
  subscriptionsLoaded?: boolean;
}

const defaults: SubscriptionsStateModel = {};
export const SubscriptionsStoreKeys = [];

@State<SubscriptionsStateModel>({
  name: CompanySubscriptionsActionsName,
  defaults,
})
@Injectable()
export class CompanySubscriptionsState {
  @Select(CompanySubscriptionsState.subscriptionsPacks)
  subscriptionsPacks$!: Observable<CompanySubscriptionsPack[] | undefined>;
  @Select(CompanySubscriptionsState.subscriptionsPacksError)
  subscriptionsPacksError$!: Observable<string | undefined>;
  @Select(CompanySubscriptionsState.subscriptions)
  subscriptions$!: Observable<CompanySubscription[] | undefined>;
  @Select(CompanySubscriptionsState.subscriptionsError)
  subscriptionsError$!: Observable<string | undefined>;

  @Select(
    actionsExecuting([
      LoadSubscriptionsPacksAction,
      CreateSubscriptionsPackAction,
      UpdateSubscriptionsPackAction,
    ])
  )
  packProcessing$!: Observable<boolean | undefined>;

  @Select(
    actionsExecuting([UpdateSubscriptionAction, DeleteSubscriptionAction])
  )
  subscriptionProcessing$!: Observable<boolean | undefined>;

  @Select(actionsExecuting([DeleteSubscriptionsPackAction]))
  deleting$!: Observable<boolean | undefined>;

  constructor(
    private companySubscriptionsApiService: CompanySubscriptionsApiService,
    private store: Store,
    private toastService: ToastService
  ) {}

  @Selector()
  static subscriptionsPacks(state: SubscriptionsStateModel) {
    return state.subscriptionsPacks;
  }

  @Selector()
  static subscriptionsPacksError(state: SubscriptionsStateModel) {
    return state.subscriptionsPacksError;
  }

  @Selector()
  static subscriptions(state: SubscriptionsStateModel) {
    return state.subscriptions;
  }

  @Selector()
  static subscriptionsError(state: SubscriptionsStateModel) {
    return state.subscriptionsError;
  }

  loadSubscriptionsPacks$() {
    return this.store.dispatch(new LoadSubscriptionsPacksAction());
  }

  reloadSubscriptionsPacks$() {
    return this.store.dispatch(new ReloadSubscriptionsPacksAction());
  }

  loadSubscriptions$() {
    return this.store.dispatch(new LoadSubscriptionsAction());
  }

  reloadSubscriptions$() {
    return this.store.dispatch(new ReloadSubscriptionsAction());
  }

  createSubscriptionsPack$(data: CreateCompanySubscriptionsPack) {
    return this.store.dispatch(new CreateSubscriptionsPackAction(data));
  }

  updateSubscriptionsPack$(id: string, data: UpdateCompanySubscriptionsPack) {
    return this.store.dispatch(new UpdateSubscriptionsPackAction(id, data));
  }

  updateSubscription$(
    id: string,
    data: UpdateCompanySubscriptionsPack['subscriptions'][0]
  ) {
    return this.store.dispatch(new UpdateSubscriptionAction(id, data));
  }

  deleteSubscriptionsPack$(id: string) {
    return this.store.dispatch(new DeleteSubscriptionsPackAction(id));
  }

  deleteSubscription$(id: string) {
    return this.store.dispatch(new DeleteSubscriptionAction(id));
  }

  @Action(LoadSubscriptionsAction)
  private _loadSubscriptions$(ctx: StateContext<SubscriptionsStateModel>) {
    if (ctx.getState().subscriptionsLoaded) {
      return of(ctx.getState().subscriptions);
    }

    ctx.patchState({
      subscriptionsLoaded: true,
      subscriptionsError: undefined,
    });

    return this.companySubscriptionsApiService.getSubscriptions$().pipe(
      tap((subscriptions) =>
        ctx.patchState({
          subscriptions,
        })
      ),
      catchError((subscriptionsError) => {
        ctx.patchState({
          subscriptionsError,
          subscriptionsLoaded: false,
        });

        throw subscriptionsError;
      })
    );
  }

  @Action(ReloadSubscriptionsAction)
  private _reloadSubscriptions$(ctx: StateContext<SubscriptionsStateModel>) {
    ctx.patchState({
      subscriptionsLoaded: false,
    });

    return this.loadSubscriptions$();
  }

  @Action(LoadSubscriptionsPacksAction)
  private _loadSubscriptionsPacks$(ctx: StateContext<SubscriptionsStateModel>) {
    if (ctx.getState().subscriptionsPacksLoaded) {
      return of(ctx.getState().subscriptionsPacks);
    }

    ctx.patchState({
      subscriptionsPacksLoaded: true,
      subscriptionsPacksError: undefined,
    });

    return this.companySubscriptionsApiService.getSubscriptionsPacks$().pipe(
      tap((subscriptionsPacks) =>
        ctx.patchState({
          subscriptionsPacks,
        })
      ),
      catchError((subscriptionsPacksError) => {
        ctx.patchState({
          subscriptionsPacksError,
          subscriptionsPacksLoaded: false,
        });

        throw subscriptionsPacksError;
      })
    );
  }

  @Action(ReloadSubscriptionsPacksAction)
  private _reloadSubscriptionsPacks$(
    ctx: StateContext<SubscriptionsStateModel>
  ) {
    ctx.patchState({
      subscriptionsPacksLoaded: false,
    });

    return this.loadSubscriptionsPacks$();
  }

  @Action(CreateSubscriptionsPackAction)
  private _createSubscriptionsPack$(
    ctx: StateContext<SubscriptionsStateModel>,
    action: CreateSubscriptionsPackAction
  ) {
    return this.companySubscriptionsApiService
      .createSubscriptionsPack$(action.data)
      .pipe(
        catchError((error) => {
          this.toastService.showToast({
            color: 'danger',
            message: error,
            skipTranslation: true,
            duration: 2000,
            position: 'top',
          });

          throw error;
        }),
        switchMap(() =>
          zip(this.reloadSubscriptionsPacks$(), this.reloadSubscriptions$())
        )
      );
  }

  @Action(UpdateSubscriptionsPackAction)
  private _updateSubscriptionsPack$(
    ctx: StateContext<SubscriptionsStateModel>,
    action: UpdateSubscriptionsPackAction
  ) {
    return this.companySubscriptionsApiService
      .updateSubscriptionsPack$(action.id, action.data)
      .pipe(
        catchError((error) => {
          this.toastService.showToast({
            color: 'danger',
            message: error,
            skipTranslation: true,
            duration: 2000,
            position: 'top',
          });

          throw error;
        }),
        switchMap(() =>
          zip(this.reloadSubscriptionsPacks$(), this.reloadSubscriptions$())
        )
      );
  }

  @Action(UpdateSubscriptionAction)
  private _updateSubscription$(
    ctx: StateContext<SubscriptionsStateModel>,
    action: UpdateSubscriptionAction
  ) {
    return this.companySubscriptionsApiService
      .updateSubscription$(action.id, action.data)
      .pipe(
        catchError((error) => {
          this.toastService.showToast({
            color: 'danger',
            message: error,
            skipTranslation: true,
            duration: 2000,
            position: 'top',
          });

          throw error;
        }),
        switchMap(() =>
          zip(this.reloadSubscriptionsPacks$(), this.reloadSubscriptions$())
        )
      );
  }

  @Action(DeleteSubscriptionsPackAction)
  private _deleteSubscriptionsPack$(
    ctx: StateContext<SubscriptionsStateModel>,
    action: DeleteSubscriptionsPackAction
  ) {
    return this.companySubscriptionsApiService
      .deleteSubscriptionsPack$(action.id)
      .pipe(
        catchError((error) => {
          this.toastService.showToast({
            color: 'danger',
            message: error,
            skipTranslation: true,
            duration: 2000,
            position: 'top',
          });

          throw error;
        }),
        switchMap(() => this.reloadSubscriptionsPacks$())
      );
  }

  @Action(DeleteSubscriptionAction)
  private _deleteSubscription$(
    ctx: StateContext<SubscriptionsStateModel>,
    action: DeleteSubscriptionAction
  ) {
    return this.companySubscriptionsApiService
      .deleteSubscription$(action.id)
      .pipe(
        catchError((error) => {
          this.toastService.showToast({
            color: 'danger',
            message: error,
            skipTranslation: true,
            duration: 2000,
            position: 'top',
          });

          throw error;
        }),
        switchMap(() => this.reloadSubscriptions$())
      );
  }
}
