import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, switchMap } from 'rxjs';
import { MyCompaniesState } from '../states/my-companies.state';

@Injectable()
export class ActiveCompanyInterceptorService implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    httpRequest: HttpRequest<unknown>,
    nextHandler: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.store.selectOnce(MyCompaniesState.activeCompany).pipe(
      switchMap((activeCompany) => {
        if (activeCompany) {
          httpRequest = httpRequest.clone({
            headers: httpRequest.headers.set('acid', activeCompany.id),
          });
        }

        return nextHandler.handle(httpRequest);
      })
    );
  }
}
