import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseInput } from '@dr/utils';
import { IonicModule, IonInput } from '@ionic/angular';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-input',
  templateUrl: 'input.component.html',
  styleUrls: ['input.component.scss'],
  standalone: true,
  imports: [IonicModule, ReactiveFormsModule, TextComponent, MaskitoDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent<V> extends BaseInput<V> {
  @ViewChild(IonInput) ionInput!: IonInput;

  @Input() max?: number;
  @Input() min?: number;
  @Input() step?: number;
  @Input() mask?: MaskitoOptions;
  @Input() type?:
    | 'text'
    | 'password'
    | 'email'
    | 'number'
    | 'search'
    | 'tel'
    | 'url' = 'text';
  @Input() inputMode?:
    | 'decimal'
    | 'email'
    | 'numeric'
    | 'search'
    | 'text'
    | 'tel'
    | 'url' = 'text';

  passwordVisible = false;
  inputType: InputComponent<V>['type'];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  readonly maskElement: MaskitoElementPredicate = async (el) =>
    (el as HTMLIonInputElement).getInputElement();

  focus(): void {
    this.ionInput.setFocus();
  }

  override ngOnChanges() {
    super.ngOnChanges();

    this.checkInputType();
  }

  togglePassword() {
    this.passwordVisible = !this.passwordVisible;
    this.checkInputType();
    this.changeDetectorRef.detectChanges();
  }

  private checkInputType() {
    this.inputType =
      this.type === 'password'
        ? this.passwordVisible
          ? 'text'
          : 'password'
        : this.type;
  }
}
