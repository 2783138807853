import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ButtonComponent,
  EmptyComponent,
  SkeletonComponent,
  TextComponent,
} from '@dr/ui';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CompanySubscriptionsPack } from '../../interfaces/company';
import { CompanySubscriptionsPackComponent } from '../company-subscriptions-pack/company-subscriptions-pack.component';

@Component({
  selector: 'dr-company-subscriptions-packs-list',
  templateUrl: './company-subscriptions-packs-list.component.html',
  styleUrls: ['./company-subscriptions-packs-list.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    EmptyComponent,
    TranslateModule,
    TextComponent,
    ButtonComponent,
    CompanySubscriptionsPackComponent,
    SkeletonComponent,
  ],
})
export class CompanySubscriptionsPacksListComponent {
  @Input() subscriptionsPacks?: CompanySubscriptionsPack[];

  @Output() editPack = new EventEmitter<CompanySubscriptionsPack>();
  @Output() deletePack = new EventEmitter<CompanySubscriptionsPack>();
}
