<div class="tabs">
  @for (tab of tabs; track $index) {
    <div
      #tabGroupComponent
      class="tab-item"
      [attr.key]="tab.key"
      [class.active]="tab.key === activeTabKey"
      (click)="makeTabActive(tab)"
    >
      <dr-text [color]="tab.key === activeTabKey ? 'primary' : 'medium'">
        {{ tab?.skipTranslation ? tab.label : (tab.label | translate) }}
      </dr-text>
      <ion-ripple-effect></ion-ripple-effect>
    </div>
  }
</div>

<div
  [class.animated]="animateIndicator()"
  [style.transform]="indicatorTransform()"
  [style.width]="indicatorWidth()"
  class="indicator"
></div>
