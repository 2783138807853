import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'dr-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['checkbox.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, ReactiveFormsModule],
})
export class CheckboxComponent<V> {
  @Input({ required: true }) fControl!: FormControl<V>;
  @Input() alignment?: 'center' | 'start' = 'start';
  @Input() labelPlacement?: 'end' | 'fixed' | 'stacked' | 'start' = 'end';
}
