import {
  computed,
  Directive,
  inject,
  OnInit,
  Signal,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Capacitor } from '@capacitor/core';
import { QRCodeType, QrScanModalComponent } from '@dr/barcode';
import { Company } from '@dr/company';
import { ConfirmComponent } from '@dr/ui';
import { User } from '@dr/user';
import { SmartComponent, ToastService } from '@dr/utils';
import {
  IonRefresher,
  IonSearchbar,
  ModalController,
  NavController,
} from '@ionic/angular';
import { catchError, finalize, Observable, tap } from 'rxjs';
import { Connection } from '../../interfaces/connection';
import { ConnectionsApiService } from '../../services/connections-api.service';
import {
  ConnectionSocket,
  ConnectionSocketService,
} from '../../socket/connection-socket.service';
import { ConnectionsState } from '../../states/connections.state';

@Directive()
export abstract class AddConnection extends SmartComponent implements OnInit {
  readonly connectionsState = inject(ConnectionsState);
  readonly toastService = inject(ToastService);
  readonly modalController = inject(ModalController);
  readonly connectionsApiService = inject(ConnectionsApiService);
  readonly connectionSocketService = inject(ConnectionSocketService);
  readonly navController = inject(NavController);

  readonly connectionActionLoading = toSignal(
    this.connectionsState.actionsLoading$
  );
  readonly ionSearchbar = viewChild(IonSearchbar);
  readonly ionRefresher = viewChild(IonRefresher);
  readonly confirmCancelRequest = viewChild<ConfirmComponent>(
    'confirmCancelRequest'
  );

  targetConnection = signal<Connection | undefined>(undefined);
  connections = signal<Connection[] | undefined>(undefined);
  isQrScanOpened = signal<boolean>(false);
  loading = signal<boolean>(false);
  searchText = signal<string>('');

  canFind = computed(() => this.searchText().trim().length > 2);

  abstract currentUser: Signal<User | undefined>;
  abstract currentCompany: Signal<Company | undefined>;
  abstract requestedParams: Signal<Omit<Connection['con'], 'id'>>;
  abstract connectedParams: Signal<Omit<Connection['con'], 'id'>>;
  abstract qRCodeType: QRCodeType;
  abstract findConnections$(): Observable<Connection[]>;
  abstract addByQr$(qrId: string): Observable<unknown>;
  abstract markConnectionAsConnected(entity: unknown): void;
  abstract deleteRequest(connection: Connection): void;
  abstract deleteConnection(connection: Connection): void;
  abstract approveConnection(connection: Connection): void;
  abstract requestConnection(connection: Connection): void;
  abstract markConnectionAsApproved(connection: Connection): void;
  abstract markConnectionAsRequested(connection: Connection): void;
  abstract openConnection(connection: Connection): void;
  abstract handleSocketEvents(socket: ConnectionSocket): void;

  override async ngOnInit() {
    await super.ngOnInit();

    if (Capacitor.isNativePlatform()) {
      setTimeout(() => this.ionSearchbar()?.setFocus(), 300);
    }

    this.connectionSocketService.onEvent$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => this.handleSocketEvents(event));
  }

  async scan() {
    if (this.isQrScanOpened()) {
      return;
    }

    this.isQrScanOpened.set(true);

    const modal = await this.modalController.create({
      component: QrScanModalComponent,
      componentProps: {
        validKeys: [this.qRCodeType],
      },
    });

    await modal.present();

    try {
      const { data } = await modal.onWillDismiss();
      const qrId = data[this.qRCodeType];

      if (!qrId) {
        return;
      }

      this.addConnectionByQrCode(qrId);
    } finally {
      this.isQrScanOpened.set(false);
    }
  }

  askCancelRequest(connection: Connection) {
    this.targetConnection.set(connection);
    this.confirmCancelRequest()?.open();
  }

  findConnections(searchText: string) {
    this.searchText.set(searchText);

    if (!this.canFind()) {
      return;
    }

    this.loading.set(true);

    this.findConnections$()
      .pipe(
        tap((connections) => this.connections.set(connections)),
        this.catchError$(),
        finalize(() => this.loading.set(false))
      )
      .subscribe();
  }

  refresh() {
    this.findConnections$()
      .pipe(
        tap((connections) => this.connections.set(connections)),
        this.catchError$(),
        finalize(() => this.ionRefresher()?.complete())
      )
      .subscribe();
  }

  addConnectionByQrCode(qrId: string) {
    this.addByQr$(qrId).subscribe((entity) =>
      this.markConnectionAsConnected(entity)
    );
  }

  protected catchError$() {
    return catchError((err) => {
      this.toastService.showToast({
        color: 'danger',
        message: err,
        duration: 1000,
        position: 'bottom',
        skipTranslation: true,
      });

      throw err;
    });
  }
}
