@for (entity of visibleEntities(); track $index) {
  <div (click)="openEntityProfile(entity)" class="avatar">
    <dr-avatar
      [src]="entity.avatar"
      [name]="entity.name"
      [color]="entity.color"
    ></dr-avatar>
  </div>
}
@if (additionalEntities(); as additionalEntities) {
  <div class="avatar">
    <dr-text>
      <span>+{{ additionalEntities }}</span>
    </dr-text>
  </div>
}
