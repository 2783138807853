<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="navController.back()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title>{{
      'connections.new_company_connection' | translate
    }}</ion-title>

    <ion-buttons slot="end">
      @if (loading()) {
        <ion-button>
          <ion-spinner></ion-spinner>
        </ion-button>
      } @else {
        <ion-button (click)="scan()">
          <ion-icon name="scan-outline"></ion-icon>
        </ion-button>
      }
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar>
    <ion-searchbar
      (ionInput)="findConnections($any($event.detail.value))"
      [debounce]="500"
      [placeholder]="'connections.find_companies' | translate"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <dr-loading [show]="connectionActionLoading()"></dr-loading>

  @if (canFind()) {
    <ion-refresher (ionRefresh)="refresh()" slot="fixed">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
  }

  @if (connections(); as connections) {
    @for (connection of connections; track connection.c?.id) {
      <ion-item>
        <div class="connection">
          <dr-entity-name
            [firstName]="$any(connection.c?.nm)"
            [subtitle]="$any(connection.c?.nch)"
            [imgUrl]="$any(connection.c?.lXS)"
            [color]="$any(connection.c?.lc)"
            (click)="openConnection(connection)"
          ></dr-entity-name>

          <dr-add-connection-action
            [loaded]="true"
            [connectionStatus]="connection.con?.s"
            [currentUserId]="currentUser()?.id"
            [currentCompanyId]="currentCompany()?.id"
            [requestedCompanyId]="connection?.con?.fc?.id"
            [requestedUserId]="connection?.con?.fu?.id"
            (cancelRequest)="askCancelRequest(connection)"
            (approveConnection)="approveConnection(connection)"
            (requestConnection)="requestConnection(connection)"
          ></dr-add-connection-action>
        </div>
      </ion-item>
    } @empty {
      <dr-empty
        [message]="'connections.no_connections_found' | translate"
      ></dr-empty>
    }
  } @else {
    <dr-empty [message]="'connections.type_to_find' | translate"></dr-empty>
  }

  <dr-confirm
    #confirmCancelRequest
    (confirm)="deleteRequest($any(targetConnection()))"
    [title]="'connections.confirm_delete_request' | translate"
  ></dr-confirm>
</ion-content>
