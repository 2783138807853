import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppLanguage } from '@dr/localization';
import { AppTheme } from '@dr/theme';
import {
  PaginationList,
  PaginationParams,
  RegularSuccessResponse,
} from '@dr/utils';
import { Observable } from 'rxjs';
import { User } from '../interfaces/user';

export interface UserPing {
  fbt?: string;
}

export interface UpdateUserData extends Partial<User> {
  device?: {
    language?: AppLanguage;
    theme?: AppTheme;
    pushNotifications?: boolean;
    notificationSound?: boolean;
    notificationVibration?: boolean;
  };
}

export interface OnlineUsers {
  users: number;
  devices: number;
}

@Injectable()
export class UserApiService {
  constructor(private httpClient: HttpClient) {}

  me$(): Observable<User> {
    return this.httpClient.get<User>('/api/user/me');
  }

  userProfileById$(userId: string): Observable<User> {
    return this.httpClient.get<User>(`/api/user/info/${userId}`);
  }

  onlineData$(): Observable<OnlineUsers> {
    return this.httpClient.get<OnlineUsers>('/api/user/online');
  }

  updateCurrentUser$(newUserData: Partial<User>) {
    return this.httpClient.put<User>('/api/user/me', {
      ...newUserData,
    });
  }

  ping$(data: UserPing) {
    return this.httpClient.put<User>('/api/user/ping', {
      ...data,
    });
  }

  uploadAvatar$(file: File) {
    const formData = new FormData();

    formData.append('avatar', file, file.name);

    return this.httpClient.put<User>('/api/user/me', formData);
  }

  deleteAvatar$() {
    return this.httpClient.delete<User>('/api/user/avatar');
  }

  users$(params: PaginationParams): Observable<PaginationList<User>> {
    return this.httpClient.get<PaginationList<User>>('/api/user/list', {
      params: {
        ...params,
      },
    });
  }

  deleteUsers$(ids: string[]): Observable<RegularSuccessResponse> {
    return this.httpClient.delete<RegularSuccessResponse>('/api/user/list', {
      body: {
        ids,
      },
    });
  }
}
