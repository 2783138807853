import { AppTheme } from '../states/theme/theme.actions';

export const appThemes: { translateKey: string; value: AppTheme }[] = [
  {
    translateKey: 'theme.Light',
    value: 'light',
  },
  {
    translateKey: 'theme.Dark',
    value: 'dark',
  },
  {
    translateKey: 'theme.System',
    value: 'system',
  },
];
