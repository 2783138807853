import {
  DestroyRef,
  Directive,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MaskitoOptions } from '@maskito/core';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';

export const phoneMask: MaskitoOptions = {
  mask: [
    '+',
    '3',
    '8',
    ' ',
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ],
};

export const priceMask = maskitoNumberOptionsGenerator({ precision: 2 });

@Directive()
export class BaseInput<V> implements OnChanges, OnInit {
  @Input({ required: true }) fControl!: FormControl<V>;

  @Input() label?: string;
  @Input() required = false;
  @Input() autofocus?: boolean;
  @Input() disabled?: boolean = false;
  @Input() labelPlacement?: 'stacked' | 'floating' = 'floating';
  @Input() spellcheck?: boolean;
  @Input() fill?: 'solid' | 'clear' = 'solid';
  @Input() counter?: boolean;
  @Input() counterFormatter?: (
    inputLength: number,
    maxLength: number
  ) => string;
  @Input() helperText?: string = ' ';
  @Input() errors?: {
    [key: string]: string;
  };
  @Input() maxlength?: number;
  @Input() minlength?: number;
  @Input() autocapitalize?:
    | 'off'
    | 'none'
    | 'on'
    | 'sentences'
    | 'words'
    | 'characters';
  @Input() enterKey?:
    | 'done'
    | 'enter'
    | 'go'
    | 'next'
    | 'previous'
    | 'search'
    | 'send' = 'done';
  @Input() debounce?: number;
  @Input() placeholder?: string;

  @Output() enter: EventEmitter<V> = new EventEmitter<V>();
  @Output() focusIn: EventEmitter<V> = new EventEmitter<V>();
  @Output() focusOut: EventEmitter<V> = new EventEmitter<V>();

  errorText = signal<string>('');

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (this.errors) {
      this.fControl.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.checkErrors();
        });
    }
  }

  ngOnChanges() {
    this.checkErrors();
  }

  validate(): void {
    this.fControl.markAsDirty();
    this.fControl.markAsTouched();
    this.checkErrors();
  }

  reset(): void {
    this.fControl.reset();
    this.checkErrors();
  }

  private checkErrors(): void {
    if (!this.errors) {
      return;
    }

    const serverError = this.errors['server'];

    if (serverError) {
      this.fControl.setErrors({ server: !!serverError });
    } else {
      const errors = Object.keys(this.fControl.errors || {})
        .filter((key) => key !== 'server')
        .reduce(
          (obj, key) => ({
            ...obj,
            [key]: this.fControl.errors?.[key],
          }),
          {}
        );

      this.fControl.setErrors(Object.keys(errors).length ? errors : null);
      this.errorText.set(this.getErrorText());
    }
  }

  private getErrorText(): string {
    if (!this.errors || !this.fControl?.dirty) {
      return '';
    }

    const errors = Object.keys(this.errors);

    if (!errors.length) {
      return '';
    }

    const controlErrorsKeys = Object.keys(this.fControl.errors || {});

    if (controlErrorsKeys.length) {
      return this.errors[controlErrorsKeys[0]];
    }

    return '';
  }
}
