import { AsyncPipe } from '@angular/common';
import {
  Component,
  inject,
  Inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeaturesModule } from '@dr/features';
import {
  ButtonComponent,
  EmptyComponent,
  ErrorFooterComponent,
  LoadingComponent,
  SkeletonComponent,
  TextComponent,
} from '@dr/ui';
import {
  KeyboardDirective,
  IsWebVersion,
  AppEnvironment,
  AppEnvironmentI,
  SmartComponent,
} from '@dr/utils';
import { IonicModule, IonModal, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { first, map, Observable } from 'rxjs';
import { ContactFormCreateTopicComponent } from '../../components/contact-form-create-topic/contact-form-create-topic.component';
import { ContactFormMessagesComponent } from '../../components/contact-form-messages/contact-form-messages.component';
import { ContactFormSendMessageComponent } from '../../components/contact-form-send-message/contact-form-send-message.component';
import { ContactFormTopicsComponent } from '../../components/contact-form-topics/contact-form-topics.component';
import {
  ContactFormSubjectType,
  ContactFormTopic,
} from '../../interfaces/contact-form';
import { ContactFormState } from '../../states/contact-form/contact-form.state';

export interface ContactFormSubject {
  label: string;
  value: ContactFormSubjectType;
}

@Component({
  selector: 'dr-contact-form',
  templateUrl: 'contact-form.component.html',
  styleUrls: ['contact-form.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TranslateModule,
    ButtonComponent,
    TextComponent,
    KeyboardDirective,
    ContactFormCreateTopicComponent,
    AsyncPipe,
    LoadingComponent,
    ContactFormTopicsComponent,
    EmptyComponent,
    ContactFormMessagesComponent,
    ContactFormSendMessageComponent,
    ErrorFooterComponent,
    FeaturesModule,
    SkeletonComponent,
  ],
})
export class ContactFormComponent extends SmartComponent implements OnInit {
  @ViewChild(IonModal) ionModal!: IonModal;

  readonly IsWebVersion = IsWebVersion;
  readonly navController = inject(NavController);

  selectedTopicId = signal<string | undefined>(
    this.activatedRoute.snapshot.queryParams['id']
  );

  currentTopic$: Observable<ContactFormTopic | undefined> =
    this.contactFormState.topics$.pipe(
      map((topics) =>
        topics?.find((topic) => topic.id === this.selectedTopicId())
      )
    );

  currentTopicMessagesState$ = this.contactFormState.topicMessages$.pipe(
    map(
      (topicMessages) =>
        topicMessages[this.selectedTopicId() as ContactFormTopic['id']]
    )
  );

  message = this.activatedRoute.snapshot.queryParams['message'];
  useBackButton = this.activatedRoute.snapshot.data['useBackButton'];

  constructor(
    public contactFormState: ContactFormState,
    @Inject(AppEnvironment) public appEnvironment: AppEnvironmentI,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.init();
  }

  override onActiveCompanyChanged() {
    super.onActiveCompanyChanged();

    this.init();
  }

  tryAgain() {
    this.contactFormState.topics$.pipe(first()).subscribe((topics) => {
      if (!topics?.length) {
        this.contactFormState.getTopicsList$();
      } else {
        this.contactFormState.loadMoreTopics$();
      }
    });
  }

  selectTopic(topic: ContactFormTopic) {
    if (!IsWebVersion()) {
      this.router.navigateByUrl(`/contact-form/chat/${topic.id}`);
    } else {
      this.selectedTopicId.set(undefined);

      setTimeout(() => {
        this.contactFormState.openTopic$(topic.id);
        this.selectedTopicId.set(topic.id);

        this.router.navigate([], {
          replaceUrl: true,
          queryParams: {
            id: topic.id,
          },
        });
      });
    }
  }

  private init() {
    this.contactFormState.getTopicsList$();

    if (this.message) {
      this.router.navigate([], {
        queryParams: {},
        replaceUrl: true,
      });

      setTimeout(() => {
        this.ionModal.present();
      }, 500);
    }
  }
}
