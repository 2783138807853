<div
  class="checkmark-wrapper animate__animated animate__jackInTheBox animate__fast"
>
  <div
    class="checkmark-container animate__animated animate__flipInX animate__fast"
  >
    <ion-icon
      class="animate__animated animate__flipInY animate__fast"
      name="checkmark-outline"
    ></ion-icon>
  </div>
</div>

@if (message) {
  <dr-text>
    <span class="title" [innerText]="message"></span>
  </dr-text>
}
