import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ContactFormApiService } from './services/contact-form-api.service';
import { ContactFormState } from './states/contact-form/contact-form.state';

@NgModule({
  imports: [NgxsModule.forFeature([ContactFormState])],
  providers: [ContactFormApiService],
})
export class ContactFormModule {}
