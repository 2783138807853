import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ButtonComponent,
  CheckboxComponent,
  ConfirmComponent,
  InputComponent,
  LoadingComponent,
  SelectComponent,
  TextComponent,
} from '@dr/ui';
import { IsWebVersion, priceMask } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CompaniesModule } from '../../companies.module';
import {
  CompanySubscription,
  CompanySubscriptionConfig,
} from '../../interfaces/company';
import { CompanySubscriptionsState } from '../../states/company-subscriptions.state';
import { CompanySubscriptionItemComponent } from '../company-subscription-item/company-subscription-item.component';

@Component({
  selector: 'dr-company-subscription-form',
  templateUrl: './company-subscription-form.component.html',
  styleUrls: ['./company-subscription-form.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TextComponent,
    TranslateModule,
    InputComponent,
    ButtonComponent,
    NgTemplateOutlet,
    ConfirmComponent,
    SelectComponent,
    CheckboxComponent,
    CompanySubscriptionItemComponent,
    CompaniesModule,
    AsyncPipe,
    LoadingComponent,
  ],
})
export class CompanySubscriptionFormComponent implements OnInit {
  @Input({ required: true }) subscription!: CompanySubscription;
  @Input() annuallyDiscount?: number;
  @Input() canDelete?: boolean = true;

  @Output() remove: EventEmitter<void> = new EventEmitter<void>();

  readonly IsWebVersion = IsWebVersion;
  readonly priceMask = priceMask;

  previewSubscriptionItem?: {
    primary: boolean;
    title: string;
    oldPrice?: number;
    currentPrice?: number;
    bulletPoints?: CompanySubscriptionConfig[];
  };
  subscriptionsNames!: { label: string; value: string }[];
  bulletPoints!: { label: string; value: string }[];

  formGroup = new FormGroup({
    id: new FormControl<string | null>(null),
    primary: new FormControl(false),
    title: new FormControl<string>('', [Validators.required]),
    oldPrice: new FormControl<number | null>(null),
    currentPrice: new FormControl<number | null>(null, [Validators.required]),
    bulletPoints: new FormControl<string[]>([]),
  });

  constructor(
    public companySubscriptionsState: CompanySubscriptionsState,
    private translateService: TranslateService
  ) {
    this.defineSubscriptionNames();
    this.defineBulletPoints();
  }

  ngOnInit() {
    this.formGroup.patchValue({
      id: this.subscription.id,
      primary: this.subscription.p,
      title: this.subscription.t,
      oldPrice: this.subscription.op,
      currentPrice: this.subscription.cp,
      bulletPoints: (this.subscription.cbp || []).map((bulletPoints) =>
        JSON.stringify(bulletPoints)
      ),
    });
  }

  previewSubscription(subscriptionControl: FormGroup) {
    this.previewSubscriptionItem = {
      primary: subscriptionControl.value.primary,
      title: subscriptionControl.value.title,
      oldPrice: subscriptionControl.value.oldPrice,
      currentPrice: subscriptionControl.value.currentPrice,
      bulletPoints: (subscriptionControl.value.bulletPoints || []).map(
        (bp: string) => JSON.parse(bp)
      ),
    };
  }

  private defineSubscriptionNames(): void {
    this.subscriptionsNames = [
      {
        label: this.translateService.instant(
          'subscriptions.subscription.name.basic'
        ),
        value: 'subscriptions.subscription.name.basic',
      },
      {
        label: this.translateService.instant(
          'subscriptions.subscription.name.basic_plus'
        ),
        value: 'subscriptions.subscription.name.basic_plus',
      },
      {
        label: this.translateService.instant(
          'subscriptions.subscription.name.professional'
        ),
        value: 'subscriptions.subscription.name.professional',
      },
      {
        label: this.translateService.instant(
          'subscriptions.subscription.name.professional_plus'
        ),
        value: 'subscriptions.subscription.name.professional_plus',
      },
      {
        label: this.translateService.instant(
          'subscriptions.subscription.name.advanced'
        ),
        value: 'subscriptions.subscription.name.advanced',
      },
      {
        label: this.translateService.instant(
          'subscriptions.subscription.name.advanced_plus'
        ),
        value: 'subscriptions.subscription.name.advanced_plus',
      },
    ];
  }

  private defineBulletPoints(): void {
    this.bulletPoints = [
      {
        label: this.translateService.instant('bullet_points.mobile_app'),
        value: {
          key: 'ma',
          label: 'bullet_points.mobile_app',
          value: true,
        },
      },
      {
        label: this.translateService.instant(
          'bullet_points.push_notifications'
        ),
        value: {
          key: 'pn',
          label: 'bullet_points.push_notifications',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.branding'),
        value: {
          key: 'b',
          label: 'bullet_points.branding',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.dashboard'),
        value: {
          key: 'd',
          label: 'bullet_points.dashboard',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.messaging'),
        value: {
          key: 'm',
          label: 'bullet_points.messaging',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.user_rating'),
        value: {
          key: 'us',
          label: 'bullet_points.user_rating',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.user_reviews'),
        value: {
          key: 'ur',
          label: 'bullet_points.user_reviews',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.user_notes'),
        value: {
          key: 'un',
          label: 'bullet_points.user_notes',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.user_goals'),
        value: {
          key: 'ug',
          label: 'bullet_points.user_goals',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.add_user_reviews'),
        value: {
          key: 'upr',
          label: 'bullet_points.add_user_reviews',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.user_add_tips'),
        value: {
          key: 'ut',
          label: 'bullet_points.user_add_tips',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.qr_actions'),
        value: {
          key: 'qr',
          label: 'bullet_points.qr_actions',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.company_news'),
        value: {
          key: 'cn',
          label: 'bullet_points.company_news',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.company_adds'),
        value: {
          key: 'ca',
          label: 'bullet_points.company_adds',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.company_store'),
        value: {
          key: 'cs',
          label: 'bullet_points.company_store',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.export_data'),
        value: {
          key: 'ed',
          label: 'bullet_points.export_data',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.see_user_reviews'),
        value: {
          key: 'nar',
          label: 'bullet_points.see_user_reviews',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.top_rated'),
        value: {
          key: 'tr',
          label: 'bullet_points.top_rated',
          value: true,
        },
      },
      {
        label: this.translateService.instant('bullet_points.support24'),
        value: {
          key: 's24',
          label: 'bullet_points.support24',
          value: true,
        },
      },
      ...[5, 25, 50, 75, 100, 150, 200, 300, 400, 500].map((value) => ({
        label: this.translateService.instant('bullet_points.max_colleagues', {
          value,
        }),
        value: {
          key: 'cc',
          label: 'bullet_points.max_colleagues',
          value,
        } as CompanySubscriptionConfig,
      })),
      ...[5, 10, 20, 30, 50, 100].map((value) => ({
        label: this.translateService.instant('bullet_points.max_groups', {
          value,
        }),
        value: {
          key: 'gc',
          label: 'bullet_points.max_groups',
          value,
        } as CompanySubscriptionConfig,
      })),
      ...[50, 100, 200, 500, 1000, 5000, 10000].map((value) => ({
        label: this.translateService.instant('bullet_points.max_clients', {
          value,
        }),
        value: {
          key: 'uc',
          label: 'bullet_points.max_clients',
          value,
        } as CompanySubscriptionConfig,
      })),
    ].map((bulletPoints) => ({
      label: bulletPoints.label,
      value: JSON.stringify(bulletPoints.value),
    }));
  }
}
