import { Inject, Injectable } from '@angular/core';
import { AuthState } from '@dr/auth';
import { LocalizationState } from '@dr/localization';
import { AppEnvironment, AppEnvironmentI } from '@dr/utils';
import { Socket } from 'ngx-socket-io';
import { combineLatest, filter, first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserSocketService extends Socket {
  constructor(
    private authState: AuthState,
    @Inject(AppEnvironment) private appEnvironment: AppEnvironmentI,
    private localizationState: LocalizationState
  ) {
    super({
      url: appEnvironment.socketUrl,
      options: {
        transports: ['websocket'],
        autoConnect: false,
        withCredentials: true,
      },
    });
  }

  override connect(): void {
    combineLatest([
      this.authState.tokenOnes$,
      this.localizationState.appLanguage$.pipe(filter(Boolean), first()),
    ]).subscribe(([token, lang]) => {
      if (token) {
        this.ioSocket.io.opts.query = {
          token,
          lang,
        };

        super.connect();
      } else {
        this.disconnect(true);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  onEvent(event: string, callback: Function) {
    this.off(event);
    this.on(event, callback);
  }
}
