import { registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import uk from '@angular/common/locales/uk';
import { TimeagoIntl } from 'ngx-timeago';
import { LocalizationState } from './states/localization/localization.state';

registerLocaleData(uk, 'uk');

@NgModule({
  imports: [NgxsModule.forFeature([LocalizationState])],
  providers: [TimeagoIntl],
})
export class LocalizationModule {}
