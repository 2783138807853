import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-empty',
  templateUrl: 'empty.component.html',
  styleUrls: ['empty.component.scss'],
  standalone: true,
  imports: [TextComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyComponent {
  @Input({ required: true }) message!: string;
}
