import { computed, DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { CompanyApiService } from '@dr/company';
import { UserApiService } from '@dr/user';
import {
  ActiveCompanyChangedAction,
  PaginationList,
  PaginationParams,
  ResetCurrentUserDataAction,
  ToastService,
} from '@dr/utils';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import {
  catchError,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import {
  CalculateConnections,
  Connection,
  ConnectionType,
} from '../interfaces/connection';
import { ConnectionsApiService } from '../services/connections-api.service';
import { ConnectionSocketService } from '../socket/connection-socket.service';
import {
  AddConnection,
  ApproveConnection,
  ConnectionsStateActionsName,
  ConnectionStateType,
  CountConnections,
  CountNewRequests,
  DeleteConnection,
  ExpandConnectionsList,
  GetConnectionStatus,
  GetEntityProfile,
  LoadConnectionsList,
  SetCurrentEntity,
  ConnectionApproved,
  RejectConnection,
  ConnectionRejected,
  RequestConnection,
  ConnectionRequested,
  AddConnectionByQr,
  AddConnectionRequest,
} from './connections.actions';

export interface EntityConnections {
  items?: Connection[];
  error?: string;
  loaded?: boolean;
  canLoadMore?: boolean;
}

export interface CachedConnection {
  profile?: Connection['u'] | Connection['c'];
  countConnections?: CalculateConnections;
  connectionWithCurrent?: Connection;
  users?: EntityConnections;
  companies?: EntityConnections;
}

export interface ConnectionsStateModel {
  currentEntity?: {
    id: string;
    type: ConnectionType;
  };
  newRequestsCounter?: number;
  requests?: EntityConnections;
  cache: {
    [entityId: string]: CachedConnection;
  };
}

const defaults: ConnectionsStateModel = {
  cache: {},
};

@State<ConnectionsStateModel>({
  name: ConnectionsStateActionsName,
  defaults,
})
@Injectable()
export class ConnectionsState {
  @Select(ConnectionsState) state$!: Observable<ConnectionsStateModel>;
  @Select(actionsExecuting([LoadConnectionsList, ExpandConnectionsList]))
  listLoading$!: Observable<boolean>;
  @Select(
    actionsExecuting([
      DeleteConnection,
      ApproveConnection,
      RejectConnection,
      RequestConnection,
      AddConnectionByQr,
    ])
  )
  actionsLoading$!: Observable<boolean>;

  connectionsApiService = inject(ConnectionsApiService);
  store = inject(Store);
  toastService = inject(ToastService);
  connectionSocketService = inject(ConnectionSocketService);
  destroyRef = inject(DestroyRef);
  userApiService = inject(UserApiService);
  companyApiService = inject(CompanyApiService);

  state = toSignal(this.state$);
  newRequestsCounter = computed(() => this.state()?.newRequestsCounter);

  private socketSubscription?: Subscription;

  getEntity(entityId: string) {
    return this.state()?.cache[entityId] || {};
  }

  getEntityConnections(entityId: string, type: ConnectionStateType) {
    if (type === 'request') {
      return this.state()?.requests || {};
    }

    return (
      this.getEntity(entityId)[type === 'user' ? 'users' : 'companies'] || {}
    );
  }

  setCurrentEntity$(entityId: string, type: ConnectionType) {
    return this.store.dispatch(new SetCurrentEntity(entityId, type));
  }

  countNewRequests$() {
    return this.store.dispatch(new CountNewRequests());
  }

  countConnections$(entityId: string, type: ConnectionType, reload?: boolean) {
    return this.store.dispatch(new CountConnections(entityId, type, reload));
  }

  getConnectionStatus$(
    entityId: string,
    type: ConnectionType,
    reload?: boolean
  ) {
    return this.store.dispatch(new GetConnectionStatus(entityId, type, reload));
  }

  getEntityProfile$(entityId: string, type: ConnectionType, reload?: boolean) {
    return this.store.dispatch(new GetEntityProfile(entityId, type, reload));
  }

  loadConnectionsList$(
    entityId: string,
    type: ConnectionStateType,
    pagination: PaginationParams,
    reload?: boolean
  ) {
    return this.store.dispatch(
      new LoadConnectionsList(entityId, type, pagination, reload)
    );
  }

  expandConnectionsList$(
    entityId: string,
    type: ConnectionStateType,
    pagination: PaginationParams
  ) {
    return this.store.dispatch(
      new ExpandConnectionsList(entityId, type, pagination)
    );
  }

  deleteConnection$(type: ConnectionType, connection: Connection) {
    return this.store.dispatch(new DeleteConnection(type, connection));
  }

  approveConnection$(entityId: string, type: ConnectionType) {
    return this.store.dispatch(new ApproveConnection(entityId, type));
  }

  rejectConnection$(entityId: string, type: ConnectionType) {
    return this.store.dispatch(new RejectConnection(entityId, type));
  }

  requestConnection$(entityId: string, type: ConnectionType) {
    return this.store.dispatch(new RequestConnection(entityId, type));
  }

  addConnectionByQr$(qrId: string, type: ConnectionType) {
    return this.store.dispatch(new AddConnectionByQr(qrId, type));
  }

  listenSocketEvents() {
    this.socketSubscription = this.connectionSocketService.onEvent$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        console.log('event', event)
        if (
          event.name === 'connection_request_user' ||
          event.name === 'connection_request_company'
        ) {
          this.store.dispatch(new AddConnectionRequest(event.connection));
        } else if (event.name === 'connection_add_company') {
          const companyId = event.connection.c?.id;

          if (!companyId) {
            return;
          }

          this.store.dispatch(new AddConnection('company', event.connection));

          this.getEntityProfile$(companyId, 'company', true);
          this.getConnectionStatus$(companyId, 'company', true);
        } else if (event.name === 'connection_add_user') {
          const userId = event.connection.u?.id;

          if (!userId) {
            return;
          }

          this.store.dispatch(new AddConnection('user', event.connection));

          this.getEntityProfile$(userId, 'user', true);
          this.getConnectionStatus$(userId, 'user', true);
        } else if (event.name === 'connection_delete_user') {
          const userId = event.connection.u?.id;

          if (!userId) {
            return;
          }

          this.store.dispatch(
            new DeleteConnection('user', event.connection, true)
          );

          this.getEntityProfile$(userId, 'user', true);
        } else if (event.name === 'connection_delete_company') {
          const companyId = event.connection.c?.id;

          if (!companyId) {
            return;
          }

          this.store.dispatch(
            new DeleteConnection('company', event.connection, true)
          );

          this.getEntityProfile$(companyId, 'company', true);
        }
      });
  }

  @Action(SetCurrentEntity)
  private setCurrentEntityId(
    ctx: StateContext<ConnectionsStateModel>,
    action: SetCurrentEntity
  ) {
    ctx.patchState({
      currentEntity: {
        id: action.entityId,
        type: action.type,
      },
    });
  }

  @Action(AddConnectionRequest)
  private addConnectionRequest(
    ctx: StateContext<ConnectionsStateModel>,
    action: AddConnectionRequest
  ) {
    const state = ctx.getState();
    const currentEntity = state.currentEntity;

    if (
      !currentEntity ||
      currentEntity.id === action.connection.u?.id ||
      currentEntity.id === action.connection.c?.id
    ) {
      return;
    }

    const userId = action.connection.u?.id;
    const companyId = action.connection.c?.id;
    const fromEntityId = userId || companyId;

    if (fromEntityId) {
      this.getConnectionStatus$(
        fromEntityId,
        userId ? 'user' : 'company',
        true
      );
    }

    ctx.patchState({
      newRequestsCounter: (state.newRequestsCounter || 0) + 1,
      requests: {
        ...state.requests,
        items: [action.connection, ...(state.requests?.items || [])],
      },
    });
  }

  @Action(CountNewRequests)
  private CountNewRequests(ctx: StateContext<ConnectionsStateModel>) {
    return this.connectionsApiService.countNewRequests$().pipe(
      tap((newRequestsCounter) =>
        ctx.patchState({
          newRequestsCounter,
        })
      ),
      this.catchError$()
    );
  }

  @Action(CountConnections)
  private countConnections(
    ctx: StateContext<ConnectionsStateModel>,
    action: CountConnections
  ) {
    const state = this.getEntity(action.entityId);

    if (!action.reload && state?.countConnections) {
      return of(undefined);
    }

    return (
      action.type === 'user'
        ? this.connectionsApiService.countUserConnections$(action.entityId)
        : this.connectionsApiService.countCompanyConnections$(action.entityId)
    ).pipe(
      tap((countConnections) => {
        this.updateCachedConnection(ctx, action.entityId, {
          countConnections,
        });
      })
    );
  }

  @Action(GetConnectionStatus)
  private getConnectionStatus(
    ctx: StateContext<ConnectionsStateModel>,
    action: GetConnectionStatus
  ) {
    const state = this.getEntity(action.entityId);

    if (!action.reload && state?.connectionWithCurrent) {
      return of(undefined);
    }

    return (
      action.type === 'user'
        ? this.connectionsApiService.getConnectionStatusWithUser$(
            action.entityId
          )
        : this.connectionsApiService.getConnectionStatusWithCompany$(
            action.entityId
          )
    ).pipe(
      tap((connectionWithCurrent) => {
        this.updateCachedConnection(ctx, action.entityId, {
          connectionWithCurrent: connectionWithCurrent || {},
        });
      })
    );
  }

  @Action(GetEntityProfile)
  private getEntityProfile(
    ctx: StateContext<ConnectionsStateModel>,
    action: GetEntityProfile
  ) {
    const state = this.getEntity(action.entityId);

    if (!action.reload && state?.profile) {
      return of(undefined);
    }

    return action.type === 'user'
      ? this.userApiService.userProfileById$(action.entityId).pipe(
          tap((profile) => {
            this.updateCachedConnection(ctx, action.entityId, {
              profile,
            });
          })
        )
      : this.companyApiService.getCompanyProfileId$(action.entityId).pipe(
          tap((profile) => {
            this.updateCachedConnection(ctx, action.entityId, {
              profile,
            });
          })
        );
  }

  @Action(LoadConnectionsList)
  private loadConnectionsList(
    ctx: StateContext<ConnectionsStateModel>,
    action: LoadConnectionsList
  ) {
    const state = this.getEntityConnections(action.entityId, action.type);

    if (!action.reload && state?.loaded) {
      return of(undefined);
    }

    this.updateEntityConnections(ctx, action.entityId, action.type, {
      loaded: true,
      error: undefined,
    });

    return this.getConnectionsListRequest$(
      ctx,
      action.type,
      action.entityId,
      action.pagination
    ).pipe(
      tap(({ count, items }) =>
        this.updateEntityConnections(ctx, action.entityId, action.type, {
          items,
          canLoadMore: count > items.length,
        })
      )
    );
  }

  @Action(ExpandConnectionsList)
  private expandConnectionsList(
    ctx: StateContext<ConnectionsStateModel>,
    action: ExpandConnectionsList
  ) {
    this.updateEntityConnections(ctx, action.entityId, action.type, {
      error: undefined,
    });

    const state = this.getEntityConnections(action.entityId, action.type);

    if (!state.canLoadMore) {
      return of(undefined);
    }

    return this.getConnectionsListRequest$(ctx, action.type, action.entityId, {
      ...action.pagination,
      after: state.items?.length ? state.items[state.items.length - 1].id : '',
    }).pipe(
      tap((response) => {
        this.updateEntityConnections(ctx, action.entityId, action.type, {
          items: [...(state.items || []), ...response.items],
          canLoadMore: response.items.length < response.count,
        });
      })
    );
  }

  @Action(DeleteConnection)
  private DeleteConnection(
    ctx: StateContext<ConnectionsStateModel>,
    action: DeleteConnection
  ) {
    const currentEntity = ctx.getState().currentEntity;

    if (!currentEntity) {
      return of(undefined);
    }

    let obs$: Observable<unknown>;
    const userId = action.connection?.u?.id;
    const companyId = action.connection?.c?.id;

    if (action.skipRequest) {
      obs$ = of(undefined);
    } else if (action.type === 'user' && userId) {
      obs$ = this.connectionsApiService
        .deleteUserConnection$(userId)
        .pipe(tap(() => this.removeRequest(ctx, userId)));
    } else if (action.type === 'company' && companyId) {
      obs$ = this.connectionsApiService
        .deleteCompanyConnection$(companyId)
        .pipe(tap(() => this.removeRequest(ctx, companyId)));
    } else {
      return of(undefined);
    }

    return obs$.pipe(
      switchMap(() =>
        currentEntity.id !== userId && currentEntity.id !== companyId
          ? this.getConnectionStatus$(
              (action.type === 'user' ? userId : companyId) as string,
              action.type,
              true
            )
          : of(undefined)
      ),
      tap(() => {
        const state = this.getEntityConnections(currentEntity.id, action.type);

        this.countConnections$(currentEntity.id, currentEntity.type, true);

        this.updateEntityConnections(ctx, currentEntity.id, action.type, {
          items: state.items?.filter(
            (connection) => connection.id !== action.connection.id
          ),
        });

        const entityId = userId || companyId;

        if (entityId) {
          this.removeRequest(ctx, entityId);
        }
      }),
      this.catchError$()
    );
  }

  @Action(AddConnection)
  private addConnection(
    ctx: StateContext<ConnectionsStateModel>,
    action: AddConnection
  ) {
    const currentEntity = ctx.getState().currentEntity;

    if (!currentEntity) {
      return of(undefined);
    }

    const state = this.getEntityConnections(
      currentEntity.id,
      currentEntity.type
    );

    this.countConnections$(currentEntity.id, currentEntity.type, true);

    this.updateEntityConnections(ctx, currentEntity.id, action.type, {
      items: [action.connection, ...(state.items || [])],
    });

    return of(undefined);
  }

  @Action(ApproveConnection)
  @Action(AddConnectionByQr)
  @Action(RejectConnection)
  @Action(RequestConnection)
  private ApproveUserConnection(
    ctx: StateContext<ConnectionsStateModel>,
    action:
      | ApproveConnection
      | RejectConnection
      | RequestConnection
      | AddConnectionByQr
  ) {
    let obs$: Observable<string>;
    const currentEntity = ctx.getState().currentEntity;

    if (action instanceof ApproveConnection) {
      obs$ = (
        action.type === 'user'
          ? this.connectionsApiService.approveUserRequest$(action.entityId)
          : this.connectionsApiService.approveCompanyRequest$(action.entityId)
      ).pipe(
        map(() => action.entityId),
        tap(() => {
          this.store.dispatch(
            new ConnectionApproved(action.entityId, action.type)
          );

          if (currentEntity) {
            this.countConnections$(currentEntity.id, currentEntity.type, true);
          }

          this.removeRequest(ctx, action.entityId);
        })
      );
    } else if (action instanceof AddConnectionByQr) {
      obs$ = (
        action.type === 'user'
          ? this.connectionsApiService
              .addUser$(action.qrId)
              .pipe(map((user) => user.id))
          : this.connectionsApiService
              .addCompany$(action.qrId)
              .pipe(map((company) => company.id))
      ).pipe(tap((entityId) => this.removeRequest(ctx, entityId)));
    } else if (action instanceof RejectConnection) {
      obs$ = (
        action.type === 'user'
          ? this.connectionsApiService.deleteUserConnection$(action.entityId)
          : this.connectionsApiService.deleteCompanyConnection$(action.entityId)
      ).pipe(
        map(() => action.entityId),
        tap(() => {
          this.store.dispatch(
            new ConnectionRejected(action.entityId, action.type)
          );

          this.removeRequest(ctx, action.entityId);
        })
      );
    } else {
      obs$ = (
        action.type === 'user'
          ? this.connectionsApiService.requestToUser$(action.entityId)
          : this.connectionsApiService.requestToCompany$(action.entityId)
      ).pipe(
        map(() => {
          this.store.dispatch(
            new ConnectionRequested(action.entityId, action.type)
          );

          this.toastService.showToast({
            color: 'success',
            message: 'connections.request_sent',
            duration: 2000,
            position: 'bottom',
          });

          return action.entityId;
        })
      );
    }

    return obs$.pipe(
      switchMap((entityId) =>
        this.getConnectionStatus$(entityId, action.type, true).pipe(
          tap((connection: Connection) => {
            const state = ctx.getState();
            const currentEntityId = state.currentEntity?.id;

            if (!currentEntityId || action instanceof RequestConnection) {
              return;
            }

            const key = action.type === 'user' ? 'users' : 'companies';

            ctx.patchState({
              cache: {
                ...state.cache,
                [currentEntityId]: {
                  ...state.cache[currentEntityId],
                  [key]: {
                    ...state.cache[currentEntityId]?.[key],
                    items:
                      action instanceof ApproveConnection ||
                      action instanceof AddConnectionByQr
                        ? [
                            connection,
                            ...(state.cache[currentEntityId]?.[key]?.items ||
                              []),
                          ]
                        : state.cache[currentEntityId]?.[key]?.items?.filter(
                            (connection) =>
                              connection.u?.id !== entityId &&
                              connection.c?.id !== entityId
                          ),
                  },
                },
              },
            });
          })
        )
      ),
      this.catchError$()
    );
  }

  @Action(ResetCurrentUserDataAction)
  @Action(ActiveCompanyChangedAction)
  private reset(ctx: StateContext<ConnectionsStateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      currentEntity: undefined,
      requests: undefined,
      newRequestsCounter: undefined,
      cache: {
        ...Object.keys(state.cache).reduce(
          (cache, key) => ({
            ...cache,
            [key]: {
              ...cache[key],
              connectionWithCurrent: undefined,
            },
          }),
          {} as ConnectionsStateModel['cache']
        ),
        ...(state.currentEntity
          ? {
              [state.currentEntity.id]: {},
            }
          : {}),
      },
    });

    this.socketSubscription?.unsubscribe();
  }

  private catchError$() {
    return catchError((err) => {
      this.toastService.showToast({
        color: 'danger',
        message: err,
        duration: 1000,
        position: 'top',
        skipTranslation: true,
      });

      throw err;
    });
  }

  private updateCachedConnection(
    ctx: StateContext<ConnectionsStateModel>,
    entityId: string,
    value: Partial<CachedConnection>
  ) {
    const ctxState = ctx.getState();

    ctx.patchState({
      cache: {
        ...ctxState.cache,
        [entityId]: {
          ...ctxState.cache[entityId],
          ...value,
        },
      },
    });
  }

  private updateEntityConnections(
    ctx: StateContext<ConnectionsStateModel>,
    entityId: string,
    type: ConnectionStateType,
    value: Partial<EntityConnections>
  ) {
    const ctxState = ctx.getState();

    if (type === 'request') {
      ctx.patchState({
        requests: {
          ...ctxState.requests,
          ...value,
        },
      });
    } else {
      const listKey = type === 'user' ? 'users' : 'companies';

      ctx.patchState({
        cache: {
          ...ctxState.cache,
          [entityId]: {
            ...ctxState.cache[entityId],
            [listKey]: {
              ...ctxState.cache[entityId]?.[listKey],
              ...value,
            },
          },
        },
      });
    }
  }

  private getConnectionsListRequest$(
    ctx: StateContext<ConnectionsStateModel>,
    type: ConnectionStateType,
    entityId: string,
    pagination: PaginationParams
  ) {
    let obs$: Observable<PaginationList<Connection>>;

    if (type === 'request') {
      obs$ = this.connectionsApiService.newRequestsList$(pagination);
    } else if (type === 'user') {
      obs$ = this.connectionsApiService.usersList$(entityId, pagination);
    } else {
      obs$ = this.connectionsApiService.companiesList$(entityId, pagination);
    }

    return obs$.pipe(
      catchError((error) => {
        this.updateEntityConnections(ctx, entityId, type, {
          error,
        });

        throw error;
      })
    );
  }

  private removeRequest(
    ctx: StateContext<ConnectionsStateModel>,
    entityId: string
  ) {
    const state = ctx.getState();
    const newRequestsCounter = state.newRequestsCounter || 0;
    const hasRequest = state.requests?.items?.some(
      (request) => request.u?.id === entityId || request?.c?.id === entityId
    );

    if (hasRequest) {
      ctx.patchState({
        newRequestsCounter: Math.max(newRequestsCounter - 1, 0),
        requests: {
          ...state.requests,
          items: state.requests?.items?.filter(
            (request) =>
              request.u?.id !== entityId && request.c?.id !== entityId
          ),
        },
      });
    }
  }
}
