@switch (action) {
  @case ('download') {
    <a
      [href]="url"
      [class.disabled]="!url"
      download
      target="_blank"
      class="wrapper"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
  }
  @case ('uploader') {
    <div class="wrapper">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
  }
}

<ng-template #content>
  <ion-icon name="document-outline" color="tertiary"></ion-icon>

  <dr-text color="tertiary" class="nowrap">
    <small>{{ name }}</small>
  </dr-text>

  @if (size) {
    <dr-text color="dark" class="size">
      <small class="nowrap">{{ fileSize }}</small>
    </dr-text>
  }

  @if (removable) {
    <div (click)="remove.emit()" class="remove">
      <ion-icon name="trash-outline" color="danger"></ion-icon>
    </div>
  }
</ng-template>
