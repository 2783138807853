<div class="header-title">
  <span class="border"></span>
  <dr-text [centered]="true">{{ 'sign in with' | translate }}</dr-text>
  <span class="border"></span>
</div>

<div class="buttons">
  <div
    *drHasFeature="{ name: 'a_p_g' }"
    (click)="authWithGoogle()"
    class="social-button"
  >
    <ion-icon name="google"></ion-icon>
  </div>
  @if (canAuthWithApple) {
    <div
      *drHasFeature="{ name: 'a_p_a' }"
      (click)="authWithApple()"
      class="social-button"
    >
      <ion-icon name="logo-apple"></ion-icon>
    </div>
  }
</div>
