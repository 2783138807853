<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="navController.back()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ion-label>DayRouter {{ 'Support' | translate }}</ion-label>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content #ionContent drKeyboard>
  <dr-contact-form-messages
    #contactFormMessagesComponent
    [id]="currentTopicId"
    (messagesLoaded)="ionContent.scrollToBottom(0)"
  ></dr-contact-form-messages>
</ion-content>

@if (currentTopic$ | async; as currentTopic) {
  <ion-footer>
    @if (currentTopicMessagesState$ | async; as currentTopicMessagesState) {
      <ion-toolbar>
        @if (!currentTopicMessagesState.error) {
          @if (currentTopic.s !== 'resolved') {
            <dr-contact-form-send-message
              [currentTopicId]="currentTopicId"
              [canSend]="!!currentTopicMessagesState?.items?.length"
              (sent)="ionContent.scrollToBottom(200)"
            ></dr-contact-form-send-message>
          } @else {
            <dr-text color="medium">
              <small>{{ 'contact_form.resolved_question' | translate }}</small>
            </dr-text>
          }
        } @else {
          <dr-error-footer
            [message]="currentTopicMessagesState.error"
            (tryAgain)="contactFormMessagesComponent.loadMessages()"
          ></dr-error-footer>
        }
      </ion-toolbar>
    }
  </ion-footer>
}
