import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { CompanyApiService } from './services/company-api.service';
import { CompanyUpdatesState } from './states/company-updates.state';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([CompanyUpdatesState])],
  providers: [CompanyApiService],
})
export class CompanyUpdatesModule {}
