import { AsyncPipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { FeaturesModule } from '@dr/features';
import {
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
  LinkComponent,
  TextComponent,
} from '@dr/ui';
import { ValidationService } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AuthState } from '../../states/auth/auth.state';
import { AuthProviderComponent } from '../auth-provider/auth-provider.component';

@Component({
  selector: 'dr-sign-up',
  templateUrl: 'sign-up.component.html',
  styleUrls: ['../../auth.scss', './sign-up.component.scss'],
  imports: [
    TextComponent,
    InputComponent,
    CheckboxComponent,
    LinkComponent,
    ButtonComponent,
    IonicModule,
    TranslateModule,
    AuthProviderComponent,
    AsyncPipe,
    FeaturesModule,
  ],
  standalone: true,
})
export class SignUpComponent implements OnDestroy {
  @ViewChild('firstName') firstName?: InputComponent<string>;
  @ViewChild('lastName') lastName?: InputComponent<string>;
  @ViewChild('email') email?: InputComponent<string>;
  @ViewChild('password') password?: InputComponent<string>;

  @Output() submitted = new EventEmitter<void>();
  @Output() signIn = new EventEmitter<void>();
  @Output() openPrivacyPolicy = new EventEmitter<void>();
  @Output() openTermsConditions = new EventEmitter<void>();

  firstNameControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.firstNameValidators
  );
  lastNameControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.lastNameValidators
  );
  emailControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.emailValidators
  );
  passwordControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.passwordValidators
  );
  privacyTerms = new FormControl<boolean | null>(null, [
    Validators.requiredTrue,
  ]);

  private destroyRef = inject(DestroyRef);

  constructor(
    public validationService: ValidationService,
    public authState: AuthState
  ) {}

  ngOnDestroy() {
    this.authState.resetSignUpData();
  }

  signUp(): void {
    this.validateFormElements();

    if (
      this.firstNameControl.invalid ||
      this.lastNameControl.invalid ||
      this.emailControl.invalid ||
      this.passwordControl.invalid ||
      this.privacyTerms.invalid
    ) {
      return;
    }

    this.authState
      .signUpWithCredentials$({
        firstName: this.firstNameControl.value as string,
        lastName: this.lastNameControl.value as string,
        email: this.emailControl.value as string,
        password: this.passwordControl.value as string,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.submitted.emit());
  }

  private validateFormElements(): void {
    this.firstName?.validate();
    this.lastName?.validate();
    this.email?.validate();
    this.password?.validate();
    this.privacyTerms.markAsDirty();
  }
}
