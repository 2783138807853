import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmptyComponent, LoadingComponent, SkeletonComponent } from '@dr/ui';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Notification } from '../../interfaces/notification';
import { NotificationsState } from '../../state/notifications.state';
import { NotificationItemComponent } from '../notification-item/notification-item.component';

@Component({
  selector: 'dr-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    EmptyComponent,
    TranslateModule,
    NotificationItemComponent,
    AsyncPipe,
    LoadingComponent,
    SkeletonComponent,
  ],
})
export class NotificationsListComponent {
  @Input() notifications?: Notification[] | null;
  @Input() loading?: boolean | null;

  @Output() read: EventEmitter<Notification> = new EventEmitter<Notification>();

  constructor(public notificationsState: NotificationsState) {}
}
