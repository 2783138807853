@for (subscription of subscriptionsPack.sbs; track subscription.id) {
  <div class="pack-container" [class.compressed]="!subscription.p">
    <dr-company-subscription-item
      #subscriptionItemComponent
      [annuallyDiscount]="subscriptionsPack.adp"
      [primary]="subscription.p"
      [title]="subscription.t"
      [oldPrice]="subscription.op"
      [currentPrice]="subscription.cp"
      [bulletPoints]="subscription.cbp"
      [class.animate__animated]="animated"
      [class.animate__zoomIn]="IsWebVersion"
      [class.animate__zoomInLeft]="!IsWebVersion"
      [class.animate__delay-1s]="$index === 1 && !IsWebVersion"
      [class.animate__delay-2s]="$index === 2 && !IsWebVersion"
      [class.animate__delay-3s]="$index === 3 && !IsWebVersion"
      [class.animate__delay-4s]="$index === 4 && !IsWebVersion"
      [class.animate__delay-5s]="$index === 5 && !IsWebVersion"
      (animationend)="
        subscriptionItemComponent.elementRef.nativeElement.classList.remove(
          'animate__animated'
        )
      "
      (getStarted)="
        subscribe(subscription, subscriptionItemComponent.periodType)
      "
    ></dr-company-subscription-item>
  </div>
}
