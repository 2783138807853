import { Injectable } from '@angular/core';
import { ToastService } from '@dr/utils';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { catchError, Observable, tap } from 'rxjs';
import { CompanyRequestChanges } from '../interfaces/company';
import { CompanyApiService } from '../services/company-api.service';
import {
  ApproveCompanyRequestChangesAction,
  CompanyUpdatesActionsName,
  CountCompanyRequestChangesAction,
  LoadCompanyRequestChangesAction,
  RejectCompanyRequestChangesAction,
  ReloadCompanyRequestChangesAction,
} from './company-updates.actions';

export interface CompanyUpdatesStateModel {
  countRequestChanges?: number;
  requestChanges?: CompanyRequestChanges[];
  requestChangesError?: string;
  requestChangesLoaded?: boolean;
}

const defaults: CompanyUpdatesStateModel = {};

export const CompanyUpdatesStoreKeys = [];

@State<CompanyUpdatesStateModel>({
  name: CompanyUpdatesActionsName,
  defaults,
})
@Injectable()
export class CompanyUpdatesState {
  @Select(CompanyUpdatesState.countRequestChanges)
  countRequestChanges$!: Observable<number>;
  @Select(CompanyUpdatesState.requestChanges)
  requestChanges$!: Observable<CompanyRequestChanges[] | undefined>;
  @Select(CompanyUpdatesState.requestChangesError)
  requestChangesError$!: Observable<string | undefined>;
  @Select(CompanyUpdatesState.requestChangesLoaded)
  requestChangesLoaded$!: Observable<string | undefined>;

  @Select(
    actionsExecuting([
      LoadCompanyRequestChangesAction,
      ReloadCompanyRequestChangesAction,
      CountCompanyRequestChangesAction,
    ])
  )
  requestChangesLoading$!: Observable<boolean>;

  @Select(
    actionsExecuting([
      ApproveCompanyRequestChangesAction,
      RejectCompanyRequestChangesAction,
    ])
  )
  requestChangesProcessing$!: Observable<boolean>;

  constructor(
    private companyApiService: CompanyApiService,
    private toastService: ToastService,
    private store: Store
  ) {}

  @Selector()
  static countRequestChanges(state: CompanyUpdatesStateModel) {
    return state.countRequestChanges || 0;
  }

  @Selector()
  static requestChanges(state: CompanyUpdatesStateModel) {
    return state.requestChanges;
  }

  @Selector()
  static requestChangesError(state: CompanyUpdatesStateModel) {
    return state.requestChangesError;
  }

  @Selector()
  static requestChangesLoaded(state: CompanyUpdatesStateModel) {
    return state.requestChangesLoaded;
  }

  loadCompanyRequestChanges$() {
    return this.store.dispatch(new LoadCompanyRequestChangesAction());
  }

  reloadCompanyRequestChanges$() {
    return this.store.dispatch(new ReloadCompanyRequestChangesAction());
  }

  countCompanyRequestChanges$() {
    return this.store.dispatch(new CountCompanyRequestChangesAction());
  }

  approveCompanyRequestChanges$(id: string) {
    return this.store.dispatch(new ApproveCompanyRequestChangesAction(id));
  }

  rejectCompanyRequestChanges$(id: string, message: string) {
    return this.store.dispatch(
      new RejectCompanyRequestChangesAction(id, message)
    );
  }

  @Action(ReloadCompanyRequestChangesAction)
  private _reloadCompanyRequestChanges$(
    ctx: StateContext<CompanyUpdatesStateModel>
  ) {
    ctx.patchState({
      requestChangesLoaded: false,
    });

    return this.loadCompanyRequestChanges$();
  }

  @Action(LoadCompanyRequestChangesAction)
  private _loadCompanyRequestChanges$(
    ctx: StateContext<CompanyUpdatesStateModel>
  ) {
    if (ctx.getState().requestChangesLoaded) {
      return;
    }

    ctx.patchState({
      requestChangesError: undefined,
      requestChangesLoaded: true,
    });

    return this.companyApiService.getRequestChanges$().pipe(
      tap(({ items }) => {
        ctx.patchState({
          requestChanges: items,
        });
      }),
      catchError((requestChangesError) => {
        ctx.patchState({
          requestChangesLoaded: ctx.getState().requestChangesLoaded,
          requestChangesError,
        });

        throw requestChangesError;
      })
    );
  }

  @Action(CountCompanyRequestChangesAction)
  private _countCompanyRequestChanges$(
    ctx: StateContext<CompanyUpdatesStateModel>
  ) {
    return this.companyApiService.countRequestChanges$().pipe(
      tap((countRequestChanges) => {
        ctx.patchState({
          countRequestChanges,
        });
      })
    );
  }

  @Action(ApproveCompanyRequestChangesAction)
  @Action(RejectCompanyRequestChangesAction)
  private _handleRequestChanges(
    ctx: StateContext<CompanyUpdatesStateModel>,
    action:
      | ApproveCompanyRequestChangesAction
      | RejectCompanyRequestChangesAction
  ) {
    const countRequestChanges = ctx.getState().countRequestChanges;
    const requestChanges = ctx.getState().requestChanges;

    return (
      action instanceof ApproveCompanyRequestChangesAction
        ? this.companyApiService.approveRequestChanges$(action.id)
        : this.companyApiService.rejectRequestChanges$(
            action.id,
            action.message
          )
    ).pipe(
      tap(() => {
        ctx.patchState({
          countRequestChanges: countRequestChanges
            ? Math.max(countRequestChanges - 1, 0)
            : countRequestChanges,
          requestChanges: requestChanges?.filter(
            (company) => company.id !== action.id
          ),
        });
      }),
      catchError((error) => {
        this.toastService.showToast({
          color: 'danger',
          message: error,
          skipTranslation: true,
          duration: 2000,
          position: 'top',
        });

        throw error;
      })
    );
  }
}
