import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TextComponent } from '../../text/text.component';
import { LinkComponent } from '../link.component';

@Component({
  selector: 'app-contact-form-link',
  templateUrl: 'contact-form-link.component.html',
  styleUrls: ['contact-form-link.component.scss'],
  standalone: true,
  imports: [IonicModule, LinkComponent, TextComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormLinkComponent {
  constructor(public router: Router) {}
}
