<div class="img-container">
  <div
    class="checkmark-container animate__animated animate__flipInX animate__fast"
  >
    <ion-icon
      class="animate__animated animate__flipInY animate__fast"
      name="checkmark-outline"
    ></ion-icon>
  </div>

  <ion-img
    src="/assets/images/company/survey-finish-fire.svg"
    class="animate__animated animate__zoomIn animate__delay-1s"
  ></ion-img>
</div>

<dr-text class="message" [centered]="true">
  <div
    [innerHTML]="
      'company.survey.finish_message'
        | translate: { email: (currentUserState.currentUser$ | async)?.email }
    "
  ></div>
</dr-text>

<dr-text class="support_message" color="medium" [centered]="true">
  <div>{{ 'company.survey.finish_support' | translate }}</div>
</dr-text>

<dr-button
  size="small"
  fill="clear"
  [label]="'contact_form.title' | translate"
  (click)="support.emit()"
></dr-button>

<div class="footer">
  <dr-button
    class="animate__animated animate__fadeInUp animate__delay-1s"
    color="primary"
    [label]="'company.survey.finish_close_btn' | translate"
    (click)="closed.emit()"
  ></dr-button>

  <dr-button
    class="animate__animated animate__fadeIn animate__delay-3s"
    size="small"
    fill="clear"
    color="danger"
    [label]="'company.survey.finish_delete_btn' | translate"
    (click)="confirmDialog.open()"
  ></dr-button>
</div>

<dr-confirm
  #confirmDialog
  confirmButtonColor="danger"
  [title]="'company.survey.finish_delete_message' | translate"
  (confirm)="deleted.emit(request)"
></dr-confirm>
