<div class="header">
  <dr-text>
    <span class="title">{{ 'Forgot password' | translate }}</span>
  </dr-text>
  <dr-text>
    <span class="subtitle">{{ 'auth.enter_account_email' | translate }}</span>
  </dr-text>
</div>

<dr-input
  #email
  type="email"
  inputMode="email"
  [fControl]="emailControl"
  [label]="'Email' | translate"
  [errors]="validationService.appValidationRules.emailErrorMessages()"
  [required]="true"
  (focusOut)="authState.updateVerificationEmail($any($event))"
></dr-input>

<dr-button
  expand="block"
  iconName="arrow-forward-outline"
  [label]="'Send' | translate"
  (click)="send()"
></dr-button>

@if (authState.forgotPasswordError$ | async; as error) {
  <dr-text [small]="true" [centered]="true" color="danger" class="error">
    <span>{{ error }}</span>
  </dr-text>
}

<dr-text class="switch-link" [centered]="true">
  {{ 'Back to' | translate }}
  <dr-link (click)="signIn.emit()">{{ 'Sign in' | translate }}</dr-link>
</dr-text>
