import { AuthData, SignUpData } from '../../services/auth-api.service';

export const AuthActionsName = 'Auth';

export namespace AuthActions {
  export class Logout {
    static readonly type = `${AuthActionsName} Logout`;
  }

  export class UpdateVerificationEmail {
    static readonly type = `${AuthActionsName} Update verification email`;
    constructor(public email: string) {}
  }

  export class SignUpWithCredentials {
    static readonly type = `${AuthActionsName} Sign Up With Credentials`;
    constructor(public data: SignUpData) {}
  }

  export class ResetSignUpData {
    static readonly type = `${AuthActionsName} Reset Sign Up Data`;
  }

  export class VerifySignUpWithCode {
    static readonly type = `${AuthActionsName} Verify Sign Up With Code`;
    constructor(public code: string) {}
  }

  export class ResendCodeVerification {
    static readonly type = `${AuthActionsName} Resend Code Verification`;
  }

  export class ResetCodeVerificationData {
    static readonly type = `${AuthActionsName} Reset Code Verification Data`;
  }

  export class SignInWithCredentials {
    static readonly type = `${AuthActionsName} Sign In With Credentials`;
    constructor(public data: AuthData) {}
  }

  export class ResetSignInData {
    static readonly type = `${AuthActionsName} Reset SignIn Data`;
  }

  export class SignInWithGoogle {
    static readonly type = `${AuthActionsName} Sign In With Google`;
  }

  export class SignInWithApple {
    static readonly type = `${AuthActionsName} Sign In With Apple`;
  }

  export class ForgotPassword {
    static readonly type = `${AuthActionsName} Forgot Password`;
  }

  export class ResetForgotPasswordData {
    static readonly type = `${AuthActionsName} Reset Forgot Password Data`;
  }

  export class VerifyForgotPasswordWithCode {
    static readonly type = `${AuthActionsName} Verify Forgot Password Code`;

    constructor(public code: string) {}
  }

  export class ResetPassword {
    static readonly type = `${AuthActionsName} Reset Password`;

    constructor(public password: string) {}
  }

  export class ClearResetPasswordData {
    static readonly type = `${AuthActionsName} Clear Reset Password Data`;
  }
}
