@if (text) {
  <ion-popover
    [arrow]="false"
    [showBackdrop]="false"
    alignment="center"
    side="top"
    class="readonly"
  >
    <ng-template>
      <ion-content>
        <dr-text class="tooltip-text">
          <small>{{ text | translate }}</small>
        </dr-text>
      </ion-content>
    </ng-template>
  </ion-popover>
}
