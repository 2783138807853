import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { IonicModule, IonPopover } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-tooltip',
  templateUrl: 'tooltip.component.html',
  styleUrls: ['tooltip.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, TextComponent, TranslateModule],
})
export class TooltipComponent {
  @ViewChild(IonPopover) private ionPopover?: IonPopover;

  @Input() text?: string;

  present(event: MouseEvent) {
    this.ionPopover?.present(event);
  }

  dismiss() {
    this.ionPopover?.dismiss();
  }
}
