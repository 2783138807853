import {
  computed,
  Directive,
  inject,
  OnInit,
  Signal,
  signal,
  viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { SmartComponent } from '@dr/utils';
import {
  IonContent,
  IonInfiniteScroll,
  IonRefresher,
  NavController,
} from '@ionic/angular';
import { finalize, first, map } from 'rxjs';
import { Connection, ConnectionType } from '../../interfaces/connection';
import { ConnectionStateType } from '../../states/connections.actions';
import {
  ConnectionsState,
  EntityConnections,
} from '../../states/connections.state';

@Directive()
export abstract class ConnectionsList extends SmartComponent implements OnInit {
  abstract connectionType: Signal<ConnectionStateType>;
  abstract isCurrentEntity: Signal<boolean>;
  abstract hasSideMenu: Signal<boolean>;

  readonly navController = inject(NavController);
  readonly connectionsState = inject(ConnectionsState);
  readonly activatedRoute = inject(ActivatedRoute);

  readonly ionRefresher = viewChild(IonRefresher);
  readonly ionContent = viewChild(IonContent);
  readonly ionInfiniteScroll = viewChild(IonInfiniteScroll);

  readonly targetConnection = signal<Connection | undefined>(undefined);
  readonly searchText = signal<string>('');

  readonly connectionActionLoading = toSignal(
    this.connectionsState.actionsLoading$
  );
  readonly connectionListLoading = toSignal(this.connectionsState.listLoading$);
  readonly activatedRouteId = toSignal(
    this.activatedRoute.params.pipe(map((params) => params['id']))
  );

  readonly state = computed<EntityConnections>(() =>
    this.connectionsState.getEntityConnections(
      this.activatedRouteId(),
      this.connectionType()
    )
  );

  override async ngOnInit() {
    await super.ngOnInit();

    this.loadConnections$().subscribe();
  }

  override onResumeApp() {
    super.onResumeApp();

    this.loadConnections$(true).subscribe();
  }

  addUserConnection() {
    this.navController.navigateForward(`/connections/add/user`);
  }

  addCompanyConnection() {
    this.navController.navigateForward(`/connections/add/company`);
  }

  openUser(userId: string) {
    if (!userId) {
      return;
    }

    this.navController.navigateForward(`/user/${userId}`);
  }

  openCompany(companyId: string) {
    if (!companyId) {
      return;
    }

    this.navController.navigateForward(`/company/${companyId}`);
  }

  createEvent() {
    alert('Not available yet');
  }

  search(searchText: string) {
    this.searchText.set(searchText);

    this.loadConnections$(true).subscribe(() => {
      this.ionContent()?.scrollToTop();
    });
  }

  refresh() {
    this.loadConnections$(true)
      .pipe(finalize(() => this.ionRefresher()?.complete()))
      .subscribe();
  }

  expand() {
    this.connectionsState
      .expandConnectionsList$(this.activatedRouteId(), this.connectionType(), {
        search: this.searchText(),
      })
      .pipe(finalize(() => this.ionInfiniteScroll()?.complete()))
      .subscribe();
  }

  tryAgain() {
    this.loadConnections$(true).subscribe();
  }

  delete() {
    const connection = this.targetConnection();

    if (!connection) {
      return;
    }

    this.connectionsState.deleteConnection$(
      this.connectionType() as ConnectionType,
      connection
    );
  }

  private loadConnections$(reload?: boolean) {
    return this.connectionsState
      .loadConnectionsList$(
        this.activatedRouteId(),
        this.connectionType(),
        {
          search: this.searchText(),
        },
        reload
      )
      .pipe(first());
  }
}
