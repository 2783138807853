<dr-loading
  [show]="!!(companyUpdatesState.requestChangesProcessing$ | async)"
  [message]="'company.list.loading' | translate"
></dr-loading>

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>{{ 'company.request_changes.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content
  [class.no-scroll]="!(companyUpdatesState.requestChanges$ | async)?.length"
>
  @if ((companyUpdatesState.requestChanges$ | async)?.length === 0) {
    <dr-empty [message]="'list.empty' | translate"></dr-empty>
  }

  @if (!(companyUpdatesState.requestChanges$ | async)) {
    <dr-skeleton [maxHeight]="60"></dr-skeleton>
  }

  @if (companyUpdatesState.requestChanges$ | async; as requestChanges) {
    <ion-accordion-group>
      @for (requestChange of requestChanges; track requestChange.id) {
        <ion-accordion>
          <ion-item slot="header" color="light" class="header">
            <dr-entity-name
              (click)="
                $event.stopPropagation(); openCompanyPreview(requestChange.ce)
              "
              [imgUrl]="$any(requestChange.lXS || requestChange?.ce?.lXS)"
              [firstName]="requestChange.nm"
              [subtitle]="requestChange.nch"
              [color]="requestChange.lc"
            ></dr-entity-name>
          </ion-item>

          <div class="content" slot="content">
            <dr-company-request-changes
              [requestChange]="requestChange"
              [company]="requestChange.ce"
            ></dr-company-request-changes>
          </div>
        </ion-accordion>
      }
    </ion-accordion-group>
  }
</ion-content>

@if (companyUpdatesState.requestChangesError$ | async; as requestChangesError) {
  <ion-footer>
    <dr-error-footer
      [message]="requestChangesError"
      (tryAgain)="companyUpdatesState.reloadCompanyRequestChanges$()"
    ></dr-error-footer>
  </ion-footer>
}
