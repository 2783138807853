import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {
  ErrorInterceptorService,
  AuthInterceptorService,
  AuthModule,
} from '@dr/auth';
import { ConnectionsModule } from '@dr/connections';
import { LocalizationModule } from '@dr/localization';
import { LostConnectionComponent } from '@dr/network';
import { ThemeModule } from '@dr/theme';
import {
  CurrentUserConnectorComponent,
  CurrentUserModule,
  UsersModule,
} from '@dr/user';
import {
  ApiInterceptorService,
  AppEnvironment,
  AppEnvironmentI,
  HttpLoaderFactory,
  MissingTranslationService,
} from '@dr/utils';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsDevelopmentModule, NgxsModule } from '@ngxs/store';
import { SocketIoModule } from 'ngx-socket-io';
import {
  TimeagoCustomFormatter,
  TimeagoFormatter,
  TimeagoModule,
} from 'ngx-timeago';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { appStoreKeys } from './app-state';
import { AppComponent } from './app.component';
import { SideMenuModule } from './side-menu/side-menu.module';

const ngxsImports = [
  environment.production ? [] : NgxsReduxDevtoolsPluginModule.forRoot(),
  environment.production
    ? []
    : NgxsDevelopmentModule.forRoot({
        warnOnUnhandledActions: true,
      }),
  NgxsStoragePluginModule.forRoot({
    key: appStoreKeys,
  }),
  NgxsModule.forRoot([], {
    developmentMode: !environment.production,
    selectorOptions: {
      suppressErrors: false,
      injectContainerState: false,
    },
    compatibility: {
      strictContentSecurityPolicy: true,
    },
  }),
  NgxsSelectSnapshotModule.forRoot(),
  NgxsActionsExecutingModule.forRoot(),
];

const firebaseImports = [
  AngularFireModule.initializeApp(environment.firebase),
  AngularFireAuthModule,
];

const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptorService,
    multi: true,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios',
      scrollAssist: false,
      spinner: 'crescent',
      backButtonText: '',
    }),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: (appEnvironment: AppEnvironmentI) =>
          new MissingTranslationService(appEnvironment),
        deps: [AppEnvironment],
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
    TimeagoModule.forRoot({
      formatter: {
        provide: TimeagoFormatter,
        useClass: TimeagoCustomFormatter,
      },
    }),
    SocketIoModule,
    ...ngxsImports,
    ...firebaseImports,
    SideMenuModule,
    AppRoutingModule,
    ThemeModule,
    LocalizationModule,
    AuthModule,
    UsersModule,
    ConnectionsModule,
    CurrentUserModule,
    SideMenuModule,
    LostConnectionComponent,
    CurrentUserConnectorComponent,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: AppEnvironment, useValue: environment },
    ...interceptors,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
