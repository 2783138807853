export type AppLanguage = 'en' | 'uk';

export const appLanguages: AppLanguage[] = ['uk', 'en'];

export const appLanguagesOptions: {
  translateKey: string;
  value: AppLanguage;
}[] = [
  {
    translateKey: 'language.English',
    value: 'en',
  },
  {
    translateKey: 'language.Ukrainian',
    value: 'uk',
  },
];
