import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { IsWebVersion } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MyCompaniesState } from '../../states/my-companies.state';
import { CompanyActiveSubscriptionViewComponent } from '../company-active-subscription-view/company-active-subscription-view.component';

@Component({
  selector: 'dr-company-current-subscription',
  templateUrl: './company-current-subscription.component.html',
  styleUrls: ['./company-current-subscription.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    AsyncPipe,
    CompanyActiveSubscriptionViewComponent,
    TranslateModule,
  ],
})
export class CompanyCurrentSubscriptionComponent {
  readonly IsWebVersion = IsWebVersion;
  constructor(public myCompaniesState: MyCompaniesState) {}
}
