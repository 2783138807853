<ion-content>
  <div class="content">
    @if (topics?.length) {
      @for (topic of topics; track $index) {
        <dr-contact-form-topic-item
          [topic]="topic"
          (click)="selectTopic.emit(topic)"
          [class.selected]="currentTopicId === topic.id"
        >
        </dr-contact-form-topic-item>
      }
    }

    @if (contactFormState.canLoadMore$ | async) {
      <ion-infinite-scroll (ionInfinite)="loadMore()">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    }
  </div>
</ion-content>
