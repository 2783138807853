@if (subscriptionsPacks === undefined) {
  <dr-skeleton [maxHeight]="80"></dr-skeleton>
} @else {
  @if (!subscriptionsPacks.length) {
    <dr-empty [message]="'subscriptions.packs.empty' | translate"></dr-empty>
  } @else {
    <ion-accordion-group>
      @for (
        subscriptionsPack of subscriptionsPacks;
        track subscriptionsPack.id
      ) {
        <ion-accordion>
          <ion-item slot="header" color="light" class="header">
            <dr-text>
              <div class="text-container">
                <div class="pack-name">
                  {{ subscriptionsPack.l }}
                </div>
                <div>
                  <small
                    >{{ 'subscriptions.packs.annually_discount' | translate }}:
                    <b>{{ subscriptionsPack.adp || 0 }}%</b></small
                  >
                </div>
              </div>
            </dr-text>
          </ion-item>
          <div class="pack-container" slot="content">
            <div class="pack-actions">
              <dr-button
                iconName="pencil-outline"
                color="medium"
                [label]="'shared.edit' | translate"
                (click)="editPack.emit(subscriptionsPack)"
              ></dr-button>
              <dr-button
                iconName="trash-outline"
                color="danger"
                [label]="'shared.delete' | translate"
                (click)="deletePack.emit(subscriptionsPack)"
              ></dr-button>
            </div>

            <dr-company-subscriptions-pack
              [subscriptionsPack]="subscriptionsPack"
              [animated]="false"
            ></dr-company-subscriptions-pack>
          </div>
        </ion-accordion>
      }
    </ion-accordion-group>
  }
}
