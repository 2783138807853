import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Company, MyCompaniesModule, MyCompaniesState } from '@dr/company';
import {
  ConfirmComponent,
  EmptyComponent,
  EntityNameComponent,
  LoadingComponent,
} from '@dr/ui';
import { User } from '@dr/user';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectionSocket } from '../../../socket/connection-socket.service';
import { AddConnectionActionComponent } from '../add-connection-action/add-connection-action.component';
import { AddCompanyConnection } from './add-company-connection';

@Component({
  selector: 'dr-connections-add-company-to-company-connection',
  templateUrl: './add-company-connection.component.html',
  styleUrls: ['./add-company-connection.component.scss'],
  standalone: true,
  imports: [
    MyCompaniesModule,
    IonicModule,
    TranslateModule,
    LoadingComponent,
    ConfirmComponent,
    AddConnectionActionComponent,
    EmptyComponent,
    EntityNameComponent,
  ],
})
export class AddCompanyToCompanyConnectionComponent extends AddCompanyConnection {
  readonly myCompaniesState = inject(MyCompaniesState);

  readonly currentUser = signal<User | undefined>(undefined);
  readonly currentCompany = toSignal<Company | undefined>(
    this.myCompaniesState.activeCompany$
  );
  readonly requestedParams = computed(() => ({
    s: 'requested',
    fc: this.currentCompany(),
  }));
  readonly connectedParams = computed(() => ({
    s: 'connected',
    fc: this.currentCompany(),
  }));

  override handleSocketEvents(socket: ConnectionSocket) {
    if (socket.name === 'connection_request_company') {
      this.markConnectionAsRequested(socket.connection);
    } else if (socket.name === 'connection_add_company') {
      this.markConnectionAsApproved(socket.connection);
    } else if (socket.name === 'connection_delete_company') {
      this.deleteConnection(socket.connection);
    }
  }
}
