import { Company } from '../interfaces/company';

export interface CompanyClientsSize {
  label: string;
  value: Pick<Company, 'cmn' | 'cmx'>;
}

export const companyClientsSize: CompanyClientsSize[] = [
  {
    label: '1-50',
    value: {
      cmn: 1,
      cmx: 50,
    },
  },
  {
    label: '50-100',
    value: {
      cmn: 50,
      cmx: 100,
    },
  },
  {
    label: '100-200',
    value: {
      cmn: 100,
      cmx: 200,
    },
  },
  {
    label: '200-500',
    value: {
      cmn: 200,
      cmx: 500,
    },
  },
  {
    label: '> 500',
    value: {
      cmn: 500,
    },
  },
];
