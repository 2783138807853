import { AsyncPipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FileUploaderComponent } from '@dr/file';
import {
  ButtonComponent,
  LoadingComponent,
  SelectComponent,
  SuccessComponent,
  TextareaComponent,
  TextComponent,
} from '@dr/ui';
import { IsWebVersion, ValidationService } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContactFormModule } from '../../contact-form.module';
import { ContactFormSubject } from '../../pages/contact-form/contact-form.component';
import { ContactFormState } from '../../states/contact-form/contact-form.state';

@Component({
  selector: 'dr-contact-form-create-topic',
  templateUrl: 'contact-form-create-topic.component.html',
  styleUrls: ['contact-form-create-topic.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    ButtonComponent,
    ReactiveFormsModule,
    SelectComponent,
    TextareaComponent,
    TranslateModule,
    SuccessComponent,
    FileUploaderComponent,
    TextComponent,
    ContactFormModule,
    AsyncPipe,
    LoadingComponent,
  ],
})
export class ContactFormCreateTopicComponent implements OnInit {
  @ViewChild('subject') subjectElement?: SelectComponent<string>;
  @ViewChild('message') messageElement?: TextareaComponent<string>;

  @Input() message?: string;
  @Input() subject?: string;

  @Output() created = new EventEmitter<void>();
  subjects!: ContactFormSubject[];

  readonly IsWebVersion = IsWebVersion;

  formGroup = new FormGroup({
    subject: new FormControl(
      null,
      this.validationService.appValidationRules.contactFormSubjectValidators
    ),
    message: new FormControl(
      '',
      this.validationService.appValidationRules.contactFormMessageValidators
    ),
    file: new FormControl(null),
  });

  file?: File | null;

  subjectFormControl = this.formGroup.get('subject') as FormControl;
  messageFormControl = this.formGroup.get('message') as FormControl;
  fileControl = this.formGroup.get('file') as FormControl;

  sent = signal<boolean>(false);

  private destroyRef = inject(DestroyRef);

  constructor(
    public validationService: ValidationService,
    private translateService: TranslateService,
    public contactFormState: ContactFormState
  ) {
    this.initSubjects();
  }

  ngOnInit() {
    if (this.subject) {
      this.subjectFormControl.patchValue(this.subject);
    }

    if (this.message) {
      this.messageFormControl.patchValue(this.message);

      setTimeout(() => this.messageElement?.ionTextarea?.setFocus(), 500);
    }
  }

  submitForm() {
    this.subjectElement?.validate();
    this.messageElement?.validate();

    if (this.formGroup.invalid) {
      return;
    }

    this.contactFormState
      .createTopic$({
        subject: this.subjects.find(
          (s) => s.value === this.subjectFormControl.value
        )?.label as string,
        subjectType: this.subjectFormControl.value,
        message: this.messageFormControl.value,
        file: this.fileControl.value,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.sent.set(true);
        this.reset();

        setTimeout(() => {
          this.created.emit();

          setTimeout(() => this.sent.set(false), 1000);
        }, 3000);
      });
  }

  reset() {
    this.subjectFormControl.setValue(null);
    this.messageFormControl.setValue('');

    this.subjectElement?.reset();
    this.messageElement?.reset();
  }

  private initSubjects(): void {
    this.subjects = [
      {
        label: this.translateService.instant('contact_form.help_with_platform'),
        value: 'question',
      },
      {
        label: this.translateService.instant('contact_form.partnership'),
        value: 'proposal',
      },
      {
        label: this.translateService.instant('contact_form.report_bug'),
        value: 'issue',
      },
      {
        label: this.translateService.instant('contact_form.give_feedback'),
        value: 'feedback',
      },
      {
        label: this.translateService.instant('contact_form.other'),
        value: 'other',
      },
    ];
  }
}
