import { AsyncPipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IonicModule, IonInfiniteScroll } from '@ionic/angular';
import { finalize } from 'rxjs';
import { ContactFormTopic } from '../../interfaces/contact-form';
import { ContactFormState } from '../../states/contact-form/contact-form.state';
import { ContactFormTopicItemComponent } from '../contact-form-topic-item/contact-form-topic-item.component';

@Component({
  selector: 'dr-contact-form-topics',
  templateUrl: 'contact-form-topics.component.html',
  styleUrls: ['contact-form-topics.component.scss'],
  standalone: true,
  imports: [IonicModule, ContactFormTopicItemComponent, AsyncPipe],
})
export class ContactFormTopicsComponent {
  @ViewChild(IonInfiniteScroll) ionInfiniteScroll!: IonInfiniteScroll;

  @Input() topics?: ContactFormTopic[] | null;
  @Input() currentTopicId?: string;

  @Output() selectTopic = new EventEmitter<ContactFormTopic>();

  private destroyRef = inject(DestroyRef);

  constructor(public contactFormState: ContactFormState) {}

  loadMore() {
    this.contactFormState
      .loadMoreTopics$()
      .pipe(
        finalize(() => {
          this.ionInfiniteScroll?.complete();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
