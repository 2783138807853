@if (subscriptions === undefined) {
  <dr-skeleton [maxHeight]="60"></dr-skeleton>
} @else {
  @if (!subscriptions.length) {
    <dr-empty
      [message]="'subscriptions.subscription.empty' | translate"
    ></dr-empty>
  } @else {
    <ion-accordion-group>
      @for (subscription of subscriptions; track subscription.id) {
        <ion-accordion>
          <ion-item slot="header" color="light" class="header">
            <dr-text>
              <div class="text-container">
                <div class="pack-name">
                  <span>{{ subscription.t | translate }}</span>
                  <span class="price">${{ subscription.cp }}</span>
                </div>
                <div>
                  <small>
                    {{ 'subscriptions.subscription.active_pack' | translate }}:
                    @if (subscription.pl) {
                      <b>{{ subscription.pl }}</b>
                    } @else {
                      <i>{{
                        'subscriptions.subscription.no_pack' | translate
                      }}</i>
                    }
                  </small>
                </div>
                <div>
                  <small>
                    {{
                      'subscriptions.subscription.active_companies' | translate
                    }}:
                    <b>{{ subscription.cc || 0 }}</b>
                  </small>
                </div>
              </div>
            </dr-text>
          </ion-item>
          <div class="pack-container" slot="content">
            <div class="pack-actions">
              <dr-button
                iconName="pencil-outline"
                color="medium"
                [label]="'shared.edit' | translate"
                (click)="editCompanySubscription = subscription"
              ></dr-button>
              @if (!subscription.cc && !subscription.pl) {
                <dr-button
                  iconName="trash-outline"
                  color="danger"
                  [label]="'shared.delete' | translate"
                  (click)="delete.emit(subscription)"
                ></dr-button>
              }
            </div>

            <dr-company-subscription-item
              [primary]="subscription.p"
              [title]="subscription.t"
              [annuallyDiscount]="subscription.adp"
              [oldPrice]="subscription.op"
              [currentPrice]="subscription.cp"
              [bulletPoints]="subscription.cbp"
            ></dr-company-subscription-item>
          </div>
        </ion-accordion>
      }
    </ion-accordion-group>
  }
}

<ion-modal
  [isOpen]="!!editCompanySubscription"
  (didDismiss)="editCompanySubscription = undefined"
>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title></ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="editCompanySubscription = undefined">
            <ion-icon name="close-outline" size="large"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    @if (editCompanySubscription) {
      <ion-content class="ion-padding" drKeyboard>
        <div class="edit-subscription-content">
          <dr-company-subscription-form
            #subscriptionFormComponent
            [subscription]="editCompanySubscription"
            [canDelete]="false"
          ></dr-company-subscription-form>

          <dr-button
            class="submit-btn"
            expand="block"
            [label]="'subscriptions.packs.form.edit_btn_label' | translate"
            [disabled]="subscriptionFormComponent.formGroup.invalid"
            (click)="submit(subscriptionFormComponent.formGroup)"
          ></dr-button>
        </div>
      </ion-content>
    }
  </ng-template>
</ion-modal>
