import { Pipe, PipeTransform } from '@angular/core';
import { differenceInDays } from 'date-fns/differenceInDays';
import { differenceInHours } from 'date-fns/differenceInHours';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { differenceInMonths } from 'date-fns/differenceInMonths';
import { differenceInWeeks } from 'date-fns/differenceInWeeks';
import { differenceInYears } from 'date-fns/differenceInYears';

@Pipe({
  name: 'diffBetweenDates',
  standalone: true,
  pure: false,
})
export class DiffBetweenDatesPipe implements PipeTransform {
  transform(startDate: string | Date, currentDate: string | Date): string {
    const units = [
      { unit: 'р', diffFunction: differenceInYears },
      { unit: 'м', diffFunction: differenceInMonths },
      { unit: 'т', diffFunction: differenceInWeeks },
      { unit: 'д', diffFunction: differenceInDays },
      { unit: 'год', diffFunction: differenceInHours },
      { unit: 'хв', diffFunction: differenceInMinutes },
    ];

    let difference = null;

    for (const { unit, diffFunction } of units) {
      difference = diffFunction(currentDate, startDate);

      if (difference < 0) {
        return '';
      }

      if (difference !== 0) {
        return `${difference}${unit}`;
      }
    }

    return '0';
  }
}
