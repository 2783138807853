import { AsyncPipe, DatePipe } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import {
  ButtonComponent,
  ConfirmComponent,
  EmptyComponent,
  ErrorFooterComponent,
  LoadingComponent,
  TextComponent,
  EntityNameComponent,
  SkeletonComponent,
} from '@dr/ui';
import { getPaginationNumber, KeyboardDirective, ShortNumberPipe } from '@dr/utils';
import { IonicModule, IonInfiniteScroll } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { finalize, first } from 'rxjs';
import { User } from '../../interfaces/user';
import { UsersState } from '../../states/users/users.state';

export interface UserItem extends User {
  checked: boolean;
}

@Component({
  selector: 'dr-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  imports: [
    IonicModule,
    AsyncPipe,
    TranslateModule,
    EntityNameComponent,
    ErrorFooterComponent,
    TextComponent,
    DatePipe,
    FormsModule,
    ButtonComponent,
    ConfirmComponent,
    KeyboardDirective,
    LoadingComponent,
    EmptyComponent,
    SkeletonComponent,
    ShortNumberPipe,
  ],
  standalone: true,
})
export class UsersListComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) ionInfiniteScroll?: IonInfiniteScroll;

  users = signal<UserItem[] | null>(null);
  usersArr = computed<UserItem[]>(() => this.users() || []);
  hasCheckedUser = computed(() => this.usersArr().some((user) => user.checked));
  countCheckedUser = computed(
    () => this.usersArr().filter((user) => user.checked).length
  );

  toggleAllEnabled = false;

  private destroyRef = inject(DestroyRef);
  private checked = false;

  constructor(public usersState: UsersState) {}

  ngOnInit() {
    //fixme check onresume app
    this.usersState.users$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((users) => {
        if (!this.checked && !users) {
          this.init();
        }

        this.checked = true;

        this.users.set(
          users?.map((user) => ({
            ...user,
            checked: this.toggleAllEnabled,
          })) || null
        );
      });
  }

  toggleAll(event: Event) {
    this.users.update(
      (users) =>
        users?.map((user) => ({
          ...user,
          checked: (event as CustomEvent).detail.checked,
        })) || null
    );
  }

  toggleUser(targetUser: UserItem) {
    this.users.update(
      (users) =>
        users?.map((user) => {
          if (user.id === targetUser.id) {
            return {
              ...user,
              checked: !user.checked,
            };
          }

          return user;
        }) || null
    );
  }

  search(event: Event) {
    this.init((event as CustomEvent).detail.value);
  }

  tryAgain() {
    this.init();

    this.usersState.users$.pipe(first()).subscribe((users) => {
      if (users?.length) {
        this.expand();
      } else {
        this.init();
      }
    });
  }

  delete() {
    const ids = this.usersArr()
      .filter((user) => user.checked)
      .map((user) => user.id);

    if (!ids.length) {
      return;
    }

    this.usersState
      .deleteUsers$(ids)
      .pipe(first(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.init();
      });
  }

  init(search?: string): void {
    this.usersState.init$(getPaginationNumber(60), search);
  }

  expand(): void {
    this.usersState
      .expand$(getPaginationNumber(60))
      .pipe(finalize(() => this.ionInfiniteScroll?.complete()))
      .subscribe();
  }
}
