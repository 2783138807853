import { AsyncPipe } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import {
  ButtonComponent,
  ConfirmComponent,
  EmptyComponent,
  ErrorFooterComponent,
  LoadingComponent,
  SegmentComponent,
  SegmentItem,
  TextComponent,
} from '@dr/ui';
import { KeyboardDirective } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CompaniesModule } from '../../companies.module';
import {
  CompanySubscription,
  CompanySubscriptionsPack,
} from '../../interfaces/company';
import { CompanySubscriptionsState } from '../../states/company-subscriptions.state';
import { CompanySubscriptionsListComponent } from '../company-subscriptions-list/company-subscriptions-list.component';
import { CompanySubscriptionsPackFormComponent } from '../company-subscriptions-pack-form/company-subscriptions-pack-form.component';
import { CompanySubscriptionsPackComponent } from '../company-subscriptions-pack/company-subscriptions-pack.component';
import { CompanySubscriptionsPacksListComponent } from '../company-subscriptions-packs-list/company-subscriptions-packs-list.component';

export type SubscriptionsSegment = 'packs' | 'subscriptions';

@Component({
  selector: 'dr-company-subscriptions-manager',
  templateUrl: './company-subscriptions-manager.component.html',
  styleUrls: ['./company-subscriptions-manager.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TranslateModule,
    EmptyComponent,
    CompanySubscriptionsPackFormComponent,
    CompaniesModule,
    AsyncPipe,
    ErrorFooterComponent,
    CompanySubscriptionsPackComponent,
    TextComponent,
    ButtonComponent,
    ConfirmComponent,
    LoadingComponent,
    CompanySubscriptionsPacksListComponent,
    CompanySubscriptionsListComponent,
    KeyboardDirective,
    SegmentComponent,
  ],
})
export class CompanySubscriptionsManagerComponent implements OnInit {
  currentSegmentType = signal<SubscriptionsSegment>('packs');

  editSubscriptionsPack?: CompanySubscriptionsPack;
  deleteSubscriptionsPack?: CompanySubscriptionsPack;
  deleteSubscription?: CompanySubscription;
  segmentTypes: SegmentItem<SubscriptionsSegment>[] = [
    { label: 'subscriptions.packs.title', type: 'packs' },
    { label: 'subscriptions.title', type: 'subscriptions' },
  ];

  constructor(public companySubscriptionsState: CompanySubscriptionsState) {}

  ngOnInit() {
    this.companySubscriptionsState.loadSubscriptionsPacks$();
  }

  changeSegment(type: SubscriptionsSegment) {
    this.currentSegmentType.set(type);

    this.init();
  }

  private init(): void {
    if (this.currentSegmentType() === 'packs') {
      this.companySubscriptionsState.loadSubscriptionsPacks$();
    } else {
      this.companySubscriptionsState.loadSubscriptions$();
    }
  }
}
