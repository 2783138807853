import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { TextComponent } from '@dr/ui';
import { IonicModule } from '@ionic/angular';
import { filesize } from 'filesize';

@Component({
  selector: 'dr-attachment',
  templateUrl: 'attachment.component.html',
  styleUrls: ['attachment.component.scss'],
  standalone: true,
  imports: [IonicModule, TextComponent, NgTemplateOutlet],
})
export class AttachmentComponent implements OnChanges {
  @Input({ required: true }) name!: string;
  @Input() action: 'download' | 'uploader' = 'download';
  @Input() url?: string;
  @Input() size?: number;
  @Input() removable?: boolean;

  @Output() remove = new EventEmitter<void>();

  fileSize?: string;

  ngOnChanges() {
    if (this.size) {
      this.fileSize = filesize(this.size || 0, {
        base: 2,
        standard: 'jedec',
      });
    }
  }
}
