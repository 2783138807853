<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>{{ 'subscriptions.title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="packFormModal.present()">
        <ion-icon name="add-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar>
    <dr-segment
      [items]="segmentTypes"
      [value]="currentSegmentType()"
      (changeType)="changeSegment($event)"
    ></dr-segment>
  </ion-toolbar>
</ion-header>

<ion-content
  [class.no-scroll]="
    (companySubscriptionsState.subscriptionsPacks$ | async) === undefined
  "
>
  <dr-loading
    [show]="!!(companySubscriptionsState.deleting$ | async)"
    [message]="'Wait for the process to complete' | translate"
  ></dr-loading>

  @if (currentSegmentType() === 'packs') {
    <dr-company-subscriptions-packs-list
      [subscriptionsPacks]="
        $any(companySubscriptionsState.subscriptionsPacks$ | async)
      "
      (editPack)="editSubscriptionsPack = $event; packFormModal.present()"
      (deletePack)="
        deleteSubscriptionsPack = $event; confirmDeletePackDialog.open()
      "
    ></dr-company-subscriptions-packs-list>
  } @else if (currentSegmentType() === 'subscriptions') {
    <dr-company-subscriptions-list
      [subscriptions]="$any(companySubscriptionsState.subscriptions$ | async)"
      (delete)="
        deleteSubscription = $event; confirmDeleteSubscriptionDialog.open()
      "
    ></dr-company-subscriptions-list>
  }
</ion-content>

@if (currentSegmentType() === 'packs') {
  @if (
    companySubscriptionsState.subscriptionsPacksError$ | async;
    as subscriptionsPacksError
  ) {
    <ion-footer>
      <dr-error-footer
        [message]="subscriptionsPacksError"
        (tryAgain)="companySubscriptionsState.reloadSubscriptionsPacks$()"
      ></dr-error-footer>
    </ion-footer>
  }
} @else if (currentSegmentType() === 'subscriptions') {
  @if (
    companySubscriptionsState.subscriptionsError$ | async;
    as subscriptionsError
  ) {
    <ion-footer>
      <dr-error-footer
        [message]="subscriptionsError"
        (tryAgain)="companySubscriptionsState.reloadSubscriptions$()"
      ></dr-error-footer>
    </ion-footer>
  }
}

<ion-modal #packFormModal (didDismiss)="editSubscriptionsPack = undefined">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title></ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="packFormModal.dismiss()">
            <ion-icon name="close-outline" size="large"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" drKeyboard>
      <dr-company-subscriptions-pack-form
        [editPack]="editSubscriptionsPack"
        (sent)="packFormModal.dismiss()"
      ></dr-company-subscriptions-pack-form>
    </ion-content>
  </ng-template>
</ion-modal>

<dr-confirm
  #confirmDeletePackDialog
  confirmButtonColor="danger"
  [title]="'subscriptions.packs.remove_pack_question' | translate"
  (reject)="deleteSubscriptionsPack = undefined"
  (confirm)="
    deleteSubscriptionsPack?.id &&
      companySubscriptionsState.deleteSubscriptionsPack$(
        deleteSubscriptionsPack!.id!
      );
    deleteSubscriptionsPack = undefined
  "
></dr-confirm>

<dr-confirm
  #confirmDeleteSubscriptionDialog
  confirmButtonColor="danger"
  [title]="'subscriptions.packs.remove_subscription_question' | translate"
  (reject)="deleteSubscription = undefined"
  (confirm)="
    deleteSubscription?.id &&
      companySubscriptionsState.deleteSubscription$(deleteSubscription!.id!);
    deleteSubscription = undefined
  "
></dr-confirm>
