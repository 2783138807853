import { Component, inject, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { SplashScreen } from '@capacitor/splash-screen';
import { AuthState } from '@dr/auth';
import { ConnectionSocketService } from '@dr/connections';
import { AppFeaturesState } from '@dr/features';
import { LocalizationState } from '@dr/localization';
import { ThemeState } from '@dr/theme';
import { CurrentUserState } from '@dr/user';
import { initCustomIcons, IsWebVersion } from '@dr/utils';
import { first } from 'rxjs';
import { register } from 'swiper/swiper-element';

register();

@Component({
  selector: 'dr-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly IsWebVersion = IsWebVersion;

  readonly authState = inject(AuthState);
  readonly currentUserState = inject(CurrentUserState);
  readonly themeState = inject(ThemeState);
  readonly localizationState = inject(LocalizationState);
  readonly appFeaturesState = inject(AppFeaturesState);
  readonly connectionSocketService = inject(ConnectionSocketService);

  ngOnInit() {
    if (Capacitor.isNativePlatform()) {
      ScreenOrientation.lock({
        orientation: 'portrait',
      });
    }

    this.themeState.init();
    this.localizationState.init();
    initCustomIcons();

    this.appFeaturesState
      .load()
      .pipe(first())
      .subscribe(() => {
        if (Capacitor.isNativePlatform()) {
          SplashScreen.hide({
            fadeOutDuration: 200,
          });
        }
      });
  }

  profileConnected() {
    this.connectionSocketService.connect();
  }
}
