@if (status() && !status()?.connected) {
  <ion-content>
    <div class="content">
      <div class="img-container">
        <div class="sleep">
          @for (z of zzz; track $index) {
            <span>
              {{ z }}
            </span>
          }
        </div>

        <ion-img src="assets/images/router.png"></ion-img>

        <div class="shade"></div>
      </div>

      <ion-text class="message" color="dark">
        <span [innerText]="'network_issue' | translate"></span>
      </ion-text>

      <ion-button (click)="retry()">
        @if (!checking()) {
          {{ 'check_network_connection' | translate }}
        } @else {
          <ion-spinner name="dots"></ion-spinner>
        }
      </ion-button>
    </div>
  </ion-content>
}
