import { NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AvatarComponent, ButtonComponent, TextComponent } from '@dr/ui';
import { NavController, PopoverController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TimeagoModule } from 'ngx-timeago';
import { Notification } from '../../interfaces/notification';
import { NotificationsState } from '../../state/notifications.state';

@Component({
  selector: 'dr-notification-item',
  templateUrl: 'notification-item.component.html',
  styleUrls: ['notification-item.component.scss'],
  standalone: true,
  imports: [
    AvatarComponent,
    TextComponent,
    TimeagoModule,
    ButtonComponent,
    TranslateModule,
    NgTemplateOutlet,
  ],
})
export class NotificationItemComponent {
  @Input({ required: true }) notification!: Notification;

  @Output() read: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private navController: NavController,
    private popoverController: PopoverController,
    private notificationsState: NotificationsState
  ) {}

  contactForm() {
    const topicId = this.notification.cft?.id;

    this.readNotification();

    this.navController.navigateForward(
      topicId ? `/contact-form/chat/${topicId}` : '/contact-form'
    );
  }

  approveConnect() {
    this.notificationsState.approveConnectionAction$(this.notification);
  }

  openProfile() {
    if (this.notification.fc) {
      this.navController.navigateForward(`/company/${this.notification.fc.id}`);
    }

    if (this.notification.fu) {
      this.navController.navigateForward(`/user/${this.notification.fu.id}`);
    }
  }

  private readNotification() {
    this.popoverController.getTop().then((el) => {
      if (el) {
        this.popoverController.dismiss();
      }
    });

    this.read.emit();
  }
}
