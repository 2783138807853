import { Notification } from '../interfaces/notification';

export const NotificationsActionsName = 'Notifications';

export class ListAction {
  static readonly type = `${NotificationsActionsName} List`;

  constructor(public reload: boolean) {}
}

export class AddNewNotificationAction {
  static readonly type = `${NotificationsActionsName} Add new Notification`;

  constructor(public notification: Notification) {}
}

export class LoadMoreAction {
  static readonly type = `${NotificationsActionsName} Load More`;
}

export class ReadAllUnreadAction {
  static readonly type = `${NotificationsActionsName} Read All Unread`;
}

export class MarkAllAsReadAction {
  static readonly type = `${NotificationsActionsName} Mark All As Read`;
}

export class ReadNotificationAction {
  static readonly type = `${NotificationsActionsName} Read Notification`;

  constructor(public notification: Notification) {}
}

export class ApproveConnectionAction {
  static readonly type = `${NotificationsActionsName} Approve Connection`;

  constructor(public notification: Notification) {}
}

export class NotificationListVisibleAction {
  static readonly type = `${NotificationsActionsName} Notification List Visible`;

  constructor(public visible: boolean) {}
}

export class CountUnreadAction {
  static readonly type = `${NotificationsActionsName} Count Unread`;
}
