import { Component, computed, inject, input } from '@angular/core';
import { Company } from '@dr/company';
import { AvatarComponent, TextComponent } from '@dr/ui';
import { formatUserName, User } from '@dr/user';
import { NavController } from '@ionic/angular';
import { shuffle } from 'lodash';
import { ConnectionType } from '../../interfaces/connection';

interface BurgerEntity {
  id: User['id'] | Company['id'];
  name: string;
  type: ConnectionType;
  avatar: string;
  color: string;
}

@Component({
  selector: 'dr-connections-burger',
  templateUrl: './connections-burger.component.html',
  styleUrls: ['./connections-burger.component.scss'],
  standalone: true,
  imports: [AvatarComponent, TextComponent],
})
export class ConnectionsBurgerComponent {
  users = input.required<Partial<User>[]>();
  companies = input.required<Partial<Company>[]>();
  totalUsers = input.required<number>();
  totalCompanies = input.required<number>();
  maxVisibleEntities = input<number>(3);

  readonly navController = inject(NavController);

  total = computed(() => this.totalUsers() + this.totalCompanies());
  additionalEntities = computed(() => {
    return Math.max(0, this.total() - this.visibleEntities().length);
  });
  visibleEntities = computed(() => {
    return shuffle([
      ...this.users().map((user) => ({
        id: user.id,
        avatar: user.aXS,
        name: formatUserName(user),
        type: 'user',
        color: user.ac,
      })),
      ...this.companies().map((company) => ({
        id: company.id,
        avatar: company.lXS,
        name: company.nm,
        type: 'company',
        color: company.lc,
      })),
    ]).slice(0, this.maxVisibleEntities()) as BurgerEntity[];
  });

  openEntityProfile(entity: BurgerEntity) {
    this.navController.navigateForward(`/${entity.type}/${entity.id}`);
  }
}
