import { NgModule } from '@angular/core';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { FeaturesModule } from '@dr/features';
import { NgxsModule } from '@ngxs/store';
import { AuthApiService } from './services/auth-api.service';
import { AuthState } from './states/auth/auth.state';

@NgModule({
  imports: [NgxsModule.forFeature([AuthState]), FeaturesModule],
  providers: [AuthApiService, SignInWithApple],
})
export class AuthModule {}
