<div class="text-container">
  <dr-text class="title" color="dark">
    <div class="nowrap">{{ topic.sbj }}</div>
  </dr-text>

  @if (topic.msg) {
    <dr-text color="medium">
      <div class="nowrap">{{ topic.msg.text }}</div>
    </dr-text>
  }
</div>

<div class="status-container">
  @if (appEnvironment.appPrefix === 'admin') {
    <div
      (click)="$event.stopPropagation(); statusPopover.present($event)"
      class="status-trigger"
    >
      <ng-container
        [ngTemplateOutlet]="statusTemplate"
        [ngTemplateOutletContext]="{ status: topic.s }"
      ></ng-container>
    </div>
  }

  @if (appEnvironment.appPrefix !== 'admin') {
    <ng-container
      [ngTemplateOutlet]="statusTemplate"
      [ngTemplateOutletContext]="{ status: topic.s }"
    ></ng-container>
  }

  @if (
    (topic.sna && appEnvironment.appPrefix === 'admin') ||
    (topic.rna && appEnvironment.appPrefix !== 'admin')
  ) {
    <div class="new"></div>
  }

  <dr-text class="subtitle" color="medium">
    <small>{{ topic.cAt | localizedDate: 'mediumDate' }}</small>
  </dr-text>
</div>

<ion-popover
  #statusPopover
  alignment="start"
  arrow="false"
  side="left"
  [showBackdrop]="false"
>
  <ng-template>
    <ion-radio-group
      class="statuses"
      [value]="topic.s"
      (ionChange)="changeStatus($event)"
    >
      <ion-radio value="pending">{{ 'Pending' | translate }}</ion-radio>
      <ion-radio value="in progress">{{ 'In progress' | translate }}</ion-radio>
      <!--      <ion-radio value="need answer">{{ 'Need answer' | translate }}</ion-radio>-->
      <ion-radio value="resolved">{{ 'Resolved' | translate }}</ion-radio>
    </ion-radio-group>
  </ng-template>
</ion-popover>

<ng-template #statusTemplate let-status="status">
  @switch (status) {
    @case ('pending') {
      <ion-badge color="medium">{{ 'Pending' | translate }}</ion-badge>
    }
    @case ('in progress') {
      <ion-badge color="primary">{{ 'In progress' | translate }}</ion-badge>
    }
    @case ('need answer') {
      <ion-badge color="warning">{{ 'Need answer' | translate }}</ion-badge>
    }
    @case ('resolved') {
      <ion-badge color="success">{{ 'Resolved' | translate }}</ion-badge>
    }
  }
</ng-template>
