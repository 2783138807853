import { User } from '../interfaces/user';

export const formatUserName = (user: Partial<User>): string => {
  const firstName = user.fn || '';
  const lastName = user.ln || '';
  const isIncognito = !firstName && !lastName;

  if (isIncognito) {
    if (!user.email) {
      return 'User';
    }

    return user.email.substring(0, 1);
  }

  return `${firstName} ${lastName}`.trim();
};
