@if (title) {
  <dr-text class="title" [color]="primary ? 'light' : 'dark'">
    <h2>{{ title | translate }}</h2>
  </dr-text>
}

@if (oldPrice) {
  <div class="price-container old">
    <dr-text>
      <div>$</div>
    </dr-text>
    <dr-text [color]="primary ? 'light' : 'dark'">
      <div class="price">{{ formatPrice(oldPrice) }}</div>
    </dr-text>
    <dr-text>
      <div>/m</div>
    </dr-text>
  </div>
}

@if (currentPrice !== undefined) {
  <div class="price-container">
    <dr-text>
      <div>$</div>
    </dr-text>
    <dr-text [color]="primary ? 'light' : 'dark'">
      <div class="price">{{ formatPrice($any(currentPrice)) }}</div>
    </dr-text>
    <dr-text>
      <div>/m</div>
    </dr-text>
  </div>
}

<dr-segment
  [class.primary]="primary"
  [items]="segmentItems"
  [value]="periodType"
  (changeType)="periodType = $event"
></dr-segment>

@if (bulletPoints) {
  <div class="checklist">
    @for (bulletPoint of bulletPoints; track $index) {
      <div class="checklist-item">
        <ion-icon
          name="checkmark-outline"
          [color]="primary ? 'light' : 'dark'"
        ></ion-icon>
        <dr-text [color]="primary ? 'light' : 'dark'">
          <span>{{
            bulletPoint.label | translate: { value: bulletPoint.value }
          }}</span>
        </dr-text>
      </div>
    }
  </div>
}

<div class="footer">
  <div (click)="getStarted.emit()" class="btn" [class.primary]="primary">
    <dr-text [color]="primary ? 'primary' : 'light'" [centered]="true">
      <span>{{ 'subscriptions.get_started' | translate }}</span>
    </dr-text>
  </div>
</div>
