import { AsyncPipe, DatePipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ButtonComponent, LinkComponent, TextComponent } from '@dr/ui';
import { TranslateModule } from '@ngx-translate/core';
import { CodeInputComponent, CodeInputModule } from 'angular-code-input';
import { catchError, interval, Subject, takeUntil, tap } from 'rxjs';
import { AuthState } from '../../states/auth/auth.state';

@Component({
  selector: 'dr-code-verification',
  templateUrl: 'code-verification.component.html',
  styleUrls: ['../../auth.scss', './code-verification.component.scss'],
  imports: [
    TextComponent,
    TranslateModule,
    ButtonComponent,
    CodeInputModule,
    LinkComponent,
    DatePipe,
    AsyncPipe,
  ],
  standalone: true,
})
export class CodeVerificationComponent implements OnInit, OnDestroy {
  @ViewChild(CodeInputComponent) codeInput?: CodeInputComponent;

  @Input() type?: 'signUp' | 'forgotPassword';
  @Input() backLinkLabel?: string;

  @Output() verified = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();

  timeToResend = signal<number>(0);
  codeAnimation = signal<string>('animate__bounce');

  private destroyRef = inject(DestroyRef);

  constructor(public authState: AuthState) {}

  ngOnInit(): void {
    this.runTimeToResend();

    if (!this.type) {
      alert('Pls add verification type');
    }
  }

  ngOnDestroy() {
    this.authState.resetCodeVerificationData();
  }

  resend(): void {
    if (this.timeToResend() > 0) {
      return;
    }

    this.authState
      .resendCodeVerification$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.runTimeToResend());
  }

  onCodeCompleted(code: string): void {
    (this.type === 'signUp'
      ? this.authState.verifySignUpWithCode$(code)
      : this.authState.verifyForgotPasswordWithCode$(code)
    )
      .pipe(
        catchError((err) => {
          this.codeInput?.reset();
          this.codeAnimation.set('animate__wobble');

          throw err;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.verified.emit();
      });
  }

  private runTimeToResend(): void {
    const destroyTimer$ = new Subject<void>();

    this.timeToResend.set(59 * 1000);

    interval(1000)
      .pipe(
        tap(() => {
          const nextTick = this.timeToResend() - 1000;

          this.timeToResend.set(nextTick);

          if (nextTick <= 0) {
            destroyTimer$.next();
          }
        }),
        takeUntil(destroyTimer$),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
