<div class="header">
  <dr-text>
    <span class="title">{{ 'Create account' | translate }}</span>
  </dr-text>
  <dr-text
    *drHasFeature="{
      name: 'a_su_ep'
    }"
  >
    <span class="subtitle">{{ 'auth.enter_credentials' | translate }}</span>
  </dr-text>
</div>

<ng-container
  *drHasFeature="{
    name: 'a_su_ep'
  }"
>
  <dr-input
    #firstName
    [fControl]="firstNameControl"
    [label]="'First name' | translate"
    [errors]="validationService.appValidationRules.firstNameErrorMessages()"
    [required]="true"
    autocapitalize="on"
  ></dr-input>

  <dr-input
    #lastName
    [fControl]="lastNameControl"
    [label]="'Last name' | translate"
    [errors]="validationService.appValidationRules.lastNameErrorMessages()"
    [required]="true"
    autocapitalize="on"
  ></dr-input>

  <dr-input
    #email
    type="email"
    inputMode="email"
    [fControl]="emailControl"
    [label]="'Email' | translate"
    [errors]="validationService.appValidationRules.emailErrorMessages()"
    [required]="true"
    (focusOut)="authState.updateVerificationEmail($any($event))"
  ></dr-input>

  <dr-input
    #password
    type="password"
    autocapitalize="off"
    [fControl]="passwordControl"
    [label]="'Password' | translate"
    [errors]="validationService.appValidationRules.passwordErrorMessages()"
    [required]="true"
  ></dr-input>

  <div class="checkbox-container">
    <dr-checkbox [fControl]="privacyTerms"> </dr-checkbox>
    <dr-text [small]="true" color="medium">
      {{ 'I agree to the' | translate }}
      <dr-link
        class="semi-bold"
        (click)="openTermsConditions.emit()"
        [underline]="true"
        >{{ 'Terms & Conditions' | translate }}</dr-link
      >
      {{ 'and our' | translate }}
      <dr-link
        class="semi-bold"
        (click)="openPrivacyPolicy.emit()"
        [underline]="true"
        >{{ 'Privacy Policy' | translate }}</dr-link
      >
    </dr-text>
  </div>

  @if (privacyTerms.dirty && privacyTerms.invalid) {
    <dr-text color="danger" [small]="true" class="error">
      <span>{{ 'auth.confirm_pp' | translate }}</span>
    </dr-text>
  }

  <dr-button
    expand="block"
    iconName="arrow-forward-outline"
    [label]="'Create account' | translate"
    (click)="signUp()"
  ></dr-button>
</ng-container>

@if (!!(authState.signUpError$ | async)) {
  <dr-text [small]="true" [centered]="true" color="danger" class="error">
    <span>{{ authState.signUpError$ | async }}</span>
  </dr-text>
}

<dr-auth-provider
  *drHasFeature="{
    name: 'a_p'
  }"
></dr-auth-provider>

<dr-text class="switch-link" [centered]="true">
  {{ 'auth.has_account' | translate }}
  <dr-link (click)="signIn.emit()">{{ 'Sign in' | translate }}</dr-link>
</dr-text>
