import { Injectable, NgZone } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor, PermissionState } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import { first, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  readonly token$ = new Subject<string>();

  readonly permissionsState$: ReplaySubject<PermissionState> =
    new ReplaySubject<PermissionState>(1);

  constructor(private ngZone: NgZone) {}

  async init(): Promise<void> {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    this.disable();
    await this.requestPermissions();

    App.addListener('appStateChange', async ({ isActive }) => {
      if (isActive) {
        await this.requestPermissions();

        this.permissionsState$.pipe(first()).subscribe((state) => {
          if (state === 'denied') {
            this.disable();
          }
        });
      }
    });
  }

  disable() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    PushNotifications.unregister();
    PushNotifications.removeAllDeliveredNotifications();
    PushNotifications.removeAllListeners();
  }

  private async requestPermissions() {
    try {
      await PushNotifications.removeAllDeliveredNotifications();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[Push removeAllDeliveredNotifications]:', error);
    }

    try {
      this.checkRegistration();

      let check = await PushNotifications.checkPermissions();

      if (check.receive !== 'denied') {
        check = await PushNotifications.requestPermissions();
      }

      this.ngZone.run(() => {
        this.permissionsState$.next(check.receive);
      });

      if (check.receive === 'granted') {
        await PushNotifications.register();

        this.listenToPushNotifications();
      } else {
        // eslint-disable-next-line no-console
        console.log('[Push notifications]: Permissions denied');
        // Show some error
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[Push notifications]:', error);
    }
  }

  private checkRegistration(): void {
    PushNotifications.addListener('registration', ({ value }) =>
      this.token$.next(value)
    );

    PushNotifications.addListener('registrationError', (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error('[Push notifications] registration: ', error);
    });
  }

  private listenToPushNotifications(): void {
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // eslint-disable-next-line no-console
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // eslint-disable-next-line no-console
        console.log('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }
}
