import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  standalone: true,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: Date | string, format = 'mediumDate'): string | null {
    const datePipe = new DatePipe(this.translateService.defaultLang || 'en');

    try {
      return datePipe.transform(value, format);
    } catch {
      return String(value);
    }
  }
}
