import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ButtonComponent,
  LinkComponent,
  SelectComponent,
  TextareaComponent,
  TextComponent,
} from '@dr/ui';
import {
  CountyService,
  IsWebVersion,
  KeyboardDirective,
  openLocation,
  ValidationService,
} from '@dr/utils';
import { IonicModule, IonModal } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { first, map, switchMap, withLatestFrom } from 'rxjs';
import { CompaniesModule } from '../../companies.module';
import { Company } from '../../interfaces/company';
import { CompaniesState } from '../../states/companies.state';
import { CompanyDetailsState } from '../../states/company-details.state';
import { CompanySubscriptionsState } from '../../states/company-subscriptions.state';
import { companySources } from '../../utils/company-sources';

@Component({
  selector: 'dr-company-short-info',
  templateUrl: 'company-short-info.component.html',
  styleUrls: ['company-short-info.component.scss'],
  imports: [
    IonicModule,
    TextComponent,
    LinkComponent,
    TranslateModule,
    ButtonComponent,
    AsyncPipe,
    KeyboardDirective,
    SelectComponent,
    TextareaComponent,
    CompaniesModule,
    NgTemplateOutlet,
  ],
  providers: [CountyService],
  standalone: true,
})
export class CompanyShortInfoComponent implements OnInit {
  @ViewChild('approveModal') approveModal!: IonModal;
  @ViewChild('rejectModal') rejectModal!: IonModal;

  @Input({ required: true }) company!: Company;

  readonly openLocation = openLocation;
  readonly IsWebVersion = IsWebVersion;

  subscriptionPackControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.subscriptionValidators
  );

  rejectMessageControl = new FormControl<string>(
    '',
    this.validationService.appValidationRules.companyRejectMessageValidators
  );

  subscriptionsPacks$ = this.companySubscriptionsState.subscriptionsPacks$.pipe(
    map((subscriptionPacks) =>
      (subscriptionPacks || []).map((subscription) => ({
        label: `${subscription.l} (${subscription.adp}%)`,
        value: subscription.id as string,
      }))
    )
  );

  constructor(
    public validationService: ValidationService,
    public companySubscriptionsState: CompanySubscriptionsState,
    public companiesState: CompaniesState,
    private countyService: CountyService,
    private companyDetailsState: CompanyDetailsState
  ) {}

  ngOnInit() {
    this.companySubscriptionsState.loadSubscriptionsPacks$();
  }

  getCountry(country: string) {
    return this.countyService.countries?.find((c) => c.value === country)
      ?.label;
  }

  getSource(sourceValue: string) {
    return companySources.find((s) => s.value === sourceValue);
  }

  approve() {
    if (
      !this.subscriptionPackControl.value ||
      this.subscriptionPackControl.invalid
    ) {
      return;
    }

    this.companiesState
      .approvePendingCompany$(
        this.company.id,
        this.subscriptionPackControl.value
      )
      .pipe(
        first(),
        withLatestFrom(this.companySubscriptionsState.subscriptionsPacks$),
        switchMap(([, subscriptionsPacks]) =>
          this.companyDetailsState.updateCompanyData$(this.company.id, {
            s: 'approved',
            sp: subscriptionsPacks?.find(
              (pack) => pack.id === this.subscriptionPackControl.value
            ),
          })
        )
      )
      .subscribe(() => {
        this.approveModal.dismiss();
      });
  }

  reject() {
    if (!this.rejectMessageControl.value || this.rejectMessageControl.invalid) {
      return;
    }

    this.companiesState
      .rejectPendingCompany$(this.company.id, this.rejectMessageControl.value)
      .pipe(
        first(),
        switchMap(() =>
          this.companyDetailsState.updateCompanyData$(this.company.id, {
            s: 'rejected',
            msg: this.rejectMessageControl.value as string,
          })
        )
      )
      .subscribe(() => this.rejectModal.dismiss());
  }
}
