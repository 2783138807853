@if (!inner()) {
  <ion-loading
    [isOpen]="!!show()"
    [message]="message() || ('Loading...' | translate)"
    [showBackdrop]="hasBackdrop()"
    mode="ios"
  ></ion-loading>
} @else if (!!inner() && !!show()) {
  <div [class.backdrop]="hasBackdrop()" class="inner-container">
    <ion-spinner></ion-spinner>
  </div>
}
