import { Component, inject, OnDestroy, OnInit, output } from '@angular/core';
import { SmartComponent } from '@dr/utils';
import { UserSocketService } from '../../services/user-socket.service';
import { CurrentUserState } from '../../states/current-user/current-user.state';

@Component({
  selector: 'dr-current-user-connector',
  templateUrl: 'current-user-connector.component.html',
  styleUrls: ['current-user-connector.component.scss'],
  standalone: true,
})
export class CurrentUserConnectorComponent
  extends SmartComponent
  implements OnInit, OnDestroy
{
  connected = output<void>();
  disconnected = output<void>();

  private readonly currentUserState = inject(CurrentUserState);
  private readonly userSocketService = inject(UserSocketService);

  override async ngOnInit() {
    await super.ngOnInit();

    this.currentUserState.check$();
    this.userSocketService.connect();
    this.connected.emit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.userSocketService.disconnect();
    this.disconnected.emit();
  }

  override onResumeApp() {
    super.onResumeApp();

    this.currentUserState.check$();
  }
}
