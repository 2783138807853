import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { AttachmentComponent, FileService } from '@dr/file';
import { TextareaComponent } from '@dr/ui';
import { ValidationService } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, finalize } from 'rxjs';
import { ContactFormState } from '../../states/contact-form/contact-form.state';

@Component({
  selector: 'dr-contact-form-send-message',
  templateUrl: 'contact-form-send-message.component.html',
  styleUrls: ['contact-form-send-message.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TextareaComponent,
    TranslateModule,
    AttachmentComponent,
  ],
  providers: [FileService],
})
export class ContactFormSendMessageComponent {
  @Input({ required: true }) currentTopicId!: string;
  @Input({ required: true }) canSend!: boolean;

  @Output() sent = new EventEmitter<void>();

  messageControl = new FormControl(
    '',
    this.validationService.appValidationRules.contactFormMessageValidators
  );

  file = signal<File | undefined>(undefined);

  uploading = signal<boolean>(false);

  private destroyRef = inject(DestroyRef);

  constructor(
    public validationService: ValidationService,
    private fileService: FileService,
    private contactFormState: ContactFormState
  ) {}

  send() {
    if (this.messageControl.invalid && this.canSend) {
      return;
    }

    const text = this.messageControl.value as string;
    const file = this.file();

    this.file.set(undefined);
    this.messageControl.reset();
    setTimeout(() => this.sent.emit(), 100);

    this.contactFormState
      .sendMessage$(this.currentTopicId, {
        text,
        file,
      })
      .pipe(
        catchError((err) => {
          this.messageControl.patchValue(text);
          this.file.set(file);

          throw err;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  uploadFile() {
    if (this.uploading()) {
      return;
    }

    this.uploading.set(true);

    this.fileService
      .attachMediaFromGallery$()
      .pipe(
        finalize(() => {
          this.uploading.set(false);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((files) => {
        this.file.set(files[0]);
      });
  }
}
