import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import {
  SignInWithApple,
  ASAuthorizationAppleIDRequest,
} from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { RegularSuccessResponse } from '@dr/utils';
import {
  catchError,
  filter,
  first,
  from,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import * as firebaseAuth from 'firebase/auth';
import { getAuthHeaderParams } from '../interceptors/auth-interceptor.service';

export interface SignUpData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface AuthData {
  email: string;
  password: string;
}

export interface VerifyCodeData {
  email: string;
  code: string;
}

export interface ResetPasswordData {
  email: string;
  code: string;
  password: string;
}

export interface ResendCodeData {
  email: string;
}

export interface AuthSuccess {
  token: string;
}

export const providerClosedErrorCode = 'closed_by_user';

@Injectable()
export class AuthApiService {
  constructor(
    private httpClient: HttpClient,
    private angularFireAuth: AngularFireAuth,
    private signInWithApple: SignInWithApple
  ) {}

  logout$(token: string): Observable<RegularSuccessResponse> {
    if (!token) {
      return of({ success: true });
    }

    return this.httpClient.post<RegularSuccessResponse>(
      '/api/auth/logout',
      {},
      getAuthHeaderParams(token)
    );
  }

  signUpWithCredentials$(data: SignUpData): Observable<RegularSuccessResponse> {
    return this.httpClient.post<RegularSuccessResponse>(
      `/api/auth/sign-up`,
      data
    );
  }

  verifySignUp$(data: VerifyCodeData): Observable<AuthSuccess> {
    return this.httpClient.post<AuthSuccess>(`/api/auth/verify/sign-up`, data);
  }

  resendCodeVerification$(
    data: ResendCodeData
  ): Observable<RegularSuccessResponse> {
    return this.httpClient.post<RegularSuccessResponse>(
      `/api/auth/verify/resend-code`,
      data
    );
  }

  signInWithCredentials$(data: AuthData) {
    return this.httpClient.post<AuthSuccess>(`/api/auth/sign-in`, data);
  }

  signInWithGoogle$(): Observable<{
    token: string;
  }> {
    if (!Capacitor.isNativePlatform()) {
      return from(
        this.angularFireAuth.signInWithPopup(
          new firebaseAuth.GoogleAuthProvider()
        )
      ).pipe(
        catchError((err: { code: string }) => {
          if (err.code === 'auth/popup-closed-by-user') {
            throw providerClosedErrorCode;
          }

          throw err.code;
        }),
        switchMap(() => this.signInWithProvider$())
      );
    }

    GoogleAuth.initialize();

    return from(GoogleAuth.signOut()).pipe(
      switchMap(() =>
        from(GoogleAuth.signIn()).pipe(
          catchError((err: { code: string }) => {
            // -5 means that user closed manually
            if (err.code === '-5') {
              throw providerClosedErrorCode;
            }

            throw err.code;
          })
        )
      ),
      switchMap(({ authentication }) => {
        return from(
          this.angularFireAuth.signInWithCredential(
            new firebaseAuth.OAuthProvider('google.com').credential({
              idToken: authentication.idToken,
            })
          )
        ).pipe(switchMap(() => this.signInWithProvider$()));
      })
    );
  }

  signInWithApple$(): Observable<{
    token: string;
  }> {
    if (!Capacitor.isNativePlatform()) {
      return from(
        this.angularFireAuth.signInWithPopup(
          new firebaseAuth.OAuthProvider('apple.com')
        )
      ).pipe(
        catchError((err: { code: string }) => {
          if (err.code === 'auth/popup-closed-by-user') {
            throw providerClosedErrorCode;
          }

          throw err.code;
        }),
        switchMap(() => this.signInWithProvider$())
      );
    }

    return from(
      this.signInWithApple.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
        ],
      })
    ).pipe(
      switchMap(({ identityToken }) => {
        return from(
          this.angularFireAuth.signInWithCredential(
            new firebaseAuth.OAuthProvider('apple.com').credential({
              idToken: identityToken,
            })
          )
        ).pipe(switchMap(() => this.signInWithProvider$()));
      }),
      catchError((err: { code: string }) => {
        // 1000, 1001 means that user closed manually
        if (['1000', '1001'].includes(String(err.code))) {
          throw providerClosedErrorCode;
        }

        throw err.code;
      })
    );
  }

  forgotPassword$(email: string): Observable<RegularSuccessResponse> {
    return this.httpClient.post<RegularSuccessResponse>(
      `/api/auth/forgot-password`,
      { email }
    );
  }

  verifyForgotPassword$(
    data: VerifyCodeData
  ): Observable<RegularSuccessResponse> {
    return this.httpClient.post<RegularSuccessResponse>(
      `/api/auth/verify/forgot-password`,
      data
    );
  }

  resetPassword$(data: ResetPasswordData): Observable<AuthSuccess> {
    return this.httpClient.post<AuthSuccess>(`/api/auth/reset-password`, data);
  }

  private signInWithProvider$(): Observable<AuthSuccess> {
    return this.getFirebaseToken$().pipe(
      switchMap(({ token }) =>
        this.httpClient.post<AuthSuccess>(`/api/auth/provider/sign-in`, {
          token: token,
        })
      )
    );
  }

  private getFirebaseToken$(): Observable<AuthSuccess> {
    return this.angularFireAuth.idToken.pipe(
      filter(Boolean),
      first(),
      map((token) => ({ token }))
    );
  }
}
