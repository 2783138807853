import { NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseInput } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { InputComponent } from '../../input/input.component';
import { EntityNameComponent } from '../../user/entity-name/entity-name.component';

export interface MultiSelectOption {
  key: string;
  label: string;
  value: unknown;
  img?: string;
  color: string;
}

@Component({
  selector: 'dr-multi-select',
  templateUrl: 'multi-select.html',
  styleUrls: ['multi-select.scss'],
  standalone: true,
  imports: [IonicModule, InputComponent, EntityNameComponent, NgOptimizedImage],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent extends BaseInput<MultiSelectOption[]> {
  @Input({ required: true }) options!: MultiSelectOption[];
  @Input() loading?: boolean;

  @Output() search: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();

  searchControl = new FormControl<string>('');
  isPopoverOpened = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  @HostListener('document:click')
  hidePopover() {
    this.isPopoverOpened = false;
  }

  addOption(option: MultiSelectOption) {
    if (this.optionSelected(option)) {
      return;
    }

    this.fControl.setValue([...(this.fControl.value || []), option]);
  }

  removeOption(option: MultiSelectOption) {
    this.fControl.setValue(
      this.fControl.value.filter((item) => item.key !== option.key)
    );
  }

  optionSelected(option: MultiSelectOption) {
    return this.fControl.value?.some(
      (item: MultiSelectOption) => item.key === option.key
    );
  }

  override reset() {
    super.reset();

    this.searchControl.reset();
    this.changeDetectorRef.detectChanges();
  }
}
