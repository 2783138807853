import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthActions } from '../states/auth/auth.actions';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private store: Store,
    private translateService: TranslateService
  ) {}

  intercept(
    httpRequest: HttpRequest<unknown>,
    nextHandler: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return nextHandler.handle(httpRequest).pipe(
      catchError((response) => {
        if (response instanceof HttpErrorResponse) {
          if (response.status === 0) {
            // fixme handle if server not responding
          }

          if (response.status === 401) {
            this.store.dispatch(new AuthActions.Logout());
          }

          if (response.error?.error) {
            return throwError(
              this.translateService.instant(response.error.error)
            );
          }

          if (response.error?.message) {
            return throwError(
              this.translateService.instant(response.error.message)
            );
          }
        }

        return throwError(this.translateService.instant(response.statusText));
      })
    );
  }
}
