import { Directive } from '@angular/core';
import { QRCodeType } from '@dr/barcode';
import { Company } from '@dr/company';
import { CompanyConnection, Connection } from '../../../interfaces/connection';
import { AddConnection } from '../add-connection';

@Directive()
export abstract class AddCompanyConnection extends AddConnection {
  override qRCodeType = QRCodeType.company;

  openConnection(connection: Connection) {
    const companyId = connection?.c?.id;

    if (!companyId) {
      return;
    }

    this.navController.navigateForward(`/company/${companyId}`);
  }

  override addByQr$(qrId: string) {
    return this.connectionsState.addConnectionByQr$(qrId, 'company');
  }

  override findConnections$() {
    return this.connectionsApiService.findPossibleCompanyConnections$({
      search: this.searchText(),
    });
  }

  override deleteRequest(connection: Connection) {
    const companyId = connection?.c?.id;

    if (!companyId) {
      return;
    }

    this.connectionsState
      .rejectConnection$(companyId, 'company')
      .subscribe(() => {
        this.deleteConnection(connection);
      });
  }

  override deleteConnection(targetConnection: Connection) {
    this.connections.set(
      this.connections()?.map(
        (connection) =>
          ({
            ...connection,
            con:
              targetConnection.c?.id === connection.c?.id
                ? undefined
                : connection.con,
          }) as CompanyConnection
      )
    );
  }

  override approveConnection(targetConnection: Connection) {
    const companyId = targetConnection?.c?.id;

    if (!companyId) {
      return;
    }

    this.connectionsState
      .approveConnection$(companyId, 'company')
      .subscribe(() => {
        this.markConnectionAsApproved({
          ...targetConnection,
          s: 'connected',
          c: {
            ...targetConnection.c,
            id: companyId,
          },
        } as Connection);
      });
  }

  override requestConnection(targetConnection: Connection) {
    const companyId = targetConnection?.c?.id;

    if (!companyId) {
      return;
    }

    this.connectionsState
      .requestConnection$(companyId, 'company')
      .subscribe(() => {
        this.connections.set(
          this.connections()?.map(
            (connection) =>
              ({
                ...connection,
                con:
                  companyId === connection.c?.id
                    ? this.requestedParams()
                    : connection.con,
              }) as CompanyConnection
          )
        );
      });
  }

  override markConnectionAsConnected(entity: Company) {
    this.connections.set(
      this.connections()?.map(
        (connection) =>
          ({
            ...connection,
            con:
              entity.id === connection.c?.id
                ? this.connectedParams()
                : connection.con,
          }) as CompanyConnection
      )
    );
  }

  override markConnectionAsApproved(targetConnection: Connection) {
    this.connections.set(
      this.connections()?.map(
        (connection) =>
          ({
            ...connection,
            con:
              targetConnection.c?.id === connection.c?.id
                ? targetConnection
                : connection.con,
          }) as CompanyConnection
      )
    );
  }

  override markConnectionAsRequested(targetConnection: Connection) {
    this.connections.set(
      this.connections()?.map(
        (connection) =>
          ({
            ...connection,
            con:
              targetConnection.c?.id === connection.c?.id
                ? {
                    ...targetConnection,
                    fc: targetConnection.c,
                  }
                : connection.con,
          }) as CompanyConnection
      )
    );
  }
}
