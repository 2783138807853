@for (s of skeletonItems; track $index) {
  @switch (type) {
    @case ('avatar-text') {
      <div class="rounded-skeleton">
        <ion-thumbnail slot="start">
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-thumbnail>
        <div class="skeleton-text">
          <ion-skeleton-text
            [animated]="true"
            style="width: 80%"
          ></ion-skeleton-text>
          <ion-skeleton-text
            [animated]="true"
            style="width: 60%"
          ></ion-skeleton-text>
          <ion-skeleton-text
            [animated]="true"
            style="width: 30%"
          ></ion-skeleton-text>
        </div>
      </div>
    }
    @case ('profile') {
      <div class="rounded-skeleton">
        <ion-thumbnail slot="start">
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-thumbnail>
        <div class="skeleton-text">
          <ion-skeleton-text
            [animated]="true"
            style="width: 80%"
          ></ion-skeleton-text>
          <ion-skeleton-text
            [animated]="true"
            style="width: 60%"
          ></ion-skeleton-text>
        </div>
      </div>
    }
    @case ('counter') {
      <div class="counter">
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      </div>
    }
    @case ('small-button') {
      <div class="small-button">
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      </div>
    }
    @default {
      <div class="single-skeleton">
        <ion-skeleton-text
          [animated]="true"
          [ngStyle]="{
            width: '100%',
            height: (maxHeight || 100) + 'px'
          }"
        ></ion-skeleton-text>
      </div>
    }
  }
}
