<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>{{ 'Users' | translate }}</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-checkbox
        mode="md"
        [(ngModel)]="toggleAllEnabled"
        [disabled]="!usersArr().length"
        (ionChange)="toggleAll($event)"
      ></ion-checkbox>
    </ion-buttons>
    <ion-searchbar
      [value]="usersState.searchText$ | async"
      [debounce]="500"
      [disabled]="!usersArr().length && (usersState.searchText$ | async) === ''"
      (ionInput)="search($event)"
    ></ion-searchbar>
    <ion-buttons slot="end">
      <ion-button
        [class.infinite-rotating]="!!(usersState.usersLoading$ | async)"
        [disabled]="
          (!usersArr().length && (usersState.searchText$ | async) === '') ||
          !!(usersState.usersLoading$ | async)
        "
        (click)="init()"
      >
        <ion-icon name="refresh-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <div class="actions">
      @if (!hasCheckedUser()) {
        <dr-text class="actions-title" color="dark">
          <small>{{ 'Total' | translate }}: </small
          >{{ (usersState.count$ | async | shortNumber) || '-' }}
        </dr-text>
      } @else {
        <dr-text class="actions-title" color="dark">
          <small>{{ 'Selected' | translate }}: </small> {{ countCheckedUser() }}
        </dr-text>
        <dr-button
          size="small"
          fill="solid"
          color="danger"
          [label]="'Delete' | translate"
          (click)="confirmDialog.open()"
        ></dr-button>
      }
    </div>
  </ion-toolbar>
</ion-header>

<ion-content [class.no-scroll]="users() === null" drKeyboard>
  @if (
    users() !== null &&
    !users()?.length &&
    (usersState.searchText$ | async) !== ''
  ) {
    <dr-empty [message]="'list.nothing_found' | translate"></dr-empty>
  }

  @if (users() === null) {
    <dr-skeleton [maxHeight]="60"></dr-skeleton>
  }

  @if (users()?.length) {
    <ion-accordion-group>
      @for (user of usersArr(); track user.id) {
        <ion-accordion>
          <ion-item slot="header" color="light" class="header">
            <ion-checkbox
              mode="md"
              slot="start"
              [ngModel]="user.checked"
              (click)="$event.stopPropagation()"
              (ionChange)="toggleUser(user)"
            ></ion-checkbox>

            <dr-entity-name
              [imgUrl]="user.aXS"
              [firstName]="user.fn"
              [lastName]="user.ln"
              [subtitle]="user.email"
              [color]="user.ac"
            ></dr-entity-name>
          </ion-item>
          <div class="ion-padding" slot="content">
            <div class="row">
              <dr-text color="medium">
                <span>{{ 'Gender' | translate }}:</span>
              </dr-text>
              <dr-text>
                <span>{{ user.g || '-' }}</span>
              </dr-text>
            </div>

            <div class="row">
              <dr-text color="medium">
                <span>{{ 'Date of birth' | translate }}:</span>
              </dr-text>
              <dr-text>
                <span>{{ user.bd || '-' }}</span>
              </dr-text>
            </div>

            <div class="row">
              <dr-text color="medium">
                <span>{{ 'Phone' | translate }}:</span>
              </dr-text>
              <dr-text>
                <span>{{ user.ph || '-' }}</span>
              </dr-text>
            </div>

            <div class="row">
              <dr-text color="medium">
                <span>{{ 'Instagram' | translate }}:</span>
              </dr-text>
              <dr-text>
                <span>{{ user.inst || '-' }}</span>
              </dr-text>
            </div>

            <div class="row">
              <dr-text color="medium">
                <span>{{ 'Other link' | translate }}:</span>
              </dr-text>
              <dr-text>
                <span>{{ user.ol || '-' }}</span>
              </dr-text>
            </div>

            <div class="row">
              <dr-text color="medium">
                <span>{{ 'Recent activity' | translate }}:</span>
              </dr-text>
              <dr-text>
                <span>{{ user.lvd | date: 'medium' }}</span>
              </dr-text>
            </div>
          </div>
        </ion-accordion>
      }
    </ion-accordion-group>
  }

  @if (usersState.canLoadMore$ | async) {
    <ion-infinite-scroll (ionInfinite)="expand()">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  }
</ion-content>

@if (usersState.usersError$ | async; as usersError) {
  <ion-footer>
    <dr-error-footer
      [message]="usersError"
      (tryAgain)="tryAgain()"
    ></dr-error-footer>
  </ion-footer>
}

<dr-confirm
  #confirmDialog
  [title]="'auth.delete_account' | translate"
  (confirm)="delete()"
></dr-confirm>

<dr-loading
  [show]="(usersState.deleting$ | async) === true"
  [message]="'Wait for the process to complete' | translate"
></dr-loading>
