import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, signal, ViewChild } from '@angular/core';
import { PrivacyPolicyComponent, TermsConditionsComponent } from '@dr/docs';
import { LoadingComponent } from '@dr/ui';
import { KeyboardDirective } from '@dr/utils';
import { IonContent, IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { first, fromEvent, of, switchMap } from 'rxjs';
import { AuthSuccessComponent } from '../../components/auth-success/auth-success.component';
import { AuthWrapperComponent } from '../../components/auth-wrapper/auth-wrapper.component';
import { CodeVerificationComponent } from '../../components/code-verification/code-verification.component';
import { ForgotPasswordComponent } from '../../components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../../components/reset-password/reset-password.component';
import { SignInComponent } from '../../components/sign-in/sign-in.component';
import { SignUpComponent } from '../../components/sign-up/sign-up.component';
import { AuthState } from '../../states/auth/auth.state';

type AuthFormType =
  | 'signIn'
  | 'forgotPassword'
  | 'forgotPasswordVerification'
  | 'resetPassword'
  | 'resetPasswordSuccess'
  | 'signUp'
  | 'signUpVerification'
  | 'signUpSuccess';

@Component({
  selector: 'dr-auth',
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.scss'],
  imports: [
    IonicModule,
    AuthWrapperComponent,
    SignUpComponent,
    CodeVerificationComponent,
    AuthSuccessComponent,
    TranslateModule,
    SignInComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoadingComponent,
    AsyncPipe,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    KeyboardDirective,
  ],
  standalone: true,
})
export class AuthComponent {
  @ViewChild('formContainer') formContainer?: ElementRef<HTMLElement>;
  @ViewChild(IonContent) ionContent?: IonContent;

  activeForm = signal<AuthFormType>('signIn');
  activeFormAnimation = signal<string | unknown>(undefined);

  visibleDocument?: 'privacy-policy' | 'terms-conditions';

  constructor(public authState: AuthState) {}

  goToSignIn(): void {
    this.changeActiveForm({
      animationFrom: 'animate__fadeOutRight',
      animationTo: 'animate__fadeInLeft',
      callback: () => {
        this.activeForm.set('signIn');
      },
    });
  }
  goToForgotPassword(): void {
    this.changeActiveForm({
      animationFrom:
        this.activeForm() === 'forgotPasswordVerification'
          ? 'animate__fadeOutDown'
          : 'animate__fadeOutLeft',
      animationTo:
        this.activeForm() === 'forgotPasswordVerification'
          ? 'animate__fadeInDown'
          : 'animate__fadeInRight',
      callback: () => {
        this.activeForm.set('forgotPassword');
      },
    });
  }

  goToForgotPasswordVerificationSuccess(): void {
    this.changeActiveForm({
      animationFrom: 'animate__fadeOutUp',
      animationTo: 'animate__fadeInUp',
      callback: () => {
        this.activeForm.set('forgotPasswordVerification');
      },
    });
  }

  goToResetPassword(): void {
    this.changeActiveForm({
      animationFrom: 'animate__fadeOut',
      animationTo: 'animate__fadeIn',
      callback: () => {
        this.activeForm.set('resetPassword');
      },
    });
  }

  goToResetPasswordSuccess(): void {
    this.changeActiveForm({
      animationFrom: 'animate__fadeOut',
      animationTo: 'animate__fadeIn',
      callback: () => {
        this.activeForm.set('resetPasswordSuccess');
      },
    });
  }

  goToSignUp(): void {
    this.changeActiveForm({
      animationFrom:
        this.activeForm() === 'signUpVerification'
          ? 'animate__fadeOutDown'
          : 'animate__fadeOutLeft',
      animationTo:
        this.activeForm() === 'signUpVerification'
          ? 'animate__fadeInDown'
          : 'animate__fadeInRight',
      callback: () => {
        this.activeForm.set('signUp');
      },
    });
  }

  goToSignUpVerification(): void {
    this.changeActiveForm({
      animationFrom: 'animate__fadeOutUp',
      animationTo: 'animate__fadeInUp',
      callback: () => {
        this.activeForm.set('signUpVerification');
      },
    });
  }

  goToSignUpSuccess(): void {
    this.changeActiveForm({
      animationFrom: 'animate__fadeOut',
      animationTo: 'animate__fadeIn',
      callback: () => {
        this.activeForm.set('signUpSuccess');
      },
    });
  }

  private changeActiveForm(params: {
    animationFrom: string;
    animationTo: string;
    callback: () => void;
  }): void {
    if (!this.formContainer) {
      return;
    }

    this.activeFormAnimation.set(params.animationFrom);

    fromEvent(this.formContainer.nativeElement, 'animationend')
      .pipe(
        first(),
        switchMap(() => {
          this.ionContent?.scrollToTop();
          params.callback();
          this.activeFormAnimation.set(params.animationTo);

          if (!this.formContainer) {
            return of(undefined);
          }

          return fromEvent(
            this.formContainer.nativeElement,
            'animationend'
          ).pipe(first());
        })
      )
      .subscribe(() => {
        this.activeFormAnimation.set(undefined);
      });
  }
}
