<ion-content>
  @if (permissionsDenied() === 'denied') {
    <div class="perm-container">
      <ion-img src="/assets/images/denied.gif" />
    </div>
  }

  <div
    id="qr-scanner"
    class="scanner"
    [class.loading]="!started()"
    [class.web]="!nativeDevice"
  ></div>

  @if (!nativeDevice) {
    <div class="qr-scanner-square"></div>
  }
</ion-content>

<ion-fab vertical="bottom" horizontal="center">
  <ion-fab-button (click)="modalController.dismiss()" color="light">
    <ion-icon name="close"></ion-icon>
  </ion-fab-button>
</ion-fab>

@if (permissionsDenied() !== 'denied') {
  <dr-loading
    [show]="!started()"
    [inner]="true"
    [hasBackdrop]="false"
  ></dr-loading>
}
