<dr-avatar
  [src]="src"
  [name]="name"
  [color]="color()"
  [class.disabled]="disabled"
  size="extra-large"
  (click)="!disabled && ionActionSheet.present()"
></dr-avatar>

<div class="actions">
  <ion-icon name="image-outline"></ion-icon>
</div>

<ion-action-sheet
  #ionActionSheet
  [header]="'Avatar actions' | translate"
  [subHeader]="'Choose preferred action' | translate"
  [buttons]="actions()"
  (didDismiss)="setResult($any($event))"
></ion-action-sheet>

@if (loading()) {
  <div class="loading-container">
    <app-spinner></app-spinner>
  </div>
}
