<ion-header>
  <ion-accordion-group>
    @if (currentUserState.currentUser$ | async; as currentUser) {
      <ion-accordion class="user-accordion" [value]="userAccordionState()">
        <div class="header" slot="header">
          <dr-entity-name
            (click)="openProfile('/profile', $event)"
            [class.active]="router.url.indexOf('/profile') !== -1"
            [imgUrl]="currentUser.aXS"
            [firstName]="currentUser.fn"
            [lastName]="currentUser.ln"
            [subtitle]="currentUser.bio"
            [color]="currentUser.ac"
          ></dr-entity-name>

          <div class="header-action" (click)="openNotifications($event)">
            <ion-icon
              class="notification"
              color="dark"
              name="notifications-outline"
              [class.new]="
                (notificationsState.hasUnreadNotifications$ | async) !== false
              "
            ></ion-icon>
          </div>

          <div class="header-action" (click)="toggleUserAccordion()">
            <ion-icon
              color="dark"
              [name]="userAccordionArrowState()"
            ></ion-icon>
          </div>
        </div>

        <dr-navigation-menu
          slot="content"
          [menuItems]="headerMenuItems"
        ></dr-navigation-menu>
      </ion-accordion>
    }
  </ion-accordion-group>
</ion-header>

<ion-content>
  <dr-navigation-menu [menuItems]="contentMenuItems"></dr-navigation-menu>
</ion-content>

<ion-footer>
  @if (usersState.onlineUsersData$ | async; as onlineUsersData) {
    <div class="footer-data">
      <div class="title">
        <dr-text>
          <small>{{ 'online.label' | translate }}</small>
        </dr-text>
        <ion-icon name="wifi-outline"></ion-icon>
      </div>
      <div class="footer-data-content">
        <div class="online-item">
          <ion-icon name="people-outline"></ion-icon>
          <dr-text> - {{ onlineUsersData.users | shortNumber }}</dr-text>
        </div>
        <div class="online-item">
          <ion-icon name="phone-portrait-outline"></ion-icon>
          <dr-text> - {{ onlineUsersData.devices | shortNumber }}</dr-text>
        </div>
      </div>
    </div>
  }
</ion-footer>

<ion-popover
  #notificationsPopover
  side="right"
  alignment="top"
  arrow="false"
  class="notifications-popover"
  (didDismiss)="
    notificationsState.markAllAsRead();
    notificationsState.updateNotificationsVisibility(false)
  "
>
  <ng-template>
    <ion-content [class.no-scroll]="notificationsState.loading$ | async">
      <ng-container
        *drHasFeature="{ name: 'n', noFeatureTemplate: codingTemplate }"
      >
        <dr-notifications-list
          [notifications]="notifications$ | async"
          [loading]="notificationsState.loading$ | async"
        ></dr-notifications-list>

        @if ((notificationsState.canLoadMore$ | async) !== false) {
          <div class="notifications-footer">
            @if (!(notificationsState.loadingMore$ | async)) {
              <dr-button
                [label]="'Load more' | translate"
                fill="clear"
                size="small"
                (click)="loadMoreNotifications()"
              ></dr-button>
            }

            @if (!!(notificationsState.loadingMore$ | async)) {
              <ion-spinner></ion-spinner>
            }
          </div>
        }
      </ng-container>

      <ng-template #codingTemplate>
        <dr-coding></dr-coding>
      </ng-template>
    </ion-content>
  </ng-template>
</ion-popover>
