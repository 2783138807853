import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  Keyboard,
  KeyboardResize,
  KeyboardResizeOptions,
} from '@capacitor/keyboard';
import { ScreenOrientation } from '@capacitor/screen-orientation';

export const resetKeyboardStyles = () => {
  document.body.style.maxHeight = '';
};

@Directive({
  selector: '[drKeyboard]',
  standalone: true,
})
export class KeyboardDirective implements OnInit, OnDestroy {
  @Input() keyboardOffset?: number;
  @Input() optimizePerformance?: boolean = true;

  private backupResizeModeOptions?: KeyboardResizeOptions;
  private backupDocumentTransitionStyle = '';
  private backupDocumentWillChangeStyle = '';
  private backupRfChangeStyle = '';

  private keyboardWillShow?: () => Promise<void>;
  private keyboardWillHide?: () => Promise<void>;
  private screenOrientationChange?: () => Promise<void>;

  constructor(private elementRef: ElementRef) {}

  async ngOnInit() {
    if (Capacitor.getPlatform() !== 'ios') {
      return;
    }

    this.backupResizeModeOptions = await Keyboard.getResizeMode();

    await Keyboard.setResizeMode({
      mode: KeyboardResize.None,
    });

    let scrollOffset = 0;
    let initialHeight = document.documentElement.getBoundingClientRect().height;

    const screenOrientationChange = await ScreenOrientation.addListener(
      'screenOrientationChange',
      () => {
        initialHeight = document.documentElement.getBoundingClientRect().height;
      }
    );

    this.screenOrientationChange = screenOrientationChange.remove;

    this.backupResizeModeOptions = await Keyboard.getResizeMode();
    this.backupDocumentTransitionStyle = document.body.style.transition;

    document.body.style.transition =
      'max-height .3s cubic-bezier(.25, 1, 0.5, .95)';

    if (this.optimizePerformance) {
      this.backupDocumentWillChangeStyle = document.body.style.willChange;
      document.body.style.willChange = 'max-height';

      this.backupRfChangeStyle = this.elementRef.nativeElement.style.willChange;

      this.elementRef.nativeElement.style.willChange =
        'scroll-position, max-height';
    }

    const keyboardWillShow = await Keyboard.addListener(
      'keyboardWillShow',
      async ({ keyboardHeight }) => {
        const activeElementProps =
          document.activeElement?.getBoundingClientRect();

        if (activeElementProps) {
          const additionalGap = 30;
          const activeElementPosition =
            activeElementProps.top + activeElementProps.height;
          const expectedPosition =
            activeElementPosition +
            additionalGap +
            (this.keyboardOffset || 0) -
            initialHeight +
            keyboardHeight;

          if (expectedPosition > 0) {
            scrollOffset = Math.min(expectedPosition, keyboardHeight);
          } else {
            scrollOffset = 0;
          }
        }

        requestAnimationFrame(() => {
          document.body.style.maxHeight = `${
            100 - Math.floor((100 / initialHeight) * keyboardHeight)
          }%`;

          if (scrollOffset) {
            this.elementRef.nativeElement.scrollByPoint(0, scrollOffset, 300);
          }
        });
      }
    );

    this.keyboardWillShow = keyboardWillShow.remove;

    const keyboardWillHide = await Keyboard.addListener(
      'keyboardWillHide',
      () => {
        requestAnimationFrame(() => {
          this.elementRef.nativeElement.scrollByPoint(0, -scrollOffset, 300);
          resetKeyboardStyles();
        });
      }
    );

    this.keyboardWillHide = keyboardWillHide.remove;
  }

  ngOnDestroy() {
    if (Capacitor.getPlatform() !== 'ios') {
      return;
    }

    if (this.keyboardWillShow) {
      this.keyboardWillShow();
    }

    if (this.keyboardWillHide) {
      this.keyboardWillHide();
    }

    if (this.screenOrientationChange) {
      this.screenOrientationChange();
    }

    document.body.style.transition = this.backupDocumentTransitionStyle;

    if (this.optimizePerformance) {
      document.body.style.willChange = this.backupDocumentWillChangeStyle;
      this.elementRef.nativeElement.style.willChange = this.backupRfChangeStyle;
    }

    if (this.backupResizeModeOptions) {
      Keyboard.setResizeMode(this.backupResizeModeOptions);
    }
  }
}
