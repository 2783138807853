<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="navController.back()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title>{{ 'Notifications' | translate }}</ion-title>

    @if (pushNotificationsService.permissionsState$ | async; as pushState) {
      @if (pushState === 'denied') {
        <ion-buttons slot="end">
          <ion-button (click)="pushPopover.present($event)">
            <ion-icon name="warning" color="danger"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-popover #pushPopover [arrow]="false">
          <ng-template>
            <div class="pn-warn-container">
              <dr-text>
                <span>{{ 'push_notifications.warn_message' | translate }}</span>
              </dr-text>

              <dr-button
                [label]="'app_settings.label' | translate"
                size="small"
                fill="clear"
                (click)="openNativeSettings()"
              ></dr-button>
            </div>
          </ng-template>
        </ion-popover>
      }
    }
  </ion-toolbar>
</ion-header>

<ion-content [class.no-scroll]="notificationsState.loading$ | async">
  <ion-refresher (ionRefresh)="refresh()" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ng-container
    *drHasFeature="{ name: 'n', noFeatureTemplate: codingTemplate }"
  >
    <dr-notifications-list
      [notifications]="notifications$ | async"
      [loading]="notificationsState.loading$ | async"
      (read)="notificationsState.readNotification$($event)"
    ></dr-notifications-list>

    @if (notificationsState.canLoadMore$ | async) {
      <ion-infinite-scroll (ionInfinite)="loadMore()">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    }
  </ng-container>

  <ng-template #codingTemplate>
    <dr-coding></dr-coding>
  </ng-template>
</ion-content>

@if (notificationsState.error$ | async; as error) {
  <ion-footer>
    <dr-error-footer
      [message]="error"
      (tryAgain)="tryAgain()"
    ></dr-error-footer>
  </ion-footer>
}
