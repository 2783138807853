import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  HostBinding,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { TextComponent } from '@dr/ui';
import { AppEnvironment, AppEnvironmentI, LocalizedDatePipe } from '@dr/utils';
import { IonicModule, IonPopover } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  ContactFormStatus,
  ContactFormTopic,
} from '../../interfaces/contact-form';
import { ContactFormState } from '../../states/contact-form/contact-form.state';

@Component({
  selector: 'dr-contact-form-topic-item',
  templateUrl: 'contact-form-topic-item.component.html',
  styleUrls: ['contact-form-topic-item.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TextComponent,
    TranslateModule,
    NgTemplateOutlet,
    LocalizedDatePipe,
  ],
})
export class ContactFormTopicItemComponent {
  @ViewChild(IonPopover) ionPopover?: IonPopover;

  @Input({ required: true }) topic!: ContactFormTopic;

  @HostBinding('class.selected')
  @Input()
  selected?: boolean;

  constructor(
    @Inject(AppEnvironment) public appEnvironment: AppEnvironmentI,
    private contactFormState: ContactFormState
  ) {}

  changeStatus(event: Event) {
    if (this.appEnvironment.appPrefix !== 'admin') {
      return;
    }

    this.ionPopover?.dismiss();

    const status = (event as CustomEvent).detail.value as ContactFormStatus;

    this.contactFormState.changeTopicStatus$(this.topic.id, status);
  }
}
