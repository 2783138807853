import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IonicModule, IonModal } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../button/button.component';
import { TextComponent } from '../../text/text.component';

@Component({
  selector: 'dr-confirm',
  templateUrl: 'confirm.component.html',
  styleUrls: ['confirm.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, TextComponent, ButtonComponent, TranslateModule],
})
export class ConfirmComponent {
  @ViewChild(IonModal) ionModal!: IonModal;

  @Input() title?: string;
  @Input() confirmButtonColor?: ButtonComponent['color'] = 'dark';

  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();
  @Output() reject: EventEmitter<void> = new EventEmitter<void>();

  open() {
    this.ionModal.present();
  }

  close(type: 'cancel' | 'confirm') {
    this.ionModal.dismiss(type);
  }
}
