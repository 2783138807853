<div [class.disabled]="disabled">
  <ion-textarea
    autosize
    [fill]="fill"
    [formControl]="fControl"
    [minRows]="minRows"
    [maxRows]="maxRows"
    [helperText]="helperText"
    [placeholder]="placeholder"
    [errorText]="errorText()"
    [labelPlacement]="disabled ? 'stacked' : labelPlacement || 'floating'"
    [maxlength]="$any(maxlength)"
    [minlength]="$any(minlength)"
    [counter]="counter"
    [counterFormatter]="counterFormatter"
    [autofocus]="autofocus"
    [debounce]="debounce"
    [enterkeyhint]="enterKey"
    [readonly]="disabled"
    [autocapitalize]="autocapitalize"
    [required]="$any(required)"
    [spellcheck]="spellcheck"
    (ionInput)="!disabled && enter.emit(fControl.value)"
    (ionBlur)="!disabled && focusOut.emit(fControl.value)"
  >
    @if (label) {
      <div slot="label">
        {{ label }}
        @if (required) {
          <dr-text color="danger">*</dr-text>
        }
      </div>
    }
  </ion-textarea>
</div>
