<ion-button
  [fill]="fill"
  [expand]="expand"
  [shape]="shape"
  [size]="size"
  [disabled]="disabled || loading"
  [type]="type"
  [color]="color"
  mode="ios"
>
  @if (iconName) {
    <ion-icon
      [slot]="iconPosition"
      [name]="iconName"
      [color]="iconColor"
      [size]="iconSize"
    ></ion-icon>
  }

  {{ label }}

  @if (loading) {
    <app-spinner size="small"></app-spinner>
  }
</ion-button>
