<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      @if (useBackButton) {
        <ion-button (click)="navController.back()">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ion-button>
      } @else {
        <ion-menu-button></ion-menu-button>
      }
    </ion-buttons>
    <ion-title>{{ 'contact_form.title' | translate }}</ion-title>
    <ng-container *drHasFeature="{ name: 'cf_ct' }">
      @if (appEnvironment.appPrefix !== 'admin') {
        <ion-buttons slot="end">
          <ion-button (click)="formModal.present()">
            <ion-icon name="add-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      }
    </ng-container>
  </ion-toolbar>
</ion-header>

<ion-content
  [class.no-scroll]="!(contactFormState.topics$ | async)"
  class="content-wrapper"
>
  @if (!(contactFormState.topics$ | async)) {
    <dr-skeleton></dr-skeleton>
  }

  @if ((contactFormState.topicsLoaded$ | async) === true) {
    @if (contactFormState.topics$ | async; as topics) {
      @if (!topics.length) {
        <dr-empty
          [message]="'contact_form.request_history' | translate"
        ></dr-empty>
      }

      <div class="content">
        <dr-contact-form-topics
          [topics]="topics"
          [currentTopicId]="selectedTopicId()"
          (selectTopic)="selectTopic($event)"
        ></dr-contact-form-topics>

        @if (IsWebVersion()) {
          @if (selectedTopicId(); as currentTopicId) {
            <div class="messages-container">
              <dr-contact-form-messages
                #contactFormMessagesComponent
                [id]="currentTopicId"
                (messagesLoaded)="
                  contactFormMessagesComponent.elementRef.nativeElement.scrollTop =
                    contactFormMessagesComponent.elementRef.nativeElement.scrollHeight
                "
              ></dr-contact-form-messages>

              @if (currentTopic$ | async; as currentTopic) {
                @if (
                  currentTopicMessagesState$ | async;
                  as currentTopicMessagesState
                ) {
                  @if (!currentTopicMessagesState.error) {
                    @if (currentTopic.s !== 'resolved') {
                      <dr-contact-form-send-message
                        [canSend]="!!currentTopicMessagesState?.items?.length"
                        [currentTopicId]="currentTopicId"
                        (sent)="
                          contactFormMessagesComponent.elementRef.nativeElement.scrollTo(
                            {
                              left: 0,
                              top: contactFormMessagesComponent.elementRef
                                .nativeElement.scrollHeight,
                              behavior: 'smooth'
                            }
                          )
                        "
                      ></dr-contact-form-send-message>
                    } @else {
                      <dr-text color="medium" class="footer-message">
                        <small>{{
                          'contact_form.resolved_question' | translate
                        }}</small>
                      </dr-text>
                    }
                  } @else {
                    <dr-error-footer
                      [message]="currentTopicMessagesState.error"
                      (tryAgain)="contactFormMessagesComponent.loadMessages()"
                    ></dr-error-footer>
                  }
                }
              }
            </div>
          } @else {
            <dr-empty
              [message]="'contact_form.select_topic' | translate"
            ></dr-empty>
          }
        }
      </div>
    }
  }
</ion-content>

@if (contactFormState.topicsError$ | async; as topicsError) {
  <ion-footer>
    <dr-error-footer
      [message]="topicsError"
      (tryAgain)="tryAgain()"
    ></dr-error-footer>
  </ion-footer>
}

<ion-modal #formModal>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ 'contact_form.title' | translate }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="formModal.dismiss(); message = undefined">
            <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" drKeyboard>
      <dr-contact-form-create-topic
        [message]="message"
        [subject]="message ? 'other' : ''"
        (created)="formModal.dismiss(); contactFormState.getTopicsList$()"
      ></dr-contact-form-create-topic
    ></ion-content>
  </ng-template>
</ion-modal>
