import { NgModule } from '@angular/core';
import { CompanyApiService } from '@dr/company';
import { UserApiService } from '@dr/user';
import { NgxsModule } from '@ngxs/store';
import { ConnectionsApiService } from '../services/connections-api.service';
import { ConnectionsState } from './connections.state';

@NgModule({
  imports: [NgxsModule.forFeature([ConnectionsState])],
  providers: [ConnectionsApiService, UserApiService, CompanyApiService],
})
export class ConnectionsModule {}
