import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
import { TextComponent } from '../text.component';

@Component({
  selector: 'dr-collapsed-text',
  templateUrl: 'collapsed-text.component.html',
  styleUrls: ['collapsed-text.component.scss'],
  standalone: true,
  imports: [ButtonComponent, TextComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsedTextComponent extends TextComponent implements OnChanges {
  @ViewChild('spanElement', { static: false })
  spanElement!: ElementRef<HTMLElement>;

  readonly linesCount = 3;

  @Input() text?: string;

  collapsed = true;
  isTextCouldBeCollapsed = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnChanges() {
    setTimeout(() => {
      this.isTextCouldBeCollapsed =
        this.spanElement.nativeElement.scrollHeight /
          parseInt(getComputedStyle(this.spanElement.nativeElement).fontSize) >
        this.linesCount + 1;

      this.changeDetectorRef.detectChanges();
    }, 100);
  }
}
