import { inject } from '@angular/core';
import { NavController } from '@ionic/angular';
import { map, tap } from 'rxjs';
import { AuthState } from '../states/auth/auth.state';

export const auth = () => {
  const authState = inject(AuthState);
  const navController = inject(NavController);

  return authState.isAuthenticated$.pipe(
    tap((result) => {
      if (!result) {
        navController.navigateRoot('/auth', {
          animated: false,
        });
      }
    })
  );
};

export const noAuth = () => {
  const authState = inject(AuthState);
  const navController = inject(NavController);

  return authState.isAuthenticated$.pipe(
    map((isAuthenticated) => !isAuthenticated),
    tap((result) => {
      if (!result) {
        navController.navigateRoot('/', {
          animated: false,
        });
      }
    })
  );
};
