<ng-container
  *drHasFeature="{
    name: 'a_si'
  }"
>
  <div class="header">
    <dr-text>
      <span class="title">{{ 'Sign in' | translate }}</span>
    </dr-text>
    <dr-text
      *drHasFeature="{
        name: 'a_si_ep'
      }"
    >
      <span class="subtitle">{{
        'auth.enter_email_and_password' | translate
      }}</span>
    </dr-text>
  </div>

  <ng-container
    *drHasFeature="{
      name: 'a_si_ep'
    }"
  >
    <dr-input
      #email
      type="email"
      inputMode="email"
      [fControl]="emailControl"
      [label]="'Email' | translate"
      [errors]="validationService.appValidationRules.emailErrorMessages()"
      [required]="true"
    ></dr-input>

    <dr-input
      #password
      type="password"
      [fControl]="passwordControl"
      [label]="'Password' | translate"
      [errors]="validationService.appValidationRules.passwordErrorMessages()"
      [required]="true"
    ></dr-input>
  </ng-container>

  <dr-link
    *drHasFeature="{
      name: 'a_fp'
    }"
    (click)="forgotPassword.emit()"
    color="dark"
    class="forgot-password"
    >{{ 'Forgot password' | translate }}
  </dr-link>

  <dr-button
    *drHasFeature="{
      name: 'a_si_ep'
    }"
    expand="block"
    iconName="arrow-forward-outline"
    [label]="'Sign in' | translate"
    (click)="signIn()"
  ></dr-button>

  @if (!!(authState.signInError$ | async)) {
    <dr-text [small]="true" [centered]="true" color="danger" class="error">
      <span>{{ authState.signInError$ | async }}</span>
    </dr-text>
  }

  <dr-auth-provider
    *drHasFeature="{
      name: 'a_p'
    }"
  ></dr-auth-provider>

  <dr-text
    *drHasFeature="{
      name: 'a_su'
    }"
    class="switch-link"
    [centered]="true"
  >
    {{ 'auth.dont_have_account' | translate }}
    <dr-link (click)="signUp.emit()">{{ 'Sign Up' | translate }}</dr-link>
  </dr-text>
</ng-container>
