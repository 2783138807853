import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../button/button.component';
import { TextComponent } from '../../text/text.component';

@Component({
  selector: 'dr-error-footer',
  templateUrl: 'error-footer.component.html',
  styleUrls: ['error-footer.component.scss'],
  standalone: true,
  imports: [TranslateModule, TextComponent, ButtonComponent],
})
export class ErrorFooterComponent {
  @Input() message?: string;

  @Output() readonly tryAgain = new EventEmitter<void>();
}
