<dr-text class="message" [centered]="true">
  <h2 [innerHTML]="'company.survey.intro_title' | translate"></h2>
</dr-text>

<ion-img
  src="/assets/images/company/survey-intro.png"
  class="animate__animated animate__fadeIn"
></ion-img>

<dr-text class="message" [centered]="true">
  <span [innerHTML]="'company.survey.intro_message' | translate"></span>
</dr-text>

<dr-button
  class="animate__animated animate__fadeInUp"
  color="primary"
  [label]="'company.survey.intro_button_label' | translate"
  (click)="started.emit()"
></dr-button>
