<dr-loading
  [show]="!!(companySubscriptionsState.packProcessing$ | async)"
  [message]="'Wait for the process to complete' | translate"
></dr-loading>

<div class="form">
  <dr-text>
    <h1>{{ 'subscriptions.packs.form.title' | translate }}</h1>
  </dr-text>

  <dr-input
    [fControl]="labelControl"
    [label]="'subscriptions.packs.form.label' | translate"
    [required]="true"
  ></dr-input>

  <dr-input
    inputMode="numeric"
    type="number"
    [fControl]="annuallyDiscountControl"
    [label]="'subscriptions.packs.form.annually_label' | translate"
    [required]="true"
    [max]="100"
  ></dr-input>

  <dr-text>
    <h3>
      {{ 'subscriptions.packs.form.subscription_types' | translate }}
      <dr-text color="danger">*</dr-text>
    </h3>
  </dr-text>

  @for (subscription of subscriptions; track $index) {
    <dr-company-subscription-form
      [subscription]="$any(subscription)"
      (remove)="removeSubscription($index)"
    ></dr-company-subscription-form>
  }

  <dr-button
    iconName="add-outline"
    expand="block"
    fill="outline"
    iconSize="large"
    (click)="addSubscription()"
  ></dr-button>

  <dr-button
    class="submit-btn"
    expand="block"
    [label]="
      (editPack
        ? 'subscriptions.packs.form.edit_btn_label'
        : 'subscriptions.packs.form.create_btn_label'
      ) | translate
    "
    (click)="submit()"
  ></dr-button>
</div>

<ion-modal
  [isOpen]="!!previewSubscriptionItem"
  (didDismiss)="previewSubscriptionItem = undefined"
>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title></ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="previewSubscriptionItem = undefined">
            <ion-icon name="close-outline" size="large"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div class="preview">
        @if (previewSubscriptionItem) {
          <dr-company-subscription-item
            [primary]="previewSubscriptionItem.primary"
            [title]="previewSubscriptionItem.title"
            [oldPrice]="previewSubscriptionItem.oldPrice"
            [currentPrice]="previewSubscriptionItem.currentPrice"
            [bulletPoints]="$any(previewSubscriptionItem.bulletPoints)"
            [annuallyDiscount]="$any(annuallyDiscountControl.value)"
          ></dr-company-subscription-item>
        }
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
