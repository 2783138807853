import { Injectable } from '@angular/core';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { map, Observable, tap } from 'rxjs';
import { Company } from '../interfaces/company';
import { CompanyApiService } from '../services/company-api.service';
import {
  CompanyDetailsActionsName,
  LoadCompanyDetailsAction,
  SetInitialCompanyDetailsAction,
  UpdateCompanyAction,
} from './company-details.actions';

export type CompanyDetailsStateModel = Record<string, Company>;

const defaults: CompanyDetailsStateModel = {};

@State<CompanyDetailsStateModel>({
  name: CompanyDetailsActionsName,
  defaults,
})
@Injectable()
export class CompanyDetailsState {
  @Select(CompanyDetailsState)
  private state$!: Observable<CompanyDetailsStateModel>;

  @Select(
    actionsExecuting([LoadCompanyDetailsAction, SetInitialCompanyDetailsAction])
  )
  detailsLoading$!: Observable<boolean>;

  constructor(
    private companyApiService: CompanyApiService,
    private store: Store
  ) {}

  public getCompanyById$(id: string) {
    return this.state$.pipe(map((state) => state[id]));
  }

  public loadCompanyDetails$(id: string) {
    return this.store.dispatch(new LoadCompanyDetailsAction(id));
  }

  public updateCompanyData$(id: string, data: Partial<Company>) {
    return this.store.dispatch(new UpdateCompanyAction(id, data));
  }

  public setInitialCompanyDetails$(company: Company) {
    return this.store.dispatch(new SetInitialCompanyDetailsAction(company));
  }

  @Action(LoadCompanyDetailsAction)
  private _loadCompanyDetails$(
    ctx: StateContext<CompanyDetailsStateModel>,
    action: LoadCompanyDetailsAction
  ) {
    return this.companyApiService.getCompanyProfileId$(action.id).pipe(
      tap((company) => {
        ctx.patchState({
          [company.id]: company,
        });
      })
    );
  }

  @Action(SetInitialCompanyDetailsAction)
  private _setInitialCompanyDetails$(
    ctx: StateContext<CompanyDetailsStateModel>,
    action: SetInitialCompanyDetailsAction
  ) {
    if (ctx.getState()[action.company.id]) {
      return;
    }

    ctx.patchState({
      [action.company.id]: action.company,
    });
  }

  @Action(UpdateCompanyAction)
  private _updateCompanyAction$(
    ctx: StateContext<CompanyDetailsStateModel>,
    action: UpdateCompanyAction
  ) {
    const company = ctx.getState()[action.id];

    if (!company) {
      return;
    }

    ctx.patchState({
      [action.id]: {
        ...company,
        ...action.data,
      },
    });
  }
}
