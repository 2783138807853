import { Component, computed, inject, OnInit, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ButtonComponent,
  EmptyComponent,
  EntityNameComponent,
  ErrorFooterComponent,
  LoadingComponent,
  SkeletonComponent,
} from '@dr/ui';
import { CurrentUserState } from '@dr/user';
import { SmartComponent } from '@dr/utils';
import {
  IonicModule,
  IonInfiniteScroll,
  IonRefresher,
  NavController,
} from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { finalize, first, of } from 'rxjs';
import { Connection } from '../../interfaces/connection';
import { ConnectionsState } from '../../states/connections.state';

@Component({
  selector: 'dr-requests-list',
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.scss'],
  imports: [
    IonicModule,
    EmptyComponent,
    TranslateModule,
    LoadingComponent,
    ErrorFooterComponent,
    EntityNameComponent,
    ButtonComponent,
    SkeletonComponent,
  ],
  standalone: true,
})
export class RequestsListComponent extends SmartComponent implements OnInit {
  readonly ionRefresher = viewChild(IonRefresher);
  readonly ionInfiniteScroll = viewChild(IonInfiniteScroll);

  readonly navController = inject(NavController);
  readonly connectionsState = inject(ConnectionsState);
  readonly currentUserState = inject(CurrentUserState);

  currentUser = toSignal(this.currentUserState.currentUser$);
  actionsLoading = toSignal(this.connectionsState.actionsLoading$);
  requests = computed(() => this.connectionsState.state()?.requests);

  override async ngOnInit() {
    await super.ngOnInit();

    this.reloadNewRequests();
  }

  override onResumeApp() {
    super.onResumeApp();

    this.reloadNewRequests();
  }

  openUser(userId: string) {
    if (!userId) {
      return;
    }

    this.navController.navigateForward(`/user/${userId}`);
  }

  openCompany(companyId: string) {
    if (!companyId) {
      return;
    }

    this.navController.navigateForward(`/company/${companyId}`);
  }

  refresh() {
    this.reloadRequests$()
      .pipe(finalize(() => this.ionRefresher()?.complete()))
      .subscribe();
  }

  reloadNewRequests() {
    this.reloadRequests$().subscribe();
  }

  approveUserRequest(request: Connection) {
    const userId = request?.u?.id;

    if (!userId) {
      return;
    }

    this.connectionsState.approveConnection$(userId, 'user');
  }

  approveCompanyRequest(request: Connection) {
    const companyId = request?.c?.id;

    if (!companyId) {
      return;
    }

    this.connectionsState.approveConnection$(companyId, 'company');
  }

  rejectUserRequest(request: Connection) {
    const userId = request?.u?.id;

    if (!userId) {
      return;
    }

    this.connectionsState.rejectConnection$(userId, 'user');
  }

  rejectCompanyRequest(request: Connection) {
    const companyId = request?.c?.id;

    if (!companyId) {
      return;
    }

    this.connectionsState.rejectConnection$(companyId, 'company');
  }

  expand() {
    const currentUser = this.currentUser();

    if (!currentUser) {
      this.ionInfiniteScroll()?.complete();
    } else {
      this.connectionsState
        .expandConnectionsList$(currentUser.id, 'request', {})
        .pipe(
          first(),
          finalize(() => {
            this.ionInfiniteScroll()?.complete();
          })
        )
        .subscribe();
    }
  }

  private reloadRequests$() {
    const currentUser = this.currentUser();

    if (currentUser) {
      return this.connectionsState.loadConnectionsList$(
        currentUser.id,
        'request',
        {},
        true
      );
    }

    return of(undefined);
  }
}
