<div
  class="message-container"
  [class.right]="
    message.u.id === currentUserId ||
    (appEnvironment.appPrefix === 'admin' && message.u.roles?.includes('admin'))
  "
>
  <dr-text color="dark">
    <span [innerText]="message.text"></span>
  </dr-text>

  @if (message.f) {
    <dr-attachment
      [url]="message.f.url"
      [name]="message.f.nm"
      [size]="message.f.sz"
    ></dr-attachment>
  }
</div>
