<ion-content [fullscreen]="true" drKeyboard>
  <div class="wrapper">
    <div class="content">
      <dr-button
        class="close-btn"
        fill="clear"
        iconName="close-outline"
        iconSize="large"
        (click)="step() === 'finish' ? close() : closeDialog.open()"
      >
      </dr-button>

      <dr-loading [show]="loading()"></dr-loading>

      @switch (step()) {
        @case ('intro') {
          <dr-company-survey-intro
            (started)="step.set('form')"
          ></dr-company-survey-intro>
        }
        @case ('form') {
          <dr-company-survey-form
            (closed)="step.set('intro')"
            (finish)="finish($event)"
          ></dr-company-survey-form>
        }
      }

      @if (step() === 'finish' && request(); as request) {
        <dr-company-survey-finish
          [request]="$any(request)"
          (deleted)="delete($event)"
          (closed)="close()"
          (support)="contactSupport()"
        ></dr-company-survey-finish>
      }
    </div>
  </div>
</ion-content>

<dr-confirm
  #closeDialog
  [title]="'company.survey.close_message' | translate"
  (confirm)="close()"
></dr-confirm>
