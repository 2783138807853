@if (myCompaniesState.activeCompany$ | async; as activeCompany) {
  @if (!IsWebVersion()) {
    <ion-header collapse="fade">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ 'company_settings.subscription' | translate }}</ion-title>
      </ion-toolbar>
    </ion-header>
  }

  <ion-content class="no-scroll">
    <dr-company-active-subscription-view
      [company]="activeCompany"
      [includeSubscriptionActions]="true"
    ></dr-company-active-subscription-view>
  </ion-content>
}
