<ng-content></ng-content>

@if (premium) {
  <div class="mark" (click)="click($event)"></div>
}

<ion-popover
  [showBackdrop]="false"
  [isOpen]="isPopoverOpened"
  (didDismiss)="isPopoverOpened = false"
>
  <ng-template>
    <div class="popover-content">
      <ion-icon name="diamond"></ion-icon>

      <dr-text [small]="true">{{ 'Premium account' | translate }}</dr-text>
    </div>
  </ng-template>
</ion-popover>
