import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, EmptyComponent, SkeletonComponent, TextComponent } from '@dr/ui';
import { KeyboardDirective } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { maskitoParseNumber } from '@maskito/kit';
import { TranslateModule } from '@ngx-translate/core';
import { delay, first } from 'rxjs';
import { CompanySubscription } from '../../interfaces/company';
import { UpdateCompanySubscriptionsPack } from '../../services/company-subscriptions-api.service';
import { CompanySubscriptionsState } from '../../states/company-subscriptions.state';
import { CompanySubscriptionFormComponent } from '../company-subscription-form/company-subscription-form.component';
import { CompanySubscriptionItemComponent } from '../company-subscription-item/company-subscription-item.component';

@Component({
  selector: 'dr-company-subscriptions-list',
  templateUrl: './company-subscriptions-list.component.html',
  styleUrls: ['./company-subscriptions-list.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    EmptyComponent,
    TranslateModule,
    TextComponent,
    CompanySubscriptionItemComponent,
    ButtonComponent,
    CompanySubscriptionFormComponent,
    KeyboardDirective,
    SkeletonComponent,
  ],
})
export class CompanySubscriptionsListComponent {
  @Input() subscriptions?: CompanySubscription[];

  @Output() delete = new EventEmitter<CompanySubscription>();

  editCompanySubscription?: CompanySubscription;

  constructor(private companySubscriptionsState: CompanySubscriptionsState) {}

  submit(formGroup: CompanySubscriptionFormComponent['formGroup']) {
    if (formGroup.invalid || !formGroup.value.id) {
      return;
    }

    this.companySubscriptionsState
      .updateSubscription$(formGroup.value.id, {
        ...formGroup.value,
        bulletPoints: (formGroup.value.bulletPoints || []).map((bp) =>
          JSON.parse(bp)
        ),
        currentPrice:
          maskitoParseNumber(String(formGroup.value.currentPrice)) || 0,
        oldPrice: formGroup.value.oldPrice
          ? maskitoParseNumber(String(formGroup.value.oldPrice))
          : null,
      } as UpdateCompanySubscriptionsPack['subscriptions'][0])
      .pipe(first(), delay(100))
      .subscribe(() => {
        this.editCompanySubscription = undefined;
      });
  }
}
