@if (connectionsState.newRequestsCounter(); as count) {
  <ion-item lines="none" color="light" (click)="openRequests()">
    <ion-label>{{
      'connections.new_connection_requests' | translate
    }}</ion-label>
    <div class="attention-el"></div>
    <ion-note class="new" slot="end">{{ count | shortNumber }}</ion-note>
    <ion-icon name="chevron-forward-outline" slot="end" color="dark"></ion-icon
  ></ion-item>
}
