<div class="header">
  <dr-text>
    <span class="title">{{
      (type === 'signUp' ? 'auth.enter_code' : 'auth.enter_reset_code')
        | translate
    }}</span>
  </dr-text>
  <dr-text>
    <span class="subtitle">{{
      (type === 'signUp' ? 'auth.sign_up_code_sent' : 'auth.code_sent')
        | translate: { email: (authState.verificationEmail$ | async) }
    }}</span>
  </dr-text>
</div>

<div
  class="code-container animate__animated"
  [class]="codeAnimation()"
  (animationend)="codeAnimation.set('')"
>
  <code-input [codeLength]="6" (codeCompleted)="onCodeCompleted($event)">
  </code-input>

  @if (authState.codeVerificationError$ | async; as error) {
    <dr-text class="error" color="danger" [small]="true" [centered]="true">
      <div [innerHTML]="error"></div>
    </dr-text>
  }
</div>

<dr-text [centered]="true">
  <span class="subtitle">{{ 'auth.check_email' | translate }}</span>
</dr-text>

<dr-button
  expand="block"
  iconName="arrow-forward-outline"
  [disabled]="timeToResend() > 0"
  [label]="
    ('Resend email' | translate) +
    (timeToResend() > 0 ? ' 00:' + (timeToResend() | date: 'ss') : '')
  "
  (click)="resend()"
></dr-button>

@if (authState.resendCodeVerificationError$ | async; as error) {
  <dr-text class="error" color="danger" [small]="true" [centered]="true">
    <div [innerHTML]="error"></div>
  </dr-text>
}

<dr-text class="switch-link" [centered]="true">
  {{ 'Back to' | translate }}
  <dr-link (click)="back.emit()">{{ backLinkLabel }}</dr-link>
</dr-text>
