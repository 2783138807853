@if (currentUserState.currentUser$ | async; as user) {
  @if (messages$ | async; as messages) {
    <dr-loading
      [show]="!messages.items"
      [inner]="true"
      [hasBackdrop]="false"
    ></dr-loading>

    <div class="messages">
      @for (message of messages.items; track $index) {
        <dr-contact-form-message
          [message]="message"
          [currentUserId]="user.id"
        ></dr-contact-form-message>
      }
    </div>
  }
}
