import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-toggle',
  templateUrl: 'toggle.component.html',
  styleUrls: ['toggle.component.scss'],
  standalone: true,
  imports: [IonicModule, TextComponent, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent<V> {
  @Input({ required: true }) fControl!: FormControl<V>;
  @Input() label?: string;
  @Input() disabled?: boolean;

  @Output() changed: EventEmitter<V> = new EventEmitter<V>();
}
