export const UsersActionsName = 'Users';

export class UsersListAction {
  static readonly type = `${UsersActionsName} Users List`;

  constructor(public take: number, public search?: string) {}
}
export class OnlineUsersDataAction {
  static readonly type = `${UsersActionsName} Users Online Data`;
}

export class UsersListExpandAction {
  static readonly type = `${UsersActionsName} Users List Expand`;

  constructor(public take: number) {}
}

export class DeleteUsersAction {
  static readonly type = `${UsersActionsName} Delete Users`;

  constructor(public ids: string[]) {}
}
