import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { auth, noAuth } from '@dr/auth';
import { hasFeature } from '@dr/features';

const routes: Routes = [
  {
    path: 'profile',
    canActivate: [auth],
    loadComponent: () =>
      import('@dr/profile').then((m) => m.UserProfileComponent),
  },
  {
    path: 'user/:userId',
    canActivate: [auth],
    loadComponent: () =>
      import('@dr/profile').then((m) => m.UserProfileComponent),
  },
  {
    path: 'company/:companyId',
    canActivate: [auth],
    loadComponent: () =>
      import('@dr/profile').then((m) => m.CompanyProfileComponent),
  },
  {
    path: 'notifications',
    canActivate: [auth],
    loadComponent: () =>
      import('@dr/notifications').then((m) => m.NotificationsComponent),
  },
  {
    path: 'inform',
    canActivate: [auth],
    loadComponent: () => import('@dr/inform').then((m) => m.InformComponent),
  },
  {
    path: 'contact-form',
    canActivate: [auth, () => hasFeature('cf')],
    loadComponent: () =>
      import('@dr/contact-form').then((m) => m.ContactFormComponent),
  },
  {
    path: 'contact-form/chat/:id',
    canActivate: [auth, () => hasFeature('cf')],
    loadComponent: () =>
      import('@dr/contact-form').then((m) => m.ContactFormChatComponent),
  },
  {
    path: 'settings',
    canActivate: [auth],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@dr/settings').then((m) => m.SettingsComponent),
      },
      {
        path: 'general',
        canActivate: [() => hasFeature('cu')],
        loadComponent: () =>
          import('@dr/settings').then((m) => m.UserInfoSettingsComponent),
      },
      {
        path: 'system',
        canActivate: [() => hasFeature('cd')],
        loadComponent: () =>
          import('@dr/settings').then((m) => m.SystemSettingsComponent),
      },
      {
        path: 'delete-profile',
        canActivate: [() => hasFeature('dp')],
        loadComponent: () =>
          import('@dr/settings').then((m) => m.DeleteDataComponent),
        data: {
          title: 'delete_data.delete_profile',
          contactMessage: 'delete_data.delete_profile_contact_message',
          message: 'delete_data.delete_profile_message',
          imgSrc: '/assets/images/sad2.gif',
        },
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'subscriptions',
    canActivate: [auth],
    loadComponent: () =>
      import('@dr/company').then((m) => m.CompanySubscriptionsManagerComponent),
  },
  {
    path: 'users',
    canActivate: [auth],
    loadComponent: () => import('@dr/user').then((m) => m.UsersListComponent),
  },
  {
    path: 'companies',
    canActivate: [auth],
    children: [
      {
        path: 'list',
        loadComponent: () =>
          import('@dr/company').then((m) => m.CompaniesManagerComponent),
      },
      {
        path: 'request-changes',
        loadComponent: () =>
          import('@dr/company').then(
            (m) => m.RequestCompanyChangesListComponent
          ),
      },
      {
        path: 'company/:id',
        loadComponent: () =>
          import('@dr/company').then((m) => m.CompaniesPreviewComponent),
      },
      {
        path: '',
        redirectTo: '/companies/list',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: '/companies/list',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'auth',
    canActivate: [noAuth],
    loadComponent: () => import('@dr/auth').then((m) => m.AdminAuthComponent),
  },
  {
    path: '',
    redirectTo: 'users',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'users',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
