import { UpdateUserData } from '../../services/user-api.service';

export const CurrentUserActionsName = 'CurrentUser';

export namespace CurrentUserActions {
  export class Me {
    static readonly type = `[${CurrentUserActionsName}] Check`;
  }

  export class Update {
    static readonly type = `[${CurrentUserActionsName}] Update`;

    constructor(public newUserData: UpdateUserData) {}
  }

  export class SetFBT {
    static readonly type = `[${CurrentUserActionsName}] Set FBT`;

    constructor(public token: string) {}
  }

  export class UploadAvatar {
    static readonly type = `[${CurrentUserActionsName}] Upload Avatar`;

    constructor(public file: File) {}
  }

  export class DeleteAvatar {
    static readonly type = `[${CurrentUserActionsName}] Delete Avatar`;
  }
}
