import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { catchError, finalize, Observable, tap } from 'rxjs';
import { User } from '../../interfaces/user';
import { OnlineUsers, UserApiService } from '../../services/user-api.service';
import {
  DeleteUsersAction,
  OnlineUsersDataAction,
  UsersActionsName,
  UsersListAction,
  UsersListExpandAction,
} from './users.actions';

export interface UsersStateModel {
  users?: User[];
  count?: number;
  usersError?: string;
  canLoadMore?: boolean;
  deleting?: boolean;
  onlineUsersData?: OnlineUsers;
  searchText?: string;
}

const defaults: UsersStateModel = {};

export const UsersStoreKeys = [];

@State<UsersStateModel>({
  name: UsersActionsName,
  defaults,
})
@Injectable()
export class UsersState {
  @Select(UsersState) readonly state$!: Observable<UsersStateModel>;

  @Select(UsersState.users) users$!: Observable<User[] | undefined>;
  @Select(UsersState.onlineUsersData) onlineUsersData$!: Observable<
    OnlineUsers | undefined
  >;
  @Select(UsersState.usersError) usersError$!: Observable<string | undefined>;
  @Select(UsersState.canLoadMore) canLoadMore$!: Observable<boolean>;
  @Select(UsersState.deleting) deleting$!: Observable<boolean>;
  @Select(UsersState.count) count$!: Observable<number>;
  @Select(UsersState.searchText) searchText$!: Observable<string>;

  @Select(actionsExecuting([UsersListAction, UsersListExpandAction]))
  usersLoading$!: Observable<boolean>;

  constructor(
    private userApiService: UserApiService,
    private store: Store,
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  @Selector()
  static users(state: UsersStateModel) {
    return state.users;
  }

  @Selector()
  static usersError(state: UsersStateModel) {
    return state.usersError;
  }

  @Selector()
  static canLoadMore(state: UsersStateModel) {
    return state.canLoadMore;
  }

  @Selector()
  static deleting(state: UsersStateModel) {
    return state.deleting;
  }

  @Selector()
  static count(state: UsersStateModel) {
    return state.count;
  }

  @Selector()
  static onlineUsersData(state: UsersStateModel) {
    return state.onlineUsersData;
  }

  @Selector()
  static searchText(state: UsersStateModel) {
    return state.searchText || '';
  }

  init$(take: number, search?: string) {
    return this.store.dispatch(new UsersListAction(take, search));
  }

  expand$(take: number) {
    return this.store.dispatch(new UsersListExpandAction(take));
  }

  deleteUsers$(ids: string[]) {
    return this.store.dispatch(new DeleteUsersAction(ids));
  }

  getOnlineUsersData$() {
    return this.store.dispatch(new OnlineUsersDataAction());
  }

  @Action(UsersListAction, { cancelUncompleted: true })
  private _init$(ctx: StateContext<UsersStateModel>, action: UsersListAction) {
    ctx.patchState({
      usersError: undefined,
      users: undefined,
      searchText: action.search || '',
    });

    return this.userApiService
      .users$({
        take: action.take,
        search: action.search || '',
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            users: response.items,
            count: response.count,
            canLoadMore: response.items.length < response.count,
          });
        }),
        catchError((usersError) => {
          ctx.patchState({
            usersError,
          });

          throw usersError;
        })
      );
  }

  @Action(UsersListExpandAction)
  private _expand$(
    ctx: StateContext<UsersStateModel>,
    action: UsersListExpandAction
  ) {
    ctx.patchState({
      usersError: undefined,
    });

    const users = ctx.getState().users || [];

    return this.userApiService
      .users$({
        take: action.take,
        after: users[users.length - 1]?.id,
        search: ctx.getState().searchText,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            users: [...users, ...response.items],
            canLoadMore: response.items.length < response.count,
          });
        }),
        catchError((usersError) => {
          ctx.patchState({
            usersError,
          });

          throw usersError;
        })
      );
  }

  @Action(OnlineUsersDataAction)
  private _getOnlineUsersData$(ctx: StateContext<UsersStateModel>) {
    return this.userApiService.onlineData$().pipe(
      tap((onlineUsersData) => {
        ctx.patchState({
          onlineUsersData,
        });
      })
    );
  }

  @Action(DeleteUsersAction)
  private _deleteUsers$(
    ctx: StateContext<UsersStateModel>,
    action: DeleteUsersAction
  ) {
    ctx.patchState({
      deleting: true,
    });

    return this.userApiService.deleteUsers$(action.ids).pipe(
      catchError((message) => {
        this.toastController
          .create({
            header: this.translateService.instant(
              'An error occurred while deleting. Try again'
            ),
            message,
            position: 'top',
            duration: 2000,
            color: 'danger',
          })
          .then((t) => t.present());

        throw message;
      }),
      finalize(() => {
        ctx.patchState({
          deleting: false,
        });
      })
    );
  }
}
