<dr-text color="danger">
  <small>
    {{ message || ('Something went wrong. Please try again' | translate) }}
  </small>
</dr-text>

<dr-button
  size="small"
  fill="clear"
  [label]="'Try again' | translate"
  (click)="tryAgain.emit()"
></dr-button>
