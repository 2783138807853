import {
  Company,
  CompanyPaidStatus,
  CompanyStatus,
} from '../interfaces/company';

export const CompanyActionsName = 'Companies';

export class LoadListByStatusAction {
  static readonly type = `[${CompanyActionsName}] Load List By Status`;

  constructor(public status: CompanyStatus, public search?: string) {}
}

export class ReloadListByStatusAction {
  static readonly type = `[${CompanyActionsName}] Reload List By Status`;

  constructor(public status: CompanyStatus, public search?: string) {}
}

export class LoadListByPaidStatusAction {
  static readonly type = `[${CompanyActionsName}] Load List By Paid Status`;

  constructor(public status: CompanyPaidStatus, public search?: string) {}
}

export class ReloadListByPaidStatusAction {
  static readonly type = `[${CompanyActionsName}] Reload List By Paid Status`;

  constructor(public status: CompanyPaidStatus, public search?: string) {}
}

export class ExtendListByStatusAction {
  static readonly type = `[${CompanyActionsName}] Extend List By Status`;

  constructor(public status: CompanyStatus, public take: number) {}
}

export class ExtendListByPaidStatusAction {
  static readonly type = `[${CompanyActionsName}] Extend List By Paid Status`;

  constructor(public status: CompanyPaidStatus, public take: number) {}
}

export class CountByStatusAction {
  static readonly type = `[${CompanyActionsName}] Count By Status`;
}

export class RejectPendingCompanyAction {
  static readonly type = `[${CompanyActionsName}] Reject Pending Company`;

  constructor(public id: string, public message: string) {}
}

export class ApprovePendingCompanyAction {
  static readonly type = `[${CompanyActionsName}] Approve Pending Company`;

  constructor(public companyId: string, public packId: string) {}
}

export class DeleteCompaniesByIdAction {
  static readonly type = `[${CompanyActionsName}] Delete Companies By Id`;

  constructor(
    public ids: string[],
    public list: CompanyStatus | CompanyPaidStatus
  ) {}
}

export class AddToListAction {
  static readonly type = `[${CompanyActionsName}] Add To List`;

  constructor(
    public status: CompanyStatus | CompanyPaidStatus,
    public company: Company
  ) {}
}

export class DeleteFromListAction {
  static readonly type = `[${CompanyActionsName}] Delete From List`;

  constructor(
    public status: CompanyStatus | CompanyPaidStatus,
    public id: string
  ) {}
}
