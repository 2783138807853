import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { HasFeatureDirective } from './directives/has-feature.directive';
import { FeaturesApiService } from './services/features-api.service';
import { AppFeaturesState } from './states/app-features/app-features.state';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([AppFeaturesState])],
  providers: [FeaturesApiService],
  declarations: [HasFeatureDirective],
  exports: [HasFeatureDirective],
})
export class FeaturesModule {}
