import { Inject, Injectable } from '@angular/core';
import { CurrentUserState } from '@dr/user';
import { AppEnvironment, AppEnvironmentI, ToastService } from '@dr/utils';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import {
  ContactFormStatus,
  ContactFormTopic,
  ContactFormTopicMessage,
  NewTopicData,
  NewTopicMessage,
} from '../../interfaces/contact-form';
import { ContactFormApiService } from '../../services/contact-form-api.service';
import {
  ChangeTopicStatusAction,
  ContactFormActionsName,
  CountAttentionTopicsAction,
  CountPendingTopicsAction,
  CreateTopicAction,
  GetTopicListAction,
  GetTopicMessagesListAction,
  LoadMoreTopicsAction,
  OpenTopicAction,
  SendMessageAction,
} from './contact-form.actions';

export type TopicMessages = Record<
  ContactFormTopic['id'],
  {
    error: string;
    loaded: boolean;
    items: ContactFormTopicMessage[];
  }
>;

export interface ContactFormStateModel {
  topicCreating?: boolean;
  topicCreateError?: string;
  topics?: ContactFormTopic[];
  topicsError?: string;
  topicsLoaded?: boolean;
  canLoadMore?: boolean;
  attentionTopicsCounter?: number;
  pendingTopicsCounter?: number;
  topicMessages?: TopicMessages;
}

const defaults: ContactFormStateModel = {};

@State<ContactFormStateModel>({
  name: ContactFormActionsName,
  defaults,
})
@Injectable()
export class ContactFormState {
  @Select(ContactFormState.topicCreating)
  topicCreating$!: Observable<boolean>;
  @Select(ContactFormState.topicCreateError) topicCreateError$!: Observable<
    string | undefined
  >;
  @Select(ContactFormState.topics) topics$!: Observable<
    ContactFormTopic[] | undefined
  >;
  @Select(ContactFormState.topicsError) topicsError$!: Observable<
    string | undefined
  >;
  @Select(ContactFormState.topicsLoaded) topicsLoaded$!: Observable<boolean>;
  @Select(ContactFormState.canLoadMore) canLoadMore$!: Observable<boolean>;
  @Select(ContactFormState.topicMessages)
  topicMessages$!: Observable<TopicMessages>;
  @Select(ContactFormState.attentionTopicsCounter)
  attentionTopicsCounter$!: Observable<number>;
  @Select(ContactFormState.pendingTopicsCounter)
  pendingTopicsCounter$!: Observable<number>;

  constructor(
    private contactFormApiService: ContactFormApiService,
    private store: Store,
    private toastService: ToastService,
    private currentUserState: CurrentUserState,
    @Inject(AppEnvironment) public appEnvironment: AppEnvironmentI
  ) {}

  @Selector()
  static topicCreating(state: ContactFormStateModel) {
    return state.topicCreating;
  }

  @Selector()
  static topicCreateError(state: ContactFormStateModel) {
    return state.topicCreateError;
  }

  @Selector()
  static topics(state: ContactFormStateModel) {
    return state.topics;
  }

  @Selector()
  static topicsError(state: ContactFormStateModel) {
    return state.topicsError;
  }

  @Selector()
  static topicsLoaded(state: ContactFormStateModel) {
    return state.topicsLoaded;
  }

  @Selector()
  static canLoadMore(state: ContactFormStateModel) {
    return state.canLoadMore;
  }

  @Selector()
  static attentionTopicsCounter(state: ContactFormStateModel) {
    return state.attentionTopicsCounter;
  }

  @Selector()
  static pendingTopicsCounter(state: ContactFormStateModel) {
    return state.pendingTopicsCounter;
  }

  @Selector()
  static topicMessages(state: ContactFormStateModel) {
    return state.topicMessages;
  }

  createTopic$(data: NewTopicData) {
    return this.store.dispatch(new CreateTopicAction(data));
  }

  getTopicsList$() {
    return this.store.dispatch(new GetTopicListAction());
  }

  changeTopicStatus$(topicId: string, status: ContactFormStatus) {
    return this.store.dispatch(new ChangeTopicStatusAction(topicId, status));
  }

  getTopicMessagesList$(topicId: string) {
    return this.store.dispatch(new GetTopicMessagesListAction(topicId));
  }

  openTopic$(topicId: string) {
    return this.store.dispatch(new OpenTopicAction(topicId));
  }

  sendMessage$(topicId: string, data: NewTopicMessage) {
    return this.store.dispatch(new SendMessageAction(topicId, data));
  }

  loadMoreTopics$() {
    return this.store.dispatch(new LoadMoreTopicsAction());
  }

  countAttentionTopics$() {
    return this.store.dispatch(new CountAttentionTopicsAction());
  }

  countPendingTopics$() {
    return this.store.dispatch(new CountPendingTopicsAction());
  }

  @Action(CreateTopicAction)
  private _createTopic$(
    ctx: StateContext<ContactFormStateModel>,
    action: CreateTopicAction
  ) {
    ctx.patchState({
      topicCreateError: undefined,
      topicCreating: true,
    });

    return this.contactFormApiService.createTopic$(action.data).pipe(
      tap(() => {
        ctx.patchState({
          topicCreating: false,
        });
      }),
      catchError((message) => {
        ctx.patchState({
          topicCreateError: message,
          topicCreating: false,
        });

        this.toastService.showToast({
          message,
          skipTranslation: true,
          color: 'danger',
        });

        throw message;
      })
    );
  }

  @Action(GetTopicListAction)
  private _getTopicsList$(ctx: StateContext<ContactFormStateModel>) {
    ctx.patchState({
      topicsError: undefined,
      topicsLoaded: true,
    });

    return this.contactFormApiService.getTopics$({}).pipe(
      tap((response) => {
        ctx.patchState({
          topics: response.items,
          canLoadMore: response.count > response.items.length,
        });
      }),
      catchError((topicsError) => {
        ctx.patchState({
          topicsError,
          topicsLoaded: !ctx.getState().topics?.length,
        });

        throw topicsError;
      })
    );
  }

  @Action(CountAttentionTopicsAction)
  private _countAttentionTopics$(ctx: StateContext<ContactFormStateModel>) {
    return this.contactFormApiService.countAttentionTopics$().pipe(
      tap((attentionTopicsCounter) => {
        ctx.patchState({
          attentionTopicsCounter,
        });
      })
    );
  }

  @Action(CountPendingTopicsAction)
  private _countPendingTopics$(ctx: StateContext<ContactFormStateModel>) {
    return this.contactFormApiService.countPendingTopics$().pipe(
      tap((pendingTopicsCounter) => {
        ctx.patchState({
          pendingTopicsCounter,
        });
      })
    );
  }

  @Action(LoadMoreTopicsAction)
  private _loadMoreTopics$(ctx: StateContext<ContactFormStateModel>) {
    const topics = ctx.getState().topics || [];

    if (!topics.length) {
      return of(undefined);
    }

    const lastTopicId = topics[topics.length - 1].id;

    return this.contactFormApiService.getTopics$({ after: lastTopicId }).pipe(
      tap((response) => {
        ctx.patchState({
          topics: [...topics, ...response.items],
          canLoadMore: response.count > response.items.length,
        });
      }),
      catchError((topicsError) => {
        ctx.patchState({
          topicsError,
        });

        throw topicsError;
      })
    );
  }

  @Action(ChangeTopicStatusAction)
  private _changeTopicStatus$(
    ctx: StateContext<ContactFormStateModel>,
    action: ChangeTopicStatusAction
  ) {
    const topics = [...(ctx.getState().topics as ContactFormTopic[])];
    const targetTopic = topics.find((topic) => topic.id === action.topicId);
    const pendingTopicsCounter = ctx.getState().pendingTopicsCounter || 0;

    ctx.patchState({
      pendingTopicsCounter:
        action.status === 'pending' && targetTopic?.s !== 'pending'
          ? pendingTopicsCounter + 1
          : targetTopic?.s === 'pending' && action.status !== 'pending'
            ? Math.max(0, pendingTopicsCounter - 1)
            : pendingTopicsCounter,
      topics: topics.map((topic) => {
        if (topic.id === action.topicId) {
          return {
            ...topic,
            s: action.status,
          };
        }

        return topic;
      }),
    });

    return this.contactFormApiService
      .changeTopicStatus$(action.topicId, action.status)
      .pipe(
        catchError((message) => {
          ctx.patchState({
            topics,
          });

          this.toastService.showToast({
            message,
            skipTranslation: true,
            color: 'danger',
          });

          throw message;
        })
      );
  }

  @Action(GetTopicMessagesListAction)
  private _getTopicMessagesList$(
    ctx: StateContext<ContactFormStateModel>,
    action: GetTopicMessagesListAction
  ) {
    const currentState = ctx.getState().topicMessages?.[action.topicId];

    ctx.patchState({
      topicMessages: {
        ...ctx.getState().topicMessages,
        [action.topicId]: {
          loaded: true,
          error: null,
          items: currentState?.items,
        },
      } as TopicMessages,
    });

    return this.contactFormApiService.getTopicMessages$(action.topicId).pipe(
      tap((items) => {
        ctx.patchState({
          topicMessages: {
            ...ctx.getState().topicMessages,
            [action.topicId]: {
              ...ctx.getState().topicMessages?.[action.topicId],
              items,
            },
          } as TopicMessages,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          topicMessages: {
            ...ctx.getState().topicMessages,
            [action.topicId]: {
              ...ctx.getState().topicMessages?.[action.topicId],
              error,
              loaded: false,
            },
          } as TopicMessages,
        });

        throw error;
      })
    );
  }

  @Action(OpenTopicAction)
  private _openTopic$(
    ctx: StateContext<ContactFormStateModel>,
    action: OpenTopicAction
  ) {
    const topics = ctx.getState().topics;
    const attentionTopicsCounter = ctx.getState().attentionTopicsCounter;

    if (!topics) {
      return;
    }
    const needAttention = topics.find(
      (topic) =>
        topic.id === action.topicId &&
        (this.appEnvironment.appPrefix === 'admin' ? topic.sna : topic.rna)
    );

    ctx.patchState({
      attentionTopicsCounter:
        attentionTopicsCounter && needAttention
          ? attentionTopicsCounter - 1
          : attentionTopicsCounter,
      topics: topics.map((topic) => {
        if (topic.id === action.topicId) {
          return {
            ...topic,
            sna: false,
            rna: false,
          };
        }

        return topic;
      }),
    });

    return this.contactFormApiService.openTopic$(action.topicId).pipe(
      catchError((message) => {
        ctx.patchState({
          topics,
          attentionTopicsCounter,
        });

        this.toastService.showToast({
          message,
          skipTranslation: true,
          color: 'danger',
        });

        throw message;
      })
    );
  }

  @Action(SendMessageAction)
  private _sendMessage$(
    ctx: StateContext<ContactFormStateModel>,
    action: SendMessageAction
  ) {
    const currentState = ctx.getState().topicMessages?.[action.topicId];

    if (!currentState) {
      return of(undefined);
    }

    return this.currentUserState.userOnce$.pipe(
      switchMap((user) => {
        ctx.patchState({
          topicMessages: {
            ...ctx.getState().topicMessages,
            [action.topicId]: {
              ...currentState,
              items: [
                ...(currentState.items as ContactFormTopicMessage[]),
                {
                  text: action.data.text,
                  f: action.data.file && {
                    nm: action.data.file.name,
                    url: (window.URL || window.webkitURL).createObjectURL(
                      action.data.file
                    ),
                    sz: action.data.file.size,
                  },
                  u: {
                    id: user.id,
                  },
                },
              ],
            },
          },
        });

        return this.contactFormApiService
          .sendMessage$(action.topicId, action.data)
          .pipe(
            catchError((message) => {
              ctx.patchState({
                topicMessages: {
                  ...ctx.getState().topicMessages,
                  [action.topicId]: currentState,
                },
              });

              this.toastService.showToast({
                message,
                skipTranslation: true,
                color: 'danger',
              });

              throw message;
            })
          );
      })
    );
  }
}
