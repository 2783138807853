import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Capacitor } from '@capacitor/core';
import { FeaturesModule } from '@dr/features';
import { TextComponent } from '@dr/ui';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthState } from '../../states/auth/auth.state';

@Component({
  selector: 'dr-auth-provider',
  templateUrl: 'auth-provider.component.html',
  styleUrls: ['./auth-provider.component.scss'],
  imports: [IonicModule, FeaturesModule, TextComponent, TranslateModule],
  standalone: true,
})
export class AuthProviderComponent {
  protected readonly canAuthWithApple = Capacitor.getPlatform() !== 'android';

  private destroyRef = inject(DestroyRef);

  constructor(
    public authState: AuthState,
    private navController: NavController
  ) {}

  authWithGoogle(): void {
    this.signIn(this.authState.signInWithGoogle$());
  }

  authWithApple(): void {
    this.signIn(this.authState.signInWithApple$());
  }

  private signIn(obs$: Observable<void>): void {
    obs$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() =>
      setTimeout(() => {
        this.navController.navigateRoot('/', {
          animated: false,
        });
      })
    );
  }
}
