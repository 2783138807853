export * from './lib/components/file-uploader/file-uploader.component';
export * from './lib/components/attachment/attachment.component';
export * from './lib/components/avatar-form/avatar-form.component';

export * from './lib/services/file-api.service';
export * from './lib/services/file.service';

export * from './lib/utils/file-to-base64';

export * from './lib/file.module';
