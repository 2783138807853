import { AsyncPipe } from '@angular/common';
import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { IonColor, ShortNumberPipe } from '@dr/utils';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TextComponent } from '../../text/text.component';
import { TooltipComponent } from '../../tooltip/tooltip.component';

export interface NavigationMenuItem {
  title: string;
  leftIcon: string;
  url?: () => string;
  rightIcon$?: Observable<string>;
  counters$?: Observable<
    { value: number; color?: IonColor; tooltip?: string }[]
  >;
  hidden$?: Observable<boolean>;
  rootNavigation?: boolean;
  hasBottomLine?: boolean;
  callback?: () => void;
}

@Component({
  selector: 'dr-navigation-menu',
  templateUrl: 'navigation-menu.component.html',
  styleUrls: ['navigation-menu.component.scss'],
  imports: [
    IonicModule,
    TranslateModule,
    AsyncPipe,
    TextComponent,
    TooltipComponent,
    ShortNumberPipe,
  ],
  standalone: true,
})
export class NavigationMenuComponent {
  @ViewChildren(TooltipComponent)
  tooltipComponents!: QueryList<TooltipComponent>;

  @Input({ required: true }) menuItems!: NavigationMenuItem[];

  constructor(
    public router: Router,
    public navController: NavController
  ) {}

  async openRoute(menuItem: NavigationMenuItem) {
    if (menuItem.url) {
      if (menuItem.rootNavigation) {
        await this.navController.navigateRoot(menuItem.url());
      } else {
        await this.router.navigateByUrl(menuItem.url());
      }
    }

    setTimeout(() => {
      menuItem?.callback && menuItem.callback();
      this.tooltipComponents.forEach((tooltipComponent) =>
        tooltipComponent.dismiss()
      );
    }, 200);
  }
}
