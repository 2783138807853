<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="navController.back()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title>{{
      'connections.new_connection_requests' | translate
    }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <dr-loading [show]="actionsLoading()"></dr-loading>

  <ion-refresher (ionRefresh)="refresh()" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  @if (requests()?.items; as requests) {
    @for (request of requests; track request.id) {
      <ion-item>
        <div class="connection">
          @if (request.u) {
            <dr-entity-name
              [firstName]="$any(request.u.fn)"
              [lastName]="$any(request.u.ln)"
              [subtitle]="$any(request.u.email || request.u.ph)"
              [imgUrl]="$any(request.u.aXS)"
              [color]="$any(request.u.ac)"
              (click)="openUser($any(request.u.id))"
            ></dr-entity-name>

            <div class="actions">
              <dr-button
                (click)="approveUserRequest(request)"
                [label]="'connections.approve_connect' | translate"
                color="success"
                size="small"
                expand="block"
              ></dr-button>
              <dr-button
                (click)="rejectUserRequest(request)"
                [label]="'connections.reject_connect' | translate"
                color="danger"
                size="small"
                expand="block"
              ></dr-button>
            </div>
          } @else if (request.c) {
            <dr-entity-name
              [firstName]="$any(request.c.nm)"
              [subtitle]="$any(request.c.nch)"
              [imgUrl]="$any(request.c.lXS)"
              [color]="$any(request.c.lc)"
              (click)="openCompany($any(request.c.id))"
            ></dr-entity-name>

            <div class="actions">
              <dr-button
                (click)="approveCompanyRequest(request)"
                [label]="'connections.approve_connect' | translate"
                color="success"
                size="small"
                expand="block"
              ></dr-button>
              <dr-button
                (click)="rejectCompanyRequest(request)"
                [label]="'connections.reject_connect' | translate"
                color="danger"
                size="small"
                expand="block"
              ></dr-button>
            </div>
          }
        </div>
      </ion-item>
    } @empty {
      <dr-empty
        [message]="'connections.no_requests_yet' | translate"
      ></dr-empty>
    }
  } @else {
    <dr-skeleton></dr-skeleton>
  }

  @if (requests()?.canLoadMore) {
    <ion-infinite-scroll (ionInfinite)="expand()">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  }
</ion-content>

@if (requests()?.error; as error) {
  <ion-footer>
    <dr-error-footer
      [message]="error"
      (tryAgain)="reloadNewRequests()"
    ></dr-error-footer>
  </ion-footer>
}
