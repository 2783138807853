<!--fixme check translates keys-->
<div class="row">
  <dr-text color="medium">
    <span class="nowrap">{{ 'company.list.link' | translate }}:</span>
  </dr-text>
  @if (company.l) {
    <dr-link [underline]="true">
      <a [href]="company.l" target="_blank">{{ company.l }}</a>
    </dr-link>
  } @else {
    <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
  }
</div>

<div class="row">
  <dr-text color="medium">
    <span class="nowrap">{{ 'company.list.employees_min' | translate }}:</span>
  </dr-text>
  @if (company.emn) {
    <dr-text>
      <span>{{ company.emn }}</span>
    </dr-text>
  } @else {
    <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
  }
</div>

<div class="row">
  <dr-text color="medium">
    <span class="nowrap">{{ 'company.list.employees_max' | translate }}:</span>
  </dr-text>
  @if (company.emx) {
    <dr-text>
      <span>{{ company.emx || '-' }}</span>
    </dr-text>
  } @else {
    <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
  }
</div>

<div class="row">
  <dr-text color="medium">
    <span class="nowrap">{{ 'company.list.clients_min' | translate }}:</span>
  </dr-text>
  @if (company.cmn) {
    <dr-text>
      <span>{{ company.cmn }}</span>
    </dr-text>
  } @else {
    <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
  }
</div>

<div class="row">
  <dr-text color="medium">
    <span class="nowrap">{{ 'company.list.clients_max' | translate }}:</span>
  </dr-text>
  @if (company.cmx) {
    <dr-text>
      <span>{{ company.cmx }}</span>
    </dr-text>
  } @else {
    <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
  }
</div>

<div class="row">
  <dr-text color="medium">
    <span class="nowrap">{{ 'field_name.country' | translate }}:</span>
  </dr-text>
  @if (getCountry(company.c); as country) {
    <dr-text>
      <span>{{ country }}</span>
    </dr-text>
  } @else {
    <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
  }
</div>

<div class="row">
  <dr-text color="medium">
    <span class="nowrap">{{ 'field_name.company_place' | translate }}:</span>
  </dr-text>
  @if (company.p) {
    <dr-link [underline]="true">
      <a
        [href]="openLocation(getCountry(company.c) + ' ' + company.p)"
        target="_blank"
        >{{ company.p }}</a
      >
    </dr-link>
  } @else {
    <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
  }
</div>

<div class="row">
  <dr-text color="medium">
    <span class="nowrap">{{ 'field_name.source' | translate }}:</span>
  </dr-text>
  @if (getSource(company.src); as source) {
    <dr-text>
      <span>{{ source.label | translate }}</span>
    </dr-text>
  } @else {
    <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
  }
</div>

@switch (company.s) {
  @case ('pending') {
    <dr-text>
      <h1>{{ 'company.info.expected_actions' | translate }}</h1>
    </dr-text>

    <div class="actions">
      <dr-button
        color="primary"
        expand="full"
        [label]="'company.list.approve_request' | translate"
        (click)="approveModal.present()"
      ></dr-button>

      <dr-button
        color="danger"
        expand="full"
        [label]="'company.list.reject_request' | translate"
        (click)="rejectModal.present()"
      ></dr-button>
    </div>
  }
}

<ng-template #noDataTemplate>
  <dr-text>
    <span>-</span>
  </dr-text>
</ng-template>

<ion-modal #approveModal [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{
          'company.list.approve_request_title' | translate
        }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="approveModal.dismiss()">
            <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" drKeyboard>
      <dr-select
        [interface]="IsWebVersion() ? 'popover' : 'action-sheet'"
        [fControl]="subscriptionPackControl"
        [options]="$any(subscriptionsPacks$ | async)"
        [placeholder]="'company.list.select_subscription' | translate"
        [errors]="
          validationService.appValidationRules.subscriptionErrorMessages()
        "
      ></dr-select>

      <dr-button
        class="modal-btn"
        color="primary"
        expand="block"
        [disabled]="
          !subscriptionPackControl.value || subscriptionPackControl.invalid
        "
        [label]="'company.list.approve_request' | translate"
        (click)="approve()"
      ></dr-button>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal #rejectModal [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{
          'company.list.reject_request_title' | translate
        }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="rejectModal.dismiss()">
            <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" drKeyboard>
      <dr-textarea
        [fControl]="rejectMessageControl"
        [minRows]="5"
        [maxRows]="10"
        [counter]="true"
        [required]="true"
        [maxlength]="2000"
        [label]="'company.list.enter_reject_reason' | translate"
        [errors]="
          validationService.appValidationRules.companyRejectMessageErrorMessages()
        "
        enterKey="done"
      ></dr-textarea>

      <dr-button
        class="modal-btn"
        color="danger"
        expand="block"
        [disabled]="!rejectMessageControl.value || rejectMessageControl.invalid"
        [label]="'company.list.reject_request' | translate"
        (click)="reject()"
      ></dr-button>
    </ion-content>
  </ng-template>
</ion-modal>
