<div class="text-container">
  @if (title) {
    <div>
      <dr-text color="light">
        <span class="title">{{ title }}</span>
      </dr-text>
    </div>
  }

  @if (subtitle) {
    <div>
      <dr-text color="light">
        <span class="subtitle">{{ subtitle }}</span>
      </dr-text>
    </div>
  }
</div>

@if (actionTitle) {
  <div class="action" (click)="action.emit()">
    {{ actionTitle }}
  </div>
}
