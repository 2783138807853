<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      @if (hasSideMenu() && isCurrentEntity()) {
        <ion-menu-button></ion-menu-button>
      } @else {
        <ion-button (click)="navController.back()">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ion-button>
      }
    </ion-buttons>

    @if (isCurrentEntity()) {
      <ion-buttons slot="end">
        <ion-button (click)="addUserConnection()">
          <ion-icon name="add-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    }
  </ion-toolbar>

  <ion-toolbar>
    <ion-searchbar
      (ionInput)="search($any($event.detail.value))"
      [value]="searchText()"
      [debounce]="500"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content [class.no-scroll]="!state().items">
  <dr-loading [show]="connectionActionLoading()"></dr-loading>

  <ion-refresher (ionRefresh)="refresh()" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  @if (state(); as state) {
    @if (state.items; as connections) {
      @for (connection of connections; track $index) {
        @if (connection.u) {
          <ion-item-sliding #sliding [disabled]="!isCurrentEntity()">
            <ion-item>
              <div class="connection-item">
                <dr-entity-name
                  [firstName]="$any(connection.u.fn)"
                  [lastName]="$any(connection.u.ln)"
                  [subtitle]="$any(connection.u.email || connection.u.ph)"
                  [imgUrl]="$any(connection.u.aXS)"
                  [color]="$any(connection.u.ac)"
                  (click)="openUser($any(connection.u.id))"
                ></dr-entity-name>
              </div>
            </ion-item>

            @if (isCurrentEntity()) {
              <ion-item-options
                (ionSwipe)="
                  targetConnection.set(connection);
                  sliding.close();
                  confirmRemoveConnection.open()
                "
                side="end"
              >
                <ion-item-option
                  (click)="
                    targetConnection.set(connection);
                    sliding.close();
                    confirmRemoveConnection.open()
                  "
                  color="danger"
                  expandable
                >
                  <ion-icon color="light" name="trash" slot="top"></ion-icon>
                  <dr-text color="light">{{
                    'connections.delete' | translate
                  }}</dr-text>
                </ion-item-option>

                <!--                <ion-item-option-->
                <!--                  (click)="createEvent(); sliding.close()"-->
                <!--                  color="success"-->
                <!--                  expandable-->
                <!--                >-->
                <!--                  <ion-icon-->
                <!--                    color="light"-->
                <!--                    name="calendar-outline"-->
                <!--                    slot="top"-->
                <!--                  ></ion-icon>-->
                <!--                  <dr-text color="light">{{-->
                <!--                    'events.create' | translate-->
                <!--                  }}</dr-text>-->
                <!--                </ion-item-option>-->
              </ion-item-options>
            }
          </ion-item-sliding>
        }
      } @empty {
        @if (connectionListLoading()) {
          <dr-loading
            [inner]="true"
            [show]="true"
            [hasBackdrop]="false"
          ></dr-loading>
        } @else {
          <dr-empty
            [message]="
              (searchText()
                ? 'connections.no_connections_found'
                : 'connections.no_connections_yet'
              ) | translate
            "
          ></dr-empty>
        }
      }

      @if (state.canLoadMore) {
        <ion-infinite-scroll (ionInfinite)="expand()">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      }
    } @else {
      <dr-skeleton type="profile"></dr-skeleton>
    }
  }
</ion-content>

@if (state().error; as error) {
  <ion-footer>
    <dr-error-footer
      [message]="error"
      (tryAgain)="tryAgain()"
    ></dr-error-footer>
  </ion-footer>
}

<dr-confirm
  #confirmRemoveConnection
  (confirm)="delete()"
  [title]="'connections.confirm_delete_connection' | translate"
></dr-confirm>
