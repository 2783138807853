import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  PushNotificationsService,
  ResetCurrentUserDataAction,
} from '@dr/utils';
import {
  MenuController,
  ModalController,
  NavController,
  PopoverController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { catchError, filter, first, Observable, tap } from 'rxjs';
import {
  AuthApiService,
  AuthData,
  providerClosedErrorCode,
  SignUpData,
} from '../../services/auth-api.service';
import { AuthActions, AuthActionsName } from './auth.actions';

export interface AuthStateModel {
  token?: string;
  signInError?: string;
  signUpError?: string;
  codeVerificationError?: string;
  resendCodeVerificationError?: string;
  forgotPasswordError?: string;
  verificationEmail?: string;
  verificationCode?: string;
  resetPasswordError?: string;
}

const defaults: AuthStateModel = {};

@State<AuthStateModel>({
  name: AuthActionsName,
  defaults,
})
@Injectable()
export class AuthState {
  @Select(AuthState.token) token$!: Observable<string | undefined>;
  @Select(AuthState.isAuthenticated) isAuthenticated$!: Observable<boolean>;
  @Select(AuthState.verificationEmail) verificationEmail$!: Observable<string>;
  @Select(AuthState.signInError) signInError$!: Observable<string | undefined>;
  @Select(AuthState.signUpError) signUpError$!: Observable<string | undefined>;
  @Select(AuthState.codeVerificationError)
  codeVerificationError$!: Observable<string>;
  @Select(AuthState.resendCodeVerificationError)
  resendCodeVerificationError$!: Observable<string>;
  @Select(AuthState.forgotPasswordError)
  forgotPasswordError$!: Observable<string>;
  @Select(AuthState.resetPasswordError)
  resetPasswordError$!: Observable<string>;
  @Select(
    actionsExecuting([
      AuthActions.SignUpWithCredentials,
      AuthActions.VerifySignUpWithCode,
      AuthActions.ResendCodeVerification,
      AuthActions.SignInWithCredentials,
      AuthActions.SignInWithApple,
      AuthActions.SignInWithGoogle,
      AuthActions.ForgotPassword,
      AuthActions.VerifyForgotPasswordWithCode,
      AuthActions.ResetPassword,
    ])
  )
  loading$!: Observable<boolean>;

  tokenOnes$ = this.token$.pipe(filter(Boolean), first());

  isAuthenticated = toSignal(this.isAuthenticated$);

  constructor(
    private authApiService: AuthApiService,
    private store: Store,
    private translateService: TranslateService,
    private pushNotificationsService: PushNotificationsService,
    private navController: NavController,
    private popoverController: PopoverController,
    private menuController: MenuController,
    private modalController: ModalController
  ) {}

  @Selector()
  static token(state: AuthStateModel) {
    return state?.token;
  }

  @Selector()
  private static verificationEmail(state: AuthStateModel) {
    return state?.verificationEmail;
  }

  @Selector()
  private static codeVerificationError(state: AuthStateModel) {
    return state?.codeVerificationError;
  }

  @Selector()
  private static resendCodeVerificationError(state: AuthStateModel) {
    return state?.resendCodeVerificationError;
  }

  @Selector()
  private static forgotPasswordError(state: AuthStateModel) {
    return state?.forgotPasswordError;
  }

  @Selector()
  private static resetPasswordError(state: AuthStateModel) {
    return state?.resetPasswordError;
  }

  @Selector()
  private static isAuthenticated(state: AuthStateModel) {
    return !!state?.token;
  }

  @Selector()
  private static signInError(state: AuthStateModel) {
    return state?.signInError;
  }

  @Selector()
  private static signUpError(state: AuthStateModel) {
    return state?.signUpError;
  }

  updateVerificationEmail(email: string) {
    return this.store.dispatch(new AuthActions.UpdateVerificationEmail(email));
  }

  signUpWithCredentials$(data: SignUpData) {
    return this.store.dispatch(new AuthActions.SignUpWithCredentials(data));
  }

  resetSignUpData() {
    this.store.dispatch(new AuthActions.ResetSignUpData());
  }

  verifySignUpWithCode$(code: string) {
    return this.store.dispatch(new AuthActions.VerifySignUpWithCode(code));
  }

  resendCodeVerification$() {
    return this.store.dispatch(new AuthActions.ResendCodeVerification());
  }

  resetCodeVerificationData() {
    this.store.dispatch(new AuthActions.ResetCodeVerificationData());
  }

  signInWithCredentials$(data: AuthData) {
    return this.store.dispatch(new AuthActions.SignInWithCredentials(data));
  }

  signInWithGoogle$() {
    return this.store.dispatch(new AuthActions.SignInWithGoogle());
  }

  signInWithApple$() {
    return this.store.dispatch(new AuthActions.SignInWithApple());
  }

  resetSignInData() {
    this.store.dispatch(new AuthActions.ResetSignInData());
  }

  logOut$() {
    return this.store.dispatch(new AuthActions.Logout());
  }

  forgotPassword$() {
    return this.store.dispatch(new AuthActions.ForgotPassword());
  }

  resetForgotPasswordData(): void {
    this.store.dispatch(new AuthActions.ResetForgotPasswordData());
  }

  clearResetPasswordData(): void {
    this.store.dispatch(new AuthActions.ClearResetPasswordData());
  }

  resetPassword$(password: string) {
    return this.store.dispatch(new AuthActions.ResetPassword(password));
  }

  verifyForgotPasswordWithCode$(code: string) {
    return this.store.dispatch(
      new AuthActions.VerifyForgotPasswordWithCode(code)
    );
  }

  @Action(AuthActions.UpdateVerificationEmail)
  private _updateVerificationEmail(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.UpdateVerificationEmail
  ) {
    ctx.patchState({
      verificationEmail: action.email,
    });
  }

  @Action(AuthActions.SignUpWithCredentials)
  private _signUpWithCredentials$(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SignUpWithCredentials
  ) {
    ctx.patchState({
      signUpError: undefined,
    });

    return this.authApiService.signUpWithCredentials$(action.data).pipe(
      catchError((signUpError) => {
        ctx.patchState({
          signUpError,
        });

        throw signUpError;
      })
    );
  }

  @Action(AuthActions.ResetSignUpData)
  private _resetSignUpData$(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      signUpError: undefined,
    });
  }

  @Action(AuthActions.VerifySignUpWithCode)
  private _verifySignUpWithCode$(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.VerifySignUpWithCode
  ) {
    const email = this.getVerificationEmail(ctx, 'codeVerificationError');

    ctx.patchState({
      codeVerificationError: undefined,
    });

    return this.authApiService
      .verifySignUp$({
        email,
        code: action.code,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            token: response.token,
          });
        }),
        catchError((codeVerificationError) => {
          ctx.patchState({
            codeVerificationError,
          });

          throw codeVerificationError;
        })
      );
  }

  @Action(AuthActions.ResendCodeVerification)
  private _resendCodeVerification$(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      codeVerificationError: undefined,
    });

    const email = this.getVerificationEmail(ctx, 'resendCodeVerificationError');

    return this.authApiService
      .resendCodeVerification$({
        email,
      })
      .pipe(
        tap(() => {
          ctx.patchState({
            resendCodeVerificationError: undefined,
          });
        }),
        catchError((resendCodeVerificationError) => {
          ctx.patchState({
            resendCodeVerificationError,
          });

          throw resendCodeVerificationError;
        })
      );
  }

  @Action(AuthActions.ResetCodeVerificationData)
  private _resetCodeVerificationData$(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      codeVerificationError: undefined,
      resendCodeVerificationError: undefined,
    });
  }

  @Action(AuthActions.SignInWithCredentials)
  private _signInWithCredentials$(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SignInWithCredentials
  ) {
    ctx.patchState({
      signInError: undefined,
    });

    return this.signIn$(
      ctx,
      this.authApiService.signInWithCredentials$(action.data)
    );
  }

  @Action(AuthActions.ResetSignInData)
  private _resetSignInData$(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      signInError: undefined,
    });
  }

  @Action(AuthActions.SignInWithGoogle)
  private _signInWithGoogle(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      signInError: undefined,
    });

    return this.signIn$(ctx, this.authApiService.signInWithGoogle$());
  }

  @Action(AuthActions.SignInWithApple)
  private _signInWithApple(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      signInError: undefined,
    });

    return this.signIn$(ctx, this.authApiService.signInWithApple$());
  }

  @Action(AuthActions.Logout)
  private _logout$(ctx: StateContext<AuthStateModel>) {
    const token = ctx.getState().token;

    ctx.patchState({
      token: undefined,
    });

    this.store.dispatch(new ResetCurrentUserDataAction());

    this.navController.navigateRoot('/auth', {
      animated: false,
    });

    this.pushNotificationsService.disable();

    this.popoverController.dismiss();
    this.menuController.close();
    this.modalController.dismiss();

    return this.authApiService.logout$(token as string);
  }

  @Action(AuthActions.ForgotPassword)
  private _forgotPassword$(ctx: StateContext<AuthStateModel>) {
    const email = this.getVerificationEmail(ctx, 'forgotPasswordError');

    ctx.patchState({
      forgotPasswordError: undefined,
    });

    return this.authApiService.forgotPassword$(email).pipe(
      catchError((forgotPasswordError) => {
        ctx.patchState({
          forgotPasswordError,
        });

        throw forgotPasswordError;
      })
    );
  }

  @Action(AuthActions.ResetForgotPasswordData)
  private _resetForgotPasswordData(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      forgotPasswordError: undefined,
    });
  }

  @Action(AuthActions.VerifyForgotPasswordWithCode)
  private _verifyForgotPasswordWithCode$(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.VerifyForgotPasswordWithCode
  ) {
    const email = this.getVerificationEmail(ctx, 'codeVerificationError');

    ctx.patchState({
      codeVerificationError: undefined,
    });

    return this.authApiService
      .verifyForgotPassword$({
        email,
        code: action.code,
      })
      .pipe(
        tap(() =>
          ctx.patchState({
            verificationCode: action.code,
          })
        ),
        catchError((codeVerificationError) => {
          ctx.patchState({
            codeVerificationError,
          });

          throw codeVerificationError;
        })
      );
  }

  @Action(AuthActions.ResetPassword)
  private _resetPassword$(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.ResetPassword
  ) {
    const email = this.getVerificationEmail(ctx, 'resetPasswordError');
    const code = this.getVerificationCode(ctx, 'resetPasswordError');

    ctx.patchState({
      resetPasswordError: undefined,
    });

    return this.authApiService
      .resetPassword$({
        email,
        code,
        password: action.password,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            token: response.token,
          });
        }),
        catchError((resetPasswordError) => {
          ctx.patchState({
            resetPasswordError,
          });

          throw resetPasswordError;
        })
      );
  }

  private signIn$(
    ctx: StateContext<AuthStateModel>,
    obs$: Observable<{
      token: string;
    }>
  ): Observable<{
    token: string;
  }> {
    return obs$.pipe(
      tap(({ token }) => {
        ctx.patchState({
          token: token,
        });
      }),
      catchError((signInError) => {
        if (signInError !== providerClosedErrorCode) {
          ctx.patchState({
            signInError,
          });
        }

        throw signInError;
      })
    );
  }

  @Action(AuthActions.ClearResetPasswordData)
  private _clearResetPasswordData(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      resetPasswordError: undefined,
    });
  }

  private getVerificationEmail(
    ctx: StateContext<AuthStateModel>,
    actionErrorKey: keyof AuthStateModel
  ): string {
    const email = ctx.getState().verificationEmail;

    if (!email) {
      const error = 'auth.find_email_error';

      ctx.patchState({
        [actionErrorKey]: this.translateService.instant(error),
      });

      throw error;
    }

    return email;
  }

  private getVerificationCode(
    ctx: StateContext<AuthStateModel>,
    actionErrorKey: keyof AuthStateModel
  ): string {
    const email = ctx.getState().verificationCode;

    if (!email) {
      const error = 'auth.find_code_error';
      ctx.patchState({
        [actionErrorKey]: this.translateService.instant(error),
      });

      throw error;
    }

    return email;
  }
}
