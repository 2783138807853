import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MyCompaniesState } from '@dr/company';
import {
  ConfirmComponent,
  EmptyComponent,
  EntityNameComponent,
  ErrorFooterComponent,
  LoadingComponent,
  SkeletonComponent,
  TextComponent,
} from '@dr/ui';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectionStateType } from '../../../states/connections.actions';
import { ConnectionsList } from '../connections-list';

@Component({
  selector: 'dr-company-to-companies-connections-list',
  templateUrl: './connections-companies-list.component.html',
  styleUrls: ['./connections-companies-list.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    ConfirmComponent,
    ErrorFooterComponent,
    TranslateModule,
    LoadingComponent,
    EmptyComponent,
    EntityNameComponent,
    SkeletonComponent,
    TextComponent,
  ],
})
export class CompanyToCompaniesConnectionsListComponent extends ConnectionsList {
  readonly myCompaniesState = inject(MyCompaniesState);

  override connectionType = signal<ConnectionStateType>('company');
  override hasSideMenu = signal<boolean>(true);

  readonly currentCompany = toSignal(this.myCompaniesState.activeCompany$);
  readonly isCurrentEntity = computed(
    () => this.currentCompany()?.id === this.activatedRouteId()
  );
}
