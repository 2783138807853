import { Component, input, output } from '@angular/core';
import { ButtonComponent, SkeletonComponent } from '@dr/ui';
import { TranslateModule } from '@ngx-translate/core';
import { Connection } from '../../../interfaces/connection';

@Component({
  selector: 'dr-add-connection-action',
  templateUrl: './add-connection-action.component.html',
  styleUrls: ['./add-connection-action.component.scss'],
  standalone: true,
  imports: [ButtonComponent, TranslateModule, SkeletonComponent],
})
export class AddConnectionActionComponent {
  connectionStatus = input<Connection['s'] | undefined>();
  currentUserId = input();
  requestedUserId = input();
  currentCompanyId = input();
  requestedCompanyId = input();
  loaded = input();

  cancelRequest = output<void>();
  approveConnection = output<void>();
  requestConnection = output<void>();
}
