<ion-header collapse="fade">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="goBack()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="content">
    <dr-text class="title" [centered]="true">
      <div>{{ 'subscriptions.selection.title' | translate }}</div>
    </dr-text>

    <div class="title-border"></div>

    <dr-text class="subtitle" [centered]="true" color="medium">
      <div>{{ 'subscriptions.selection.subtitle' | translate }}</div>
    </dr-text>

    @if ((myCompaniesState.activeCompany$ | async)?.sp; as subscriptionsPack) {
      <dr-company-subscriptions-pack
        [subscriptionsPack]="subscriptionsPack"
        (selectSubscription)="subscribe($event)"
      ></dr-company-subscriptions-pack>
    }

    <dr-text [centered]="true" color="medium">
      <div>{{ 'subscriptions.selection.not_sure_question' | translate }}</div>
      <div>
        {{ 'subscriptions.selection.dont_hesitate' | translate }}
        <dr-link (click)="contactSupport()">{{
          'subscriptions.selection.ask_advice' | translate
        }}</dr-link>
        {{ 'subscriptions.selection.our_experts' | translate }}
      </div>
    </dr-text>
  </div>
</ion-content>
