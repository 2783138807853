import { AsyncPipe } from '@angular/common';
import { Component, computed, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AuthState } from '@dr/auth';
import { QrCodeComponent, QRCodeType } from '@dr/barcode';
import {
  ButtonComponent,
  ConfirmComponent,
  LoadingComponent,
  TextComponent,
} from '@dr/ui';
import { formatUserName, CurrentUserState, UserCompany } from '@dr/user';
import { ToastService } from '@dr/utils';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, finalize, switchMap } from 'rxjs';
import { MyCompaniesState } from '../../states/my-companies.state';
import { CompanyRejectedComponent } from '../company-rejected/company-rejected.component';
import { CompanySurveyFinishComponent } from '../company-survey-finish/company-survey-finish.component';
import { CompanyApiService } from '../../services/company-api.service';
import { MyCompaniesListComponent } from '../my-companies-list/my-companies-list.component';

@Component({
  selector: 'dr-my-companies',
  templateUrl: 'my-companies.component.html',
  styleUrls: ['my-companies.component.scss'],
  imports: [
    IonicModule,
    TranslateModule,
    ConfirmComponent,
    AsyncPipe,
    QrCodeComponent,
    TextComponent,
    ButtonComponent,
    LoadingComponent,
    CompanyRejectedComponent,
    CompanySurveyFinishComponent,
    MyCompaniesListComponent,
  ],
  standalone: true,
})
export class MyCompaniesComponent {
  readonly formatUserName = formatUserName;

  targetRequest = signal<UserCompany | undefined>(undefined);
  loading = signal<boolean>(false);

  profile = toSignal(this.currentUserState.currentUser$);
  qrParams = computed(() => ({
    [QRCodeType.user]: this.profile()?.qr,
  }));

  private destroyRef = inject(DestroyRef);

  constructor(
    public myCompaniesState: MyCompaniesState,
    public currentUserState: CurrentUserState,
    private navController: NavController,
    private authState: AuthState,
    private companyApiService: CompanyApiService,
    private toastService: ToastService
  ) {}

  createRequest() {
    this.navController.navigateRoot('/company/survey', {
      animated: false,
    });
  }

  contactSupport() {
    this.targetRequest.set(undefined);
    setTimeout(() => {
      this.navController.navigateRoot('/contact-form', {
        animated: false,
      });
    }, 200);
  }

  setActiveCompany(id: string) {
    this.myCompaniesState
      .setActiveCompany$(id, true)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.navController.navigateRoot('/', {
          animated: false,
        });
      });
  }

  delete() {
    const id = this.targetRequest()?.id;

    if (!id) {
      return;
    }

    this.loading.set(true);

    this.companyApiService
      .deleteRequest$(id)
      .pipe(
        switchMap(() => this.currentUserState.check$()),
        catchError((error) => {
          this.toastService.showToast({
            color: 'danger',
            message: error,
            skipTranslation: true,
            duration: 2000,
            position: 'top',
          });

          throw error;
        }),
        finalize(() => {
          this.loading.set(false);
        })
      )
      .subscribe(() => {
        this.toastService.showToast({
          color: 'success',
          message: 'company.survey.delete_request_success_message',
          duration: 2000,
          position: 'top',
        });

        this.targetRequest.set(undefined);
      });
  }

  logout(): void {
    this.authState
      .logOut$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
