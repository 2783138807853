import { PaginationParams } from '@dr/utils';
import { Connection, ConnectionType } from '../interfaces/connection';

export const ConnectionsStateActionsName = 'Connections';

export type ConnectionStateType = ConnectionType | 'request';

export class CountNewRequests {
  static readonly type = `[${ConnectionsStateActionsName}] Count New Requests`;
}

export class SetCurrentEntity {
  static readonly type = `[${ConnectionsStateActionsName}] Set Current Entity`;

  constructor(
    public entityId: string,
    public type: ConnectionType
  ) {}
}

export class CountConnections {
  static readonly type = `[${ConnectionsStateActionsName}] Count Connections`;

  constructor(
    public entityId: string,
    public type: ConnectionType,
    public reload?: boolean
  ) {}
}

export class GetConnectionStatus {
  static readonly type = `[${ConnectionsStateActionsName}] Get Connection Status`;

  constructor(
    public entityId: string,
    public type: ConnectionType,
    public reload?: boolean
  ) {}
}

export class GetEntityProfile {
  static readonly type = `[${ConnectionsStateActionsName}] Get Entity Profile`;

  constructor(
    public entityId: string,
    public type: ConnectionType,
    public reload?: boolean
  ) {}
}

export class LoadConnectionsList {
  static readonly type = `[${ConnectionsStateActionsName}] Load Connections List`;

  constructor(
    public entityId: string,
    public type: ConnectionStateType,
    public pagination: PaginationParams,
    public reload?: boolean
  ) {}
}

export class ExpandConnectionsList {
  static readonly type = `[${ConnectionsStateActionsName}] Expand Connections List`;

  constructor(
    public entityId: string,
    public type: ConnectionStateType,
    public pagination: PaginationParams
  ) {}
}

export class DeleteConnection {
  static readonly type = `[${ConnectionsStateActionsName}] Delete Connection`;

  constructor(
    public type: ConnectionType,
    public connection: Connection,
    public skipRequest?: boolean
  ) {}
}

export class AddConnection {
  static readonly type = `[${ConnectionsStateActionsName}] Add Connection`;

  constructor(
    public type: ConnectionStateType,
    public connection: Connection
  ) {}
}

export class ApproveConnection {
  static readonly type = `[${ConnectionsStateActionsName}] Approve Connection`;

  constructor(
    public entityId: string,
    public type: ConnectionType
  ) {}
}

export class ConnectionApproved {
  static readonly type = `[${ConnectionsStateActionsName}] Connection Approved`;

  constructor(
    public entityId: string,
    public type: ConnectionType
  ) {}
}

export class RejectConnection {
  static readonly type = `[${ConnectionsStateActionsName}] Reject Connection`;

  constructor(
    public entityId: string,
    public type: ConnectionType
  ) {}
}

export class ConnectionRejected {
  static readonly type = `[${ConnectionsStateActionsName}] Connection Rejected`;

  constructor(
    public entityId: string,
    public type: ConnectionType
  ) {}
}

export class RequestConnection {
  static readonly type = `[${ConnectionsStateActionsName}] Request Connection`;

  constructor(
    public entityId: string,
    public type: ConnectionType
  ) {}
}

export class ConnectionRequested {
  static readonly type = `[${ConnectionsStateActionsName}] Connection Requested`;

  constructor(
    public entityId: string,
    public type: ConnectionType
  ) {}
}

export class AddConnectionByQr {
  static readonly type = `[${ConnectionsStateActionsName}] Add Connection By Qr`;

  constructor(
    public qrId: string,
    public type: ConnectionType
  ) {}
}

export class AddConnectionRequest {
  static readonly type = `[${ConnectionsStateActionsName}] Add Connection Request`;

  constructor(public connection: Connection) {}
}
