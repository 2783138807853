<ion-modal [backdropDismiss]="false">
  <ng-template>
    <div class="wrapper">
      @if (title) {
        <dr-text [centered]="true">
          <div>{{ title }}</div>
        </dr-text>
      }

      <div class="buttons">
        <dr-button
          color="medium"
          fill="outline"
          [label]="'Cancel' | translate"
          (click)="reject.emit(); close('cancel')"
        ></dr-button>
        <dr-button
          [color]="confirmButtonColor"
          [label]="'Yes' | translate"
          (click)="confirm.emit(); close('confirm')"
        ></dr-button>
      </div>
    </div>
  </ng-template>
</ion-modal>
