export const fileToBase64 = async (file: File): Promise<string> => {
  const initialFile = file;

  try {
    if (file.type === 'image/heic') {
      const heic2any = await import('heic2any');
      const blob = (await heic2any.default({
        blob: file,
        toType: 'image/jpeg',
      })) as File;

      if (Array.isArray(blob)) {
        file = blob[0];
      } else {
        file = blob;
      }
    }
  } catch {
    file = initialFile;
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const base64ToFileSync = (
  string64: string,
  fileName: string,
  type: string
) => {
  const imageContent = atob(string64.replace(/.+,/, ''));
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  const blob = new Blob([buffer], { type });

  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type,
  });
};
