<dr-loading
  [show]="!!(notificationsState.notificationsActionsLoading$ | async)"
></dr-loading>

@if (loading) {
  <dr-skeleton type="avatar-text"></dr-skeleton>
} @else {
  @if (!notifications?.length) {
    <dr-empty [message]="'list.empty' | translate"></dr-empty>
  } @else {
    <div class="notifications-container">
      @for (notification of notifications; track notification.id) {
        <dr-notification-item
          [notification]="notification"
          (read)="read.emit(notification)"
        ></dr-notification-item>
      }
    </div>
  }
}
