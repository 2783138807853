import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { IonColor } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-hint',
  templateUrl: 'hint.component.html',
  styleUrls: ['hint.component.scss'],
  standalone: true,
  imports: [IonicModule, TextComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintComponent {
  @Input({ required: true }) text!: string;
  @Input() color?: IonColor = 'dark';
  @Input() iconColor?: IonColor = 'secondary';

  @HostBinding('class')
  @Input()
  bgColor?: IonColor;
}
