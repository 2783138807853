import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { LocalizedDatePipe } from '@dr/utils';
import { IonicModule, IonPopover } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-date',
  templateUrl: 'date.component.html',
  styleUrls: ['date.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TextComponent,
    TranslateModule,
    ReactiveFormsModule,
    LocalizedDatePipe,
  ],
})
export class DateComponent<V> {
  @ViewChild(IonPopover) ionPopover!: IonPopover;

  @Input({ required: true }) fControl!: FormControl<V | null>;
  @Input() label?: string;
  @Input() disabled?: boolean;

  @Output() changed: EventEmitter<V | null> = new EventEmitter<V | null>();

  clear() {
    this.fControl.patchValue(null);
    this.changed.emit(null);
  }

  showPopover(event: MouseEvent) {
    this.ionPopover.present(event);
  }
}
