<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons collapse="fade" slot="start">
      <ion-button (click)="addCompanyDialog.open()">
        <ion-icon color="primary" size="large" name="add-outline"></ion-icon>
      </ion-button>
      <ion-button (click)="qrModal.present()">
        <ion-icon name="qr-code"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ion-label>{{ 'company.my_companies.title' | translate }}</ion-label>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <dr-loading [show]="loading()"></dr-loading>

  @if (currentUserState.currentUser$ | async; as currentUser) {
    <ion-header collapse="condense">
      <ion-toolbar>
        <ion-title size="large">
          <ion-label>
            <div class="label">
              {{ 'company.my_companies.title' | translate }}
            </div>
          </ion-label>
        </ion-title>

        @if (currentUser.mcs?.length) {
          <ion-buttons slot="end">
            <ion-button (click)="addCompanyDialog.open()">
              <ion-icon
                color="primary"
                size="large"
                name="add-outline"
              ></ion-icon>
            </ion-button>
            <ion-button (click)="qrModal.present()">
              <ion-icon name="qr-code"></ion-icon>
            </ion-button>
          </ion-buttons>
        }
      </ion-toolbar>
    </ion-header>

    <dr-my-companies-list
      (setRequestCompany)="targetRequest.set($event)"
      (setActiveCompany)="setActiveCompany($event.id)"
      (addCompany)="addCompanyDialog.open()"
      (showQr)="qrModal.present()"
    ></dr-my-companies-list>
  }
</ion-content>

<ion-footer [translucent]="true" collapse="fade">
  <ion-toolbar>
    <ion-title>
      <ion-label (click)="logoutDialog.open()" color="medium">
        <small>
          {{ 'logout.label' | translate }}
        </small>
      </ion-label>
    </ion-title>
  </ion-toolbar>
</ion-footer>

<dr-confirm
  #addCompanyDialog
  [title]="'company.list.create_message' | translate"
  (confirm)="createRequest()"
></dr-confirm>

<dr-confirm
  #logoutDialog
  [title]="'logout.confirmation' | translate"
  (confirm)="logout()"
></dr-confirm>

<ion-modal #qrModal class="qr-modal">
  <ng-template>
    <ion-content>
      @if (profile(); as profile) {
        <dr-qr-code
          (click)="qrModal.dismiss()"
          [data]="qrParams()"
          [name]="formatUserName(profile)"
          [imageUrl]="profile.aXS"
          [color]="profile.ac"
        ></dr-qr-code>
      }
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal
  [isOpen]="!!targetRequest()"
  [backdropDismiss]="false"
  class="request-status-modal"
>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button (click)="targetRequest.set(undefined)">
            <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      @if (targetRequest()?.s === 'pending') {
        <dr-company-survey-finish
          [request]="$any(targetRequest())"
          (closed)="targetRequest.set(undefined)"
          (support)="contactSupport()"
          (deleted)="delete()"
        ></dr-company-survey-finish>
      } @else if (targetRequest()?.s === 'rejected') {
        <dr-company-company-rejected
          [request]="$any(targetRequest())"
          (closed)="targetRequest.set(undefined)"
          (support)="contactSupport()"
          (deleted)="delete()"
        ></dr-company-company-rejected>
      }
    </ion-content>
  </ng-template>
</ion-modal>
