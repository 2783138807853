import { Injectable } from '@angular/core';
import { LocalizationState } from '@dr/localization';
import { ThemeState } from '@dr/theme';
import { ResetCurrentUserDataAction } from '@dr/utils';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { catchError, filter, first, map, Observable, of, tap } from 'rxjs';
import { User } from '../../interfaces/user';
import {
  UpdateUserData,
  UserApiService,
} from '../../services/user-api.service';
import {
  CurrentUserActions,
  CurrentUserActionsName,
} from './current-user.actions';

export interface CurrentUserStateModel {
  user: User | null;
  checkError: string | null;
  checked: boolean;
  updateError: string | null;
}

const defaults: CurrentUserStateModel = {
  user: null,
  checked: false,
  checkError: null,
  updateError: null,
};

@State<CurrentUserStateModel>({
  name: CurrentUserActionsName,
  defaults,
})
@Injectable()
export class CurrentUserState {
  @Select(CurrentUserState) state$!: Observable<CurrentUserStateModel>;

  @Select(
    actionsExecuting([
      CurrentUserActions.Update,
      CurrentUserActions.UploadAvatar,
      CurrentUserActions.DeleteAvatar,
    ])
  )
  updating$!: Observable<boolean>;

  @Select(actionsExecuting([CurrentUserActions.Me]))
  checking$!: Observable<boolean>;

  currentUser$ = this.state$.pipe(
    map((state) => state.user),
    filter(Boolean)
  );

  checkedUserOnes$ = this.state$.pipe(
    filter((state) => state.checked && !!state.user),
    first(),
    map((state) => state.user)
  ) as Observable<User>;

  userOnce$ = this.state$.pipe(
    filter((state) => !!state.user),
    first(),
    map((state) => state.user)
  ) as Observable<User>;

  constructor(
    private userApiService: UserApiService,
    private store: Store,
    private themeState: ThemeState,
    private localizationState: LocalizationState
  ) {}

  check$() {
    return this.store.dispatch(new CurrentUserActions.Me());
  }

  update$(newUserData: UpdateUserData) {
    return this.store.dispatch(new CurrentUserActions.Update(newUserData));
  }

  uploadAvatar$(file: File) {
    return this.store.dispatch(new CurrentUserActions.UploadAvatar(file));
  }

  deleteAvatar$() {
    return this.store.dispatch(new CurrentUserActions.DeleteAvatar());
  }

  setFBT$(token: string) {
    return this.store.dispatch(new CurrentUserActions.SetFBT(token));
  }

  @Action(CurrentUserActions.Me)
  private _me$(ctx: StateContext<CurrentUserStateModel>) {
    ctx.patchState({
      checkError: null,
    });

    return this.userApiService.me$().pipe(
      tap((user) => {
        ctx.patchState({
          user,
          checked: true,
        });

        if (user.d) {
          this.localizationState.setAppLanguage$(user.d.l);
          this.themeState.setAppTheme$(user.d.t);
        }
      }),
      catchError((err) => {
        ctx.patchState({
          checked: false,
          checkError: err,
        });

        throw err;
      })
    );
  }

  @Action(CurrentUserActions.UploadAvatar)
  private _uploadAvatar$(
    ctx: StateContext<CurrentUserStateModel>,
    action: CurrentUserActions.UploadAvatar
  ) {
    ctx.patchState({
      updateError: null,
    });

    return this.userApiService.uploadAvatar$(action.file).pipe(
      tap((user) => {
        ctx.patchState({
          user,
        });
      }),
      catchError((err) => {
        ctx.patchState({
          updateError: err,
        });

        throw err;
      })
    );
  }

  @Action(CurrentUserActions.DeleteAvatar)
  private _deleteAvatar$(ctx: StateContext<CurrentUserStateModel>) {
    ctx.patchState({
      updateError: null,
    });

    return this.userApiService.deleteAvatar$().pipe(
      tap((user) => {
        ctx.patchState({
          user,
        });
      }),
      catchError((err) => {
        ctx.patchState({
          updateError: err,
        });

        throw err;
      })
    );
  }

  @Action(CurrentUserActions.Update)
  private _update$(
    ctx: StateContext<CurrentUserStateModel>,
    action: CurrentUserActions.Update
  ) {
    const hasNewValue = Object.keys(action.newUserData).some(
      (key) =>
        ctx.getState().user?.[key as keyof User] !==
        action.newUserData[key as keyof User]
    );

    if (!hasNewValue) {
      return of(undefined);
    }

    ctx.patchState({
      updateError: null,
    });

    return this.userApiService.updateCurrentUser$(action.newUserData).pipe(
      tap((user) => {
        ctx.patchState({
          user,
        });

        if (action.newUserData.device?.theme) {
          this.themeState.setAppTheme$(user.d.t);
        }

        if (action.newUserData.device?.language) {
          this.localizationState.setAppLanguage$(user.d.l);
        }
      }),
      catchError((err) => {
        ctx.patchState({
          updateError: err,
        });

        throw err;
      })
    );
  }

  @Action(CurrentUserActions.SetFBT)
  private _setFBT$(
    ctx: StateContext<CurrentUserStateModel>,
    action: CurrentUserActions.SetFBT
  ) {
    return this.userApiService
      .ping$({
        fbt: action.token,
      })
      .pipe(
        tap(() => {
          const user = ctx.getState().user;

          ctx.patchState({
            user: {
              ...user,
              d: {
                ...user?.d,
                pne: user?.d?.pne === null ? true : user?.d?.pne,
              },
            } as User,
          });
        })
      );
  }

  @Action(ResetCurrentUserDataAction)
  private reset(ctx: StateContext<CurrentUserStateModel>): void {
    ctx.setState(defaults);
  }
}
