import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseInput } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dr-select',
  templateUrl: 'select.component.html',
  styleUrls: ['select.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule, ReactiveFormsModule, TextComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<V> extends BaseInput<V> {
  @Input({ required: true }) options!: { label: string; value: V }[];
  @Input() interface?: 'action-sheet' | 'popover';
  @Input() multiple?: boolean;

  @Output() changed: EventEmitter<V> = new EventEmitter<V>();
}
