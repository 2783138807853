import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { CompanyApiService } from './services/company-api.service';
import { CompaniesState } from './states/companies.state';
import { CompanyDetailsState } from './states/company-details.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([CompaniesState, CompanyDetailsState]),
  ],
  providers: [CompanyApiService],
})
export class CompaniesModule {}
