@if (sent()) {
  <dr-success-info
    [message]="'contact_form.message_sent' | translate"
  ></dr-success-info>
}

<dr-loading
  [show]="(contactFormState.topicCreating$ | async) === true"
  [inner]="true"
></dr-loading>

@if (!sent()) {
  <form
    [formGroup]="formGroup"
    [class.disabled]="(contactFormState.topicCreating$ | async) === true"
  >
    <dr-text>
      <div
        class="title"
        [innerText]="'contact_form.questions_message' | translate"
      ></div>
    </dr-text>

    <dr-select
      #subject
      [interface]="IsWebVersion() ? 'popover' : 'action-sheet'"
      [fControl]="subjectFormControl"
      [options]="subjects"
      [required]="true"
      [label]="'Subject' | translate"
      [errors]="
        validationService.appValidationRules.contactFormSubjectErrorMessages()
      "
    ></dr-select>

    <dr-textarea
      #message
      [fControl]="messageFormControl"
      [minRows]="5"
      [maxRows]="10"
      [counter]="true"
      [required]="true"
      [maxlength]="2000"
      [label]="'Message' | translate"
      [errors]="
        validationService.appValidationRules.contactFormMessageErrorMessages()
      "
      enterKey="done"
    ></dr-textarea>

    <dr-file-uploader [fControl]="fileControl"></dr-file-uploader>

    <div class="footer-container">
      @if (contactFormState.topicCreateError$ | async; as topicCreateError) {
        <dr-text color="danger" [centered]="true">
          <small>{{ topicCreateError }}</small>
        </dr-text>
      }

      <dr-button
        fill="solid"
        type="submit"
        expand="block"
        [label]="'Submit' | translate"
        (click)="submitForm()"
      ></dr-button>
    </div>
  </form>
}
