import { AsyncPipe } from '@angular/common';
import { Component, signal } from '@angular/core';
import { QrCodeComponent } from '@dr/barcode';
import { ButtonComponent, ConfirmComponent, LoadingComponent } from '@dr/ui';
import { CurrentUserState, UserCompany } from '@dr/user';
import { KeyboardDirective, ToastService } from '@dr/utils';
import { IonicModule, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, finalize, switchMap } from 'rxjs';
import {
  CompanySurveyFormComponent,
  SurveyFormData,
} from '../company-survey-form/company-survey-form.component';
import { CompanySurveyIntroComponent } from '../company-survey-intro/company-survey-intro.component';
import { CompanySurveyFinishComponent } from '../company-survey-finish/company-survey-finish.component';
import { CompanyApiService } from '../../services/company-api.service';

export type CompanySurveyStep = 'intro' | 'form' | 'finish';

@Component({
  selector: 'dr-company-survey',
  templateUrl: 'company-survey.component.html',
  styleUrls: ['company-survey.component.scss'],
  imports: [
    IonicModule,
    CompanySurveyFormComponent,
    KeyboardDirective,
    LoadingComponent,
    CompanySurveyIntroComponent,
    CompanySurveyFinishComponent,
    TranslateModule,
    ButtonComponent,
    ConfirmComponent,
    AsyncPipe,
    QrCodeComponent,
  ],
  standalone: true,
})
export class CompanySurveyComponent {
  loading = signal<boolean>(false);

  step = signal<CompanySurveyStep | undefined>('intro');

  request = signal<UserCompany | undefined>(undefined);

  constructor(
    private navController: NavController,
    private currentUserState: CurrentUserState,
    private companyApiService: CompanyApiService,
    private toastService: ToastService
  ) {}

  finish(data: SurveyFormData) {
    this.loading.set(true);

    this.companyApiService
      .createCompany$({
        name: data.name,
        link: data.link,
        min_employees: data.employeesSize.emn,
        max_employees: data.employeesSize.emx,
        min_clients: data.clientsSize.cmn,
        max_clients: data.clientsSize.cmx,
        niche: data.niche,
        logo: data.logo,
        country: data.country,
        source: data.source,
        place: data.place,
      })
      .pipe(
        switchMap((request) => {
          this.request.set(request);

          return this.currentUserState.check$();
        }),
        catchError((error) => {
          this.toastService.showToast({
            color: 'danger',
            message: error,
            skipTranslation: true,
            duration: 2000,
            position: 'top',
          });

          throw error;
        }),
        finalize(() => this.loading.set(false))
      )
      .subscribe(() => {
        this.step.set('finish');
      });
  }

  delete(request: UserCompany) {
    this.loading.set(true);

    this.companyApiService
      .deleteRequest$(request.id)
      .pipe(
        switchMap(() => this.currentUserState.check$()),
        catchError((error) => {
          this.toastService.showToast({
            color: 'danger',
            message: error,
            skipTranslation: true,
            duration: 2000,
            position: 'top',
          });

          throw error;
        }),
        finalize(() => {
          this.loading.set(false);
        })
      )
      .subscribe(() => {
        this.toastService.showToast({
          color: 'success',
          message: 'company.survey.delete_request_success_message',
          duration: 2000,
          position: 'top',
        });

        this.navController.navigateRoot('/company/select', {
          animated: false,
        });
      });
  }

  close() {
    this.navController.navigateRoot('/company/select', {
      animated: false,
    });
  }

  contactSupport() {
    this.navController.navigateForward('/contact-form');
  }
}
