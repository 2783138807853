@if (calculateConnections(); as calculateConnections) {
  <div class="connections-item" (click)="openUsers()">
    <dr-text class="count">{{ calculateConnections.u | shortNumber }}</dr-text>
    <dr-text class="label" color="medium">
      <!--      {{ 'profile.users' | translate }}-->
      {{ 'connections.connections' | translate }}
    </dr-text>
  </div>
  <!--  <div class="connections-item" (click)="openCompanies()">-->
  <!--    <dr-text class="count">{{ calculateConnections.c | shortNumber }}</dr-text>-->
  <!--    <dr-text class="label" color="medium">{{-->
  <!--      'profile.companies' | translate-->
  <!--    }}</dr-text>-->
  <!--  </div>-->
} @else {
  <dr-skeleton
    [repeat]="1"
    class="connections-item"
    type="counter"
  ></dr-skeleton>
  <!--  <dr-skeleton-->
  <!--    [repeat]="1"-->
  <!--    class="connections-item"-->
  <!--    type="counter"-->
  <!--  ></dr-skeleton>-->
}
