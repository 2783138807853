import { inject } from '@angular/core';
import { NavController } from '@ionic/angular';
import { tap } from 'rxjs';
import { AppFeatures } from '../services/features-api.service';
import { AppFeaturesState } from '../states/app-features/app-features.state';

export const hasFeature = (key: keyof AppFeatures) => {
  const appFeaturesState = inject(AppFeaturesState);
  const navController = inject(NavController);

  return appFeaturesState.isFeatureAvailable$(key).pipe(
    tap((available) => {
      if (!available) {
        navController.navigateRoot('/', {
          animated: false,
          replaceUrl: true,
        });
      }
    })
  );
};
