import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { AvatarComponent, TextComponent } from '@dr/ui';
import { IonicModule } from '@ionic/angular';
import { NgxQrcodeStylingModule, Options } from 'ngx-qrcode-styling';
import { hashQrCodeDada } from '../../utils/qr-hash';

@Component({
  selector: 'dr-qr-code',
  templateUrl: 'qr-code.component.html',
  styleUrls: ['qr-code.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    NgxQrcodeStylingModule,
    AsyncPipe,
    TextComponent,
    AvatarComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeComponent implements OnInit {
  @Input({ required: true }) data!: Record<string, unknown>;
  @Input() imageUrl?: string;
  @Input() name?: string;

  color = input<string>();

  config = signal<Options | undefined>(undefined);

  ngOnInit() {
    const size = Math.min(
      250,
      document.body.getBoundingClientRect().width - 50
    );

    this.config.set({
      type: 'svg',
      width: size,
      height: size,
      data: hashQrCodeDada(JSON.stringify(this.data)),
      image: '/assets/images/qr-logo.png',
      backgroundOptions: {
        color: 'transparent',
      },
      imageOptions: {
        margin: 5,
        imageSize: 0.3,
      },
      cornersSquareOptions: {
        type: 'extra-rounded',
      },
      dotsOptions: {
        type: 'rounded',
        gradient: {
          type: 'radial',
          colorStops: [
            { color: '#92949c', offset: 0.05 },
            { color: '#383a3e', offset: 1 },
          ],
        },
      },
    });
  }
}
