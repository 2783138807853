import { Injectable } from '@angular/core';
import { CurrentUserState, UserSocketService } from '@dr/user';
import {
  ActiveCompanyChangedAction,
  ResetCurrentUserDataAction,
  ToastService,
} from '@dr/utils';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { catchError, first, Observable, of, tap } from 'rxjs';
import { Company, SubscriptionPeriod } from '../interfaces/company';
import {
  CompanyApiService,
  UpdateCompanyInfoData,
} from '../services/company-api.service';
import {
  CancelUpdateActiveCompanyInfoAction,
  CheckActiveCompanyAction,
  MyCompaniesActionsName,
  ResetActiveCompanyAction,
  SetActiveCompanyAction,
  SubscribeAction,
  UpdateActiveCompanyInfoAction,
} from './my-companies.actions';

export interface MyCompaniesStateModel {
  active?: Company;
  activeChecked?: boolean;
}

const defaults: MyCompaniesStateModel = {};

@State<MyCompaniesStateModel>({
  name: MyCompaniesActionsName,
  defaults,
})
@Injectable()
export class MyCompaniesState {
  @Select(MyCompaniesState.activeCompany) activeCompany$!: Observable<Company>;
  @Select(MyCompaniesState.activeCompanyChecked)
  activeCompanyChecked$!: Observable<boolean>;

  @Select(
    actionsExecuting([
      SetActiveCompanyAction,
      UpdateActiveCompanyInfoAction,
      CancelUpdateActiveCompanyInfoAction,
      SubscribeAction,
      ResetActiveCompanyAction,
    ])
  )
  loading$!: Observable<boolean>;

  constructor(
    private companyApiService: CompanyApiService,
    private toastService: ToastService,
    private store: Store,
    private userSocketService: UserSocketService,
    private currentUserState: CurrentUserState
  ) {
    this.listenSocketsEvents();
  }

  @Selector()
  static activeCompany(state: MyCompaniesStateModel) {
    return state.active;
  }

  @Selector()
  static activeCompanyChecked(state: MyCompaniesStateModel) {
    return !!state.activeChecked;
  }

  setActiveCompany$(id?: string, emitChanges?: boolean) {
    return (
      id
        ? this.store.dispatch(new SetActiveCompanyAction(id))
        : this.store.dispatch(new ResetActiveCompanyAction())
    ).pipe(
      tap(() => {
        if (emitChanges) {
          this.store.dispatch(new ActiveCompanyChangedAction());
        }
      })
    );
  }

  checkActiveCompany$() {
    return this.store.dispatch(new CheckActiveCompanyAction());
  }

  updateActiveCompanyInfo$(data: UpdateCompanyInfoData) {
    return this.store.dispatch(new UpdateActiveCompanyInfoAction(data));
  }

  cancelUpdateActiveCompanyInfo$() {
    return this.store.dispatch(new CancelUpdateActiveCompanyInfoAction());
  }

  subscribe$(subscriptionId: string, period: SubscriptionPeriod) {
    return this.store.dispatch(new SubscribeAction(subscriptionId, period));
  }

  @Action(SetActiveCompanyAction)
  @Action(CheckActiveCompanyAction)
  @Action(SubscribeAction)
  @Action(UpdateActiveCompanyInfoAction)
  @Action(CancelUpdateActiveCompanyInfoAction)
  private _subscribe$(
    ctx: StateContext<MyCompaniesStateModel>,
    action:
      | SetActiveCompanyAction
      | SubscribeAction
      | UpdateActiveCompanyInfoAction
      | CancelUpdateActiveCompanyInfoAction
  ) {
    const activeCompany = ctx.getState().active;

    let obs$: Observable<Company>;

    if (action instanceof SetActiveCompanyAction) {
      obs$ = this.companyApiService.getCompanyProfileId$(action.id);
    } else if (action instanceof CheckActiveCompanyAction) {
      const id = ctx.getState().active?.id;

      if (!id) {
        return of(undefined);
      }

      obs$ = this.companyApiService.getCompanyProfileId$(id);
    } else {
      if (!activeCompany) {
        throw 'no active company';
      }

      if (action instanceof SubscribeAction) {
        obs$ = this.companyApiService.subscribe$(
          activeCompany.id,
          action.subscriptionId,
          action.period
        );
      } else if (action instanceof UpdateActiveCompanyInfoAction) {
        obs$ = this.companyApiService.requestChanges$(
          activeCompany.id,
          action.data
        );
      } else {
        obs$ = this.companyApiService.deleteRequestChanges$(activeCompany.id);
      }
    }

    return obs$.pipe(
      tap((company) => {
        ctx.patchState({
          active: company,
          activeChecked:
            action instanceof SetActiveCompanyAction ||
            action instanceof CheckActiveCompanyAction ||
            ctx.getState().activeChecked,
        });
      }),
      catchError((error) => {
        this.toastService.showToast({
          color: 'danger',
          message: error,
          skipTranslation: true,
          duration: 2000,
          position: 'top',
        });

        throw error;
      })
    );
  }

  @Action(ResetActiveCompanyAction)
  private _resetCompany(ctx: StateContext<MyCompaniesStateModel>) {
    ctx.patchState({
      active: undefined,
    });
  }

  @Action(ResetCurrentUserDataAction)
  private reset$(ctx: StateContext<MyCompaniesStateModel>): void {
    ctx.setState(defaults);
  }

  private listenSocketsEvents(): void {
    [
      'company_reject_request',
      'company_approve_request',
      'refresh_company',
    ].forEach((event) => {
      this.userSocketService.onEvent(event, () => {
        this.currentUserState.check$();
      });
    });

    this.userSocketService.onEvent(
      'refresh_company',
      (payload: { id: string }) => {
        this.activeCompany$.pipe(first()).subscribe((activeCompany) => {
          if (activeCompany?.id === payload.id) {
            this.checkActiveCompany$();
          }
        });
      }
    );
  }
}
