import { Injectable } from '@angular/core';
import { Camera } from '@capacitor/camera';
import { PermissionStatus } from '@capacitor/camera/dist/esm/definitions';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { PickMediaResult } from '@capawesome/capacitor-file-picker/dist/esm/definitions';
import { ToastService, ValidationService } from '@dr/utils';
import { TranslateService } from '@ngx-translate/core';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';
import {
  delay,
  from,
  fromEvent,
  map,
  Observable,
  Subject,
  switchMap,
  take,
  tap,
  zip,
} from 'rxjs';

@Injectable()
export class FileService {
  protected readonly MAX_FILE_SIZE: number = 10485760;

  constructor(
    private toastService: ToastService,
    private validationService: ValidationService,
    private translateService: TranslateService
  ) {}

  attachImages$(): Observable<File[]> {
    if (!Capacitor.isNativePlatform()) {
      return this.webPick$(this.validationService.formats.supportedImageTypes);
    }

    return this.nativePick$(() =>
      from(
        FilePicker.pickImages({
          skipTranscoding: true,
        })
      )
    );
  }

  attachMediaFromGallery$(): Observable<File[]> {
    if (!Capacitor.isNativePlatform()) {
      return this.webPick$([
        ...this.validationService.formats.supportedVideoTypes,
        ...this.validationService.formats.supportedImageTypes,
      ]);
    }

    return this.nativePick$(() =>
      from(
        FilePicker.pickMedia({
          skipTranscoding: true,
        })
      )
    );
  }

  private webPick$(
    fileTypes: string[],
    params?: {
      maxSize?: number;
      multiple?: boolean;
    }
  ): Observable<File[]> {
    const input = document.createElement('input');
    const files$ = new Subject<File[]>();

    input.type = 'file';
    input.style.position = 'fixed';
    input.style.opacity = '0';
    input.style.zIndex = '-9999';
    input.style.left = '-1000%';
    input.style.top = '-1000%';
    input.setAttribute('accept', fileTypes.join(','));

    if (params?.multiple) {
      input.setAttribute('multiple', '');
    }

    document.body.appendChild(input);

    input.addEventListener('change', async (e: unknown) => {
      let files = Array.from(
        (e as { target: { files: File[] } }).target.files
      ) as File[];

      if (!files.length) {
        files$.error({
          message: 'Choose file canceled.',
        });

        return;
      }

      files = files.filter((file) =>
        this.isMediaSizeAvailable(file, params?.maxSize || this.MAX_FILE_SIZE)
      );

      files$.next(files);

      document.body.removeChild(input);
    });

    fromEvent(window, 'focus')
      .pipe(
        take(1),
        delay(500),
        tap(() => {
          files$.error({
            message: 'Choose file canceled.',
          });
        })
      )
      .subscribe();

    input.click();

    return files$.pipe(take(1));
  }

  private nativePick$(
    filePickFunc$: () => Observable<PickMediaResult>,
    maxSize: number = this.MAX_FILE_SIZE
  ): Observable<File[]> {
    const pickFromGallery$ = ({
      photos,
    }: PermissionStatus): Observable<File[]> => {
      if (photos === 'denied') {
        this.toastService.showToast({
          color: 'warning',
          message: 'open_gallery.warn_message',
          duration: 99999999,
          position: 'bottom',
          buttons: [
            {
              text: this.translateService.instant('open_gallery.open'),
              role: 'info',
              handler: () => {
                NativeSettings.open({
                  optionAndroid: AndroidSettings.ApplicationDetails,
                  optionIOS: IOSSettings.App,
                });
              },
            },
            {
              text: this.translateService.instant('open_gallery.close'),
              role: 'cancel',
            },
          ],
        });

        throw 'permissions denied';
      }

      if (photos === 'granted' || photos === 'limited') {
        return from(filePickFunc$()).pipe(
          switchMap((results) => {
            const files = results.files.filter((file) =>
              this.isMediaSizeAvailable(file, maxSize)
            );

            if (!files.length) {
              throw 'No valid files attached';
            }

            return zip(
              files.map((file) =>
                from(fetch(Capacitor.convertFileSrc(file.path as string))).pipe(
                  switchMap((response) =>
                    from(response.blob()).pipe(
                      map(
                        (blob) =>
                          new File([blob], file.name, {
                            type: file.mimeType,
                          })
                      )
                    )
                  )
                )
              )
            ).pipe(
              map((files) => {
                return files.filter((file) =>
                  this.isMediaSizeAvailable(file, maxSize)
                );
              })
            );
          })
        );
      }

      return from(
        Camera.requestPermissions({
          permissions: ['photos'],
        })
      ).pipe(switchMap(pickFromGallery$));
    };

    return from(Camera.checkPermissions()).pipe(switchMap(pickFromGallery$));
  }

  private isMediaSizeAvailable(
    file: { size: number },
    maxSize: number
  ): boolean {
    const sizeAvailable = file.size <= maxSize;

    if (!sizeAvailable) {
      this.toastService.showToast({
        message: 'Maximum possible size: X MB',
        messageParams: {
          size: Math.ceil(maxSize / (1024 * 1024)),
        },
      });
    }

    return sizeAvailable;
  }
}
