import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { UserApiService } from '../../services/user-api.service';
import { CurrentUserState } from './current-user.state';

@NgModule({
  imports: [NgxsModule.forFeature([CurrentUserState])],
  providers: [UserApiService],
})
export class CurrentUserModule {}
