import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TextComponent } from '../../text/text.component';

@Component({
  selector: 'dr-success-info',
  templateUrl: 'success.component.html',
  styleUrls: ['success.component.scss'],
  standalone: true,
  imports: [IonicModule, TextComponent],
})
export class SuccessComponent {
  @Input() message?: string;
}
