import { Injectable } from '@angular/core';
import { UserSocketService } from '@dr/user';
import { ToastService } from '@dr/utils';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {
  catchError,
  combineLatest,
  first,
  map,
  Observable,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CompanyItem } from '../components/companies-manager/companies-manager.component';
import {
  Company,
  CompanyPaidStatus,
  CompanyStatus,
} from '../interfaces/company';
import { CompanyApiService } from '../services/company-api.service';
import {
  AddToListAction,
  ApprovePendingCompanyAction,
  CompanyActionsName,
  DeleteFromListAction,
  DeleteCompaniesByIdAction,
  CountByStatusAction,
  RejectPendingCompanyAction,
  LoadListByStatusAction,
  LoadListByPaidStatusAction,
  ReloadListByStatusAction,
  ReloadListByPaidStatusAction,
  ExtendListByStatusAction,
  ExtendListByPaidStatusAction,
} from './companies.actions';

export interface CompanyByStatusState {
  data?: CompanyItem[];
  error?: string;
  canLoadMore?: boolean;
  loaded?: boolean;
  count?: number;
  search?: string;
}

export type CompanyStateModel = Record<
  CompanyStatus | CompanyPaidStatus,
  CompanyByStatusState
>;

const defaults: CompanyStateModel = {
  pending: {},
  approved: {},
  rejected: {},
  paid: {},
  'non-paid': {},
};

export const CompanyStoreKeys = [];

@State<CompanyStateModel>({
  name: CompanyActionsName,
  defaults,
})
@Injectable()
export class CompaniesState {
  @Select(CompaniesState.pending) pending$!: Observable<CompanyByStatusState>;
  @Select(CompaniesState.approved) approved$!: Observable<CompanyByStatusState>;
  @Select(CompaniesState.rejected) rejected$!: Observable<CompanyByStatusState>;
  @Select(CompaniesState.paid) paid$!: Observable<CompanyByStatusState>;
  @Select(CompaniesState.nonPaid) nonPaid$!: Observable<CompanyByStatusState>;

  @Select(
    actionsExecuting([
      LoadListByStatusAction,
      LoadListByPaidStatusAction,
      ReloadListByStatusAction,
      ReloadListByPaidStatusAction,
      ExtendListByStatusAction,
      ExtendListByPaidStatusAction,
    ])
  )
  listLoading$!: Observable<boolean>;

  @Select(
    actionsExecuting([
      RejectPendingCompanyAction,
      ApprovePendingCompanyAction,
      DeleteCompaniesByIdAction,
    ])
  )
  pendingCompanyProcessing$!: Observable<boolean>;

  constructor(
    private companyApiService: CompanyApiService,
    private toastService: ToastService,
    private store: Store,
    private userSocketService: UserSocketService
  ) {}

  @Selector()
  static pending(state: CompanyStateModel) {
    return state.pending;
  }

  @Selector()
  static approved(state: CompanyStateModel) {
    return state.approved;
  }

  @Selector()
  static rejected(state: CompanyStateModel) {
    return state.rejected;
  }

  @Selector()
  static paid(state: CompanyStateModel) {
    return state.paid;
  }

  @Selector()
  static nonPaid(state: CompanyStateModel) {
    return state['non-paid'];
  }

  listenSocketsEvents(): void {
    this.userSocketService.onEvent(
      'company_new_request',
      (company: Company) => {
        this.store.dispatch(new AddToListAction('pending', company));
      }
    );

    [
      'company_delete_request',
      'company_reject_request',
      'company_approve_request',
    ].forEach((event) => {
      this.userSocketService.onEvent(event, (data: { id: string }) => {
        this.store.dispatch(new DeleteFromListAction('pending', data.id));
      });
    });
  }

  loadListByStatus$(status: CompanyStatus, search?: string) {
    return this.store.dispatch(new LoadListByStatusAction(status, search));
  }

  loadListByPaidStatus$(status: CompanyPaidStatus, search?: string) {
    return this.store.dispatch(new LoadListByPaidStatusAction(status, search));
  }

  reloadListByStatus$(status: CompanyStatus, search?: string) {
    return this.store.dispatch(new ReloadListByStatusAction(status, search));
  }

  reloadListByPaidStatus$(status: CompanyPaidStatus, search?: string) {
    return this.store.dispatch(
      new ReloadListByPaidStatusAction(status, search)
    );
  }

  extendListByStatus$(status: CompanyStatus, take: number) {
    return this.store.dispatch(new ExtendListByStatusAction(status, take));
  }

  extendListByPaidStatus$(status: CompanyPaidStatus, take: number) {
    return this.store.dispatch(new ExtendListByPaidStatusAction(status, take));
  }

  countByStatus$() {
    return this.store.dispatch(new CountByStatusAction());
  }

  rejectPendingCompany$(id: string, message: string) {
    return this.store.dispatch(new RejectPendingCompanyAction(id, message));
  }

  approvePendingCompany$(companyId: string, packId: string) {
    return this.store.dispatch(
      new ApprovePendingCompanyAction(companyId, packId)
    );
  }

  delete$(ids: string[], list: CompanyStatus | CompanyPaidStatus) {
    return this.store.dispatch(new DeleteCompaniesByIdAction(ids, list));
  }

  findCompanyById$(id: string): Observable<Company | undefined> {
    return combineLatest([
      this.pending$,
      this.approved$,
      this.rejected$,
      this.paid$,
      this.nonPaid$,
    ]).pipe(
      first(),
      map(([pending, approved, rejected, paid, nonPaid]) => {
        return [
          ...(pending?.data || []),
          ...(approved?.data || []),
          ...(rejected?.data || []),
          ...(paid?.data || []),
          ...(nonPaid?.data || []),
        ].find((company) => company.id === id);
      })
    );
  }

  @Action(AddToListAction)
  private _addToList$(
    ctx: StateContext<CompanyStateModel>,
    action: AddToListAction
  ) {
    ctx.patchState({
      [action.status]: {
        ...ctx.getState().pending,
        count: (ctx.getState().pending.count || 0) + 1,
        data: [action.company, ...(ctx.getState().pending.data || [])],
      },
    });
  }

  @Action(DeleteFromListAction)
  private _deleteFromList$(
    ctx: StateContext<CompanyStateModel>,
    action: DeleteFromListAction
  ) {
    const pendingList = ctx.getState()[action.status].data;

    if (!pendingList) {
      return;
    }

    ctx.patchState({
      [action.status]: {
        ...ctx.getState().pending,
        count: Math.max((ctx.getState().pending.count || 0) - 1, 0),
        data: ctx
          .getState()
          .pending.data?.filter((company) => company.id !== action.id),
      },
    });
  }

  @Action(DeleteCompaniesByIdAction)
  private _deleteCompaniesById$(
    ctx: StateContext<CompanyStateModel>,
    action: DeleteCompaniesByIdAction
  ) {
    const data = ctx.getState()[action.list].data;

    if (!data) {
      return;
    }

    return this.companyApiService.delete$(action.ids).pipe(
      tap(() => {
        ctx.patchState({
          [action.list]: {
            ...ctx.getState()[action.list],
            count: Math.max(
              (ctx.getState()[action.list].count || 0) - action.ids.length,
              0
            ),
            data: ctx
              .getState()
              [
                action.list
              ].data?.filter((company) => !action.ids.includes(company.id)),
          },
        });
      }),
      catchError((error) => {
        this.toastService.showToast({
          color: 'danger',
          message: error,
          skipTranslation: true,
          duration: 2000,
          position: 'top',
        });

        throw error;
      })
    );
  }

  @Action(ReloadListByStatusAction)
  private _reloadListByStatusAction$(
    ctx: StateContext<CompanyStateModel>,
    action: ReloadListByStatusAction
  ) {
    ctx.patchState({
      [action.status]: {
        ...ctx.getState()[action.status],
        loaded: false,
      },
    });

    return this.loadListByStatus$(action.status, action.search);
  }

  @Action(ReloadListByPaidStatusAction)
  private _reloadListByPaidStatusAction$(
    ctx: StateContext<CompanyStateModel>,
    action: ReloadListByPaidStatusAction
  ) {
    ctx.patchState({
      [action.status]: {
        ...ctx.getState()[action.status],
        loaded: false,
      },
    });

    return this.loadListByPaidStatus$(action.status, action.search);
  }

  @Action(LoadListByStatusAction)
  @Action(LoadListByPaidStatusAction)
  private _loadListByStatus$(
    ctx: StateContext<CompanyStateModel>,
    action: LoadListByStatusAction | LoadListByPaidStatusAction
  ) {
    if (ctx.getState()[action.status].loaded) {
      return;
    }

    const search = action.search || '';

    ctx.patchState({
      [action.status]: {
        ...ctx.getState()[action.status],
        loaded: true,
        error: undefined,
        search,
      },
    });

    // const companies = ctx.getState().companies[action.status];
    // const lastTopicId = companies.data?.[companies.data.length - 1]?.id;
    return this.companyApiService
      .getCompanies$(
        action instanceof LoadListByStatusAction
          ? { status: action.status, search }
          : {
              status: 'approved',
              paidStatus: action.status,
              search,
            }
      )
      .pipe(
        tap(({ count, items }) => {
          ctx.patchState({
            [action.status]: {
              ...ctx.getState()[action.status],
              count,
              data: items,
              canLoadMore: count > items.length,
            },
          });
        }),
        catchError((error) => {
          ctx.patchState({
            [action.status]: {
              ...ctx.getState()[action.status],
              error,
            },
          });

          throw error;
        })
      );
  }

  @Action(ExtendListByStatusAction)
  @Action(ExtendListByPaidStatusAction)
  private _extendListByStatus$(
    ctx: StateContext<CompanyStateModel>,
    action: ExtendListByStatusAction | ExtendListByPaidStatusAction
  ) {
    const companiesData = ctx.getState()[action.status]?.data || [];
    const after = companiesData[companiesData.length - 1]?.id;
    const take = action.take;
    const search = ctx.getState()[action.status].search;

    return this.companyApiService
      .getCompanies$(
        action instanceof ExtendListByStatusAction
          ? {
              status: action.status,
              take,
              after,
              search,
            }
          : {
              status: 'approved',
              paidStatus: action.status,
              take,
              after,
              search,
            }
      )
      .pipe(
        tap(({ count, items }) => {
          ctx.patchState({
            [action.status]: {
              ...ctx.getState()[action.status],
              data: [...companiesData, ...items],
              canLoadMore: count > items.length,
            },
          });
        }),
        catchError((error) => {
          ctx.patchState({
            [action.status]: {
              ...ctx.getState()[action.status],
              error,
            },
          });

          throw error;
        })
      );
  }

  @Action(CountByStatusAction)
  private _countByStatus$(ctx: StateContext<CompanyStateModel>) {
    return this.companyApiService.count$().pipe(
      tap((countByStatus) => {
        ctx.patchState(
          Object.keys(countByStatus).reduce(
            (state, status) => ({
              ...state,
              [status]: {
                ...state[status as CompanyStatus],
                count: countByStatus[status as CompanyStatus],
              },
            }),
            ctx.getState()
          )
        );
      }),
      catchError((error) => {
        this.toastService.showToast({
          color: 'danger',
          message: error,
          skipTranslation: true,
          duration: 2000,
          position: 'top',
        });

        throw error;
      })
    );
  }

  @Action(RejectPendingCompanyAction)
  private _rejectPendingCompany$(
    ctx: StateContext<CompanyStateModel>,
    action: RejectPendingCompanyAction
  ) {
    return this.companyApiService.reject$(action.id, action.message).pipe(
      tap(() => {
        ctx.patchState({
          pending: {
            ...ctx.getState().pending,
            count: Math.max(0, (ctx.getState().pending.count || 0) - 1),
            data: ctx
              .getState()
              .pending.data?.filter((request) => request.id !== action.id),
          },
        });
      }),
      catchError((error) => {
        this.toastService.showToast({
          color: 'danger',
          message: error,
          skipTranslation: true,
          duration: 2000,
          position: 'top',
        });

        throw error;
      })
    );
  }

  @Action(ApprovePendingCompanyAction)
  private _approvePendingCompany$(
    ctx: StateContext<CompanyStateModel>,
    action: ApprovePendingCompanyAction
  ) {
    return this.companyApiService
      .approve$(action.companyId, action.packId)
      .pipe(
        withLatestFrom(this.nonPaid$),
        tap(([, nonPaid]) => {
          ctx.patchState({
            pending: {
              ...ctx.getState().pending,
              count: Math.max(0, (ctx.getState().pending.count || 0) - 1),
              data: ctx
                .getState()
                .pending.data?.filter(
                  (request) => request.id !== action.companyId
                ),
            },
          });

          if (nonPaid.loaded) {
            this.reloadListByPaidStatus$('non-paid', nonPaid.search);
          }
        }),
        catchError((error) => {
          this.toastService.showToast({
            color: 'danger',
            message: error,
            skipTranslation: true,
            duration: 2000,
            position: 'top',
          });

          throw error;
        })
      );
  }
}
