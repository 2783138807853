import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegularSuccessResponse } from '@dr/utils';
import { Observable } from 'rxjs';
import {
  CompanySubscription,
  CompanySubscriptionConfig,
  CompanySubscriptionsPack,
} from '../interfaces/company';

export interface CreateCompanySubscriptionsPack {
  label: string;
  annuallyDiscount: number;
  subscriptions: {
    title: string;
    primary: boolean;
    oldPrice: number;
    currentPrice: number;
    annuallyDiscount: number;
    bulletPoints: CompanySubscriptionConfig[];
  }[];
}

export interface UpdateCompanySubscriptionsPack {
  label: string;
  annuallyDiscount: number;
  subscriptions: {
    id?: string;
    primary: boolean;
    title: string;
    oldPrice: number | null;
    currentPrice: number;
    annuallyDiscount: number;
    bulletPoints: CompanySubscriptionConfig[];
  }[];
}

@Injectable()
export class CompanySubscriptionsApiService {
  constructor(private httpClient: HttpClient) {}

  getSubscriptionsPacks$(): Observable<CompanySubscriptionsPack[]> {
    return this.httpClient.get<CompanySubscriptionsPack[]>(
      '/api/company/subscriptions/packs'
    );
  }

  getSubscriptions$(): Observable<CompanySubscription[]> {
    return this.httpClient.get<CompanySubscription[]>(
      '/api/company/subscriptions/list'
    );
  }

  createSubscriptionsPack$(
    data: CreateCompanySubscriptionsPack
  ): Observable<RegularSuccessResponse> {
    return this.httpClient.post<RegularSuccessResponse>(
      '/api/company/subscriptions/packs',
      data
    );
  }

  updateSubscriptionsPack$(
    id: string,
    data: UpdateCompanySubscriptionsPack
  ): Observable<RegularSuccessResponse> {
    return this.httpClient.put<RegularSuccessResponse>(
      `/api/company/subscriptions/packs/${id}`,
      data
    );
  }

  updateSubscription$(
    id: string,
    data: UpdateCompanySubscriptionsPack['subscriptions'][0]
  ): Observable<RegularSuccessResponse> {
    return this.httpClient.put<RegularSuccessResponse>(
      `/api/company/subscriptions/${id}`,
      data
    );
  }

  deleteSubscriptionsPack$(id: string): Observable<RegularSuccessResponse> {
    return this.httpClient.delete<RegularSuccessResponse>(
      `/api/company/subscriptions/packs/${id}`,
      {}
    );
  }

  deleteSubscription$(id: string): Observable<RegularSuccessResponse> {
    return this.httpClient.delete<RegularSuccessResponse>(
      `/api/company/subscriptions/${id}`,
      {}
    );
  }
}
