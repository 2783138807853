import {
  Component,
  computed,
  EventEmitter,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FileUploaderComponent } from '@dr/file';
import {
  ButtonComponent,
  HintComponent,
  InputComponent,
  SelectComponent,
  TextareaComponent,
  TextComponent,
} from '@dr/ui';
import {
  CountyService,
  getIpData,
  IsWebVersion,
  ValidationService,
} from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  companyClientsSize,
  CompanyClientsSize,
} from '../../utils/company-clients-size';
import {
  CompanyEmployeesSize,
  companyEmployeesSize,
} from '../../utils/company-employees-size';
import { companySources } from '../../utils/company-sources';
import {
  companySurveySteps,
  SurveyStep,
  SurveyStepType,
} from '../../utils/company-survey-steps';

export interface SurveyFormData {
  name: string;
  link: string;
  employeesSize: CompanyEmployeesSize['value'];
  clientsSize: CompanyClientsSize['value'];
  niche: string;
  logo: File;
  country: string;
  source: string;
  place: string;
}

@Component({
  selector: 'dr-company-survey-form',
  templateUrl: 'company-survey-form.component.html',
  styleUrls: ['company-survey-form.component.scss'],
  imports: [
    IonicModule,
    TextComponent,
    ButtonComponent,
    TranslateModule,
    InputComponent,
    SelectComponent,
    FileUploaderComponent,
    TextareaComponent,
    HintComponent,
  ],
  providers: [CountyService],
  standalone: true,
})
export class CompanySurveyFormComponent implements OnInit {
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() finish: EventEmitter<SurveyFormData> =
    new EventEmitter<SurveyFormData>();

  readonly IsWebVersion = IsWebVersion;
  readonly employeesSizes = companyEmployeesSize;
  readonly clientsSizes = companyClientsSize;
  readonly steps: SurveyStep[] = companySurveySteps;

  currentStepId = signal<SurveyStepType>('name');
  progress = computed<number>(
    () =>
      this.steps.findIndex((step) => step.id === this.currentStepId()) /
      this.steps.length
  );
  activeControl = computed(() => {
    if (this.currentStepId() === 'name') {
      return this.nameControl;
    } else if (this.currentStepId() === 'link') {
      return this.linkControl;
    } else if (this.currentStepId() === 'employees-size') {
      return this.employeesSizeControl;
    } else if (this.currentStepId() === 'clients-size') {
      return this.clientsSizeControl;
    } else if (this.currentStepId() === 'niche') {
      return this.nicheControl;
    } else if (this.currentStepId() === 'logo') {
      return this.logoControl;
    } else if (this.currentStepId() === 'country') {
      return this.countryControl;
    } else if (this.currentStepId() === 'place') {
      return this.placeControl;
    } else if (this.currentStepId() === 'source') {
      return this.sourceControl;
    }

    return;
  });

  nameControl = new FormControl<string | null>(
    null,
    this.validationService.appValidationRules.companyNameValidators
  );
  linkControl = new FormControl<string | null>(
    null,
    this.validationService.appValidationRules.companyLinkValidators
  );
  employeesSizeControl = new FormControl<CompanyEmployeesSize['value'] | null>(
    null,
    this.validationService.appValidationRules.companySizeValidators
  );
  clientsSizeControl = new FormControl<CompanyClientsSize['value'] | null>(
    null,
    this.validationService.appValidationRules.companySizeValidators
  );
  nicheControl = new FormControl<string | null>(
    null,
    this.validationService.appValidationRules.companyNicheValidators
  );
  logoControl = new FormControl<File | undefined>(undefined, [
    Validators.required,
  ]);
  countryControl = new FormControl<string | null>(
    null,
    this.validationService.appValidationRules.companyCountryValidators
  );
  placeControl = new FormControl<string | null>(
    null,
    this.validationService.appValidationRules.companyPlaceValidators
  );
  sourceControl = new FormControl<string | null>(
    null,
    this.validationService.appValidationRules.companyCountryValidators
  );
  sourceOtherControl = new FormControl<string | null>(
    null,
    this.validationService.appValidationRules.companySourceOtherValidators
  );

  companySources?: { label: string; value: string }[];

  get canGoNext() {
    if (
      this.currentStepId() === 'source' &&
      this.sourceControl.value === 'other'
    ) {
      return this.sourceOtherControl.valid && !!this.sourceOtherControl.value;
    }

    return !!this.activeControl()?.valid && !!this.activeControl()?.value;
  }

  constructor(
    public validationService: ValidationService,
    public countyService: CountyService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    getIpData().then((ipData) => {
      const code = ipData?.country_code?.toLowerCase().trim();

      if (code) {
        this.countryControl.patchValue(code);
      }
    });

    setTimeout(() => {
      this.companySources = companySources.map((source) => ({
        ...source,
        label: this.translateService.instant(source.label),
      }));
    }, 300);
  }

  goToNext(skip?: boolean) {
    if (!skip && this.activeControl()?.invalid) {
      return;
    }

    const currentStep = this.steps.find(
      (step) => step.id === this.currentStepId()
    );

    if (currentStep?.nextId) {
      this.currentStepId.set(currentStep.nextId);
    } else {
      this.finish.next({
        name: this.nameControl.value as string,
        link: this.linkControl.value as string,
        employeesSize: this.employeesSizeControl
          .value as CompanyEmployeesSize['value'],
        clientsSize: this.clientsSizeControl
          .value as CompanyClientsSize['value'],
        niche: this.nicheControl.value as string,
        logo: this.logoControl.value as File,
        country: this.countryControl.value as string,
        source: (this.sourceControl.value === 'other'
          ? this.sourceOtherControl.value
          : this.sourceControl.value) as string,
        place: this.placeControl.value as string,
      });
    }
  }

  goBack() {
    const previousStep = this.steps.find(
      (step) => step.nextId === this.currentStepId()
    );

    if (previousStep) {
      this.currentStepId.set(previousStep.id);
    } else {
      this.closed.emit();
    }
  }
}
