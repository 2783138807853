import { AsyncPipe } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { AttachmentComponent } from '@dr/file';
import { TextComponent } from '@dr/ui';
import { AppEnvironment, AppEnvironmentI } from '@dr/utils';
import { IonicModule } from '@ionic/angular';
import { ContactFormTopicMessage } from '../../interfaces/contact-form';

@Component({
  selector: 'dr-contact-form-message',
  templateUrl: 'contact-form-message.component.html',
  styleUrls: ['contact-form-message.component.scss'],
  standalone: true,
  imports: [IonicModule, TextComponent, AsyncPipe, AttachmentComponent],
})
export class ContactFormMessageComponent {
  @Input({ required: true }) message!: ContactFormTopicMessage;
  @Input() currentUserId?: string;

  constructor(@Inject(AppEnvironment) public appEnvironment: AppEnvironmentI) {}
}
