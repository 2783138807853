import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { IonicModule, IonPopover } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TextComponent } from '../../text/text.component';

@Component({
  selector: 'dr-premium-feature',
  templateUrl: 'premium-feature.component.html',
  styleUrls: ['premium-feature.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule, TextComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PremiumFeatureComponent {
  @ViewChild(IonPopover) ionPopover!: IonPopover;

  @HostBinding('class.centered')
  @Input()
  centered?: boolean = true;
  @Input() premium?: boolean;

  isPopoverOpened = false;

  @HostListener('click', ['$event'])
  click(event: Event) {
    if (this.premium) {
      return;
    }

    this.ionPopover.event = event;
    this.isPopoverOpened = true;
  }
}
