import { HttpClient } from '@angular/common/http';
import { Component, OnInit, signal } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { finalize, first } from 'rxjs';

@Component({
  selector: 'dr-lost-connection',
  templateUrl: 'lost-connection.component.html',
  styleUrls: ['lost-connection.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule],
})
export class LostConnectionComponent implements OnInit {
  zzz = ['Z', 'Z', 'Z'];

  status = signal<ConnectionStatus | undefined>(undefined);
  checking = signal<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  ngOnInit() {
    this.checkNetworkStatus();
  }

  retry() {
    if (this.checking()) {
      return;
    }

    this.checking.set(true);

    this.httpClient
      .get('/api/ping')
      .pipe(
        first(),
        finalize(() => {
          this.checking.set(false);
        })
      )
      .subscribe(() => {
        this.status.set({ connected: true, connectionType: 'unknown' });
      });
  }

  private async checkNetworkStatus() {
    const status = await Network.getStatus();

    this.status.set(status);

    Network.addListener('networkStatusChange', (status) => {
      this.status.set(status);
    });
  }
}
