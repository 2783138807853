@if (requestChange.lXS && requestChange.lXS !== requestChange.ce.lXS) {
  <div class="row">
    <dr-avatar
      size="extra-large"
      [src]="requestChange.ce.lXS"
      [color]="requestChange.ce.lc"
    ></dr-avatar>
    <span> <ion-icon name="arrow-forward-outline"></ion-icon> </span>
    <dr-avatar
      size="extra-large"
      [src]="requestChange.lXS"
      [color]="requestChange.lc"
    ></dr-avatar>
  </div>
}

@if (requestChange.nm !== requestChange.ce.nm) {
  <div class="row">
    <dr-text color="medium">
      <span>{{ 'company_settings.name' | translate }}:</span>
    </dr-text>
    <div class="changes">
      <dr-text class="old">
        <span>{{ requestChange.ce.nm }}</span>
      </dr-text>
      <ion-icon name="arrow-forward-outline"></ion-icon>
      <dr-text>
        <span>{{ requestChange.nm }}</span>
      </dr-text>
    </div>
  </div>
}

@if (requestChange.l !== requestChange.ce.l) {
  <div class="row">
    <dr-text color="medium">
      <span>{{ 'company.list.link' | translate }}:</span>
    </dr-text>
    <div class="changes">
      <dr-link [underline]="true" class="old">
        <a [href]="requestChange.ce.l" target="_blank">{{
          requestChange.ce.l
        }}</a>
      </dr-link>
      <ion-icon name="arrow-forward-outline"></ion-icon>
      <dr-link [underline]="true">
        <a [href]="requestChange.l" target="_blank">{{ requestChange.l }}</a>
      </dr-link>
    </div>
  </div>
}

@if (
  requestChange.emn !== requestChange.ce.emn ||
  requestChange.emx !== requestChange.ce.emx
) {
  <div class="row">
    <dr-text color="medium">
      <span>{{ 'company_settings.employees_size' | translate }}:</span>
    </dr-text>
    @if (requestChange.emn !== requestChange.ce.emn) {
      <div class="changes">
        <dr-text class="old">
          <span>{{ requestChange.ce.emn }}</span>
        </dr-text>
        <ion-icon name="arrow-forward-outline"></ion-icon>
        <dr-text>
          <span>{{ requestChange.emn }}</span>
        </dr-text>
      </div>
    }

    @if (requestChange.emx !== requestChange.ce.emx) {
      <span> - </span>
      <div class="changes">
        <dr-text class="old">
          <span>{{ requestChange.ce.emx }}</span>
        </dr-text>
        <ion-icon name="arrow-forward-outline"></ion-icon>
        <dr-text>
          <span>{{ requestChange.emx }}</span>
        </dr-text>
      </div>
    }
  </div>
}

@if (
  requestChange.cmn !== requestChange.ce.cmn ||
  requestChange.cmx !== requestChange.ce.cmx
) {
  <div class="row">
    <dr-text color="medium">
      <span>{{ 'company_settings.clients_size' | translate }}:</span>
    </dr-text>
    @if (requestChange.cmn !== requestChange.ce.cmn) {
      <div class="changes">
        <dr-text class="old">
          <span>{{ requestChange.ce.cmn }}</span>
        </dr-text>
        <ion-icon name="arrow-forward-outline"></ion-icon>
        <dr-text>
          <span>{{ requestChange.cmn }}</span>
        </dr-text>
      </div>
    }

    @if (requestChange.cmx !== requestChange.ce.cmx) {
      <span> - </span>
      <div class="changes">
        <dr-text class="old">
          <span>{{ requestChange.ce.cmx }}</span>
        </dr-text>
        <ion-icon name="arrow-forward-outline"></ion-icon>
        <dr-text>
          <span>{{ requestChange.cmx }}</span>
        </dr-text>
      </div>
    }
  </div>
}

@if (requestChange.nch !== requestChange.ce.nch) {
  <div class="row">
    <dr-text color="medium">
      <span>{{ 'company_settings.niche' | translate }}:</span>
    </dr-text>
    <div class="changes">
      <dr-text class="old">
        <span>{{ requestChange.ce.nch }}</span>
      </dr-text>
      <ion-icon name="arrow-forward-outline"></ion-icon>
      <dr-text>
        <span>{{ requestChange.nch }}</span>
      </dr-text>
    </div>
  </div>
}

@if (requestChange.c !== requestChange.ce.c) {
  <div class="row">
    <dr-text color="medium">
      <span>{{ 'company_settings.country' | translate }}:</span>
    </dr-text>
    <div class="changes">
      <dr-text class="old">
        <span>{{ getCountry(requestChange.ce.c) }}</span>
      </dr-text>
      <ion-icon name="arrow-forward-outline"></ion-icon>
      <dr-text>
        <span>{{ getCountry($any(requestChange.c)) }}</span>
      </dr-text>
    </div>
  </div>
}

@if (requestChange.p !== requestChange.ce.p) {
  <div class="row">
    <dr-text color="medium">
      <span>{{ 'company.list.link' | translate }}:</span>
    </dr-text>
    <div class="changes">
      <dr-link [underline]="true" class="old">
        <a
          [href]="
            openLocation(
              getCountry(requestChange.ce.c) + ' ' + requestChange.ce.p
            )
          "
          target="_blank"
          >{{ requestChange.ce.p }}</a
        >
      </dr-link>
      <ion-icon name="arrow-forward-outline"></ion-icon>
      <dr-link [underline]="true">
        <a
          [href]="
            openLocation(
              getCountry(requestChange.c || requestChange.ce.c) +
                ' ' +
                requestChange.p
            )
          "
          target="_blank"
          >{{ requestChange.p }}</a
        >
      </dr-link>
    </div>
  </div>
}

<div class="actions">
  <dr-button
    color="primary"
    size="small"
    [label]="'company.request_changes.approve' | translate"
    (click)="confirmDialog.open()"
  ></dr-button>

  <dr-button
    color="danger"
    size="small"
    [label]="'company.request_changes.reject' | translate"
    (click)="rejectChangesModal.present()"
  ></dr-button>
</div>

<dr-confirm
  #confirmDialog
  [title]="'company.request_changes.confirm_approve' | translate"
  (confirm)="approve()"
></dr-confirm>

<ion-modal
  #rejectChangesModal
  [backdropDismiss]="false"
  (didDismiss)="resetRejectModal()"
>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{
          'company.request_changes.reject_changes' | translate
        }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="rejectChangesModal.dismiss()">
            <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" drKeyboard>
      <dr-textarea
        [fControl]="rejectMessageControl"
        [minRows]="5"
        [maxRows]="10"
        [counter]="true"
        [required]="true"
        [maxlength]="2000"
        [label]="'company.request_changes.enter_reject_reason' | translate"
        [errors]="
          validationService.appValidationRules.companyRejectMessageErrorMessages()
        "
        enterKey="done"
      ></dr-textarea>

      <dr-button
        class="modal-btn"
        color="danger"
        expand="block"
        [disabled]="!rejectMessageControl.value || rejectMessageControl.invalid"
        [label]="'company.list.reject_request' | translate"
        (click)="rejectRequest()"
      ></dr-button>
    </ion-content>
  </ng-template>
</ion-modal>
